<spinner *ngIf="isLoading"></spinner>
<p-messages [(value)]="msgs"></p-messages>
<p-toast key="wfMsg"></p-toast>
<form *ngIf="container && !isLoading" (submit)="onSubmit($event)">

    <div class="ui-g">
        <div class="ui-g-12">
            <textarea [rows]="2" class="formTitleInputText" [(ngModel)]="container.data['label']" name="label" pInputTextarea autoResize="autoResize"></textarea>
        </div>
        
        <div class="ui-g-12">
            <label for="description">Description</label>
            <textarea [rows]="3" class="form-control"  pInputTextarea [(ngModel)]="container.data.description" name="description" autoResize="autoResize"></textarea>
        </div>

        <div class="ui-g-6">
            <label for="orderId">Order Id</label>
            <input type="text" class="form-control" [(ngModel)]="container.data.order_id" name="orderId">
        </div>

        <div class="ui-g-6">
            <label for="guid">GUID</label>
            <input type="text" class="form-control" [(ngModel)]="container.guid" name="guid" placeholder="Required" readonly>
        </div>

        <div *ngIf="container.id > 0" class="ui-g-12">
            <history [inputObject]="container" inputType='container'></history>
        </div>
    </div>

    <div class="ui-g">
        <p-accordion class="ui-g-12">
            <!--
            <p-accordionTab header="Availability" [selected]="false">
                <div class="ui-g">
                    <div class="ui-g-6">
                        <label for="availableDate" style="display:block;">Available Date</label>
                        <p-calendar [(ngModel)]="container.availableDate" name="availableDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="expirationDate" style="display:block;">Expiration Date</label>
                        <p-calendar [(ngModel)]="container.expirationDate" name="expirationDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="publishDate" style="display:block;">Publish Date</label>
                        <p-calendar [(ngModel)]="publishDate" name="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                </div>
            </p-accordionTab>
            -->
            <p-accordionTab header="Tags">
                <div class="ui-g">
                    <div class="ui-g-12">
                        <label for="tags">Tags</label>
                        <container-tags-tab #maintags [container]=container [tagTypeFilterList]=tagTypeFilterList [tagTypeExcludeList]="['syndication']"></container-tags-tab>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="ui-g-12">
            <campaign-table itemsPerPage = 20 [containers]="container.containers" [parentContainer] ="container">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'content_group']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
            </campaign-table>
    </div>

    <p-toolbar class="ui-g-12">
        <div class="ui-g-12" style="z-index: 0">
            <button pButton type="submit" label="Save" [disabled]="container.status == 'waiting' || container.status == 'processing'" style="font-size:13px;float:left;margin-right:.5em"></button>
            <button pButton type="button" label="Edit" [disabled]="container.status == 'waiting' || container.status == 'processing'" [routerLink]="['/commerce/campaign', container.id]" style="font-size:13px;float:left;"></button>
            <container-workflow-status [container]="container" (onSimpleStatusChange)="onSimpleStatusChange($event)" (onComplexStatusChange)="onComplexStatusChange($event)"(onStatusTransitionFailure)="onStatusTransitionFailure($event)" style="float:right;margin-right:4px;"></container-workflow-status>

        </div>
    </p-toolbar>

</form>
