<spinner *ngIf="isLoading"></spinner>
<p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="Back" (click)="onCancel($event)" *ngIf="!isCreatingResources && !isUploading" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>  
            </div>
    </p-toolbar>  
</form>

<div class="ui-g-12">
    <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Assets</span>
        </p-header>
        <div class="card ui-g-12" *ngIf="uploadingFiles.length">
            <h1>Uploading</h1>
            <div *ngFor="let file of uploadingFiles">
                <div  *ngIf="!file.complete">
                <label for="title">{{file.name}} - {{file.size}} bytes</label>
                <p-progressBar [value]="file.progress"></p-progressBar>
            </div>
            </div>
        </div>
        <div class="card ui-g-12" *ngIf="uploadedFiles.length">
                <h1>Uploaded</h1>
                <div *ngFor="let file of uploadedFiles">
                    <label for="title">{{file.name}} - {{file.size}} bytes</label>
                    <p-progressBar [value]="file.progress"></p-progressBar>
                </div>
        </div>
        <div class="card ui-g-12" *ngIf="deferredFiles.length">
            <h1>Waiting</h1>
            <div *ngFor="let file of deferredFiles">
                <label for="title">{{file.name}} - {{file.size}} bytes</label>
                <p-progressBar [value]="file.progress"></p-progressBar>
            </div>
        </div>

        <p-fileUpload #uploadComponent name="uploadAssets" class="upload-assets-edit-form" customUpload="true" (uploadHandler)="onUpload($event)" multiple="multiple" *ngIf="!isUploading">
            <ng-template pTemplate="toolbar">
                <hr>
                <div class="ui-g-5">File Name</div>
                <div class="ui-g-2">Size</div>
                <div class="ui-g-5">Tags</div>
            </ng-template>
            <ng-template let-file pTemplate="file">
                <div class="ui-g-12 file-details">
                    <div class="ui-g-5 file-name">{{file.name}}</div>
                    <div class="ui-g-2 file-size">{{getSizeInMegaBytes(file) | number:'1.2-2'}} MB</div>
                    <div class="ui-g-4 file-tags" >
                        <container-resource-tags [fileToUpload]='file' [tagTypeFilterList]="['all']" [hideTagChips]="true" [hideTagLabel]="true"></container-resource-tags>
                    </div>
                    <div class="ui-g-1 file-remove">
                        <button icon="fa fa-close" class="btn-remove-file" pButton type="button" (click)="removeFile(file)"></button>
                    </div>
                </div>
            </ng-template>
        </p-fileUpload>

    </p-panel>
</div>