<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-settings-forms-provider-form-cancel"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-settings-forms-provider-form-save"></button>
                <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['name']" name="name" placeholder="Required" required data-element-id="app-settings-forms-provider-form-name">
                </div>
                <div class="ui-g-6">
                    <label for="origin">MSO Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.mso_id" name="mso_id" placeholder="Required" required data-element-id="app-settings-forms-provider-form-mso-id">
                </div>
                <div class="ui-g-6">
                        <label for="name">Description</label>
                        <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-settings-forms-provider-form-description">
                </div>
                <div class="ui-g-6">
                        <label for="origin">Contact Support Message</label>
                        <input type="text" class="form-control" [(ngModel)]="container.data.contact_support_message" name="contact_support_message" data-element-id="app-settings-forms-provider-form-contact-support-message">
                </div>
            </div>

        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">  
                <div class="ui-g-6">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-settings-forms-provider-form-type">
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-settings-forms-provider-form-guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>
    </div>
</form>
