<page-header title="New " [showSpinner]="isLoading"></page-header>
<div *ngIf="container" class="card card-w-title top-level-card">
  <h1>New Creative</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <creative-form #form [container]="container" (formSubmit)="onSubmit(container)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></creative-form>
    </div>
  </div>
</div>
