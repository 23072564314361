<div class="card card-w-title top-level-card">
    <h1>Origins</h1>

    <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.label', 'data.description']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="caption">
            <div class="clearfix">
                <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                    <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar"/>
                </div>
                <div class="ui-g-12 ui-md-6 ui-lg-8" style="padding:0;">
                    <button pButton type="button" label="Create" [routerLink]="['/settings/origins', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
                </div>
                <spinner *ngIf="isLoading"></spinner>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Label</th>
                <th>Guid</th>
                <th>Description</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-origin>
            <tr>
                <td>{{origin.data.label}}</td>
                <td style="word-break: break-all;">{{origin.guid}}</td>
                <td>{{origin.data.description}}</td>
                <td>
                    <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/settings/origins', origin.id]"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>