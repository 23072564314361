<page-header title="Create Advertisement" [showSpinner]="isLoading" [error]="error"></page-header>

<div class="card card-w-title top-level-card">
    <h1>New Advertisement</h1>

    <div *ngIf="container && !isLoading">
        <p-messages [(value)]="msgs"></p-messages>

        <advertisement-form *ngIf="!isLoading" [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)"></advertisement-form>
    </div>

</div>
