<label for="default_ordering">Client Default Ordering</label>
<p-dropdown  [options]="orderingOptions" 
             (onChange)="onValueChange()" 
             [showClear]="true" 
             appendTo="body"
             placeholder="None"
             [(ngModel)]="selectedOrderingOption" 
             [style]="{'height':'100%','width':'100%'}"
             data-element-id="app-content-forms-view-metadata-form-panel-container-default-ordering">
</p-dropdown>
