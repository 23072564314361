<page-header [showSpinner]="isLoading" [error]="error"></page-header>
<div *ngIf="!isLoading">
    
        <div class="card card-w-title top-level-card">
                <h1>Servers</h1>
            
                <p-table #dt [value]="serverContainers" 
                        [rows]="10" 
                        [paginator]="true" 
                        paginatorPosition="bottom" 
                        selectionMode="single" 
                        (onRowSelect)="onRowSelect($event)" 
                        [(selection)]="container"
                        styleClass="p-datatable-gridlines"
                        [responsive]="true">
                    <ng-template pTemplate="caption">
                            <button pButton type="button" label="Create" [routerLink]="['/server', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
                            <spinner *ngIf="isLoading"></spinner>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Id</th>
                            <th style="width:5%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-server>
                        <tr>
                            <td>{{server.data.name}}</td>
                            <td>{{server.data.server_type}}</td>
                            <td>{{server.data.description}}</td>
                            <td>{{server.id}}</td>
                            <td>
                                <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/server', server.id]"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>


    <div class="card card-w-title top-level-card">

        <h1>Catalog Integrations</h1>
        <catalog-table></catalog-table>
    </div>

    <div class="card card-w-title top-level-card">

        <h1>Google DFP Integrations</h1>
        <div class="ui-g">

            <div class="ui-g-11 ui-md-11">
                <span>Enabled</span>
            </div>
            <div class="ui-g-1 ui-md-1">
                <p-inputSwitch [(ngModel)]="dfpEnabled" [disabled]='true'></p-inputSwitch>
            </div>

        </div>
    </div>
    
</div>
