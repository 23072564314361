<page-header title="Edit Entitytype{{!isLoading && entityType ? ': ' + entityType.name : ''}}"
    [showSpinner]="isLoading"></page-header>
<div *ngIf="entityType && !isLoading" class="card card-w-title top-level-card">
    <h1>Edit Entitytype {{entityType ? ': ' + entityType.name : ''}}</h1>
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <div class="ui-g card-content" *ngIf="entityType && !isLoading">
        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger"
                    icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" (click)="onSubmit($event)" icon="pi pi-check"
                    style="float:right;"></button>
            </div>
        </p-toolbar>
        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>
            <div class="ui-g-12 ui-md-6"><label for="type">Type</label>
                <input type="text" class="form-control" [(ngModel)]="entityType.name" disabled name="type">
            </div>
            <div class="ui-g-12 ui-md-6"><label for="schema">Schema</label>
                <input type="text" class="form-control" [(ngModel)]="schema" disabled name="schema">
            </div>
        </p-panel>
        <hr>
    </div>
</div>