<p-dropdown
    [options]="calendarOptions"
    (onChange)="handleRangeValueChange($event)" 
    [showClear]="true"
    appendTo="body"
    placeholder="None"
    [(ngModel)]="selectedCalendarOption" 
    [style]="{'height':'100%','width':'100%', 'margin-bottom': '0.75em', 'max-width': '20em'}">
</p-dropdown>
<!--
<div class="ui-g-12" style="display:flex; flex-direction:row;">
    <div [innerHTML]="rangeDatesString" style="padding-right:30px;"></div>
    <div *ngIf="rangeDatesString !== ''" style="margin-left:-20px; display:flex; flex-direction:column; justify-content:center;">
        <a (click)="clearDate(true)">
            <strong class="pi pi-times" style="color: rgb(119, 119, 119); cursor: pointer;"></strong>
        </a>
    </div>
</div>
-->
<p-calendar #calendar
    [(ngModel)]="rangeDates"
    selectionMode="range" 
    [readonlyInput]="true" 
    [inline]="true"
    (onSelect)="handleCustomCalendar($event)">
</p-calendar>
