<div class="card card-w-title top-level-card">
    <h1>Channels</h1>
    <container-dataview 
        [containers]="containers"
        rows="50"
        filterBy="label, keywords"
        [sortField]="sortField" 
        [sortOrder]="sortOrder"
        (statusSelectionChange)="onStatusSelectionChange($event)"
        [isLoading]="isLoading">
    </container-dataview>
</div>