import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { CreativeFormComponent } from '../../forms/creative-form/creative-form.component';

@Component({
  selector: 'creative-edit',
  templateUrl: './container-creative-edit.component.html',
  providers: [
    ContainerService
],
})
export class CreativeEditComponent implements OnInit {
  @ViewChild('form') form: CreativeFormComponent;
  public container: Container;
  public isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public containerService: ContainerService,
    private msgService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.route.params.subscribe(params => {
      let id = +params['containerId'];
      this.containerService.get(id)
          .subscribe(
              res => {this.container = res; this.container.data = this.container.data ?? {}},
              err => this.isLoading=false,
              () => this.isLoading = false
          );
    });
  }

  onSubmit() {
    this.isLoading = true
    this.containerService.save(this.container).subscribe(
      res => null,
      err => {this.isLoading = false, console.log(err)},
      ()  => {
        this.isLoading = false
        this.container = Object.assign(new Container(), this.container);
        this.msgService.add({severity: 'success', summary:'', detail:'Updated successfully' })
      }
    )
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  onFail(e){
    this.msgService.add(e);
  }
}
