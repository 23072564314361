import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppContext } from '../../../app.context';
import { EntityType, Schema } from '../../../content/models';
import { Message } from 'primeng/api';
import { ContainerService } from '../../../content/services';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';

@Component({
  selector: 'schema-form',
  templateUrl: './schema-form.component.html',
  styleUrls: ['./schema-form.component.css']
})
export class SchemaFormComponent implements OnInit {
  @Input() schema: Schema;
  @Input() isDisabled: Boolean = false;
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();
  @Output() formCancelled: EventEmitter<any> = new EventEmitter();
  @Output() formFailed: EventEmitter<any> = new EventEmitter();
  @ViewChild('ngForm') ngForm: NgForm;

  public isLoading = false;
  public statusList = ContainerService.statusList
  private msgs: Message[] = [];
  private entityTypes = [];
  public entityTypeList= []
  public schemaType: any;

  public entityTypeId : number;
  private entityTypeName : string = '';

  constructor(
    private appContext: AppContext,
    private entityTypeService: EntityTypeService
  ) { }

  ngOnInit() {
    this.schema.origin = this.schema.origin ? this.schema.origin : this.appContext.activeOrigin
    this.entityTypeId = this.schema.type ? this.schema.type.id : -1;

    this.entityTypeService
        .list()
        .subscribe(
          res => {
            this.entityTypes = res;
            this.entityTypes.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
            let entityType = this.entityTypes.find(t => t.id == this.entityTypeId);
            this.entityTypeName = entityType ? entityType.name : '';
          }
        )
  }

  onTypeChange() {
    let entityType = this.entityTypes.find(t => t.id == this.entityTypeId);
    this.schema.type = entityType;

}

  onSubmit(e) {
    let entityType = this.entityTypes.find(t => t.id == this.entityTypeId);
    this.schema.type = entityType ? entityType: null;

    this.msgs = [];
    if (!this.schema.label) {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter title' });
      this.formFailed.emit();
    }
    else {
      this.ngForm.form.markAsPristine()
      this.formSubmit.emit({
        schema: this.schema
      });
    }
  }

  isPristine() {
    return this.ngForm.form.pristine;
  }

  onCancel(e) {
    this.formCancelled.emit();
  }

}
