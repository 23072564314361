import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../../content/models';
import { SearchTableComponent } from './../../../content/components/search-table/search-table.component';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';


@Component({
    selector: 'worklist-table',
    templateUrl: 'worklist-table.component.html',
    styleUrls: ['worklist-table.component.css']
})
export class WorklistTableComponent implements OnInit {
    @Input() containers: any;
    @Input() defaultSearchTerm: string;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @ViewChild(SearchTableComponent) searchTable:  SearchTableComponent;

     // **** View restriction params *****
    public viewRestrictions: any = {};
    public viewRestrictionsAllowedStatus = []
    public viewRestrictionsAllowedType = []
    public viewRestrictionsAllowedTagCategory = []
    public viewRestrictionsSearchFilter = ''

    worklistConfig = {
      csKey: 'media_search_view',
      keepSelectionOnReload: false,
      customColumns: [
          {field: 'thumbnail'},
          {field: 'label'},
          {field: 'type'},
          {field: 'created_date'},
          {field: 'status'},
          {field: 'tags'}
      ]
  }

    constructor(
        private router: Router,
        private authService: AuthService,
        private appContext: AppContext,
    ) {}

    ngOnInit() {
        console.log('Container');
        console.log(this.containers);

         //Update viewrestrictions from authService
         this.authService.viewRestrictionsUpdates.subscribe(
          res => null,
          err => console.log(err),
          () => this.getViewRestrictions()
      )
    }

    getViewRestrictions()
    {
        this.viewRestrictions = this.appContext.getViewRestrictions('media_view');

        if (this.viewRestrictions && ('allowed_statuses' in this.viewRestrictions) && this.viewRestrictions['allowed_statuses'])
        {
            this.viewRestrictionsAllowedStatus = this.viewRestrictions['allowed_statuses']
        }

        if (this.viewRestrictions && ('allowed_tag_categories' in this.viewRestrictions) && this.viewRestrictions['allowed_tag_categories'])
        {
            this.viewRestrictionsAllowedTagCategory = this.viewRestrictions['allowed_tag_categories']
        }

        if (this.viewRestrictions && ('search_filter' in this.viewRestrictions) && this.viewRestrictions['search_filter'])
        {
            this.viewRestrictionsSearchFilter = this.viewRestrictions['search_filter']
        }

        if (this.viewRestrictions && ('allowed_types' in this.viewRestrictions) && this.viewRestrictions['allowed_types'])
        {
            this.viewRestrictionsAllowedType = this.viewRestrictions['allowed_types']
        } else {
          // ***** Set default allowedTypes if not found in viewRestrictions *****
            this.viewRestrictionsAllowedType = ['movie', 'media', 'episode']
        }

    }

    findParentContainerId(container) {

        console.log('clicked on container', container);
        console.log('all containers', this.containers);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];

        console.log('clicked on container parent', parentContainer);

        this.router.navigate(['/episodes', container.id, parentContainer.id || '']);
    }

    onLazyLoad(event)
    {
        this.rowSelect.emit({
            container: null
        });
    }

    handleRowSelect(event) {
        console.log("Handling Event")
        console.log(event['container'])
        this.rowSelect.emit({
            container: event['container']
        });
    }

    rowUpdate(guid) {
      this.searchTable.rowUpdate(guid);
    }
}
