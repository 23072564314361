import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SelectItem } from 'primeng/api';
import { pipelineStatusList } from '../../../shared/enum';

@Component({
  selector: 'pipeline-status-multiselect',
  templateUrl: './pipeline-status-multiselect.component.html',
  styleUrls: ['./pipeline-status-multiselect.component.css']
})
export class PipelineStatusMultiselectComponent implements OnInit {

  @Input() defaultSelectedStatusList: any;
  @Output() onStatusListUpdate: EventEmitter <any> = new EventEmitter();



  public statusList: Array<SelectItem> = [];
  public activeOrigin;
  public selectedStatusList;

  constructor() { }

  ngOnInit(): void {
    this.selectedStatusList = this.defaultSelectedStatusList;
    this.statusList = pipelineStatusList.map(s => ({ label: this.toTitleCase(s), value: s }));
    this.onStatusListUpdate.emit(this.selectedStatusList);
  }
  
  onSelectedStatusChange(event) {
    this.selectedStatusList = event.value ? event.value : this.defaultSelectedStatusList;
    this.onStatusListUpdate.emit(event);
  }

  toTitleCase(data) {
    let txt = data || '';
    if (!txt) {
        return txt;
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }
}