import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { ServerFormComponent } from '../../forms/server-form/server-form.component';

@Component({
    selector: 'server-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'server-create.component.html',
    styleUrls: ['server-create.component.css']
})
export class ServerCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: ServerFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('server create initialized');
        this.container = new Container();
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        // TEMP: default to published until status component on edit form
        this.container.status = 'published';
        this.container.published_date = Math.trunc(Date.now() / 1000);

        console.log('submit', e.model);
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/server', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onCancel(e){
      console.log("cancelled click")
      this.location.back()
    }
}
