<page-header title="Edit Server - {{container?.data.name}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

<div class="card card-w-title">
    <h1>Edit Server{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>

    <div class="ui-g" *ngIf="container && !isLoading">
        <!--<p-growl class="ui-g-12" [(value)]="requiredMsgs"></p-growl> -->
        <p-messages class="ui-g-12" [(value)]="requiredMsgs"></p-messages>
        <p-messages class="ui-g-12" [(value)]="msgs"></p-messages>

        <server-form [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)"></server-form>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
