import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as utilities from '../../../shared/utilities';
import { CanvasService } from '../../../shared/canvas.service';
import { ContentGroupService } from '../../services/content-group.service';

@Component({
    selector: 'zoom-timeline',
    templateUrl: './zoom-timeline.component.html',
    styleUrls: ['./zoom-timeline.component.css']
})
export class ZoomTimelineComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() duration;
    @Input() colors;
    @Input() keywords;
    @ViewChild('canvas') canvas: ElementRef;
    @ViewChild('canvasWrapper') canvasWrapper: ElementRef;
    private ctx: CanvasRenderingContext2D;
    private ctxEl;
    private responsiveWidth = this.getWidth();
    public config = {
        momentLength: 30,
        ctxWidth : this.responsiveWidth,
        ctxHeight : 220,
        cHeight: 80,
        cWidth: this.responsiveWidth - 20,
        topOffset: 20,
        zoomedHeightOffset: 120,
        ctxBgr : 'rgba(100, 100, 100, 0.1)',
        ctxFgr : 'rgba(100, 100, 100, 0.4)',
        ctxTextColor: '#000',
        previewBoxColor: 'rgba(89, 196, 41, 0.25)',
        previewBoxBorder: 'rgba(89, 196, 41, 0.65)'
    };
    private scaleFactor;
    private canvasLeftOffset;
    private canvasTopOffset;
    private activeKeywords = [];
    private activeTimecode = 0;
    private activeChapter = null;
    private activeChapterNumber = 1;
    private keywordsWithTimestamps = [];
    private computedWidth;
    private computedHeight;
    private zoomRangeStart = 0;
    private zoomFactor = 1;
    private throttledReset = null;
    private cgState: any;
    private destroy$ = new Subject();
    public isLoading = false;

    constructor(
        private cgService: ContentGroupService,
        private canvasSrv: CanvasService
    ) { }

    ngOnInit() {
        this.setConfig();
        this.isLoading = true;
    }

    ngAfterViewInit() {
        this.destroy$.next(false);
        this.scaleFactor = this.config.cWidth / this.duration;
        // Capture canvas
        this.ctxEl = this.canvas.nativeElement;
        this.canvasLeftOffset = this.ctxEl.getBoundingClientRect().x;
        this.canvasTopOffset = this.ctxEl.getBoundingClientRect().y;
        this.ctx = this.ctxEl.getContext('2d');
        this.createSubscriptions();
        this.resetTimelineSize(50);
    }

    private createSubscriptions(): void {
        // Create canvas state subscription
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
                //this.scaleFactor = this.config.cWidth / this.cgState['duration'];
                //console.log('state! scalefactor', this.scaleFactor);
                if (this.cgState && this.cgState['activeTimecode'] && this.cgState['activeTimecode']>0){
                this.activeTimecode = this.cgState['activeTimecode'];
                } else if (this.cgState && this.cgState['playerTimecode'] && this.cgState['playerTimecode']>0) {
                this.activeTimecode = this.cgState['playerTimecode'];
                }
                //console.log('this.activeTimecode cgState', this.activeTimecode);
                this.drawCanvas();
        });



        // TODO: needs improving
        // Render all hover elements related actions here
        const hoveringTimelineObs$ = observableFromEvent(this.ctxEl, 'mousemove')
            .pipe(filter(e => e['pageX'] < (this.config.cWidth +  this.ctxEl.getBoundingClientRect().x)));

        hoveringTimelineObs$.pipe(takeUntil(this.destroy$))
            .subscribe((e: MouseEvent) => {
                let hoverPosition = Math.round(e['pageX'] - this.ctxEl.getBoundingClientRect().x);
                this.drawCanvas();
                // Hovering over main timeline
                if ((e['offsetY'] > 20) && (e['offsetY'] < 100)) {
                    // Place hovering marker over main timeline
                    this.drawMarkerLine(hoverPosition, this.config.topOffset, 'red');
                    this.renderTime(hoverPosition, 50, 42);
                } else if ((e['offsetY'] > 120) && (e['offsetY'] < 200)) {
                    // Place hovering marker over zoomed timeline
                    this.drawMarkerLine(hoverPosition, this.config.zoomedHeightOffset, 'red');
                }
            });
    }

    private drawCanvas(): void {
        // Clear canvas
        this.clearCanvas();
        // Place main timeline
        this.drawBackdrop(this.config.topOffset);
        // Place chapters grid on main timeline
        if (this.cgState && this.cgState['activeMediaChapters']) {
            this.drawGrid(this.cgState['activeMediaChapters'], this.config.topOffset);
        }

        // If timelines been clicked on render active chapter
        // on zoomed timeline
        if (this.cgState && this.cgState['activeChapter']) {
            this.activeChapter = this.cgState['activeChapter'];
            this.zoomRangeStart = this.activeChapter[0];
            // Highlight chapter on master timeline
            this.canvasSrv.drawRectangle(
                this.ctx,
                this.activeChapter[0] * this.scaleFactor,
                this.config.topOffset,
                (this.activeChapter[1] - this.activeChapter[0]) * this.scaleFactor,
                this.config.cHeight,
                this.config.previewBoxColor
            );
        }

        // Place active keywords
        if (this.cgState && this.cgState['activeKeywords']) {
            this.activeKeywords = this.cgState['activeKeywords'];
            this.keywordsWithTimestamps = [];
            this.renderKeywordInstances(this.activeKeywords, this.config.topOffset, this.scaleFactor);
        }

        // If timelines been clicked on render active moment
        // Use `this.activeTimecode` as it can be set by `this.cgState` or `playerTimecode`
        if (this.activeTimecode) {
            // Place clicked marker line
            this.drawMarkerLine( this.activeTimecode * this.scaleFactor, this.config.topOffset);
            // Place timeline position marker
            this.canvasSrv.drawTriangle(
                this.ctx,
                this.activeTimecode * this.scaleFactor,
                this.config.topOffset,
                6,
                12,
                this.config.ctxTextColor);

                // Render timestamp for selected moment
                this.renderTime(this.activeTimecode * this.scaleFactor, 0, 42);
            }
        // Render active moment and other moments for selected media
        if (this.cgState && this.cgState['activeMediaMoments']) {
            for (let i = 0; i < this.cgState['activeMediaMoments'].length; i++) {
                if (this.cgState['activeMoment'] && this.cgState['activeMediaMoments'][i] ===  this.cgState['activeMoment']['data']['start_time_code']){
                    this.drawMomentMarker(this.cgState['activeMoment']['data']['start_time_code'] * this.scaleFactor, this.config.topOffset, '#59c429');
                } else {
                    this.drawMomentMarker(this.cgState['activeMediaMoments'][i] * this.scaleFactor, this.config.topOffset, 'grey');
                }
            }
        }

        // Render zoomed view
        this.renderZoom();
    }

    onResize() {
        this.resetTimelineSize(500);
    }

    resetTimelineSize(delay = 500) {
        if (this.throttledReset) {
            window.clearTimeout(this.throttledReset);
            this.throttledReset = null;
        }
        this.throttledReset = window.setTimeout( () => {
            this.setConfig();
            setTimeout(() => {
                this.isLoading = false;
                this.scaleFactor = this.config.cWidth / this.cgState['duration'];
                this.drawCanvas();
            }, delay);
        }, delay);
    }

    ngOnDestroy() {
        // Unsubscribe from all Subscriptions
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private setConfig() {
        this.responsiveWidth = this.getWidth();
        this.config = {
        momentLength: 30,
        ctxWidth : this.responsiveWidth,
        ctxHeight : 220,
        cHeight: 80,
        cWidth: this.responsiveWidth - 20,
        topOffset: 20,
        zoomedHeightOffset: 120,
        ctxBgr : 'rgba(100, 100, 100, 0.1)',
        ctxFgr : 'rgba(100, 100, 100, 0.4)',
        ctxTextColor: '#000',
        previewBoxColor: 'rgba(89, 196, 41, 0.25)',
        previewBoxBorder: 'rgba(89, 196, 41, 0.65)'
        };
    }

    // Select current moment
    public moveToFrame(event: MouseEvent): void {
        const zoomedClickTopOffset = this.config.ctxHeight - this.config.zoomedHeightOffset;
        const canvasY = event.y - this.ctxEl.getBoundingClientRect().y;
        const canvasX = event.x - this.ctxEl.getBoundingClientRect().x;
        const timeMaster = utilities.toRawSeconds(canvasX, this.scaleFactor);
        const timeZoom = utilities.toRawSeconds(canvasX, this.zoomFactor) + this.zoomRangeStart;

        if (canvasX > this.config.cWidth) {
            return;
        }
        if (canvasY < zoomedClickTopOffset) {
            this.activeChapter = this.cgService.getActiveChapter(timeMaster, this.cgState['activeMediaChapters'], Math.floor(this.duration));
            this.activeChapterNumber = this.cgService.getActiveChapterNumber(timeMaster, this.cgState['activeMediaChapters'])
            this.cgService.updateCgState({
                'userTimecode': timeMaster,
                'playerTimecode': 0,
                'activeChapter': this.activeChapter,
                'activeChapterNumber': this.activeChapterNumber,
                'source': 'zoom-timeline-1'
            });
        } else {
            this.cgService.updateCgState({
                'userTimecode': timeZoom,
                'playerTimecode': 0,
                'source': 'zoom-timeline-2'
            });
        }
    }

    // Clearing canvas
    private clearCanvas() {
        this.ctx.clearRect(0, 0, this.config.ctxWidth, this.config.ctxHeight);
    }

    // Draw position line on timeframe
    private drawMarkerLine(position, offset = 0, color = this.config.ctxTextColor, width = 0.5){
        this.canvasSrv.drawRectangle( this.ctx, position, offset, width, this.config.cHeight, color);
    }

    private drawMomentMarker(position, offset = 0, color = this.config.ctxTextColor, width = 1){
        this.drawMarkerLine(position, offset, color, width);
        this.canvasSrv.drawTriangle(this.ctx,position + width/2, offset, 6, 12, color);
    }

    // Render simple timestamp
    private renderTimestamp(text, x, y): void {
        this.ctx.fillStyle = this.config.ctxTextColor;
        this.ctx.fillText(utilities.makeTime(text), x, y);
    }

    // Render timestamp
    private renderTime(x, y, w): void {
        this.ctx.fillStyle = this.config.ctxTextColor;
        this.ctx.fillText(utilities.makeTime(utilities.toRawSeconds(x, this.scaleFactor)), x - w / 2, y + 10);
    }

    // Painting canvas background(timeline)
    private drawBackdrop(offset = 0): void {
        this.canvasSrv.drawRectangle( this.ctx, 0, 0 + offset, this.config.cWidth, this.config.cHeight, this.config.ctxBgr);
        this.canvasSrv.borderRect(this.ctx, 0, 0 + offset, this.config.cWidth, this.config.cHeight, this.config.ctxFgr);
    }

    // Painting grid tied to chapters or equal grid
    private drawGrid(chapters, offset = 0): void {
        this.ctx.lineWidth = .5;
        // Render chapter dividers
        chapters.map((val) => {
            this.ctx.strokeStyle = this.config.ctxFgr;
            this.ctx.beginPath();
            this.ctx.moveTo(utilities.toScaledSeconds(val, this.scaleFactor), 0 + offset);
            this.ctx.lineTo(utilities.toScaledSeconds(val, this.scaleFactor), this.config.cHeight + offset);
            this.ctx.stroke();
        });
        // Render timebox for chapters except first one; move last one in by extra 40px
        // If chapters  are too close, don't render timestamp
        let timecodeOffset = 40;
        let prevOffset = 0;
        let curOffset;
        for (let i = 1; i < chapters.length; i++) {
            if (i == chapters.length - 1) {
               timecodeOffset = 80;
            }
            curOffset = utilities.toScaledSeconds(chapters[i], this.scaleFactor);
            if (curOffset - 40 >= prevOffset) {
                this.renderTime(curOffset, this.config.cHeight + offset, timecodeOffset);

            }
            prevOffset = curOffset;
        }
    }

    // Render zoom timeline related
    private renderZoom(): void {
        this.zoomFactor = this.config.cWidth / (this.activeChapter[1] - this.activeChapter[0]);
        const yo = this.config.zoomedHeightOffset; // y offset
        const xo = this.activeTimecode - this.activeChapter[0]; // x offset
        this.canvasSrv.drawRectangle( this.ctx, 0, 0 + yo, this.config.cWidth, this.config.cHeight, this.config.previewBoxColor);
        this.canvasSrv.borderRect(this.ctx, 0, 0 + yo, this.config.cWidth, this.config.cHeight, this.config.previewBoxBorder);
        // Render zoomed keywords
        this.renderZoomedKeywordInstances(
        this.keywordsWithTimestamps,
        yo,
        this.zoomFactor,
        this.activeChapter);
        // Render current time position
        this.drawMarkerLine(xo * this.zoomFactor , yo);
        // Render active moment and other moments for selected media
        if (this.cgState && this.cgState['activeMediaMoments']){
            for (let i=0; i<this.cgState['activeMediaMoments'].length; i++) {
                if (this.cgState['activeMediaMoments'][i] < this.activeChapter[1] &&
                        this.cgState['activeMediaMoments'][i] >= this.activeChapter[0]) {
                    let markXo = this.cgState['activeMediaMoments'][i] - this.activeChapter[0];
                    this.drawMomentMarker(markXo * this.zoomFactor, yo, 'grey');
                    if (this.cgState['activeMoment'] && this.cgState['activeMediaMoments'][i] ===  this.cgState['activeMoment']['data']['start_time_code']){
                        this.drawMomentMarker(markXo * this.zoomFactor, yo, '#59c429');
                    } else {
                        this.drawMomentMarker(markXo * this.zoomFactor, yo, 'grey');
                    }
                }
            }
        }
        // Render chapter range timestamps
        this.renderTimestamp( this.activeChapter[0], 2, yo + 90 );
        this.renderTimestamp( this.activeChapter[1], this.config.cWidth - 40, yo + 90 );
    }

    // Place all keywords instances on timeline
    private renderKeywordInstances(keywords, offset = 0, factor = 0): void {
        keywords.forEach((keyword) => {
        if (keyword.timestamps.length > 0) {
            this.keywordsWithTimestamps.push(keyword);
        }
        });
        let verticalInterval = this.config.cHeight / (this.keywordsWithTimestamps.length + 1);
        this.keywordsWithTimestamps.map((keyword, i) => {
        keyword.timestamps.map((time) => {
            let verticalOffset = offset + (i + 1) * verticalInterval;
            let horizontalOffset = utilities.toScaledSeconds(time['start_time_code'], factor);
            let horizontalWidth = utilities.toScaledSeconds(time['end_time_code'] - time['start_time_code'], factor);
            this.drawKeywords(keyword, verticalOffset, horizontalOffset, horizontalWidth);
        });
        });
    }

    // Place all keywords instances on zoomed timeline
    private renderZoomedKeywordInstances(keywords, offset = 0, factor = 0, range): void {
        let verticalInterval = this.config.cHeight / (this.keywordsWithTimestamps.length + 1);
        keywords.map((keyword, i) => {
            keyword.timestamps.map(
                (stamp) => { 
                    if (stamp['start_time_code'] > range[0] && stamp['start_time_code'] < range[1] || stamp['end_time_code'] > range[0] && stamp['end_time_code'] < range[1]) {
                        let verticalOffset = offset + (i + 1) * verticalInterval;
                        let horizontalOffset = utilities.toScaledSeconds(stamp['start_time_code'], factor) - utilities.toScaledSeconds(range[0], factor);
                        let horizontalWidth = utilities.toScaledSeconds(stamp['end_time_code'] - stamp['start_time_code'], factor); 
                        this.drawKeywords(keyword, verticalOffset, horizontalOffset, horizontalWidth);
                    }
                }
            );
        });
    }

    private drawKeywords(keyword, verticalOffset, horizontalOffset, horizontalWidth) {
        let rectangeWidth = horizontalOffset + horizontalWidth <= this.config.cWidth ? horizontalWidth : this.config.cWidth - horizontalOffset;
        this.canvasSrv.drawRectangle(
        this.ctx,
        horizontalOffset,
        verticalOffset - 3,
        rectangeWidth,
        6,
        keyword.color);

        this.canvasSrv.drawArc(
        this.ctx,
        horizontalOffset,
        verticalOffset,
        keyword.color
        );
        if (horizontalOffset + horizontalWidth <= this.config.cWidth - 3) {
        this.canvasSrv.drawArc(
            this.ctx,
            horizontalOffset + horizontalWidth,
            verticalOffset,
            keyword.color
        );
        }
    }

    // Render some keyword instances on timeline
    private filterKeywords(keyword) {
        if (this.activeKeywords.length === this.keywords.length) {
            // Init logic on first click
            this.activeKeywords = [];
            this.keywords.filter(kw => kw['key'] === keyword['key'])
                        .map(kw => kw['active'] = false);
            this.keywords.filter(kw => kw['active'] == true)
                        .map(kw => this.activeKeywords.push(kw));

        } else {
            // main logic
            if (!this.activeKeywords.includes(keyword)) {
                this.activeKeywords.push(keyword);
                this.keywords.filter(kw => kw['key'] === keyword['key'])
                            .map(kw => kw['active'] = true);
            } else {
                let xx;
                this.keywords.filter(kw => kw['key'] === keyword['key'])
                            .map(kw => kw['active'] = false);
                this.activeKeywords.forEach((entry, i) => {
                    if (entry.key === keyword.key) {
                        xx = i;
                    }
                });
                this.activeKeywords.splice(xx, 1);
            }
        }
        this.cgService.updateCgState({ 'activeKeywords' : this.activeKeywords, 'source': 'zoom-timeline-3'});
    }

    // Show/hide all keyword instances on timeline
    private showAllKeywords(): void {
        if (this.activeKeywords.length > 0) {
            // Hide all keyword instances
            this.keywords.filter(kw => kw['active'])
                         .map(kw => kw['active'] = false);
            this.activeKeywords = [];
        } else {
            // Show all keyword instances
            this.keywords.filter(kw => !kw['active'])
                         .map(kw => kw['active'] = true);
            this.keywords.map(kw => this.activeKeywords.push(kw));
        }
        this.cgService.updateCgState({ 'activeKeywords' : this.activeKeywords, 'source': 'zoom-timeline-4' })
    }

    private getWidth() {
        let parentWidth;
        if (this.canvasWrapper && this.canvasWrapper.nativeElement.clientWidth) {
        parentWidth = this.canvasWrapper.nativeElement.scrollWidth;
        } else {
        parentWidth = 550;
        }
        return parentWidth;
    }

}
