<p-dialog header="Confirm Pipeline Action" 
        [(visible)]="displayDialog"
        styleClass="dialog-max-40em"
        [modal]="true" 
        [minY]="70" 
        [baseZIndex]="10000" 
        autoAlign="true" 
        [appendTo]="'body'">
    <span style="white-space: normal;">Are you sure you want to <b>{{this.filteredLabel}}</b> pipeline <b>{{this.pipeline.id}}</b>?</span>
    <p-footer>
        <button type="button" pButton icon="fa fa-check" (click)="triggerAction()" label="Yes"></button>
        <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false" label="No" class="p-button-secondary"></button>
    </p-footer>
</p-dialog>
<p-splitButton label="{{this.defaultLabel}}" 
                icon="pi pi-file" 
                [model]="listActions()" 
                (onClick)="triggerDefaultAction()" 
                appendTo="body" 
                [disabled]="this.pipeline.status == 'success' || this.pipeline.status === 'cancelled'">
</p-splitButton>
