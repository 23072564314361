<p-dialog *ngIf="selectedPipeline" 
          [header]="selectedPipeline.name" 
          [modal]="true" 
          [visible]="displayDialog" 
          [responsive]="true" 
          (visibleChange)="closeDialog()" 
          [style]="{ 'width': '70%'}">
        <json-editor #pipelinelog [options]="pipelineLogDisplayOptions" 
                                  [data]="this.selectedPipeline">
        </json-editor>
</p-dialog>
<button pButton type="button" (click)="showDialog()" label="View"  class="p-button-success" target="_blank"></button>