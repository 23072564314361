import { Component, OnInit } from '@angular/core';
import { ContainerTableComponent } from '../container-table/container-table.component';
import { ContainerComponent } from '../container/container.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';

@Component({
    selector: 'container-root',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-root.component.html',
    styleUrls: ['container-root.component.css']
})
export class ContainerRootComponent implements OnInit {
    // private searchResult = {};
    public isLoading = false;
    public error: string;

    constructor(
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        // this.isLoading = true;
        // this.containerService
        //     .search(20, 0, [], '')
        //     .subscribe(
        //         res => {
        //             console.log('result', res);
        //             this.searchResult = res;
        //         },
        //         err => {
        //             console.log(err);
        //             this.error = err.statusText;
        //         },
        //         () => this.isLoading = false
        //     );
    }
}
