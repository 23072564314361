import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models';


@Component({
    selector: 'campaign-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'campaign-create.component.html',
})
export class CACampaignCreateComponent implements OnInit {
    public container: Container = new Container();
    public error: string;
    public isLoading: Boolean = false;

    constructor(
        private location: Location,
        public containerService: ContainerService,
        private router: Router
    ) {}

    ngOnInit() {
        console.log('campaign create init');
    }

    onSubmit(e) {
        console.log('campaign  submit', e);

        this.containerService
            .save(e.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/commerce/campaign', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.error = err;
                }
            );
    }

    onCancel(e) {
        this.location.back();
    }
}