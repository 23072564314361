<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Flight Segments</span>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="segments" 
            [rows]="pageSize"
            [paginator]="true" 
            paginatorPosition="bottom"
            [lazy]="true" 
            (onLazyLoad)="onLazyload($event)"
            styleClass="p-datatable-gridlines  p-datatable-nested"
            [responsive]="true"
            [totalRecords]="segmentCount">
        <ng-template pTemplate="header">
            <tr>
                <th>Channel</th>
                <th>Title</th>
                <th>Episodes Name</th>
                <th>Schedule Date</th>
                <th>Start Time</th>
                <th>Content Group</th>
                <th>Short List</th>
                <th>IM Approved</th>
                <th>S4M Billing Line Contracts Complete</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-segment>
            <tr>
                <td>{{segment.label}}</td>
                <td>{{segment.data.title}}</td>
                <td>{{segment.data.episode_name}}</td>
                <td>{{segment.data.schedule_date}}</td>
                <td>{{segment.data.start_time}}</td>
                <td>{{segment.data.content_group_name}}</td>
                <td *ngxPermissionsOnly=modifyPermissions>
                    <p-triStateCheckbox
                    styleClass="p-tristate-checkbox"
                    [(ngModel)]="segment.data.short_list"
                    (ngModelChange)="onModifyTableCheckbox(segment, segment.data.short_list)">
                </p-triStateCheckbox>
                </td>
                <td *ngxPermissionsOnly=modifyPermissions>
                    <p-triStateCheckbox
                    styleClass="p-tristate-checkbox"
                    [(ngModel)]="segment.data.im_approved"
                    (ngModelChange)="onModifyTableCheckbox(segment, segment.data.im_approved)">
                </p-triStateCheckbox>
                </td>
                <td *ngxPermissionsOnly=modifyPermissions>
                    <p-triStateCheckbox
                    styleClass="p-tristate-checkbox"
                    [(ngModel)]="segment.data.s4m_billing"
                    (ngModelChange)="onModifyTableCheckbox(segment, segment.data.s4m_billing)">
                </p-triStateCheckbox>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>