import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../../content/models';
import { SearchTableComponent } from './../../../content/components/search-table/search-table.component';
import { ContentGroupService } from '../../services/content-group.service';


@Component({
    selector: 'contextual-ads-context-search-table',
    templateUrl: 'contextual-ads-context-search.component.html',
    styleUrls: ['contextual-ads-context-search.component.css']
})
export class CAContextSearchTableComponent implements OnInit {
    @Input() containers: any;
    @Input() defaultSearchTerm: string;
    @Input() selectedContainer: Container;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @ViewChild(SearchTableComponent) searchTable:  SearchTableComponent;
    public searchTableConfig = {
        customColumns: [
            { field: 'label' },
            { header: 'Keywords', field: 'data.segment_keywords', style: {width: '30%'}, transform: [this.toKwdLabel.bind(this)] },
            { field: 'created_date'},
            { field: 'status' }   
        ],
        csKey: 'content_group_search_view',
        keepSelectionOnReload: true
    };

    constructor(
        private router: Router,
        private cgService: ContentGroupService
    ) {}

    ngOnInit() {
        console.log('Container');
        console.log(this.containers);
    }

    findParentContainerId(container) {

        console.log('clicked on container', container);
        console.log('all containers', this.containers);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];
        this.router.navigate(['/episodes', container.id, parentContainer.id || '']);
    }

    onLazyLoad(event)
    {
        this.rowSelect.emit({
            container: null
        });
    }

    handleRowSelect(event) {
        if (event['origin']==='select') {
            this.cgService.updateCgState({
                'contentGroup': event['container'],
                'activeMoment': null,
                'userTimecode': 0,
                'playerTimecode': 0,
                'source': 'c-a-c-s-1'
            });
            // this.rowSelect.emit({
            //     container: event['container']
            // });
        } else {
            this.cgService.updateCgState({
                'contentGroup': event['container'],
                'userTimecode': 0,
                'playerTimecode': 0,
                'source': 'c-a-c-s-2'
            });
        }

    }

    rowUpdate(guid) {
      this.searchTable.rowUpdate(guid);
    }

    toKwdLabel(kwdArray) {
        let kwdLabel = '';
        if (kwdArray.length > 0) {
            //kwdArray.forEach(kwdObject => {
            for (let i = 0; i < kwdArray.length; i++) {
                kwdLabel += ('<div class="label label-success label-margin">' + kwdArray[i]['label'] + '</div>');
                if (i === 9) {
                    let remainder = kwdArray.length-10;
                    kwdLabel += ('<div class="label label-default label-margin">+' + remainder + ' more</div>');
                    break;
                }
            };
            
        } 
        return kwdLabel;
    }
}
