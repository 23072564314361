<form *ngIf="segment && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="csMsg"></p-toast>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-12 ui-md-6">
                <button pButton type="button" label="{{segment.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-12 ui-md-6">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="label">Label</label>
                    <input type="text" class="form-control" required [(ngModel)]="segment.label" name="label" placeholder="Required">
                </div>
                <div class="ui-g-6">
                    <label for="value">Value</label>
                    <input type="text" class="form-control" required [(ngModel)]="segment.value" name="value" placeholder="Required">
                </div>
                <div class="ui-g-6">
                    <label for="status">Status</label>
                    <select type="text" class="form-control" [(ngModel)]="segment.status" name="status">
                        <option *ngFor="let status of statusList" [value]="status">{{ status }}</option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <label for="origin">Origin</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="segment.origin" name="origin">
                </div>
                <div class="ui-g-6">
                    <label for="startTimeCode">Start Time Code</label>
                    <input type="text" class="form-control" required [(ngModel)]="segment.start_time_code" name="startTimeCode"  placeholder="Required">
                </div>
                <div class="ui-g-6">
                    <label for="endTimeCode">End Time Code</label>
                    <input type="text" class="form-control" required [(ngModel)]="segment.end_time_code" name="endTimeCode"  placeholder="Required">
                </div>
                <div class="ui-g-6">
                    <label for="namespace">Namespace</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.namespace" name="namespace">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Identity Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2">
                    <label for="type">Type</label>
                    <select type="text" class="form-control" [(ngModel)]="segment.type" name="type">
                        <option *ngFor="let type of segmentTypes" [value]="type">{{ type }}</option>
                    </select>
                </div>
                <div class="ui-g-5">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.guid" name="guid">
                </div>
                <div class="ui-g-5">
                    <label for="parent">Parent</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.parent" name="parent">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="publishDate" style="display:block;">Publish Date</label>
                    <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="expirationDate" style="display:block;">Expiration Date</label>
                    <p-calendar [(ngModel)]="expirationDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
            </div>
        </p-panel>

    </div>
</form>
