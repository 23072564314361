import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Container } from '../../models';
import { MenuItem } from 'primeng/api';
import { ContainerService } from '../../services/container/container.service';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'container-status-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-status-edit.component.html',
    styleUrls: ['container-status-edit.component.css']
})

export class ContainerStatusEditComponent implements OnInit {
    @Input() container: Container;
    @Output() onStatusChange: EventEmitter < any > = new EventEmitter();
    @Output() error:EventEmitter <any> = new EventEmitter();

    public newContainerStatus = '';
    private newContainerEnabled: boolean = null;

    public processingRequest = false;

    publishDate: Date;

    displayDialog: boolean = false;

    publishStatusItems: MenuItem[];
    unpublishStatusItems: MenuItem[];
    approvedStatusItems: MenuItem[];
    readyStatusItems: MenuItem[];
    rejectedStatusItems: MenuItem[];
    scheduledStatusItems: MenuItem[];
    newStatusItems: MenuItem[];
    hiddenStatusItems: MenuItem[];

    approveStatusItem = {
        label: 'Approve', icon: 'fa fa-thumbs-up', automationId: 'app-content-components-container-status-edit-approve', command: () => {
            this.changeStatusTo('approved');
        }
    }

    rejectStatusItem = {
        label: 'Reject', icon: 'fa fa-times', automationId: 'app-content-components-container-status-edit-reject', command: () => {
            this.changeStatusTo('rejected');
        }
    }

    readyStatusItem = {
        label: 'Ready', icon: 'fa fa-check', automationId: 'app-content-components-container-status-edit-ready', command: () => {
            this.changeStatusTo('ready');
        }
    }

    publishStatusItem = {
       label: 'Publish', icon: 'fa fa-check-circle', automationId: 'app-content-components-container-status-edit-publish', command: () => {
            this.changeStatusTo('published', true);
        }
    }

    unpublishStatusItem = {
        label: 'Unpublish', icon: 'fa fa-arrow-circle-down', automationId: 'app-content-components-container-status-edit-unpublish', command: () => {
            this.changeStatusTo('unpublished', false);
        }
    }

    scheduleStatusItem = {
        label: 'Schedule', icon: 'fa fa-calendar', command: () => {
            if (this.container.data && this.container.data['available_date']) {
                let now = new Date();
                let availStr = this.container.data['available_date'];
                // Add explicit UTC timezone if unspecified
                if (availStr.length <= 19) {
                    availStr += 'Z';
                }
                let avail = new Date(availStr);
                this.publishDate = avail > now ? avail : now;
            }
            this.changeStatusTo('scheduled');
        }
    }

    hideStatusItem = {
        label: 'Hide', icon: 'fa fa-minus-square-o', automationId: 'app-content-components-container-status-edit-hide', command: () => {
            this.changeStatusTo('hidden');
        }
    }

    constructor(
        private containerService: ContainerService,
        private msgSrv: MessageService,
    ) { }

    ngOnInit() {

        this.publishStatusItems = [this.unpublishStatusItem, this.hideStatusItem]

        this.approvedStatusItems = [this.publishStatusItem, this.scheduleStatusItem, this.rejectStatusItem, this.hideStatusItem]

        this.unpublishStatusItems = [this.publishStatusItem, this.rejectStatusItem]

        this.readyStatusItems = [this.approveStatusItem, this.rejectStatusItem, this.hideStatusItem]

        this.newStatusItems = [this.readyStatusItem]

        this.rejectedStatusItems = [this.readyStatusItem, this.unpublishStatusItem,]

        this.scheduledStatusItems = [this.publishStatusItem, this.rejectStatusItem,]

        this.hiddenStatusItems = [this.publishStatusItem, this.rejectStatusItem,]

    }

    capitalize(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    changeStatusTo(status, is_enabled: boolean = null) {
        this.newContainerStatus = status;
        this.newContainerEnabled = is_enabled;
        this.displayDialog = true;
    }

    getStatusItems() {
        if (this.container.status == "new") {
            return this.newStatusItems;
        }
        else if (this.container.status == "published") {
            return this.publishStatusItems;
        }
        else if (this.container.status == "unpublished") {
            return this.unpublishStatusItems;
        }
        else if (this.container.status == "rejected") {
            return this.rejectedStatusItems;
        }
        else if (this.container.status == "ready") {
            return this.readyStatusItems;
        }
        else if (this.container.status == "approved") {
            return this.approvedStatusItems;
        }
        else if (this.container.status == "scheduled") {
            return this.scheduledStatusItems;
        }
        else if (this.container.status == "hidden") {
            return this.hiddenStatusItems;
        }
        else
        {
            return [];
        }

    }

    postStatusChangeToService() {
        var prevContainerStatus = this.container.status;
        this.container.status = this.newContainerStatus;

        var prevContainerEnabled = this.container.is_enabled;
        if (this.newContainerEnabled !== null) {
            this.container.is_enabled = this.newContainerEnabled;
        }

        if (this.container.status == 'published')
        {
            this.container.published_date = Math.trunc(Date.now() / 1000);
        }
        else if (this.container.status == 'scheduled')
        {
            this.container.published_date = Math.trunc(this.publishDate.getTime() / 1000);
        }
        else if (this.container.status != 'unpublished')
        {
            this.container.published_date = null;
        } 

        console.log(this.container.published_date)
        this.processingRequest = true
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.onStatusChange.emit({
                        new_status: this.container.status,
                        old_status: prevContainerStatus,
                        new_enabled: this.container.is_enabled,
                        old_enabled: prevContainerEnabled,
                        publish_date: this.container.published_date
                    })
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgSrv.add({ key: 'csMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })
    
                    }
                    this.container.status = prevContainerStatus;
                    this.processingRequest = false;
                    this.displayDialog = false;
                    this.error.emit(err);
                },
                () => {
                    this.processingRequest = false;
                    this.displayDialog = false;
                }
            );
    }
}
