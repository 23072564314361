import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Input } from '@angular/core';
import { compareByFieldSpec } from '@fullcalendar/core';
import { SelectItem } from 'primeng/api';
import { AppContext } from '../../../app.context';
import { Container, Segment } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
    selector: 'segment-paginated-datarow',
    templateUrl: 'segment-paginated-datarow.component.html',
    providers: [
        SegmentService
    ],
    styleUrls: ['segment-paginated-datarow.component.css']
})
export class SegmentPaginatedDatarowComponent implements OnInit {
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() filterBy: Array<any>;
    @Input() type: Array<any>;
    @Input() origin: Array<any>;
    @Input() label: Array<any>;
    @Input() value: Array<any>;
    @Input() namespace: Array<any>;
    @Input() startTimeCode: Array<any>;
    @Input() endTimeCode: Array<any>;
    @Input() status: Array<any>;
    @Input() itemsPerPage: number = 10;
    @Input() offset: number = 0;
    @Input() ordering: string;
    @Input() isLoading: boolean;

    public collapsed = false;
    public loadingNextPage = false;
    public selectedOrderingOption: any;
    public selectedStatusOption: any;
    public statusList: Array<SelectItem> = [];

    private child_row_name = '';
    private paramArray: Array<any> = [];
    private paramObject = {};
    private activeFilter: string;
    private error: string;
    private allSegments: Array<Segment> = [];
    private shownSegments: Array<Segment> = [];

    public orderingOptions = [
        { label: 'Start Time Code', value: "start_time_code" },
        { label: 'Start Time Code Desc', value: "-start_time_code" },
        { label: 'Id ', value: "id" },
        { label: 'Id Desc', value: "-id" },
        { label: 'Type Alphabetical ', value: "type" },
        { label: 'Type Reverse alphabetical', value: "-type" },
        { label: 'Value Alphabetical ', value: "value" },
        { label: 'Value Reverse alphabetical', value: "-value" },
    ]

    constructor(
        private appContext: AppContext,
        private segmentService: SegmentService
    ) { }

    ngOnInit(): void {
        this.child_row_name = `${this.parentContainer.guid}:${this.type}`;

        this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));
        this.statusList.unshift({ label: 'Any Status', value: '' });

        if (!this.appContext.componentState['paginated_segment_datarow'][this.child_row_name]) {
            this.setDefaultParams();
            this.setSortParams();
        }
        this.getSortParams();

        // Initially add all inputs to param object
        this.paramObject['type']= this.type;
        this.paramObject['origin'] = this.origin;
        this.paramObject['label'] = this.label;
        this.paramObject['value'] = this.value;
        this.paramObject['parent'] = this.parentContainer.guid;
        this.paramObject['namespace'] = this.namespace;
        this.paramObject['start_time_code'] = this.startTimeCode;
        this.paramObject['end_time_code'] = this.endTimeCode;
        this.paramObject['status'] = this.status;
        this.paramObject['ordering'] = this.ordering;
        this.paramObject['limit'] = this.itemsPerPage;
        this.paramObject['offset'] = this.offset;

        this.constructParams();

        this.isLoading = true;
        this.segmentService
            .listByParameters(this.paramArray)
            .subscribe(
                res => {
                    this.allSegments = res.results;
                    this.shownSegments = [];
                    res.results.forEach(element => {
                        this.shownSegments.push(element);
                        element['show'] = true;
                    });
                    this.filter();
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    setSortParams() {
        this.appContext.componentState['paginated_segment_datarow'][this.child_row_name] = {
            ordering: this.ordering,
            status: this.status,
        };
    }

    setDefaultParams() {
        if (!this.ordering) {
            this.ordering = 'start_time_code';
        }
        if (!this.status) {
            this.status = [];
        }
    }
    
    getSortParams() {
        this.ordering = this.appContext.componentState['paginated_segment_datarow'][this.child_row_name]['ordering'];
        this.selectedOrderingOption = this.ordering;

        this.status = this.appContext.componentState['paginated_segment_datarow'][this.child_row_name]['status'];
        this.selectedStatusOption = this.status;
    }

    // Iterate through param object to construct params
    constructParams() {
        this.paramArray = [];
        for (const [key, value] of Object.entries(this.paramObject)) {
            let object = {}
            if (value) {
                object[key] = value;
                this.paramArray.push(object);
            } else {
                // Remove params with empty value from param object
                delete this.paramObject[key];
            }
        }
    }

    sort() {
        this.shownSegments = [];
        this.allSegments.forEach(element => {
            this.shownSegments.push(element);
        });

        this.filter();
        return;
    }

    onFilter(value) {
        this.activeFilter = value;
        this.filter();
    }

    filter() {
        this.shownSegments.forEach(segment => {
            if (this.activeFilter && this.activeFilter !== '') {
                segment['show'] = false;
                this.filterBy.forEach(filter => {
                    if (!segment['show']) {
                        if (String(segment[filter]).toLowerCase().includes(this.activeFilter.toLowerCase())) {
                            segment['show'] = true;
                        }
                    }
                });
            } else {
                segment['show'] = true;
            }
        })
    }

    reloadPage() {
        this.loadingNextPage = true;
        this.segmentService
            .listByParameters(this.paramArray)
            .subscribe(
                res => {
                res.results.forEach(element => {
                    this.shownSegments.push(element);
                    this.allSegments.push(element);
                    element['show'] = true;
                });
                    this.filter();
                },
                err => this.error = err,
                () => this.loadingNextPage = false
            );
    }

    loadNextPage() {
        this.offset = this.offset + this.itemsPerPage;
        this.paramObject['offset'] = this.offset;
        this.constructParams();
        this.reloadPage();
    }

    onOrderingChange() {
        this.offset = 0;
        this.allSegments = [];
        this.shownSegments = [];
        this.ordering = this.selectedOrderingOption;
        this.paramObject['ordering'] = this.ordering;
        this.paramObject['offset'] = this.offset;
        this.constructParams();
        this.reloadPage();
        this.setSortParams();
    }

    onStatusChange() {
        this.offset = 0;
        this.allSegments = [];
        this.shownSegments  = [];
        this.status = this.selectedStatusOption;
        this.paramObject['status'] = [this.status];
        this.paramObject['offset'] = this.offset;
        this.constructParams();
        this.reloadPage();
        this.setSortParams();
    }

    onScroll(event) {
        if (event.target.offsetWidth + event.target.scrollLeft >= event.target.scrollWidth) {
            this.loadNextPage();
        }
    }

    segmentRemoved(e) {
        let i = this.allSegments.indexOf(e.segment);
        this.allSegments.splice(i, 1);

        this.shownSegments = [];
        this.allSegments.forEach(element => {
            this.shownSegments.push(element);
        });

        this.filter();
        this.sort();
    }

}
