<spinner *ngIf="isLoading"></spinner>
<form  #ngForm="ngForm" *ngIf="container && !isLoading" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-toolbar class="ui-g-12">
            <div class="ui-g-12 ui-md-6">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-12 ui-md-6">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-commerce-forms-flight-form-button-save"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['commerce']" *ngxPermissionsOnly="['view.show.commerce']" (pipelineTriggered)="onPipelineTriggered($event)" [confirmDialogMessage]=flightSegmentAlert style="float:right;margin-right:4px;"></trigger-pipeline-modal-form>

            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-9">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="Label">Label</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['label']" name="label" placeholder="Required" required data-element-id="app-commerce-forms-flight-form-label">
                </div>
                <div class="ui-g-6">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['description']" name="description" placeholder="Optional" data-element-id="app-commerce-forms-flight-form-description">
                </div>
                <div class="ui-g-6">
                    <label for="rangeStartDate" style="display:block;">Range Start Date</label>
                    <p-calendar [(ngModel)]="container.rangeStartDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}" data-element-id="app-commerce-forms-flight-form-range-start-date"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="rangeEndDate" style="display:block;">Range End Date</label>
                    <p-calendar [(ngModel)]="container.rangeEndDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}" data-element-id="app-commerce-forms-flight-form-range-end-date"></p-calendar>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-3">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="publishDate" style="display:block;">Publish Date</label>
                    <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-12">
                    <label for="expirationDate">Expiration Date</label>
                    <input type="text" class="form-control p-widget" [(ngModel)]="container.data.expiration_date" name="expirationDate" disabled>
                </div>
            </div>
        </p-panel>

        <ng-content></ng-content>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Identity Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="entityType.name" name="type">
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

    </div>
</form>
