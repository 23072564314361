import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { Tag } from '../../../content/models/tag';
import { Status } from '../../../content/models/status';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message } from 'primeng/api';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ReportFormComponent } from '../../forms/report-form/report-form.component';

@Component({
    selector: 'report-edit',
    providers: [
        ContainerService
        //JobsService
    ],
    templateUrl: 'report-edit.component.html',
    styleUrls: ['report-edit.component.css']
})
export class ReportEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: ReportFormComponent;
    @ViewChild('query_filters') queryfiltersEditor: JsonEditorComponent;
    @ViewChild('filter_options') filteroptionsEditor: JsonEditorComponent;
    @ViewChild('transform_options') transformOptionsEditor: JsonEditorComponent;
    @ViewChild('render_options') renderOptionsEditor: JsonEditorComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private validSections: Object = {};
    private queryfiltersObject: Array<object> = [];
    private filteroptionsObject: Array<object> = [];
    private transformOptionsObject: Object = {};
    private renderOptionsObject: Object = {};
    private editorOptionsQueryFilters: JsonEditorOptions;
    private editorOptionsFilterOptions: JsonEditorOptions;
    private editorOptionsTransformOptions: JsonEditorOptions;
    private editorOptionsRenderOptions: JsonEditorOptions;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('report edit initialized');
        this.editorOptionsRenderOptions = new JsonEditorOptions();
        this.editorOptionsRenderOptions.mode = 'tree';
        this.editorOptionsRenderOptions.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsRenderOptions.statusBar = true;
        this.editorOptionsRenderOptions.expandAll = true;
        this.editorOptionsRenderOptions.onChange = () => this.changeJson('render_options', this.renderOptionsEditor);

        this.editorOptionsQueryFilters = new JsonEditorOptions();
        this.editorOptionsQueryFilters.mode = 'tree';
        this.editorOptionsQueryFilters.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsQueryFilters.statusBar = true;
        this.editorOptionsQueryFilters.expandAll = true;
        this.editorOptionsQueryFilters.onChange = () => this.changeJson('query_filters', this.queryfiltersEditor);

        this.editorOptionsFilterOptions = new JsonEditorOptions();
        this.editorOptionsFilterOptions.mode = 'tree';
        this.editorOptionsFilterOptions.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsFilterOptions.statusBar = true;
        this.editorOptionsFilterOptions.expandAll = true;
        this.editorOptionsFilterOptions.onChange = () => this.changeJson('filter_options', this.filteroptionsEditor);

        this.editorOptionsTransformOptions = new JsonEditorOptions();
        this.editorOptionsTransformOptions.mode = 'tree';
        this.editorOptionsTransformOptions.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsTransformOptions.statusBar = true;
        this.editorOptionsTransformOptions.expandAll = true;
        this.editorOptionsTransformOptions.onChange = () => this.changeJson('query_transform_options', this.transformOptionsEditor);


        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                        this.initValues();
                        this.validateFormData();
                        console.log("report", this.container);
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                    this.initValues();
                    this.validateFormData();
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    validateFormData() {
        this.msgs = [];
        let valid = true;
        for (let field of ['Query Filters', 'Filter Options', 'Transform Options', 'Render Options']) {
            if (this.validSections[field.toLowerCase()] === false) {
                valid = false;
                this.msgs.push({
                    severity: 'error',
                    summary: `${field}: Invalid JSON`,
                    detail: 'Cannot save invalid JSON.'
                });
            }
        }
        return valid;
    }

    onSubmit(e) {
        console.log('submit', e.model);
        let valid = this.validateFormData();
        if (!valid) {
            return;
        }
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    private initValues() {
        if (!this.container.data['render_options']) {
            this.container.data['render_options'] = {};
        }
        if (!this.container.data['query_filters']) {
            this.container.data['query_filters'] = [];
        }
        if (!this.container.data['filter_options']) {
            this.container.data['filter_options'] = [];
        }
        if (!this.container.data['query_transform_options']) {
            this.container.data['query_transform_options'] = {};
        }
        
        this.queryfiltersObject = this.container.data['query_filters'];
        this.filteroptionsObject = this.container.data['filter_options'];
        this.transformOptionsObject = this.container.data['query_transform_options'];
        this.renderOptionsObject = this.container.data['render_options'];
    }

    private changeJson(field: string, editor: JsonEditorComponent) {
        try {
            var editorData = editor.get();
        } catch (e) {
            // Keep current value
            this.validSections[field] = false;
            return;
        }
        this.validSections[field] = true;
        this.container.data[field] = editorData;
    }

}
