<div class="card card-w-title top-level-card">
    <h1>History</h1>
    <spinner *ngIf="isLoading"></spinner>
    <p-table [columns]="columns" #dt 
            [value]="histories" 
            [globalFilterFields]="['history_id', 'history_date', 'history_type', 'history_user']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            [first]="offset" 
            [lazy]="true" 
            (onLazyLoad)="loadResultsLazy($event)" 
            (onRowSelect)="onRowSelect($event)" 
            [totalRecords]="historyRecordsLength" 
            dataKey="history_id" 
            selectionMode="single" 
            [(selection)]="history"
            styleClass="p-datatable-gridlines p-datatable-no-toolbar"
            [responsive]="true"
            data-element-id="app-content-components-history-list-table">
        <ng-template pTemplate="header" let-columns="columns">
            <tr>
                <th style="width: 8em">History Id</th>
                <th style="width: 10em">History Date</th>
                <th style="width: 8em">History Type</th>
                <th>User</th>
                <th>Change Reason</th>
                <th style="width: 6em"></th>
                <th style="width: 8em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-hist let-columns="columns">
            <tr *ngIf="!isLoading">
                <td style="word-break: break-all;">{{hist.history_id}}</td>
                <td>{{hist.history_date | date: 'M/d/yy h:mm:ss a'}}</td>
                <td>{{hist.history_type}}</td>
                <td style="word-break: break-all;">{{hist.history_user}}</td>
                <td style="word-break: break-all;">{{hist.history_change_reason}}</td>
                <td>
                    <div class="text-center" *ngxPermissionsOnly="['view.show.history']">
                        <button pButton type="button" label="Diff" class="btn btn-primary" icon="pi pi-pencil" [routerLink]="['/history/'+ objectType + '/' + objectId+ '/diff', hist.history_id]" data-element-id="app-content-components-history-list-difference"></button>
                    </div>
                </td>
                <td>
                    <div class="text-center" *ngxPermissionsOnly="['view.show.revert']">
                        <button pButton type="button" label="Revert" class="btn btn-primary" icon="pi pi-arrow-left" [routerLink]="['/history/'+ objectType + '/' + objectId+ '/revert', hist.history_id, currentHistoryId]" data-element-id="app-content-components-history-list-revert"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
