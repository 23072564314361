<spinner *ngIf="isLoading"></spinner>
<form *ngIf="schema && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
  <div class="ui-g">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <p-toolbar class="ui-g-12">
      <div class="ui-g-2">
        <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-settings-forms-schema-form-cancel"></button>
      </div>
      <div class="ui-g-10">
        <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-settings-forms-schema-form-save"></button>
      </div>
    </p-toolbar>
    <p-panel class="ui-g-12">
      <p-header>
        <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
      </p-header>
      <div class="ui-g-12 ui-md-4">
        <label for="label">Title</label>
        <input type="text" class="form-control" [(ngModel)]="schema.label" name="label" placeholder="Required" data-element-id="app-settings-forms-schema-form-title">
      </div>
      <div class="ui-g-12 ui-md-4">
        <label for="type">Type</label>
          <select required [(ngModel)]="entityTypeId" (ngModelChange)="onTypeChange()" name="type" class="form-control" data-element-id="app-settings-forms-schema-form-type">
              <option *ngFor="let entityType of entityTypes" [value]="entityType.id">
                  {{ entityType.name }}
              </option>
          </select>
      </div>
      <div class="ui-g-12 ui-md-4">
        <label for="status">Status</label>
        <select type="text" class="form-control" [(ngModel)]="schema.status" name="status" [disabled]="isDisabled" data-element-id="app-settings-forms-schema-form-status">
          <option *ngFor="let status of statusList" [value]="status">{{ status }}</option>
        </select>
      </div>
      <div class="ui-g-12 ui-md-4">
        <label for="value">Namespace</label>
        <input type="text" class="form-control" [(ngModel)]="schema.namespace" name="namespace" data-element-id="app-settings-forms-schema-form-namespace">
      </div>
      <div class="ui-g-12 ui-md-4">
        <label for="origin" aria-readonly="true">Origin</label>
        <input type="text" class="form-control" readonly [(ngModel)]="schema.origin" name="origin" data-element-id="app-settings-forms-schema-form-origin">
      </div>
      <div class="ui-g-12 ui-md-4">
        <label for="guid" aria-readonly="true">GUID</label>
        <input type="text" class="form-control" readonly [(ngModel)]="schema.guid" name="guid" data-element-id="app-settings-forms-schema-form-guid">
      </div>
    </p-panel>
  </div>
</form> 
