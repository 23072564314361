<div class="card card-w-title top-level-card">
    <h1>Add {{ container?.data.title ? '' + container.data['title'] + ' to ': '' }}{{containerFilterType.charAt(0).toUpperCase()
        + containerFilterType.slice(1)}}</h1>

    <page-header title="Add {{containerFilterType.charAt(0).toUpperCase() + containerFilterType.slice(1)}} to {{ container?.data.title }}"
        [showSpinner]="false" [error]="error"></page-header>

    <p-toolbar>
        <div class="ui-g" *ngIf="!processing">
            <div class="ui-g-12" style="text-align:center;">Select all of the
                <strong>{{containerFilterType}}</strong>(s) you would like to add
                <strong>{{container?.data.title?container.data['title']:'this container'}}</strong> into, and then click 'Confirm' below.</div>
        </div>
        <div class="ui-g" *ngIf="processing">
            <div class="ui-g-12" style="text-align:center;">Processing.... Please wait...</div>
        </div>
    </p-toolbar>

    <br />

    <div *ngIf="!processing">
        <container-relate-form *ngIf="container" [parentContainer]="container" [filterType]="containerFilterType" (formSubmit)="onSubmit($event)"
            (formCancelled)="onBack($event)"></container-relate-form>
    </div>

    <div *ngIf="processing" style="margin:0.5em;">
        <p-progressBar [value]="progress"></p-progressBar>
    </div>
</div>