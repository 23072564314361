import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'metrics-reports-date-range',
    templateUrl: './metrics-reports-date-range.component.html',
    styleUrls: ['./metrics-reports-date-range.component.css']
})

export class MetricsReportsDateRangeComponent implements OnInit {
    @Output() onValueChange: EventEmitter <any> = new EventEmitter();
    @Input() filter: Array <any>;
    @Input() filterOptions: any;

    public rangeDates: [Date, Date]; // need Date objects - null/undefined throws errors in p-calendar
    //public rangeDatesString = '';
    public largeDt = false;
    public selectedCalendarOption: any;
    public calendarOptions: Array <any>;

    private rangeFilters = [{}, {}];
    
    constructor(
        private datePipe: DatePipe
     ) {}

    // Used only if no options specified in report
    public readonly defaultCalendarOptions = [
        { 'label': 'Last 1 Hour', 'value': 'now-1h/h' },
        { 'label': 'Last 4 Hours', 'value': 'now-4h/h' },
        { 'label': 'Last 24 Hours', 'value': 'now-24h/h' },
        { 'label': 'Last 48 Hours', 'value': 'now-48h/h' },
        { 'label': 'Last 7 Days', 'value': 'now-7d/d' },
        { 'label': 'Last 14 Days', 'value': 'now-14d/d' },
        { 'label': 'Last 30 Days', 'value': 'now-30d/d' },
        { 'label': 'Last 3 Months', 'value': 'now-3M/M' },
        { 'label': 'Last 6 Months', 'value': 'now-6M/M' },
        { 'label': 'Last 1 Year', 'value': 'now-1y/y' },
        { 'label': 'Today', 'value': 'today' },
        { 'label': 'Yesterday', 'value': 'yesterday' },
        { 'label': 'Week To Date', 'value': 'week_to_date' },
        { 'label': 'Previous Week', 'value': 'previous_week' },
        { 'label': 'Month To Date', 'value': 'month_to_date'},
        { 'label': 'Previous Month', 'value': 'previous_month'},
        { 'label': 'Year To Date', 'value': 'year_to_date' },
        { 'label': 'Previous Year', 'value': 'previous_year' },
        { 'label': 'Custom', 'value': 'custom' },
    ]

    private readonly allowedVerbalValues = [
        'today', 'yesterday', 'week_to_date', 'previous_week', 
        'month_to_date', 'previous_month', 'year_to_date', 'previous_year'
    ]

    private readonly allowedTimeUnits = [ 'h', 'd', 'M', 'y' ]

    ngOnInit() {
        let customPresent = false;
        this.rangeDates = [new Date(), new Date()];
        if (!this.filterOptions || this.filterOptions.length === 0) {
            this.calendarOptions = this.defaultCalendarOptions;
        } else {
            this.calendarOptions = this.filterOptions;
        }
        
        this.calendarOptions.forEach(filterValue => {
            if (('default' in filterValue) && (filterValue['default'] === true)) {
                this.selectedCalendarOption = filterValue['value'];
                this.handleRangeValueChange({'value': filterValue['value']})
            }
            if (filterValue['value'] === 'custom') {
                customPresent = true;
            }
        });
        if (! customPresent) {
            this.calendarOptions.push({ 'label': 'Custom', 'value': 'custom' });
        }
        if (this.filter.length !== 2) {
            console.error("Date range filter must be an array with two items");
        }
        this.createRangeValues();
    }

    handleRangeValueChange(event) {
        if (! this.selectedCalendarOption ) {
            this.clearDate(true);
            return;
        } else if (this.selectedCalendarOption === 'custom') {
            this.clearDate(false);
            return;
        }
        let rangeVal = event.value;
        if (this.allowedVerbalValues.includes(rangeVal)) {
            this.verbalValueToDates(rangeVal);
        } else  if (rangeVal.indexOf('now-') === 0 && this.allowedTimeUnits.includes(rangeVal.charAt(rangeVal.length - 1))) {
            let rangeNo = parseInt(rangeVal.replace('now-', ''));
            let rangeUnit = rangeVal.charAt(rangeVal.length - 1);
            if (! isNaN(rangeNo) && this.allowedTimeUnits.includes(rangeUnit)) {
                this.relativeToNowToDates(rangeNo, rangeUnit);
            } else {
                console.error('Wrong relative to now range value:', rangeVal);
            } 
        } else  {
            console.error('Unknown range value:', rangeVal);
        }
        this.createRangeValues();
    }

    createRangeValues() {
        if (this.rangeDates[0].getTime() !== this.rangeDates[1].getTime()) {
            // do not emit if range start is the same as end (new Date(), new Date())
            this.rangeFilters[0][this.filter[0]] = [this.rangeDates[0].toISOString()];
            this.rangeFilters[1][this.filter[1]] = [this.rangeDates[1].toISOString()];
            this.onValueChange.emit(
                { params: this.rangeFilters }
            );
        }
    }

    createEmptyRangeValues() {
        this.rangeFilters[0][this.filter[0]] = [''];
        this.rangeFilters[1][this.filter[1]] = [''];
        this.onValueChange.emit(
            { params: this.rangeFilters }
        );
    }

    verbalValueToDates(rangeVal) {
        let startDate = new Date(); 
        let endDate = new Date();
        let dayOfWeek = endDate.getDay();
        let dateStringWithTime = false;
        switch(rangeVal) {
            case 'today':
                endDate.setHours(23, 59, 59, 0);
                startDate.setHours(0, 0, 0, 0);
                dateStringWithTime = true;
                break;
            case 'yesterday':
                endDate.setHours(0, 0, -1, 0); // 1s before midnight
                startDate.setHours(-24, 0, 0, 0);
                dateStringWithTime = true;
                break;
            case 'week_to_date':
                endDate.setHours(23, 59, 59, 0);
                startDate.setHours((0 - dayOfWeek) * 24, 0, 0, 0);
                dateStringWithTime = true;
                break;
            case 'previous_week':
                endDate.setHours( (0 - dayOfWeek) * 24, 0, -1, 0);
                startDate.setHours( (0 - (7 + dayOfWeek)) * 24, 0, 0, 0);
                break ;
            case 'month_to_date':
                endDate.setHours(23, 59, 59, 0);
                startDate.setDate(1);
                startDate.setHours(0, 0, 0, 0);
                dateStringWithTime = true;
                break;
            case 'previous_month':
                endDate.setDate(1);
                endDate.setHours(0, 0, -1, 0);
                startDate.setMonth(startDate.getMonth() - 1); // if January, -1 will bring to December
                startDate.setDate(1);
                startDate.setHours(0, 0, 0, 0);
                break;
            case 'year_to_date':
                endDate.setHours(23, 59, 59, 0);
                startDate.setMonth(0);
                startDate.setDate(1);
                startDate.setHours(0, 0, 0, 0);
                dateStringWithTime = true;
                break;
            case 'previous_year':
                let prevYear = endDate.getFullYear() - 1;
                startDate = new Date (prevYear, 0, 1, 0, 0, 0, 0)
                endDate = new Date (prevYear, 11, 31, 23, 59, 59, 0)
                break;
        }
        this.rangeDates = [startDate, endDate];
        this.createRangeValues();
        //this.createRangeString(dateStringWithTime);
    }

    relativeToNowToDates(rangeNo, rangeUnit) {
        let endDate = new Date();
        let startDate = new Date()
        if (rangeUnit !== 'h') {
            endDate.setHours(23, 59, 59, 0);
            startDate.setHours(24, 0, 0, 0);
        }
        switch(rangeUnit) {
            case 'h':
                let currentHour = endDate.getHours();
                startDate.setHours((rangeNo * -1) + currentHour);
                break;
            case 'd':
                startDate.setHours(rangeNo * -24);
                break;
            case 'M':
                startDate.setMonth(startDate.getMonth() - rangeNo);
                break;
            case 'y':
                startDate.setFullYear(startDate.getFullYear() - rangeNo);
                break;    
        }
        this.rangeDates = [startDate, endDate];
        this.createRangeValues();
        //this.createRangeString(true);
    }

    handleCustomCalendar(event) {
        if (! this.selectedCalendarOption || this.selectedCalendarOption !== 'custom') {
            this.selectedCalendarOption = 'custom';
        }
        if (this.rangeDates.length === 2 && this.rangeDates[1] && this.rangeDates[0] !== this.rangeDates[1]) {
            this.rangeDates[0].setHours(0, 0, 0, 0); // reset to midnight
            this.rangeDates[1].setHours(24, 0, -1, 0); // add a day but take away secod to avoid new date
            this.createRangeValues();
        }
        //this.createRangeString();
    }

    // createRangeString(time=false) {
    //     let format = time ? 'yyyy-MMM-dd hh:mm:ss' : 'yyyy-MMM-dd';
    //     let separator;
    //     if (time) {
    //         format = 'yyyy-MMM-dd h:mm:ss a'
    //         this.largeDt = true;
    //         separator = ' &ndash;<br />';
    //     } else {
    //         format = 'yyyy-MMM-dd';
    //         this.largeDt = false;
    //         separator = ' &ndash; ';
    //     }
    //     let startDate = this.rangeDates[0] ? this.datePipe.transform(this.rangeDates[0], format) : '';
    //     let endDate = this.rangeDates[1] ? this.datePipe.transform(this.rangeDates[1], format) : '';
    //     this.rangeDatesString = startDate ? startDate + separator + endDate: '';
    // }

    clearDate(resetDropDown=false) {
        this.rangeDates = [new Date(), new Date()];
        //this.rangeDatesString = '';
        this.largeDt = false;
        if (resetDropDown) {
            this.selectedCalendarOption = null;
            this.createEmptyRangeValues();
        }
    }

}
