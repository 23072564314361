import { PermissionGroup, PermissionGroupDefinition } from './auth.types';
import { pipelineTypes as pipeTypes } from '../shared/enum';

export const apiPermGroup : PermissionGroupDefinition = {
    crud: {
        'container': 'Content Endpoint',
        'resource': 'Resource Endpoint',
        'segment': 'Segment Endpoint',
        'schema': 'Schema Endpoint',
        'entitytype':  'Entity Type Endpoint',
        'workflow': 'Workflow Endpoint',
        'tag': 'Container Tag Endpoint',
        'resourcetag': 'Resource Tag Endpoint',
    },
    read: {
        'historicalcontainer': 'Content History',
        'historicalresource': 'Resource History',
        'historicalschema': 'Schema History',
        'profile': 'Profile Endpoint',
    },
};

export const objectPermGroup : PermissionGroupDefinition = {
    write: {
        'all': 'All',
        'view': 'View',
        'rotator': 'Rotator',
        'collection': 'Collection',
        'series': 'Series',
        'season': 'Season',
        'episode': 'Episode',
        'media': 'Media',
        'movie': 'Movie',
        'campaign': 'Campaign',
        'content_group': 'Content Group',
        'moment': 'Moment',
        'document': 'Document',
        'person': 'Person',
        'cast': 'Cast',
        'advertisement': 'Advertisement',
        'workflow': 'Workflow',
        'hard_lock': 'Release Hard Lock',
        'soft_lock': 'Release Soft Lock',
        'test_suite': 'Test Suite',
    },
    read: {},
};

export const viewPermGroup : PermissionGroupDefinition = {
    show: {
        'content': 'Content',
        'curate' : 'Curate',
        'create': 'Create',
        'workflow': 'Workflow',
        'metrics': 'Metrics',
        'reports': 'Reports',
        'settings': 'Settings',
        'upload': 'Upload',
        'beta': 'Beta',
        'admin': 'Admin',
        'commerce': 'Commerce',
        'history': 'History',
        'revert': 'Revert',
        'bulktag': 'Tags (Bulk)',
        'schema': 'Schema',
        'entityType': 'Entity Type',
        'segment' : 'Segment',
        'persons': 'Persons',
        'locks': 'Locks',
        'tag': 'Container Tag',
        'resourcetag': 'Resource Tag',
        'tests':'Tests',
        'run_tests':'Run Tests',
    }
};

export const pipelinePermGroup : PermissionGroupDefinition = {
    type: pipeTypes,
    action: {
        'start': 'Restart/Start',
        'wait': 'Wait',
        'cancel': 'Cancel',
    },
};

export const allPermGroups = {
    api: apiPermGroup,
    view: viewPermGroup,
    object: objectPermGroup,
    pipeline: pipelinePermGroup,
};
