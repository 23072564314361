<page-header title="Edit Notification" [showSpinner]="isLoading"></page-header>
<div *ngIf="container" class="card card-w-title top-level-card">
  <h1>Edit Container{{!isLoading && container ? ' : ' +container.data.name : ''}}</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <notification-form #form [container]="container" (formSubmit)="onSubmit(container)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></notification-form>
    </div>
  </div>

  <!-- ***** Container Resource ****** -->
  <div class="ui-g-12">
    <resource-datarow *ngIf="!jobInProgress" [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
        <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload"  *ngxPermissionsOnly="['view.show.upload']"></button>
        <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource']" *ngxPermissionsOnly="['object.write.all', 'object.write.series']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
        <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Create" [disabled]="jobInProgress" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
    </resource-datarow>
  </div>

  <!-- ***** Child Items ****** -->
  <p-panel class="ui-g-12">
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-4 ui-lg-4" style="padding:0;font-size:16px;">Child Items
              <p-badge value='{{ containerCount }}' styleClass="p-mr-2" severity="info"></p-badge>
            </span>
            <div class="ui-g-12 ui-md-8 ui-lg-8" style="padding:0;">
                <container-add [container]="container" [defaultAddAction]="defaultAddAction" [addActionsList]="addActionsList" style="float:right;"></container-add>
            </div>
            <spinner *ngIf="isReordering" class="ui-g-12"></spinner>
        </div>
    </p-header>

    <div class="collection__content">
        <container-paginated-datarow type="all"
                        [typeExclude]="typeExclude"
                        itemsPerPage = 20
                        [parentContainer]="container"
                        filterBy="data.title, data.keywords"
                        [sortOptions]="collectionSortOptions"
                        class="nested-panel"
                        [badgeVisible]="False"
                        (countUpdatedEvent)="updateCount($event)" 
                        (reorder)="onReorder($event)">
        </container-paginated-datarow>
    </div>
  </p-panel>

  <div class="ui-g-12">
    <server-table [containers]="containers" [parentContainer]="container">
    </server-table>
  </div>

  <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">Template</span>
    </p-header>
    <div class="ui-g-12">
        <textarea [rows]="20" class="ui-g-12" pInputTextarea name="payload" [(ngModel)]="container.data.template" autoResize="autoResize"></textarea>
    </div>
  </p-panel>

  <p-panel class="ui-g-12">
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">QuerySet</span>
    </p-header>
    <json-editor #queryset [options]="editorOptionsQueryset" [data]="container.data.query"></json-editor>
  </p-panel>

  <div class="p-mt-5 p-p-2">
    <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
  </div>

</div>
