import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Resource } from '../../models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';


@Injectable()
export class ResourceService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    // typeName: displayPreview
    static readonly onlyTypes = {
        'file': false,
        'video': false,
        'audio': false,
        'captions': false,
        'thumbnail': true,
        'thumbnail_small': true,
        'hero': true,
        'logo': true,
        'banner': true,
        'boxart': true,
        'background': true,
        'tile_artwork': true,
        'cover_artwork_square': true,
        'cover_artwork_vertical': true,
        'metadata': false,
        'image': true,
        'link': false,
        'credential': false,
        'identifier': false,
    }

    static readonly fixedTypes = [
        //'file',
        'credential',
        'identifier',
        // 'video',
    ];

    static readonly fileTypes = [
        'file',
    ];

    static readonly noUploadTypes = [
        'credential',
        'identifier',
    ]

    static readonly statusList = [
        'new',
        'published',
        'unpublished',
        'hidden',
    ];

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private messageService: MessageService,
        private appContext:AppContext,
    ) {}

    get(id): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/resources/${id}/`, { headers: headers }
            );
    }

    list(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/resources`, { headers: headers }
            );
    }

    save(resource: Resource, containerId): Observable < any > {
        console.log('resource service save', resource);

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        // headers.append('Accept',       'application/vnd.corus.api.core.services.proxy+json');

        let resp;
        let data = JSON.stringify({
            uri: resource.uri,
            guid: resource.guid,
            namespace: resource.namespace,
            subtype: resource.subtype,
            origin: this.appContext.activeOrigin,
            tags: resource.tags,
            type: resource.type.id || resource.type,
            data: resource.data,
            status: resource.status,
        });

        // create new resource
        if (resource.id === -1) {
            resp = this.http
                .post(
                    `${this.baseUrl}/containers/${containerId}/resources/`,
                    data, { headers: headers }
                ).pipe(
                tap(
                    res => {
                        resource.id = res['id'];
                    }
                ));
        } else {
            resp = this.http
                .put(
                    `${this.baseUrl}/resources/${resource.id}/`,
                    data, { headers: headers }
                );
        }

        return resp.pipe(tap(
            // At some point it might be worth moving all the "changes saved" messages
            // to here instead of per-form versions
            null,
            // Log error message from API
            err => this.showError(err),
        ));
    }

    delete(resource: Resource): Observable < any > {
        console.log('resource service delete', resource);

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .delete(
                `${this.baseUrl}/resources/${resource.id}/`, { headers: headers }
            ).pipe(
            tap(
                // At some point it might be worth moving all the "changes saved" messages
                // to here instead of per-form versions
                null,
                // Log error message from API
                err => this.showError(err),
            ));
    }

    isResourceType(typeName: string) {
        return ResourceService.onlyTypes.hasOwnProperty(typeName);
    }

    isFixedType(typeName: string) {
        return ResourceService.fixedTypes.includes(typeName);
    }

    isFileType(typeName: string) {
        return ResourceService.fileTypes.includes(typeName);
    }

    toUpload(typename: string){
        return ResourceService.noUploadTypes.includes(typename);
    }

    displayPreview(typeName: string) {
        return !!ResourceService.onlyTypes[typeName];
    }

    getFormat(uri: string) {
        let arr = (uri || '').split('?')[0].split('.');
        return arr.length > 1 ? arr[arr.length - 1] : '';
    }

    private showError(err, summary='Error') {
        const detail = (err.error && err.error.detail) || err.statusText;
        this.messageService.add({ key: 'api', severity: 'error', summary, detail });
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }
}
