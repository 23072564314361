import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { ContainerService } from '../../services/container/container.service';
import { AuthService } from '../../../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { Resource } from '../../models';
import { Container } from '../../models/container';

import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'trigger-pipeline-modal-form',
    providers: [
        // PipelineService,
        // EntityTypeService,
        // ContainerService,
        // AuthService,
    ],
    templateUrl: 'trigger-pipeline-modal-form.component.html',
    styles: [`
        .ui-steps .ui-steps-item {
            width: 100%;
            `]
})
export class TriggerPipelineModalFormComponent implements OnInit {
    @Output() pipelineTriggered: EventEmitter<any> = new EventEmitter();
    @Output() pipelineFailed: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    @Input() container: Container;
    @Input() pipelineTypes: Array<string>;

    @Input() confirmDialogMessage: string;

    public displayDialog = false;
    public isDisabled = true;
    private error: string;
    public isLoading = false;
    public selectedPipelineId;
    private selectedEntityType;
    private pipelineContainer;
    pipelines: any[] = [];
    private selectedSeries;
    selectedProfile: string;
    selectedTemplate: string;
    private currentUser: Object = {};
    constructor(
        private pipelineService: PipelineService,
        private entityTypeService: EntityTypeService,
        private containerService: ContainerService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.currentUser = this.authService.getAuthenticatedUser();
        this.getPipelineData();
    }


    checkStatus() {
        return !(this.pipelines.length && this.container && this.container.id > 0);
    }

    resetFormData() {
        this.selectedPipelineId = '';
    }

    showDialog() {
        if ((this.confirmDialogMessage == null) || ((this.confirmDialogMessage != null) && confirm(this.confirmDialogMessage))) {
            this.resetFormData();
            this.displayDialog = true;
        }
    }

    closeDialog() {
        this.displayDialog = false;
    }

    getPipelineData() {
        this.isLoading = true;
        this.pipelineService
            .pipelineSearch(this.pipelineTypes, ['container_id'], this.currentUser)
            .subscribe(
                res => {
                    this.pipelines = res;
                    this.isDisabled = this.checkStatus();
                    console.log(this.isDisabled);
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onPipelineChange() {
        this.pipelineContainer = this.pipelines.find(t => t.id === parseInt(this.selectedPipelineId, 10));
    }

    triggerPipeline() {
        if (this.pipelineContainer) {
            this.isLoading = true;

            let username = this.authService.getAuthenticatedUserName();
            let external_id = `vms:${username}:${this.container.guid}`;
            let bundle_id = this.pipelineContainer.data['bundle_id'];

            console.log('Triggering pipeline:', bundle_id, external_id);
            this.pipelineService
                .startPipeline(
                    bundle_id,
                    { container_id: this.container.id },
                    external_id
                )
                .subscribe(
                    res => {
                        console.log('Pipeline started:', res);
                        this.onStatusComplete(res);
                    },
                    err => {
                        this.pipelineFailed.emit({
                            container: this.container,
                            error: err,
                        });
                        console.error(err);
                        this.isLoading = false;
                    },
                    () => this.isLoading = false
                );
        } else {
            console.log('No pipeline selected');
            this.onStatusComplete();
        }
    }

    onStatusComplete(startResult : Object = null) {
        let ev = {
            container: this.container,
        };
        if (startResult && startResult['data'] && startResult['data']['id']) {
            ev['pipeline_id'] = startResult['data']['id'];
            ev['bundle_id'] = startResult['data']['bundle_id'];
        }
        console.log(ev);

        this.resetFormData();
        this.pipelineTriggered.emit(ev);
        this.closeDialog();
    }
}


