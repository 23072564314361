import { Component, OnInit, SimpleChanges, Input, OnChanges } from '@angular/core';
import { Container } from '../../content/models';

@Component({
    selector: 'landing-postmodal',
    templateUrl: 'landing-postmodal.component.html',
    styleUrls: ['landing-postmodal.component.css'],
})
export class PostModalComponent implements OnChanges {
  @Input() selectedPost: Container;
  showModal: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.showModal = changes.selectedPost && this.selectedPost ? true : false
  }

  onCloseModel() {
    this.showModal = false
  }

}
