<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
    <h1>New User Profile</h1>
    <page-header title="New User Profile" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <div *ngIf="container && !isLoading">
        <p-messages [(value)]="requiredMsgs"></p-messages>
    </div>
    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <user-profile-form class="ui-g-12" #form [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)"></user-profile-form>
    </div>
</div>
