<p-panel class="ui-g-12 ui-md-12 ui-lg-12">
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">{{ label }}</span>
    </p-header>
    <div class="content-items" *ngFor="let container of containers">
        <a href="#" *ngxPermissionsOnly="['view.show.content']" (click)="navigateToContent(container,$event)">
            <i class="fa fa-fw fa-caret-right" ></i>
                {{ container.data.title || "Content" }} - {{ container.type | titlecase }}
        </a>
      </div>
</p-panel>
