import { Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MetricsReportsListComponent } from './../metrics-reports-list/metrics-reports-list.component';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { MetricsService } from '../../services/metrics.service';


@Component({
    selector: 'metrics-reports-data-table',
    templateUrl: './metrics-reports-data-table.component.html',
    styleUrls: ['metrics-reports-data-table.component.css']
})
export class MetricsReportsDataTableComponent implements OnInit, OnChanges, OnDestroy {


    @Input() reportData: any;
    @Input() renderOptions:  any;

    private sub: any;
    private isLoading = false;
    private error: any;

    resolveLabels: boolean = false;
    formattedTableData: any[];
    resolvedGUIDContainers: any;
    columns: any[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private metricsService: MetricsService

    ) 
    { 
        this.initializeTableData();
    }

    ngOnChanges(changes: SimpleChanges) {
      if ('reportData' in changes && changes['reportData'].previousValue != changes['reportData'].currentValue) {
          this.initializeTableData();
          if (this.reportData) {
              this.createTableDataFromResults(this.reportData['results']);
          }
      }
      if ('renderOptions' in changes && changes['renderOptions'].previousValue != changes['renderOptions'].currentValue ){
          this.resolveLabels = !!(this.renderOptions['resolve_labels']);
      }
    }

    ngOnInit() {
        this.columns = [
            { field: 'name', header: 'Key' },
            { field: 'value', header: 'Value' },
        ];
        // this.formattedReportData = this.reportData;
    }

    initializeTableData() {
      this.formattedTableData = [];
    }

    createTableDataFromResults(results) {
      let tableValues = results.map(element => {
        return { name: element['label'], value: element['value'] };
      });

      if (this.sub) {
          this.sub.unsubscribe();
          this.sub = null;
      }

      if (this.resolveLabels) {
          this.resolveGUIDLabels(tableValues);
      } else {
          this.formattedTableData = tableValues;
          this.addTotal(this.formattedTableData);
      }
    }

    resolveGUIDLabels(graphValues){
      let guidArray = graphValues.map(element => element['name']);
      this.isLoading = true;
      this.sub = this.metricsService
          .getContainerByGUIDArray(guidArray)
          .subscribe(
              res => {
                  this.resolvedGUIDContainers = res.results;
              },
              err => {
                  console.log(err,'Report GUID Resolver Error');
                  this.error = err.statusText;
              },
              () => {
                  const updatedValues = [];
                  const resolvedMap = new Map(this.resolvedGUIDContainers?.map(container => {
                      return [container['guid'], container];
                  }));

                  graphValues.forEach((element, i) => {
                      if (resolvedMap.has(element['name'])) {
                          const container = resolvedMap.get(element['name']);
                          let label = container['label'];
                          if (container['data']['season_number']) {
                            label = this.formatLabel(label) + "Season "+ container['data']['season_number'];
                          }
                          if (container['data']['episode_number']) {
                            label = this.formatLabel(label) + "Episode "+ container['data']['episode_number'];
                          }
                          if (container['data']['show_title']) {
                              label += " ("+ container['data']['show_title'] + ")";
                          }
                          updatedValues.push({ name: label, value: element['value'] });
                      } else {
                          updatedValues.push(element);
                      }
                  })
                  this.formattedTableData = updatedValues;
                  this.addTotal(this.formattedTableData);
                  this.isLoading = false;
              }
          );
    }
    
    formatLabel(label) {
        if(label) {
            label += ", ";
        }
        return label;
    }

    addTotal(data) {
        this.formattedTableData.push(data.reduce((acc, obj) => {
        acc.value += +obj.value // (+)obj.value to convert string value to a number value if any
        return acc
        }, {name: 'Total', value: 0}))
    }

    ngOnDestroy() {
    }

    onSubmit(e) {
    }

}
