import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AppContext } from '../../app.context';


@Injectable()
export class WorkflowService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext
    ) {}

    private appendOriginQueryParamFromContext(ignore = false)
    {
        if (!ignore)
        {
            return '&origin=' + this.appContext.activeOrigin
        }

        return ''

    }

    getContentWorklist(nest = 'full', depth = 1): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/?nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(), { headers: headers }
            );
    }

    filterByTypeName(typeName: string, nest = 'full', depth = 1): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?type=${typeName}&nest=${nest}&depth=${depth}`+ this.appendOriginQueryParamFromContext(), { headers: headers }
            );
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }
}
