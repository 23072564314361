import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WorkflowService } from '../../../workflow/services';
import { CAContextSearchTableComponent } from './../contextual-ads-context-search/contextual-ads-context-search.component';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { ContentGroupService } from '../../services/content-group.service';


@Component({
    templateUrl: './contextual-ads-context.component.html',
    styleUrls: ['contextual-ads-context.component.css']
})
export class CAContextWorklistComponent implements OnInit, OnDestroy {
    @ViewChild(CAContextSearchTableComponent) worklistTable:  CAContextSearchTableComponent;

    private sub: any;
    private containers = [];
    private requiredMsgs = [];
    private selectedContainer: Container;
    public error: any;
    public isLoading = false;
    private defaultSearchTerm: string;
    private cgState: any;
    private destroy$ = new Subject();

    constructor(
        private workflowService: WorkflowService,
        private route: ActivatedRoute,
        private router: Router,
        private cgService: ContentGroupService
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            if (params['searchterm']) {
                this.defaultSearchTerm = params['searchterm'];
            }

        });

        this.destroy$.next(false);
        this.createCgSub();
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
                this.selectedContainer = this.cgState['contentGroup'];
            });
    }

    onContainerUpdate(event){
        this.worklistTable.rowUpdate(this.selectedContainer.guid);
    }


    containerSelected(event) {
        this.selectedContainer = event.container;
    }

    onSubmit(e) {
        console.log(e);
    }
}
