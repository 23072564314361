<div *ngIf="!isLoadingTags">        
    <div *ngFor="let tagType of visibleTagsTypes" class="ui-g-4 ui-md-4 ui-lg-4">
        <label for="tagType">{{tagType | labelToDisplay}}</label>
        <span class="p-float-label">
            <p-multiSelect *ngIf="!hideTagChips" 
                            [options]="tagsDict[tagType]" 
                            [(ngModel)]="selectedTagsDict[tagType]" 
                            name="tagType" 
                            optionLabel="label" 
                            maxSelectedLabels="0" 
                            [selectedItemsLabel]="'{0} selected'"
                            [panelStyle]="{minWidth:'20em'}"
                            [defaultLabel]="'Tag(s)'"
                            (onChange)="onChangeTags()">
                <ng-template let-tag pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                </ng-template>
            </p-multiSelect>
            <p-multiSelect *ngIf="hideTagChips" 
                            [options]="tagsDict[tagType]" 
                            [(ngModel)]="selectedTagsDict[tagType]" 
                            name="tagType" 
                            optionLabel="label" 
                            maxSelectedLabels="100" 
                            [panelStyle]="{minWidth:'20em'}"
                            [defaultLabel]="'Tag(s)'"
                            (onChange)="onChangeTags()">
                <ng-template let-tag pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                </ng-template>
            </p-multiSelect>
        </span>
    </div>
    <p-chips class="ui-g-12" *ngIf="visibleTags.length > 0 && !hideTagChips" [(ngModel)]="visibleTags" [max]="visibleTags.length" field="label" (onRemove)="updateChips($event)"></p-chips>
    <p-panel *ngIf="!hideTagChips && hiddenTagsTypes && hiddenTagsTypes.size > 0" class="ui-g-12" header="Additional Tags" [toggleable]="true" [collapsed]="true">
        <div *ngFor="let tagType of hiddenTagsTypes" class="ui-g-4 ui-md-4 ui-lg-4">
            <label for="tagType">{{tagType | labelToDisplay}}</label>
            <span class="p-float-label">
                <p-multiSelect *ngIf="!hideTagChips" 
                                [options]="tagsDict[tagType]"
                                [(ngModel)]="selectedTagsDict[tagType]" 
                                name="tagType" 
                                optionLabel="label" 
                                maxSelectedLabels="0" 
                                [selectedItemsLabel]="'{0} selected'" 
                                [panelStyle]="{minWidth:'20em'}"
                                [defaultLabel]="'Tag(s)'"
                                (onChange)="onChangeTags()">
                    <ng-template let-tag pTemplate="item">
                        <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                    </ng-template>
                </p-multiSelect>
            </span>
        </div>
    </p-panel>
</div>  
 
<p-progressSpinner *ngIf="isLoadingTags == true" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>