import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Segment } from '../../models';
import { Message } from 'primeng/api';
import { AppContext } from '../../../app.context';
import { indicatorIconTypes, workflowStateSegmentTransitionTypes } from '../../../shared/enum';

@Component({
    selector: 'workflow-state-segment-form',
    templateUrl: 'workflow-state-segment-form.component.html',
    providers: [
        DatePipe
    ],
    styleUrls: ['workflow-state-segment-form.component.css']
})
export class WorkflowStateSegmentFormComponent implements OnInit {
    @Input() segment: Segment;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    public isLoading = false;
    public transitionTypes = workflowStateSegmentTransitionTypes;
    public indicatorIconTypes = indicatorIconTypes;

    private expirationDate: Date;
    private publishDate: Date;
    private msgs: Message[] = [];

    constructor(
        private datePipe: DatePipe,
        private appContext: AppContext,
    ) { }

    ngOnInit(): void {
        this.getDefaultReadOnlyValues();
        this.getDates();
    }

    getDefaultReadOnlyValues() {
        this.segment.type = 'workflow_state';
        this.segment.origin = this.segment.origin ? this.segment.origin : this.appContext.activeOrigin;
    }

    getDates() {
        this.publishDate = this.getDateFromDateString(this.segment.published_date);
        this.expirationDate = this.getDateFromDateString(this.segment.expiration_date);
    }

    getDateFromDateString(dateString) {
        if (!dateString) {
            return null;
        }
        let date = new Date(dateString);
        return date
    }

    formatDate(date) {
        if (!date) {
            return null;
        }
        let dateString = date.toISOString();
        return this.datePipe.transform(dateString, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSZ");
    }

    onSubmit(e) {
        this.segment.published_date = this.formatDate(this.publishDate);
        this.segment.expiration_date = this.formatDate(this.expirationDate);
        this.msgs = [];
        var valid = this.ngForm.form.status === 'VALID';

        if (!/\S/.test(this.segment.label)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment label.' });
            valid = false;
        }

        if (!/\S/.test(this.segment.value)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment value.' });
            valid = false;
        }

        if (!/\S/.test(this.segment.status)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment status.' });
            valid = false;
        }

        if (!('transition_type' in this.segment.data) || (!this.segment.data['transition_type'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please select the transition type.' });
        }

        if (valid) {
            this.ngForm.form.markAsPristine();
            this.formSubmit.emit({
                segment: this.segment
            });
        } else {
            this.formFailed.emit();
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

}
