import { Component, OnInit, Input, Output, EventEmitter, ElementRef,  OnDestroy, ViewChild } from '@angular/core';
import { Container } from '../../../content/models';
import { CommerceService } from '../../services/commerce.service';
import { AutoComplete } from 'primeng/autocomplete';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'segment-keyword-autocomplete',
    providers: [
        CommerceService
    ],
    templateUrl: 'segment-keyword-autocomplete.component.html',
    styleUrls: ['segment-keyword-autocomplete.component.css']
})
export class CASegmentKeywordComponent implements OnInit {

    private query: any;
    private fetchedKeywords: any[] = [];
    public suggestedKeywords: any[] = [];
    private firstSuggestedKeyword: any;
    private error: any;
    private isLoading = false;
    private isSelecting = false;
    private autosuggestSub: any;
    private selectObs: any;

    //public selectedKeywords : any[] = [];
    @Input() container: Container;
    @Output() onKeywordChange: EventEmitter <any> = new EventEmitter();
    @ViewChild('autoCmpl') autoCmpl: AutoComplete;

    constructor(
        private commerceService: CommerceService,
    ) {}

    ngOnInit() {
    }

    ngOnDestroy() {
        if(this.autosuggestSub) {
            this.autosuggestSub.unsubscribe();
        }
        if(this.selectObs) {
            this.selectObs.unsubscribe();
        }
    }

    // Only display message when adding/removing keywords from the existing container
    onChange(event){
        if (this.container['guid']) {
            this.onKeywordChange.emit ({
                requiredmsgs:  {severity:'warn', summary: 'Warning', detail: 'Keywords change(s) must be saved'}
            })
        }
    }

    // Here rather than onChange, as user may select an existing keyword - 
    // that should not result in finishAbandonedSelection
    onKeywordSelect(event) {
        this.isSelecting = false;
    }

    getKeywordSuggestions(event)
    {   
        this.query = event.query;
        this.fetchedKeywords = [];
        this.autosuggestSub = this.commerceService
            .getKeywordSuggestion(event.query.toLowerCase())
            .subscribe(
                res => { console.log(res)
                    let suggestionPayload = res;
                    console.log("KEYWORD pay:",suggestionPayload)
                    for(let c = 0; c < suggestionPayload.length; c++) {       
                        this.fetchedKeywords.push({'label':suggestionPayload[c]['label'],'value':suggestionPayload[c]['value']});
                    }
                },
                err => this.error = err,
                () =>{
                    // Fetched Keywords list is re-assigned here to avoid unexpected behaviors (maybe bugs??)
                    this.suggestedKeywords = this.fetchedKeywords;
                    if (this.suggestedKeywords.length) {
                        this.firstSuggestedKeyword = this.suggestedKeywords[0];
                    } else {
                        this.firstSuggestedKeyword = null;
                    }
                    this.isLoading = false;
                    this.isSelecting = true;
                }
        );
    }

    onDeselect(event) {
        // need a delay, because onSelect fires after onBlur
        this.selectObs = of(event)
            .pipe(delay(500))
            .subscribe(() => this.finishAbandonedSelection());
    }

    finishAbandonedSelection() {
        if (this.isSelecting) {
            // assuming user did not complete selection succesfully
            if (this.query && this.firstSuggestedKeyword) {
                if (this.container['data']['segment_keywords']) {
                    let checkExistingKeyword = this.container['data']['segment_keywords'].some(item => item.label === this.firstSuggestedKeyword.label);
                    if (!checkExistingKeyword) {
                        this.container['data']['segment_keywords'].push(this.firstSuggestedKeyword);
                    }
                } else {
                    this.container['data']['segment_keywords'] = [this.firstSuggestedKeyword];
                }
                this.onChange(null);
            }
            try {
                // very hacky, but forceSelection in p-autocomplete results in bizarre behaviour
                let childNodes = this.autoCmpl.el.nativeElement.children[0].children[0].children 
                for (let i = 0; i <  childNodes.length; i++) {
                    if (childNodes[i].children[0].nodeName === 'INPUT') {
                        childNodes[i].children[0].value = '';
                    }
                }
            } catch (e) {
                console.log('Error clearing autosuggest', e)
            }
            this.isSelecting = false;
            this.query = null;
        }
    }

}
