<p-panel class="ui-g-12 ui-md-12 ui-lg-12">
  <p-header>
    <span class="p-panel-title" style="font-size:16px;" data-element-id="app-landing-landing-whatsnew">What's New</span>
  </p-header>
  <spinner *ngIf="isLoading"></spinner>
  <div *ngFor="let post of posts" (click)="onSelect(post)" style="padding:0.5em;cursor: pointer;">
    <strong class="m-0">{{ post.data.title }}</strong>
      <p class="post-content">{{post.data.description||sanitize(post.data.content)}}</p>
  </div>
</p-panel>
