<div>

    <p-table #dt [value]="containers"
            [rows]="10" [paginator]="true"
            selectionMode="single"
            (onRowSelect)="onRowSelect($event)"
            [(selection)]="container"
            [globalFilterFields]="['data.label', 'data.bundle_id', 'data.description', 'guid', 'data.catalog_type']"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="caption">
            <div class="p-grid" style="margin-top:0">
                <div class="p-col" style="padding:4px 10px;border-bottom: 0 none;text-align:left">
                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                    <input #gb type="text"
                            pInputText size="50"
                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Global Filter">
                </div>
                <div class="p-col" style="text-align:right">
                    <button pButton type="button" label="Create" [routerLink]="['/catalog', 'create']" icon="pi pi-plus" data-element-id="app-content-components-catalog-table-create"></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Label</th>
                <th>Bundle Id</th>
                <th>Description</th>
                <th>GUID</th>
                <th>Type</th>
                <th>Status</th>
                <th style="width:6em"></th>
                <th style="width:6em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-container>
            <tr>
                <td>{{container.data?.label}}</td>
                <td style="word-break: break-word;">{{container.data?.bundle_id}}</td>
                <td>{{container.data?.description}}</td>
                <td>{{container.guid}}</td>
                <td>{{container.data?.catalog_type}}</td>
                <td>
                    <div class="ui-g-12 label"
                        [ngClass]="statusLabelClass(container.status)"
                        style="width: auto;">{{ container.status | titlecase }}
                    </div>
                </td>
                <td>
                    <button pButton type="button" (click)="openCatalog(container.guid)" label="Preview" [disabled] = "container.status === 'published' ? false: true"  class="p-button-success" target="_blank"  style="width:100%;font-size:13px;" ></button>
                </td>
                <td>
                    <button pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/catalog', container.id]" style="width:100%;font-size:13px;" ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
