import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TagService } from '../../services/tag/tag.service';
import { Container } from '../../models';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'tags-assign',
  providers: [
    TagService
],
  templateUrl: './tags-assign.component.html',
  styleUrls: ['./tags-assign.component.css']
})
export class TagsAssignComponent implements OnInit {
  public isLoading:boolean = false
  public container:Array<Container> = [];
  private uCntIds:{[key:string]:any}={}
  public reload:Boolean;

  constructor(
    private tagService: TagService,
    private msgService: MessageService,
    private location: Location,
  ) { }

  ngOnInit(): void {}

  onCntSelected(cnts) {
    this.isLoading = true
    let cnt = this._add_or_remove_cnt(cnts)
    if (cnt) {
      cnt.map(cnt => {
        this.tagService.getTagByContainerId(cnt.id).subscribe(
          (res: any) => {
            cnt.tags = res.results
            if (cnt.tags.length > 0) {
              cnt.tags.map(tag=>tag.e=true)
              !this.container[0]?.tags.some(tag=>tag.all==='n')?null:
                this.container[0].tags.map(tag=>tag.all!=='n'? null:
                (cnt.tags.push(tag),this.uCntIds.ad?.cntId.push(cnt.id),this.uCntIds.ad?.tagId.push(tag.id)))
              this.container.length>0?this._lblTags([...this.container, cnt]):
                (this.container=(cnt.tags.map(tag=>tag.all=true), [cnt]))
            } else {
              this._lblTags([...this.container, cnt])
            }
          },
          err => console.log(err),
          () => this.isLoading = false
        )
      })
    }
  }

  _add_or_remove_cnt(cnts) {
    if (cnts > this.container) {
      return cnts.filter(nCnt => !this.container.some(oCnt => oCnt.id === nCnt.id))
    } else {
      let uCnts = this.container.filter(oCnt => cnts.some(nCnt => nCnt.id===oCnt.id))
      this.onCntUnselect(uCnts)
      uCnts.length > 0 ? this._lblTags(uCnts): this.container = uCnts
    }
  }

  onCntUnselect(cnts) {
    // *** Update modified container list upon user unselects a selected container
    if (this.uCntIds.ad) {
      this.uCntIds.ad.cntId = this.uCntIds.ad.cntId.filter(cntId => cnts.some(cnt => cnt.id===cntId))
      !this.uCntIds.ad.cntId.length? this.uCntIds.ad.tagId = []: null
    }
    if (this.uCntIds.rm) {
      this.uCntIds.rm.cntId = this.uCntIds.rm.cntId.filter(cntId => cnts.some(cnt => cnt.id===cntId))
      !this.uCntIds.rm.cntId.length? this.uCntIds.rm.tagId = []: null
    }
  }

  _lblTags(cnts) {
    let mCnt = cnts.indexOf(cnts.find(cnt =>  cnt.tags.length > 0))
    mCnt === (0 || -1) ? null : cnts.unshift(cnts.splice(mCnt,1)[0])
    cnts.map(mcnt=>mcnt.tags.map(mtag=>!!cnts.map(cnt=>
      cnt.tags.some(obj=>mtag.id===obj.id)).reduce((a,b)=>a===b?a:NaN)?(mtag.all==='n'?null:mtag.all=true):mtag.all=false)
    )
    this.container = [...cnts]
  }

  onTagSelected(e){
    // *** Check if a user added a new tag or removed an existing tag
    if (e.nTags > e.oTags) {
    // *** loop thru containers and find the newly added tag by the user
      e.nTags.map(ntag => { let found = false
        for (let cnt of this.container) {
          let otag = cnt.tags.find(otag=>otag.id===ntag.id)
          if (otag){otag.all===ntag.all;found=true;break;}
        }
    // *** Build/Update list of selected containers alonwith list of tags "added" by the user to be passed into the tagService upon save
        if (!found) { ntag.all='n';
            // *** Build list of added tags
            this.uCntIds.ad?.tagId?(this.uCntIds.ad.tagId.some(id=>id===ntag.id)?null:this.uCntIds.ad.tagId.push(ntag.id)):
              this.uCntIds.ad={'tagId':Array(1).fill(ntag.id)}
            // *** Build list of added containers
            this.container.map(cnt=>{
              this.uCntIds.ad?.cntId?(this.uCntIds.ad.cntId.some(id=>id===cnt.id)?null:this.uCntIds.ad.cntId.push(cnt.id)):
                this.uCntIds.ad.cntId=Array(1).fill(cnt.id)
              cnt.tags.push(ntag)})
            !this.uCntIds.rm?null:(this.uCntIds.rm.tagId.some(id=>
              id===ntag.id)?this.uCntIds.rm.tagId.splice(this.uCntIds.rm.tagId.indexOf(ntag.id),1):null)
          }
      })
      // *** Build/Update list of selected containers alonwith list of tags "removed" by the user to be passed into the tagService upon save
    } else {
      // *** Loop thru containers and add/update "removed container" list
      this.container.map((cnt,ci)=>cnt.tags.map((etag,ti)=>{
        e.nTags.some(obj=>obj.id===etag.id)? null:(etag.e ? (
            this.uCntIds.rm?.cntId?(this.uCntIds.rm.cntId.some(id=>id===cnt.id)?null:this.uCntIds.rm.cntId.push(cnt.id)):
              this.uCntIds.rm={'cntId':Array(1).fill(cnt.id)},
            this.uCntIds.rm?.tagId?(this.uCntIds.rm.tagId.some(id=>id===etag.id)?null:this.uCntIds.rm.tagId.push(etag.id)):
              this.uCntIds.rm.tagId=Array(1).fill(etag.id)
          ):(
      // *** Remove tags from "tags added" list if the tag removed is a newly added tag and not linked to the container yet
            this.uCntIds.ad?.tagId?.some(id=>id===etag.id)?
              this.uCntIds.ad.tagId.splice(this.uCntIds.ad.tagId.indexOf(etag.id),1):null,
            this.uCntIds.ad?.cntId?.some(id=>id===cnt.id) && !this.uCntIds.ad.tagId.length?
              this.uCntIds.ad.cntId.splice(this.uCntIds.ad.cntId.indexOf(cnt.id),1):null
          ),
          this.container[ci].tags.splice(ti,1))
      }))
    }
    this._lblTags(this.container)
  }

  onSubmit(e){
    if(this.uCntIds.ad?.tagId.length || this.uCntIds.rm?.tagId.length) {
      let rmCnt = false
      Object.keys(this.uCntIds).map((k,i) => {
        let [cntId, tagId]= [this.uCntIds[k].cntId, this.uCntIds[k].tagId]
        if(!tagId.length){delete this.uCntIds[k]; rmCnt=true; return}
        let method=k==='ad'?this.tagService.relateBulkTagByContainerId(cntId,tagId)
                        :this.tagService.unrelateBulkTagByContainerId(cntId,tagId)
        method.subscribe(res => {
          const {0:pcId, 1:ptId}:any=Object.values(res)
          this.uCntIds[k].fl={'fcId':this.uCntIds[k].cntId.filter(e=> !pcId.includes(e)),
                             'ftId':this.uCntIds[k].tagId.filter(e=> !ptId.includes(e))}
          },
          err => console.log(err),
          () => {
            this.isLoading = false,
            Object.keys(this.uCntIds).length===(rmCnt?i:i+1)?(this._showmsg()):null
          }
        )
      })
    } else {
      this.msgService.clear()
      this.msgService.add({severity:'warn', summary:'', detail:'There are no changes made to the selected container(s)' })
    }
  }

  _showmsg() {
    this.msgService.clear()
    let sv, msg;
    Object.keys(this.uCntIds).map(k=> !this.uCntIds[k].fl.fcId?sv='error':sv='success')
    if (sv=='success') {
      this.reload=!this.reload
      this.container=[]
      this.uCntIds={}
      msg='Container(s) updated successfully'
    } else {
      msg = `Error occurred while updating container ids: ${Object.keys(this.uCntIds).map(k=>this.uCntIds[k].fl.fcId)}`
    }
    this.msgService.add({severity:sv, summary:'', detail:msg })
  }

  onBack(e){
    if ( !Object.keys(this.uCntIds).length || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back()
    }
  }

}
