import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import {Table} from 'primeng/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentGroupService } from '../../services/content-group.service';



@Component({
    selector: 'campaign-table',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'campaign-table.component.html',
    styleUrls: ['campaign-table.component.css']
})
export class CACampaignTableComponent implements OnInit {
    @Input() containers: Array<Container>;
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() rows: number;
    @Input() isLoading = false;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();

    @ViewChild(Table) itemTable;

    private displayDialog = false;
    public container: Container;
    private entityTypes = [];
    private entityTypeId;
    private error: any;
    private cgState: any;
    private destroy$ = new Subject();
 
    constructor(
        public containerService: ContainerService,
        private entityTypeService: EntityTypeService,
        private cgService: ContentGroupService
    ) {}

    ngOnInit() {
        this.destroy$.next(false);
        this.createCgSub();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
                if (this.cgState['contentGroup']) {
                    this.container = this.cgState['contentGroup'];
                }
            });
    }

    onRowSelect(event) {
        this.isLoading = true;
        console.log("Selected Item")
        this.cgService.updateCgState({
            'contentGroup': this.container,
            'source': 'content-group-table'
        });
        this.rowSelect.emit({
            container: this.container
        });
    }

    unselectTableItems()
    {
        console.log(this.itemTable)
        this.itemTable.selection = null
    }

    save() {
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                },
                err => {
                    console.log(err);
                    this.displayDialog = false;
                },
                () => this.isLoading = false
            );
    }

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this context?')) {
            console.log('remove', e, container,this.parentContainer);
            let i = this.containers.indexOf(container);
            this.containers.splice(i, 1);
            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                    },
                    err => console.log(err)
                );
        }
    }
}