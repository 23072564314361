<div class="card card-w-title top-level-card">
    <h1>Metrics Analytics Service</h1>
    <page-header title="Status" [showSpinner]="!statusCode && !streamStatusCode" [error]="error"></page-header>

    <div class="ui-g card-content">
        <p-panel class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="status">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Status</span>
            </p-header>
            <div style="padding: 12px;">
                <strong>HTTP Response Code: {{statusCode}}</strong>
            </div>
            <json-editor
                #masstatus
                [options]="displayOptions" 
                [data]="this.status"
            >
            </json-editor>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="streamStatus">
            <div style="padding: 12px;">
                <strong>HTTP Response Code: {{streamStatusCode}}</strong>
            </div>
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Stream Status</span>
            </p-header>
            <json-editor
                #masstreamstatus
                [options]="displayOptions" 
                [data]="this.streamStatus"
            >
            </json-editor>
        </p-panel>
    </div>
</div>
