import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Tag } from '../../../content/models';
import { TagService } from '../../../content/services/tag/tag.service';
import { TagFormComponent } from '../../forms/tag-form/tag-form.component';

@Component({
  selector: 'tag-edit',
  templateUrl: './tag-edit.component.html',
  providers: [
    TagService
],
})
export class TagEditComponent implements OnInit {
  @ViewChild('form') form: TagFormComponent;
  public tag: Tag;
  public isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public tagService: TagService,
    private msgService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.route.params.subscribe(params => {
      let id = +params['id'];
      this.tagService.get(id)
          .subscribe(
              res => {this.tag = res; this.tag.data = this.tag.data ?? {}},
              err => this.isLoading=false,
              () => this.isLoading = false
          );
    });
  }

  onSubmit(tag: Tag) {
    this.isLoading = true
    this.tagService.save(this.tag).subscribe(
      res => null,
      err => {this.isLoading = false, console.log(err)},
      ()  => {
        this.isLoading = false
        this.msgService.add({severity: 'success', summary:'', detail:'Updated successfully' })
      }
    )
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  onFail(e){
      this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the tag value.' });
  }
}
