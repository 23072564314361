import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Container } from '../../../content/models';
import { MetricsService } from '../../services/metrics.service';

import { Message } from 'primeng/api';

@Component({
    selector: 'metrics-reports-filter-containers-autocomplete',
    providers: [
        MetricsService
    ],
    templateUrl: 'metrics-reports-filter-containers-autocomplete.component.html',
    styleUrls: ['metrics-reports-filter-containers-autocomplete.component.css']
})
export class MetricsReportsFilterContainerAutoCompleteComponent implements OnInit {

    @Input() filterDefinition: any;
    @Output() onValueChange: EventEmitter<any> = new EventEmitter();

    private query: any;
    private listValues: any[] = [];
    public suggestedValues: any[] = [];
    private error: any;
    private isLoading = false;

    public selectedValues: any[] = [];

    private filterValuesQueryParameters = "";
    private filterValuesSearchParameter = "";

    constructor(
        private metricsService: MetricsService,
    ) { }

    ngOnInit() {
        this.filterValuesQueryParameters = this.filterDefinition['options']['query_parameters'];
        if (this.filterDefinition['options']['search_parameter']) {
            this.filterValuesSearchParameter = this.filterDefinition['options']['search_parameter'];
        }
    }

    onChange() {
        this.onValueChange.emit({
            params: this.buildFilterTypeMap()
        });
    }

    getValueSuggestions(event) {

        this.query = event.query;
        this.listValues = [];
        this.metricsService
            .getContainerWithSearchParams(event.query.toLowerCase(), this.filterValuesQueryParameters, this.filterValuesSearchParameter)
            .subscribe(
                res => {
                    let containerPayload = res;
                    if ("results" in containerPayload) {
                        containerPayload = containerPayload['results'];
                    }
                    for (let c = 0; c < containerPayload.length; c++) {
                        this.listValues.push({ 'label': containerPayload[c]['label'], 'value': containerPayload[c]['guid'] });
                    }
                },
                err => this.error = err,
                () => {
                    this.suggestedValues = this.listValues;
                    this.isLoading = false;
                }
            );
    }

    buildFilterTypeMap() {
        let filter_key = this.filterDefinition['filter'];
        let selectedValuesMap = [];
        let value_array = [];
        this.selectedValues.forEach(value => {
            value_array.push(value.value);
        })
        selectedValuesMap.push({ [filter_key]: value_array });
        return selectedValuesMap;
    }

    onClear() {
        this.selectedValues = [];
    }

    onDeselect(event) {
    }

}
