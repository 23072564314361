import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../auth/auth.service';
import { of as observableOf, concat as observableConcat, interval, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class JobsService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/jobs`;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    startExternalIngest(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/ingest/external/${guid}/`, { headers: headers }
            );
    }

    getStatus(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/status/${guid}/`, { headers: headers }
            );
    }

    pollStatus(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return observableConcat(observableOf(null), interval(5000)).pipe(
            mergeMap(() => this.http.get(`${this.baseUrl}/status/${guid}/`, { headers: headers })));
    }

    cancel(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/status/${guid}/`, { headers: headers }
            );
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }
}
