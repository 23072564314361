import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ViewAPIService } from '../../services/view.api.service';
import { Container } from '../../../content/models/container';

@Component({
    selector: 'preview-series-detail',
    providers: [
        ViewAPIService,
    ],
    templateUrl: 'preview-series-detail.component.html',
    styleUrls: ['preview-series-detail.component.css']
})
export class PreviewSeriesDetailComponent implements OnInit, OnDestroy {
    // @Input() series_model: any[];
    private sub: any;
    private container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private currentVideoURL: any
    private backgroundImageURL: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private viewService: ViewAPIService,
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let guid = params['guid'];
            console.log('guid', guid);
            this.isLoading = true;
            this.viewService
                .getSeries(guid)
                .subscribe(
                    res => {
                        this.container = res;
                        console.log(this.container)

                        this.backgroundImageURL = this.container.resources['background'];

                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.viewService
            .getSeries(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    ngOnDestroy() {
        this.sub.unsubscribe();

    }

    display = false;

    getURL() {
        var videoURL = this.currentVideoURL.slice(0, -4) + 'mpd';
        console.log(videoURL)
        return videoURL;
    }

    showDialog(videoURL) {

        this.currentVideoURL = videoURL;
        console.log(videoURL)
        var newWindow = window.open('http://localhost:3500/public/player/player.html', 'player', 'width=1280,height=720');
        // newWindow.getURL();
        ( < any > newWindow)
        .getURL = () => {
            return this.getURL();
        };

        this.display = true;
    }

}
