import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'logout',
    providers: [],
    templateUrl: 'logout.component.html'
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private confirm: ConfirmationService
    ) {}

    ngOnInit() {
        this.confirm.confirm({
            message: 'Are you sure you want to log out?',
            accept: () => {
                console.log('confirm logout');
                this.authService.logout(true);
            }
        });
    }
}
