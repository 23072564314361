
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Segment } from '../../models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class SegmentService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    // Types
    static readonly types = [
      'keyword',
      'schedule',
      'workflow_state'
    ]

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext,
        private msgService: MessageService,
    ) {}

    _appendHeaders() {
      return new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json')
          .append('Authorization', this.authService.getAuthHeader())
    }

    getArgNames(f) {
      return f.toString().split(')',1)[0].replace(/[\s='"|0]+/g,'').substr(5).split(',');
    }

    get(id) {
      let url = `${this.baseUrl}/segments/${id}/`
      let res = this.http.get(url, {headers: this._appendHeaders()})
      return this._handleRes(res)
    }

    getByGuid(guid) {
      let url = `${this.baseUrl}/segments/guid/${guid}/`
      let res = this.http.get(url, {headers: this._appendHeaders()})
      return this._handleRes(res)
    }

    list(id='', guid='', type='', namespace='', status='', label='', value='', parent='', origin='', start_time_code='', end_time_code='', published_date='', expiration_date='', limit=0, offset=0, ordering=''):Observable < any > {
      let argNames = this.getArgNames(this.list)
      let argValues = Array.from(arguments)

      let filters = origin ? `?origin=${origin}` : `?origin=${this.appContext.activeOrigin}`
      argNames.forEach((name, idx) => {
        if (argValues[idx] && name !== 'origin') {
            filters += `&${name}=${argValues[idx]}`
          }
      })
      let url = `${environment.PROXY_API_BASE_URL}/api/segment/v1/${filters}`
      let res = this.http.get(url, {headers: this._appendHeaders()})
      return this._handleRes(res)
    }

    listByParameters(parameters:Array<{}>) {
    /***************************** Sample Input  **************************************************************
    parameters = [{'origin':['global','HGTV',...n]},
                  {'guid':['25bb7356-b7ec-420f-a2b8-4b7da632c111','00f22609-1d83-4597-b62d-ef9e19067a96',...n]},
                  {'status':['hidden','published',...n]},
                  {'limit': 10}, {'offset': 20},
                  ...n]
    ***********************************************************************************************************/
      let filters = ''
      parameters.forEach(obj => {
        filters += (filters ? '&':'?')+`${Object.keys(obj)[0]}`
        if (['ordering','limit','offset'].indexOf(Object.keys(obj)[0]) === -1) {
          filters += (filters ? '&':'?')+`${Object.keys(obj)[0]}__in`
        }
        filters += `=${Object.values(obj).toString().replace(/,/g,'__')}`
      })
      // **** Set active Origin as default if not provided *****
      if (filters.indexOf('origin__in') === -1) {
        filters += `&origin__in=${this.appContext.activeOrigin}`
      }
      let url = `${environment.PROXY_API_BASE_URL}/api/segment/v1/${filters}`
      let res = this.http.get(url, {headers: this._appendHeaders()})
      return this._handleRes(res)
    }

    save(segment: Segment, id?:number): Observable < any > {
      let res;
      if (segment.id === -1) {
        segment.origin = this.appContext.activeOrigin;
        res = this.http.post(`${this.baseUrl}/containers/${id}/segments/`,JSON.stringify(segment),
                          { headers: this._appendHeaders()})
      } else {
        res = this.http.put(`${this.baseUrl}/segments/${segment.id}/`,JSON.stringify(segment),
                          { headers: this._appendHeaders()})
      }
      return this._handleRes(res)
    }

    saveBulkSegmentsByParentId(segment: Segment[], id:number): Observable <any> {
      let res = this.http.post(`${this.baseUrl}/containers/${id}/segments/`,JSON.stringify(segment),
                          { headers: this._appendHeaders()})
      return this._handleRes(res)
    }

    deleteByContainerId(id): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/containers/${id}/segments/`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    deleteByContainerGuid(guid): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/containers/guid/${guid}/segments/`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    deleteSegmentbyId(id): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/segments/${id}/`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    deleteSegmentbyGuid(guid): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/segments/guid/${guid}/`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    // ***** To handle errors received in API response *****
    _handleRes(res): Observable < any > {
      return res.pipe(tap({
        next: null,
        error: (err) => this._handleError(err),
        complete: null,
      }))
    }

    _handleError(err) {
      let errMsg = ''
      if (err.error instanceof Object && err.status!== 0) {
        Object.keys(err.error).map(key => {
          errMsg = key.charAt(0).toUpperCase() + key.slice(1) +' : '+err.error[key]
        })
      } else{ errMsg = err.status !== 0 ? err.status +'  '+err.statusText: err.statusText }
      this._showMsg(errMsg, 'error')
    }

    _showMsg(msg, severity) {
      this.msgService.add({severity: severity, summary:'', detail:msg });
    }
}
