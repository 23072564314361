import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { Container } from '../../../content/models/container'
import { ContainerService } from '../../../content/services/container/container.service'
import { FlightFormComponent } from '../../forms/flight-form/flight-form.component';

@Component({
    selector: 'flight-create',
    templateUrl: 'flight-create.component.html',
    styleUrls: ['flight-create.component.css']
})

export class FlightCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: FlightFormComponent
    private sub: any;
    public parentId: number;
    public containerId: number;
    public error: HttpResponse<any>;
    public container: Container = new Container();
    public isLoading = false;
    public msgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private msgService: MessageService,
    ) { }

    ngOnInit(): void {
        console.log('flight create init')
        this.sub = this.route.params.subscribe(
            params => {
                this.parentId = +params['parentId'];
            },
            err => this.error = err
        );
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe;
        }
    }

    onSubmit(e) {
        console.log('submit', e);
        this.sub = this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.containerId = res.id;
                    this.containerService
                        .relateById(this.parentId, this.containerId)
                        .subscribe(
                            res => {
                                console.log(res);
                                this.router.navigate(['/commerce/flight', this.containerId, this.parentId], { replaceUrl: true });
                            }
                        ),
                        err => {
                            console.log(err);
                            this.error = err.statusText;
                            this.isLoading = false;
                        },
                        () => {}
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                    this.isLoading = false;
                },
                () => {}
            );
    }

    onCancel(e) {
        this.location.back();
    }

    onFailure(e) {
        this.msgService.add(e);
    }

}