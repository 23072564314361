import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { Message } from 'primeng/api';

@Component({
    selector: 'server-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'server-edit.component.html',
    styleUrls: ['server-edit.component.css']
})
export class ServerEditComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    private dataSet: Array<any>;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                        this.dataSet = this.toIterable(this.container.data);
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    // refetchContainer() {
    //     this.isLoading = true;
    //     this.containerService
    //         .get(this.container.id)
    //         .subscribe(
    //             res => {
    //                 this.container = res;
    //                 this.validateFormData();
    //                 this.checkJobStatus();
    //             },
    //             err => this.error = err,
    //             () => this.isLoading = false
    //         );

    // }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    // from MapToIterablePipe
    toIterable(dict: Object): Array<any> {
        var a = [];
        for (var key in dict) {
            if (dict.hasOwnProperty(key)) {
                a.push({ key: key, val: dict[key] });
            }
        }
        return a;
    }

    addProperty(newProperty : string) {
        if (newProperty && !this.container.data.hasOwnProperty(newProperty)) {
            console.log('Adding new property to server:', newProperty);
            this.container.data[newProperty] = '';
            this.dataSet = this.toIterable(this.container.data);
        }
    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    // isDateValid(dateString: string) {
    //     var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    //     if (date_regex.test(dateString)) {
    //         return true;
    //     }
    //     return false;
    // }
    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );

    }

    onCancel(e) {
        this.location.back();
    }
}
