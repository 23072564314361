<button pButton type="button" label="Add to Notifications" icon="pi pi-plus" style="float:right; margin-left:4px;" (click)="showDialog()" [disabled]="!container.guid || container.status === 'waiting' || this.container.status === 'processing'"></button>

<p-dialog header="Add to Notifications"
        [modal]="true"
        [(visible)]="displayDialog"
        [style]="{'min-width': '400px'}"
        responsive ="true"
        (onHide)="onClose()"
        >

    <div *ngIf="!isProcessing && !isLoading" class="ui-g">
        <div class="ui-g-12">
            <label for="title">Select Notifications: </label>
            <span class="p-float-label">
                <p-multiSelect [options]="notificationContainers"
                            [(ngModel)]="selectedNotificationContainers"
                            optionLabel="label"
                            [style]="{'min-width': '400px', 'max-height': '100%'}"
                            appendTo="body">
                </p-multiSelect>
            </span>
        </div>
    </div>

    <p-footer *ngIf="!isProcessing && !isLoading">
        <button type="button" pButton icon="pi pi-check" (click)="onSubmit()" label="Confirm"></button>
        <button type="button" pButton icon="fa fa-close" (click)="onClose()" label="Close" class="p-button-secondary"></button>
    </p-footer>

    <div *ngIf="isProcessing" style="margin:0.5em;">
        <p-progressBar [value]="progress"></p-progressBar>
    </div>

</p-dialog>
