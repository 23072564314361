import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Container, Tag } from '../../models';
import { MenuItem } from 'primeng/api';
import { ContainerService } from '../../services/container/container.service';
import { TagService } from '../../services/tag/tag.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'container-tags-tab',
    providers: [
        ContainerService,
        TagService
    ],
    templateUrl: 'container-tags-tab.component.html',
    styleUrls: ['container-tags-tab.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ContainerTagsTabComponent implements OnInit {
    @Input() container: Container;
    @Input() tagTypeFilterList: any[];
    @Input() tagTypeExcludeList: any[];
    @Input() hideTagChips: boolean;
    @Output() onTagsChange: EventEmitter < any > = new EventEmitter();

    displayDialog: boolean = false;

    public isLoadingTags = false;
    private selectedTags: Tag[];
    private visibleTags: Tag[];
    private tags : Tag[];
    private tagChips: any[];

    private tagsDict = {};
    private selectedTagsDict = {};
    private tagsTypes:any;
    private visibleTagsTypes:any;
    private hiddenTagsTypes:any;

    constructor(
        private containerService: ContainerService,
        private tagService: TagService
    ) { }

    ngOnInit() {
        this.selectedTags = this.container.tags;
        this.visibleTags = [];
        this.fetchTags();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['container'] && changes['container'].previousValue != changes['container'].currentValue && this.container) {
            this.selectedTags = this.container.tags;
            this.tags = [];
            this.tagsDict = {};
            this.selectedTagsDict = {};
            this.fetchTags();
        }
        else if (changes['tagTypeFilterList'] && changes['tagTypeFilterList'].previousValue != changes['tagTypeFilterList'].currentValue && this.container) {
            this.fetchTags();
        }
        else if (changes['tagTypeExcludeList'] && changes['tagTypeExcludeList'].previousValue != changes['tagTypeExcludeList'].currentValue && this.container) {
            this.fetchTags();
        }
    }

    capitalize(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    showDialog() {
        this.displayDialog = true;
    }

    onChangeTags(emitEvent: boolean = true) {
        console.log("SELECTED TYPES");
        console.log(this.tagsTypes);
        console.log("SELECTED TAGS DICT");
        console.log(this.selectedTagsDict);
        this.selectedTags = [];
        this.tagsTypes.forEach(tagType => {
            if (this.selectedTagsDict[tagType]) {
                this.selectedTagsDict[tagType].forEach(tag => {
                    this.selectedTags.push(tag);
                });
            }
        });
        this.setVisibleTags();
        console.log(this.selectedTags);
        this.container.tags = this.selectedTags;
        if (emitEvent) {
            this.onTagsChange.emit(this.selectedTags);
        }
    }

    updateTags(tagsArray : Tag[] = null) {
        if (tagsArray) {
            this.selectedTags = tagsArray;
        }
        this.processTags();
        this.setVisibleTags();
        console.log(this.selectedTags);
    }

    fetchTags() {
        this.isLoadingTags = true;
        this.tagService
        .list()
        .subscribe(
            res => {
                this.tags = res;
                this.updateTags();
            },
            err => console.log(err),
            () => this.isLoadingTags = false
        )
    }

    processTags() {
        this.tagsTypes = this.getTagTypes(this.tags);
        this.selectedTagsDict = this.splitTagsByType(this.selectedTags);
        this.tagsDict = this.splitTagsByType(this.tags);

        if (this.tagTypeFilterList.length > 0) {
            this.visibleTagsTypes = this.filterTagTypes(this.tagTypeFilterList, this.tagsTypes);
        } else {
            this.visibleTagsTypes = this.tagsTypes;
        }

        if (this.tagTypeExcludeList && this.tagTypeExcludeList.length > 0) {
            this.visibleTagsTypes = this.excludeTagTypes(this.tagTypeExcludeList, this.visibleTagsTypes);
        }

        this.hiddenTagsTypes = new Set(Array.from(this.tagsTypes).filter(t => !this.visibleTagsTypes.has(t)));

        if (this.tagTypeExcludeList && this.tagTypeExcludeList.length > 0) {
            this.hiddenTagsTypes = this.excludeTagTypes(this.tagTypeExcludeList, this.hiddenTagsTypes);
        }
    }

    setVisibleTags() {
        if (this.tagTypeExcludeList) {
            this.visibleTags = this.selectedTags.filter(t => !this.tagTypeExcludeList.includes(t.type));
        } else {
            this.visibleTags = this.selectedTags.slice();
        }
    }

    getTagTypes(tagArray) {
        console.log(this.tagTypeFilterList, this.tagTypeExcludeList);
        let tagsTypes = new Set(tagArray.map(tag => tag.type));
        console.log(tagsTypes);
        return tagsTypes;
    }

    filterTagTypes(tagFilterList, tagTypesSet) {
        // TODO: Make sure that tags in filter list are in tagTypesSet?
        let filteredTagSet = new Set(tagFilterList);
        console.log(filteredTagSet);
        return filteredTagSet;
    }

    excludeTagTypes(tagExcludeList, tagTypesSet) {
        let filteredTagSet = new Set(tagTypesSet);
        tagExcludeList.forEach(t => filteredTagSet.delete(t));
        console.log(filteredTagSet);
        return filteredTagSet;
    }

    splitTagsByType(tagsArray) {
        let tagsDict = {};
        tagsArray.forEach(tag => {
            if (!(tag.type in tagsDict)){
                tagsDict[tag.type] = [];
            }
            tagsDict[tag.type].push(tag);
        });
        for (let key in tagsDict) {
          tagsDict[key].sort((a, b) =>  a.label < b.label? -1 : 1);
        };
        return tagsDict;
    }

    updateChips(event) {
        let tag = event.value;
        if (this.selectedTagsDict[tag.type]) {
            this.selectedTagsDict[tag.type] = this.selectedTagsDict[tag.type].filter(t => t.value != tag.value);
        }
        this.onChangeTags();
    }

}
