/*************************************************************/
/*** This file has been moved but has not been refactored! ***/ 
/*************************************************************/

import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CanvasService } from '../../../shared/canvas.service';
import * as utilities from '../../../shared/utilities';
import { ContentGroupService } from '../../services/content-group.service';

@Component({
  selector: 'keyword-frequency',
  templateUrl: './keyword-frequency.component.html',
  styleUrls: ['./keyword-frequency.component.css']
})
export class KeywordFrequencyComponent implements AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef;
  @Input() keywords;
  @Input() duration;
  @Input() moment;
  @Input() chapterSeconds;
  private ctx: CanvasRenderingContext2D;
  private ctxEl;
  private buckets;
  public state = {
        canvasWidth: 500,
        canvasHeight: 10,
        borderBottomMargin: 0
    };
  private densityColorScale = ['#c6e2fe', '#7bb3ec', '#785698', '#ce2038', '#fe0102'];

  constructor(
    private canvasSrv : CanvasService, 
    private cgService: ContentGroupService
  ) {}

  ngAfterViewInit(): void {
    // Capture canvas
    this.ctxEl = this.canvas.nativeElement;
    this.ctx = this.ctxEl.getContext('2d');
    this.drawCanvas();
    this.processBuckets();
  }

  public updateMoment(event): void {
    const scale = this.duration/this.state.canvasWidth;
    this.cgService.updateCgState({
      'source': 'keyword-frequency',
      'activeTimecode': Math.round(event.offsetX * scale),
      'activeChapter': this.cgService.getActiveChapter(event.offsetX * scale, this.chapterSeconds, this.duration)
    });
  }

  private drawCanvas(): void {
    this.ctx.rect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
    this.ctx.fillStyle = 'rgba(0, 0, 180, 0)';
    this.ctx.fill();
  }

  private processBuckets(): void {
   // draw blocks
    console.log("keywords",this.keywords);
    console.log("duration",this.duration);
    let numBuckets = Math.round(this.duration / 30);
    console.log("numBuckets", numBuckets);
    let sbw = 5;
    this.buckets = new Array(numBuckets).fill(null).map(() => ([]) );
    let bucketWidth = (this.state.canvasWidth - (sbw * 2)) / numBuckets;

    // distribute keywords into timeline bucket
    this.keywords.forEach((keyword, i) => {
      keyword.timestamps.forEach((time, j) => {
        const bucketNum = Math.round(( time['start_time_code'] / this.duration ) * numBuckets);
        if (this.buckets[bucketNum]) {
          this.buckets[bucketNum].push(keyword.key);
        }
      });
    });

    this.buckets.forEach( (block, i) => {
      let blockHeight = 2;
      let blockDensity = 0;
      if (block.length) {
        blockHeight += block.length * 2;
        blockDensity += [...new Set(block)].length;
      }

      // Draw bucket
      this.canvasSrv.drawFilledRectangle(
        this.ctx,
        sbw,
        ( this.state.canvasHeight - blockHeight - this.state.borderBottomMargin),
        bucketWidth - 1,
        blockHeight,
        this.densityColorScale[blockDensity]);

        sbw += bucketWidth;
    });
  }
}
