<div *ngIf="selectedContainer && !isLoading" class="ui-g-12">
    <div [ngClass]="autoRefreshSliderActive ? 'autorefresh': ''">
        <h1 class="ui-g-12" style="padding-top:0;">{{selectedContainer ? selectedContainer.data['category'] + ': ' +
            selectedContainer.data['label'] : ''}}</h1>
        <div class="switch" *ngIf="autoRefreshSliderActive">
            <label>Auto Update: </label>
            <p-inputSwitch [(ngModel)]="autoRefreshEnabled"></p-inputSwitch>
        </div>
    </div>
    <div class="ui-g-12">
        <div *ngIf="showReportDetails && selectedContainer.data['description']" class="ui-g-12 text-center">
            {{ selectedContainer.data['description'] }}
        </div>
        <div class="ui-g-12" style="padding:0;">
            <testing-reports-data-table (onStateChange)="handleModalViewStateChange($event)"
                [containers]="testRunContainers" [parentContainer]="selectedContainer"
                [modifyPermissions]="['view.show.admin']">
                <button type="button" pButton label="Run Tests" icon="fa fa-play" (click)="runTestSuite()"
                    class="p-button-success" style="float:right" data-element-id="app-testing-components-testing-report-panel-run-tests"></button>
            </testing-reports-data-table>
        </div>
    </div>