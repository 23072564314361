<page-header title="New Resource Tag" [showSpinner]="isLoading"></page-header>
<div *ngIf="tag" class="card card-w-title top-level-card">
  <h1>New Resource Tag</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <resource-tag-form #form [tag]="tag" (formSubmit)="onSubmit(tag)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></resource-tag-form>
    </div>
  </div>
</div>
