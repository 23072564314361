<page-header title="Edit Workflow" [showSpinner]="isLoading"></page-header>
<div *ngIf="segment" class="card card-w-title top-level-card">
  <h1>Edit Workflow Segment{{!isLoading && container ? ' : ' +container.data.name : ''}}</h1>
  <div class="ui-g card-content">
    <div class="ui-g-12">
      <workflow-state-segment-form #form [segment]="segment" (formSubmit)="onSubmit()" (formCancelled)="onCancel($event)" (formFailed)="''"></workflow-state-segment-form>
    </div>
  </div>

  <p-panel class="ui-g-12">
    <p-header>
      <span class="p-panel-title" style="font-size:16px;">Conditions</span>
    </p-header>
    <json-editor #condition [options]="conditionOptions" [data]="conditionObject" data-element-id="app-settings-components-workflow-segment-edit-condition"></json-editor>
  </p-panel>

  <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
    <p-header>
      <span class="p-panel-title" style="font-size:16px;">Pipeline Action</span>
    </p-header>
    <json-editor #pipelineaction [options]="pipelineActionOptions" [data]="pipelineActionObject"></json-editor>
  </p-panel>

  <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
    <p-header>
      <span class="p-panel-title" style="font-size:16px;">Schedulable Actions</span>
    </p-header>
    <json-editor #schedulableaction [options]="schedulableActionOptions" [data]="schedulableActionObject"></json-editor>
  </p-panel>

  <div class="p-mt-5 p-p-2">
    <object-advancedview [object]="segment" title="Advanced Editing"></object-advancedview>
  </div>

</div>
