import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ResourceService } from '../../services/resource/resource.service';
import { ContainerService } from '../../services/container/container.service';
import { ResourceFormComponent } from '../../forms/resource-form/resource-form.component';
import { Resource } from '../../models';
import { Message } from 'primeng/api';
import { Container } from '../../models/container';

@Component({
    selector: 'resource-upload',
    providers: [
        ResourceService,
        ContainerService
    ],
    templateUrl: 'resource-upload.component.html',
    styleUrls: ['resource-upload.component.css']
})
export class ResourceUploadComponent implements OnInit {
    @ViewChild('form') form: ResourceFormComponent;
    private sub: any;
    private resource: Resource = new Resource();
    public containerId;
    public isLoading: boolean = false;
    private error: string;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    public container: Container;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private resourceService: ResourceService,
        public containerService: ContainerService,
    ) {}
    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['containerId']; // + converts string to number
            console.log('containerId', id);

            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        console.log('res', res);
                        this.container = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }


    onSubmit(e) {
        this.location.back();

    }

    onBack(e) {
            this.location.back();
        
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Upload Error', detail: 'There was a problem uploading the files.' });
    }

}
