import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { ContainerFormComponent } from '../../../content/forms/container-form/container-form.component';
import { Message } from 'primeng/api';

@Component({
  selector: 'moment-edit',
  providers: [
    ContainerService
  ],
  templateUrl: 'moment-edit.component.html',
  styleUrls: ['moment-edit.component.css']
})
export class CAMomentEditComponent implements OnInit {

  @ViewChild('form') form: ContainerFormComponent;

  private sub: any;
  private containerSub: any;
  private containerId: number;
  public isLoading = false;
  public container: Container;
  public error: HttpResponse<any>;
  public requiredMsgs: Message[] = [];
  public msgs: Message[] = [];

  constructor(
    private route: ActivatedRoute,
    public containerService: ContainerService,
    private location: Location
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.containerId = params['id'];
      this.loadContainerData(this.containerId);
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadContainerData(id) {
    this.isLoading = true;
    this.containerSub = this.containerService
        .get(id)
        .subscribe(
            res => {
                this.container = res;
            },
            err => this.error = err,
            () => {
                this.isLoading = false;
                this.containerSub.unsubscribe();
            }
        );
  }

  onSubmit(e) {
    this.requiredMsgs = [];
    this.containerService
        .save(this.container)
        .subscribe(
            res => {
                this.requiredMsgs = [];
                this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                this.container = Object.assign(new Container(), this.container);
            },
            err => {
                console.log(err);
                this.error = err.statusText;
            },
            () => this.isLoading = false
        );
  }

  onCancel(e) {
      if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
      }
  }

  onFailure(e) {
      this.requiredMsgs = [];
      this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
  }

}
