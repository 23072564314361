import { Component, OnInit,Input, OnDestroy, ViewChild, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { MetricsService } from '../../services/metrics.service';


@Component({
    selector: 'metrics-report-number-cards',
    templateUrl: './metrics-report-number-cards.component.html',
    styleUrls: ['metrics-report-number-cards.component.css'],
    providers: [
        MetricsService
    ]
})
export class MetricsReportNumberCardsComponent implements OnInit {

    @Input() reportData: any;
    @Input() renderOptions: object;
    @Input() view: any;

    private sub: any;
    private requiredMsgs = [];
    private error: any;
    private isLoading = false;
    private reportResults: any;

    // options
    cardColor: string = '#232837';

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };

    data: any;
    rawData: any;
    formattedDataNGX: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private metricsService: MetricsService
    ) {
        this.initializeGraphData()
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('reportData' in changes && changes['reportData'].previousValue != changes['reportData'].currentValue) {
            this.initializeGraphData();
            if (this.reportData) {
                this.createGraphSeriesFromResultsNGX(this.reportData['results']);
            }
        }
    }
    
    ngOnInit() {
    }

    initializeGraphData() {
       this.formattedDataNGX = [];
    }

    createGraphSeriesFromResultsNGX(results) {
        for (const element of results) {
            this.formattedDataNGX.push({ name: element['label'], value: element['value'] });
        }
    }

    capitalize(word) {
        if (!word) return '<undefined>';
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    onSelect(event) {}

}
