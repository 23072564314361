import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AppContext } from '../../../app.context';
import { Container } from '../../../content/models';
import { MenuItem } from 'primeng/api';
import { MetricsService } from '../../services';
import { MultiSelect } from 'primeng/multiselect';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { SelectItem } from 'primeng/api';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['dashboard.component.css']
    //providers: [DatePipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('pipelineOriginMultiSelect') pipelineOriginMultiSelect: MultiSelect;

    private contentMetricsSub: any;
    private pipelineMetricsSub: any;
    private contentMetrics: any;
    public pipelineMetricsData: any;
    public pipelineMetricsCount: any;
    public pipelineMetricsLimit: number = 10; //must be 10; currently not configurable in the back-end
    public pipelineMetricsOffset: number = 0;
    private pipelineMetricsPage: number = 1;
    public defaultSelectedStatus = ['failure'];
    public selectedStatusList: Array<string> = [];
    public selectedOriginList: Array<string> = [];
    public error: any;
    public isContentMetricsLoading = true;
    public isPipelineMetricsLoading = true;
    public statusList: Array<SelectItem> = [];
    public selectedStatus: SelectItem = null;
    public bundle_id = '';
    public context_id = '';
    private filteredStatus = '';
    public activeOrigin;


    public pipelineColumns = [
        {default: true, header: 'Pipeline Id', field: 'id'},
        {default: true, header: 'Name', field: 'name'},
        {default: true, header: 'Bundle Id', field: 'bundle_id'},
        {default: true, header: 'Context Id', field: 'context_id'},
        //{default: true, header: 'Started At', field: 'started_at', style:{'width': '10%'}, transform: [this.formatDateTime.bind(this)]},
        //{default: true, header: 'Stopped At', field: 'stopped_at', style:{'width': '10%'},  transform: [this.formatDateTime.bind(this)]},
        {default: true, header: 'Type', field: 'pipeline_type', style:{'width': '5%'}},
        {default: true, header: 'Status', field: 'status', style: {'width': '5%'}}
    ];


    private workerColumns = [
        {default: true, header: 'Worker Id', field: 'id'},
        {default: true, header: 'Name', field: 'name'},
        {default: true, header: 'Bundle Id', field: 'bundle_id'},
        //{default: true, header: 'Started At', field: 'started_at', style:{'width': '10%'},  transform: [this.formatDateTime.bind(this)]},
        //{default: true, header: 'Stopped At', field: 'stopped_at', style:{'width': '10%'},  transform: [this.formatDateTime.bind(this)]},
        {default: true, header: 'Step', field: 'label', style: {'width': '15%'}},
        {default: true, header: 'Status', field: 'status', style: {'width': '5%'}}
    ];


    constructor(
        private metricsService: MetricsService,
        private appContext: AppContext,
        private pipelineService: PipelineService
        //private datePipe: DatePipe
    ) {}

    ngOnInit() {
        this.activeOrigin = this.appContext.activeOrigin;
        this.getMetrics();
        this.addOriginColumn();

        if (this.appContext.componentState['pipeline_list'] == null) {
            this.setStatusParams();
        }
        this.getStatusParams();
    }

    getStatusParams() {
        let statusContextParameters = this.appContext.componentState['pipeline_list'];
        this.selectedStatusList = statusContextParameters['status'] || this.defaultSelectedStatus;
        this.selectedOriginList = statusContextParameters['origin'] || [],
        this.bundle_id = (statusContextParameters['bundle_id'] || '');
        this.context_id = (statusContextParameters['context_id'] || '');
    }

    setStatusParams(reset: boolean = false) {
        if (reset) {
            this.pipelineMetricsOffset = 0;
            this.pipelineMetricsPage = 1;
        }
        this.appContext.componentState['pipeline_list'] = {
            'status': this.selectedStatusList || this.defaultSelectedStatus,
            'origin': this.selectedOriginList || [],
            'bundle_id': this.bundle_id || '',
            'context_id': this.context_id || '',
        };

    }

    getContentMetrics() {
        this.isContentMetricsLoading = true;

        this.contentMetricsSub = this.metricsService
            .getContentMetrics()
            .subscribe(
                res => {
                    console.log('res', res);
                    this.contentMetrics = res.data;

                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.isContentMetricsLoading = false
            );
    }

    getPipelineMetrics() {
        this.isPipelineMetricsLoading = true;
        let filteredOrigins = this.getFilteredOrigins();

        this.pipelineMetricsSub = this.pipelineService
            .getPipelineList(
                this.pipelineMetricsPage,
                this.bundle_id || '',
                this.context_id || '',
                this.selectedStatusList || [],
                filteredOrigins,
            )
            .subscribe(
                res => {
                    console.log("res", res);
                    this.pipelineMetricsData = res.data;
                    this.pipelineMetricsCount = res.count;
                    if (this.pipelineMetricsData) {
                        for (let i = 0; i < this.pipelineMetricsData.length; i++) {
                            let lastFailedId = '';
                            if(this.pipelineMetricsData[i]['success']) {
                                this.pipelineMetricsData[i]['workers'] = this.removePreviousWorkers(this.pipelineMetricsData[i]['workers']);
                            }
                            else {
                                lastFailedId = this.getLatestFailure(this.pipelineMetricsData[i]);
                                if(lastFailedId) {
                                    // remove earlier failed steps if more than one failures
                                    this.pipelineMetricsData[i]['workers'] = this.removePreviousWorkers(this.pipelineMetricsData[i]['workers'], lastFailedId);
                                }
                                this.pipelineMetricsData[i]['pipeline_error'] = this.getError(this.pipelineMetricsData[i]);
                                this.pipelineMetricsData[i]['worker_error'] = this.getNestedError(this.pipelineMetricsData[i], lastFailedId);
                            }
                        }
                    }
                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.isPipelineMetricsLoading = false
            );
    }

    removePreviousWorkers(workers, lastFailedId='') {
        // Removes workers failed in previous execution
        for(let i=0; i<workers.length; i++) {
            if(workers[i].status == 'failure' && workers[i].id != lastFailedId) {
                workers.splice(i, 1);
                i--;
            }
        }
        return workers;
    }

    getPipelineMetricsLazy(event) {
        this.pipelineMetricsPage = (event.first / event.rows) + 1;
        this.pipelineMetricsOffset = event.first;
        this.getPipelineMetrics();
    }

    getMetrics() {
        // let name = 'featured';
        this.getContentMetrics();
        //this.getPipelineMetrics(); -> changed to lazy load
    }

    getFieldData(container, column) {
        let data = container[column.field];
        if (column.transform) {
            for (let i = 0; i < column.transform.length; i++) {
                data = column.transform[i](data);
            }
        }
        return data;
    }

    getHeaderStyle(column) {
      return column.style ? column.style : '';
    }

    getStatusStyleClass(type, status) {
      let styleClass = 'label label-';
      switch (status) {
        case 'running': {
          styleClass += 'primary';
          break;
        }
        case 'failure': {
          styleClass += 'danger';
          break;
        }
        case 'success': {
          styleClass += 'success';
          break;
        }
        case 'waiting': {
          styleClass += 'info';
          break;
        }
        case 'cancelled': {
          styleClass += 'warning';
          break;
        }
        case 'skipped': {
          styleClass += 'warning';
          break;
        }
        case 'pending': {
          if (type == 'worker') {
            styleClass += 'info';
          } else {
            styleClass += 'default';
          }
          break;
        }
        default: {
          styleClass += 'default';
          break;
        }
      }
      return styleClass;
    }

    getError(pipeline) {
        let pipelineErrorMsg = '';
        if (pipeline.error_data) {
            let errorData = pipeline.error_data;
            let objKeys = Object.keys(errorData);
            if (objKeys.includes('error')) {
                pipelineErrorMsg = errorData['error'];
            } else if (objKeys.includes('exception')) {
                pipelineErrorMsg = errorData['exception'];
            } else {
                pipelineErrorMsg = '';
            }
        }
        return pipelineErrorMsg;
    }

    getNestedError(pipeline, lastFailedId) {
        let workerErrorMsg = '';
        if (pipeline.error_data && pipeline.error_data.context && pipeline.workers.length > 0) {
            let ctx = pipeline.error_data.context;
            if (lastFailedId) {
                workerErrorMsg += 'Worker Id: ' + lastFailedId + '\n';
                if (ctx.hasOwnProperty(lastFailedId)) {
                    if (ctx[lastFailedId].hasOwnProperty('error')) {
                        workerErrorMsg += typeof(ctx[lastFailedId]['error']) === 'string' ? ctx[lastFailedId]['error'] : ctx[lastFailedId]['error']['message'];
                    } else if (ctx[lastFailedId].hasOwnProperty('exception')) {
                        workerErrorMsg += ctx[lastFailedId]['exception'];
                    } else {
                        workerErrorMsg + '';
                    }
                }
            }
        }
        return workerErrorMsg;
    }

    getLatestFailure(pipeline) {
        const failedWorkers = pipeline.workers.filter(worker => worker.status === 'failure');
        const latestFailedWorker = failedWorkers.reduce((latestWorker, currentWorker) => {
          if (!latestWorker || new Date(currentWorker.stopped_at) > new Date(latestWorker.stopped_at)) {
            return currentWorker;
          }
          return latestWorker;
        }, null);
        return latestFailedWorker?.id
    }

    refreshPipelineMetrics() {
      this.pipelineMetricsPage = 1;
      this.pipelineMetricsOffset = 0;
      this.getPipelineMetrics();
    }

    ngOnDestroy() {
        this.contentMetricsSub.unsubscribe();
        this.pipelineMetricsSub.unsubscribe();
    }

    toTitleCase(data) {
        let txt = data || '';
        if (!txt) {
            return txt;
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    changedBundleID(event) {
        this.setStatusParams(true);
        this.getPipelineMetrics();
    }

    changedContextID(event) {
        this.setStatusParams(true);
        this.getPipelineMetrics();
    }

    onExecuteAction(pipelineObject) {
        console.log(pipelineObject);
        this.getPipelineMetrics();
    }

    onStatusListUpdate(event) {
        this.selectedStatusList = event.value ? event.value : this.defaultSelectedStatus;
        this.setStatusParams(true);
        this.getPipelineMetrics();
    }

    onOriginListUpdate(event) {
        this.selectedOriginList = event;
        this.setStatusParams(true);
        this.getPipelineMetrics();
    }

    getFilteredOrigins(): true | string[] {
        if (this.activeOrigin === 'system' && this.selectedOriginList.length) {
            return this.selectedOriginList;
        }
        return true;
    }

    private addOriginColumn() {
        if (this.activeOrigin === 'system') {
            this.pipelineColumns.push({ default: true, header: 'Origin', field: 'origin', style: { 'width': '5%' } });
        }
    }

}
