<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Test</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-4 button-wrapper" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" [rows]="10" [paginator]="true" paginatorPosition="bottom" selectionMode="single"
        (onRowSelect)="onRowSelect($event)" [(selection)]="container"
        styleClass="p-datatable-gridlines  p-datatable-nested" [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Label</th>
                <th>Initiator</th>
                <th>Results</th>
                <th>Execution Time</th>
                <th>Status</th>
                <th *ngxPermissionsOnly=modifyPermissions style="width:42px;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-test>
            <tr>
                <td>{{test.data.label}}</td>
                <td>{{test.data.initiator}}</td>
                <td>
                    <div *ngIf="test.data.results && test.data.results.passes && test.data.results.passes > 0 && test.data.test_run_status != 'timedout'"
                        class="label label-success">Passed: {{test.data.results.passes}}</div>
                    <div *ngIf="test.data.results && test.data.results.failures && test.data.results.failures > 0 && test.data.test_run_status != 'timedout'"
                        class="label label-danger">Failed: {{test.data.results.failures}}</div>
                    <div *ngIf="!test.data.results && test.data.test_run_status != 'timedout'" class="label label-warning">Pending...</div>
                    <div *ngIf="test.data.test_run_status =='timedout'" class="label label-default">No Results</div>
                </td>
                <td>
                    <div *ngIf="test.data.results && test.data.results.duration" style="word-break: break-all;">
                        {{test.data.results.duration / 1000}}s</div>
                    <div *ngIf="test.data.test_run_status == 'running'"><p-progressBar mode="indeterminate"
                            [style]="{ height: '6px' }"></p-progressBar></div>
                </td>

                <td>
                    <div *ngIf="test.data && test.data.test_run_status && test.data.test_run_status != 'timedout'" class="label label-default">
                        {{test.data.test_run_status}}
                    </div>
                    <div *ngIf="test.data && test.data.test_run_status && test.data.test_run_status == 'timedout'" class="label label-danger">
                        {{test.data.test_run_status}}
                    </div>
                </td>
                <td *ngxPermissionsOnly=modifyPermissions>
                    <testing-results-modal (onStateChange)="handleModalViewStateChange($event)"
                        [container]="test"></testing-results-modal>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>