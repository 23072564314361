<page-header title="Create Policy" [showSpinner]="isLoading" [error]="error"></page-header>
<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">

    <h1>New Policy</h1>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <policy-form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onCancel($event)"></policy-form>
    </div>

</div>
