import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../../content/models';
import { SearchTableComponent } from '../../../content/components/search-table/search-table.component';
import { ContentGroupService } from '../../services/content-group.service';


@Component({
    selector: 'contextual-ads-campaign-search-table',
    templateUrl: 'contextual-ads-campaign-search.component.html',
    styleUrls: ['contextual-ads-campaign-search.component.css']
})
export class CACampaignSearchTableComponent implements OnInit {
    @Input() containers: any;
    @Input() defaultSearchTerm: string;
    @Input() selectedContainer: Container;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @ViewChild(SearchTableComponent) searchTable:  SearchTableComponent;
    public searchTableConfig = {
        csKey: 'campaign_search_view',
        keepSelectionOnReload: true
    }

    constructor(
        private router: Router,
        private cgService: ContentGroupService
    ) {}

    ngOnInit() {
        console.log('Container');
        console.log(this.containers);
    }

    findParentContainerId(container) {

        console.log('clicked on container', container);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];

        console.log('clicked on container parent', parentContainer);

        this.router.navigate(['/episodes', container.id, parentContainer.id || '']);
    }

    onLazyLoad(event)
    {
        this.rowSelect.emit({
            container: null
        });
    }

    handleRowSelect(event) {
        console.log("Handling Event")
        console.log(event)
        if (event['origin']==='select') {
            this.cgService.updateCgState({
                'campaign': event['container'],
                'contentGroup': null,
                'source': 'c-a-cm-s-1'
            });
            // this.rowSelect.emit({
            //     container: event['container']
            // });
        } else {
            this.cgService.updateCgState({
                'campaign': event['container'],
                'source': 'c-a-cm-s-2'
            });
        }
    }

    rowUpdate(guid) {
      this.searchTable.rowUpdate(guid);
    }
}