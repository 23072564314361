import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { CatalogFormComponent } from '../../forms/catalog-form/catalog-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';

@Component({
    selector: 'catalog-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'catalog-create.component.html',
    styleUrls: ['catalog-create.component.css']
})
export class CatalogCreateComponent implements OnInit {
    public container: Container = new Container();
    public error: string;
    public isLoading = false;

    constructor(
        private location: Location,
        public containerService: ContainerService,
        private router: Router
    ) {}

    ngOnInit() {
        console.log('catalog create init');
    }

    onSubmit(e) {
        console.log('catalog create submit', e.container);
        this.containerService
            .save(e.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/catalog', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    let body = err.json()
                    this.error = body.guid || err.statusText;
                }
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
