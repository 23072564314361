<div class="card card-w-title top-level-card">
    <h1>Service Status</h1>
    <page-header title="Service Status" [showSpinner]="!lastUpdate" [error]="error"></page-header>

    <p-toolbar>
        <div class="ui-g-12">
            <button *ngIf="lastUpdate" pButton type="button" label="Clear Cache" (click)="onClearCache($event)" class="p-button-danger" icon="pi pi-trash" style="float:right;margin-left:4px;height:100%;"></button>
            <button *ngIf="lastUpdate" pButton type="button" label="Force Update" (click)="onForceUpdate($event)" class="p-button-danger" icon="fa fa-arrow-circle-up" style="float:right;margin-left:4px;height:100%;"></button>
        </div>
    </p-toolbar>

    <div class="ui-g card-content" *ngIf="lastUpdate">
        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Build Details</span>
            </p-header>
<!--
            <div style="padding:0.5em;overflow:hidden;">
                <table class="table" style="margin-bottom:0">
                    <tbody>
                         <tr>
                            <th style="border-top:none">Build ID</th>
                            <td style="border-top:none">{{ hash }}</td>
                        </tr> 

                        <tr>
                            <th>Environment</th>
                            <td>{{ environment }}</td>
                        </tr>

                        <tr>
                            <th>Build Date</th>
                            <td>{{ buildDate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
-->
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Last Update</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <table class="table">
                    <tbody>
                        <tr>
                            <th style="border-top:none">Last Updated</th>
                            <td style="border-top:none">{{ lastUpdate.updated_date | epochDate }}</td>
                        </tr>

                        <tr>
                            <th>Message</th>
                            <td>{{ lastUpdate.message }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </p-panel>
    </div>
</div>
