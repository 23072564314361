import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Segment } from '../../../content/models';
import { SegmentService } from '../../../content/services/segment/segment.service';
import { WorkflowFormComponent } from '../../forms/workflow-form/workflow-form.component';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { Message } from 'primeng/api';

@Component({
  selector: 'workflow-segment-edit',
  providers: [
    SegmentService
  ],
  templateUrl: './workflow-segment-edit.component.html',
})
export class WorkflowSegmentEditComponent implements OnInit {
  @ViewChild('form') form: WorkflowFormComponent;
  @ViewChild('condition') conditionEditor: JsonEditorComponent;
  @ViewChild('schedulableaction') schedulableActionEditor: JsonEditorComponent;
  @ViewChild('pipelineaction') pipelineActionEditor: JsonEditorComponent;

  private conditionOptions: JsonEditorOptions;
  private conditionObject: Object = {};
  private schedulableActionOptions: JsonEditorOptions;
  private schedulableActionObject: Object = [];
  private pipelineActionOptions: JsonEditorOptions;
  private pipelineActionObject: Object = [];
  private validSections: Object = {};
  public segment: Segment;
  public isLoading = false;
  public msgs: Message[] = [];


  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public segmentService: SegmentService,
    private msgService: MessageService,
  ) { }

  ngOnInit() {
    this.isLoading = true
    this.conditionOptions = new JsonEditorOptions();
    this.conditionOptions.mode = 'tree';
    this.conditionOptions.modes = ['code', 'text', 'tree', 'view'];
    this.conditionOptions.statusBar = true;
    this.conditionOptions.expandAll = true;
    this.conditionOptions.onChange = () => this.changeJson('condition', this.conditionEditor);

    this.schedulableActionOptions = new JsonEditorOptions();
    this.schedulableActionOptions.mode = 'tree';
    this.schedulableActionOptions.modes = ['code', 'text', 'tree', 'view'];
    this.schedulableActionOptions.statusBar = true;
    this.schedulableActionOptions.expandAll = true;
    this.schedulableActionOptions.onChange = () => this.changeJson('schedulable_actions', this.schedulableActionEditor);

    this.pipelineActionOptions = new JsonEditorOptions();
    this.pipelineActionOptions.mode = 'tree';
    this.pipelineActionOptions.modes = ['code', 'text', 'tree', 'view'];
    this.pipelineActionOptions.statusBar = true;
    this.pipelineActionOptions.expandAll = true;
    this.pipelineActionOptions.onChange = () => this.changeJson('pipeline_action', this.pipelineActionEditor);

    this.route.params.subscribe(params => {
      let id = +params['segmentId'];
      this.segmentService.get(id)
          .subscribe(
              res => {
                this.segment = res;
                this.segment.data = this.segment.data ?? {};
                this.initValues();
              },
              err => this.isLoading=false,
              () => this.isLoading = false
          );
    });
  }

  onSubmit() {
    this.isLoading = true
    this.segmentService.save(this.segment).subscribe(
      res => this.segment = Object.assign(new Segment(), this.segment),
      err => {this.isLoading = false, console.log(err)},
      ()  => {
        this.isLoading = false
        this.msgService.add({severity: 'success', summary:'', detail:'Updated successfully' })
      }
    )
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  validateFormData() {
    this.msgs = [];
    let valid = true;
    for (let field of ['condition', 'schedulableaction', 'pipelineaction']) {
        if (this.validSections[field.toLowerCase()] === false) {
            valid = false;
            this.msgs.push({
                severity: 'error',
                summary: `${field}: Invalid JSON`,
                detail: 'Cannot save invalid JSON.'
            });
        }
    }
    return valid;
}

  private initValues() {
    if (!this.segment.data['condition']) {
        this.segment.data['condition'] = {};
    }
    if (!this.segment.data['schedulable_actions']) {
        this.segment.data['schedulable_actions'] = [];
    }
    if (!this.segment.data['pipeline_action']) {
      this.segment.data['pipeline_action'] = {};
  }

    this.conditionObject = this.segment.data['condition'];
    this.schedulableActionObject = this.segment.data['schedulable_actions'];
    this.pipelineActionObject = this.segment.data['pipeline_action'];
}

  private changeJson(field: string, editor: JsonEditorComponent) {
    try {
        var editorData = editor.get();
    } catch (e) {
        // Keep current value
        this.validSections[field] = false;
        return;
    }
    this.validSections[field] = true;
    this.segment.data[field] = editorData;
  }

}
