<div class="card card-w-title top-level-card">
    <h1>Edit Campaign{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>

    <page-header title="Edit Campaign{{!isLoading && container ? ': ' + container.data['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <div *ngIf="container && !isLoading && !isProcessing">
        <p-toast key="cpMsg"></p-toast>
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <campaign-form class="ui-g-12 ui-g-nopad" #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)" (pipelineTriggered)="onPipelineTriggered($event)">
            <div class="ui-g-12">
                <campaign-table itemsPerPage = 20 [containers]="container.containers" [parentContainer] ="container">
                    <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'content_group']" icon="pi pi-plus" style="float:right;margin-left:0px;height:100%;"></button>
                </campaign-table>
            </div>
    
            <div class="ui-g-12">
                <flight-table itemsPerPage = 20 [parentContainer] ="container" title="Flights" rows="10">
                    <button pButton type="button" label="Create" [routerLink]="['/commerce', 'flight', 'create', container?.id]" icon="pi pi-plus" style="float:right;margin-left:0px;height:100%;"></button>
                </flight-table>
            </div>
        </campaign-form>

        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
            </resource-datarow>
        </div>

        <div class="ui-g-12">
                <object-advancedview [object]="container" title="Advanced Editing" data-element-id="app-commerce-components-campaign-edit-advanced-editing"></object-advancedview>
        </div>
    </div>
</div>
