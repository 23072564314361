import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models';
import { SearchTableComponent } from './../../../content/components/search-table/search-table.component';
import { MenuItem } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'metrics-reports-filter-bar',
    templateUrl: 'metrics-reports-filter-bar.component.html',
    styleUrls: ['metrics-reports-filter-bar.component.css']
})
export class MetricsReportsFilterBarComponent implements OnInit {
    @Output() onFilterSelected: EventEmitter<any> = new EventEmitter();
    @Input() reportContainer: Container;
    @Input() showFilters: any;

    private filtersLoading = false;
    private error: string;
    //private hiddenFilters = [];
    public reportFilters = [];
    public selectedFiltersTypeMap = {};
    public dateString = '';
    

    constructor(
        private router: Router,
        private containerService: ContainerService,
        private datePipe: DatePipe
    ) {}

    ngOnInit() {
        this.filtersLoading = true;
        this.buildFilterElements(this.reportContainer);
        this.filtersLoading = false; 
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['reportContainer'] && changes['reportContainer'].previousValue != changes['reportContainer'].currentValue) {
            this.reportFilters = [];
            this.selectedFiltersTypeMap = {};
            this.buildFilterElements(this.reportContainer);
        }
    }

    buildFilterElements(reportContainer) {
        let reportFilters = []
        if ('filter_options' in reportContainer.data) {
            reportContainer.data['filter_options'].forEach(reportFilter =>{
                // if (reportFilter['render_type'] === 'calendar_with_range'){
                //     if (Array.isArray(reportFilter['options'])) {
                //         reportFilter['options'].push({ 'label': 'Custom', 'value': 'custom' });
                //     } else {
                //         reportFilter['options'] = [{ 'label': 'Custom', 'value': 'custom' }];
                //     }
                // } else if (reportFilter['render_type'] === 'hidden'){
                //     this.hiddenFilters.push(reportFilter['filter']);
                // }
                reportFilters.push(reportFilter);
            });
        }
        this.reportFilters = reportFilters;
        this.buildDefaultSelectedFiltersMap(reportFilters);
        console.log(this.reportFilters, "reportFilters")
    }

    getFilterTypes(filterArray) {
        let filtersTypes = new Set(filterArray.map(filter => filter.filter));
        return filtersTypes;
    }

    capitalize(word) {
        if (!word) return '<undefined>';
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    buildDefaultSelectedFiltersMap(reportFilters) {
        let defaultFilterAdded = false;
        reportFilters.forEach(filter => {
            let selectedFilters = []
            console.log(filter, "Filters")
            if (('options' in filter) && Array.isArray(filter['options'])) {
                filter['options'].forEach(filterValue => {
                    if (('default' in filterValue) && (filterValue['default'] === true)) {
                        if (filter['render_type'] !== 'calendar_with_range') {
                        // calendar_with_range handled in its own component
                            if (this.selectedFiltersTypeMap[filter['filter']]) {
                                this.selectedFiltersTypeMap[filter['filter']].push(filterValue);
                            } else {
                                this.selectedFiltersTypeMap[filter['filter']] = [filterValue];
                            }
                        }
                        defaultFilterAdded = true;
                    }
                })
            }
            //this.selectedFiltersTypeMap[filter['filter']] = selectedFilters;
        });
        if (defaultFilterAdded) {
            this.onFilterSelected.emit({
                params: this.buildParamArray(this.selectedFiltersTypeMap)
            });
        }
    }

    setFilterValues(event) {
        console.log('event',event)
        let filterMap = {}
        event['params'].forEach(filterType => {
            for (const [key, value] of Object.entries(filterType)) {
                if (Array.isArray(filterType[key])) {
                    let filterMapArray = []
                    filterType[key].forEach(filter => {
                        filterMapArray.push({value:filter});
                    })
                    this.selectedFiltersTypeMap[key] = filterMapArray;
                }
            }
        })

        this.onFilterSelected.emit({
            params: this.buildParamArray(this.selectedFiltersTypeMap)
        });
    }

    buildParamArray(filtersTypeMap) {
        let filterMapArray = []
        for (const [key, value] of Object.entries(filtersTypeMap)) {
            if (Array.isArray(filtersTypeMap[key])) {
                filtersTypeMap[key].forEach(filter => {
                    filterMapArray.push({[key] :filter['value']})
                });
            } else {
                let time : any = value
                let dtString = new Date(time).toISOString();
                filterMapArray.push({ [key]: dtString });
            }
        }
        return filterMapArray;
    }


    handleSelectFilterItem() {
        console.log('Selected Filters', this.selectedFiltersTypeMap)
        this.onFilterSelected.emit({
            params: this.buildParamArray(this.selectedFiltersTypeMap)
        });
    }

    handleDateSelect(filter) {
        this.dateString = this.datePipe.transform(this.selectedFiltersTypeMap[filter], 'yyyy-MMM-dd h:mm:ss a');
        this.handleSelectFilterItem();
    } 

    clearDate(filter) {
        if (this.selectedFiltersTypeMap[filter]) {
            delete this.selectedFiltersTypeMap[filter];
        }
        this.dateString = '';
        this.handleSelectFilterItem();
    }

}
