<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
    <h1>New Collection</h1>
    <page-header title="New Collection" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <p-messages [(value)]="requiredMsgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <collection-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></collection-form>
    </div>
</div>
