import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { ResourceService } from '../../services/resource/resource.service';
import { Resource } from '../../models';

@Component({
    selector: 'resource-root',
    providers: [
        ResourceService
    ],
    templateUrl: 'resource-root.component.html'
})
export class ResourceRootComponent implements OnInit {
    public resources: any;
    private error: HttpResponse<any>;
    public isLoading = false;

    constructor(
        private resourceService: ResourceService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.resourceService
            .list()
            .subscribe(
                res => this.resources = res,
                err => this.error = err,
                () => this.isLoading = false
            );
    }
}
