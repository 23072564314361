<page-header title="New Workflow" [showSpinner]="isLoading"></page-header>
<div *ngIf="container" class="card card-w-title top-level-card">
  <h1>New Workflow</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <workflow-form #form [container]="container" (formSubmit)="onSubmit(container)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></workflow-form>
    </div>
  </div>
</div>
