import { Component, OnInit, Input } from '@angular/core';
import { Container } from '../../models';
import { SelectItem } from 'primeng/api';
import {subGenre as subGenreList} from '../../../shared/enum'

@Component({
  selector: 'container-subgenre',
  templateUrl: './container-subgenre.component.html',
  styleUrls: ['./container-subgenre.component.css']
})
export class ContainerSubgenreComponent implements OnInit {
    @Input() container: Container;
    public subgenreOptions: SelectItem[] = subGenreList.sort((a, b) => a.label.localeCompare(b.label));
    public selectedSubgenreOption: SelectItem = null;

    constructor() { }

    ngOnInit() {
        if ('subgenre' in this.container.data && this.container.data['subgenre']) {
            this.selectedSubgenreOption = this.container.data['subgenre'];
        }
    }

    onValueChange() {
        if (this.selectedSubgenreOption) {
            this.container.data['subgenre'] = this.selectedSubgenreOption;
        } else {
            delete this.container.data['subgenre'];
        }
    }

}
