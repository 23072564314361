import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { UploadComponent } from '../../../upload/upload.component';
import { ContainerService } from '../../services/container/container.service';
import { ResourceService } from '../../services/resource/resource.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { ResourceTagService } from '../../services/resourcetag/resourcetag.service';
import { Resource, Tag } from '../../models';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';


@Component({
    selector: 'resource-form',
    providers: [
        ContainerService,
        ResourceService,
        EntityTypeService,
        ResourceTagService
    ],
    templateUrl: 'resource-form.component.html',
    styleUrls: ['resource-form.component.css']
})

export class ResourceFormComponent implements OnInit {
    @Input() resource: Resource;
    @Input() containerId: number;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private fileResources : Array<Resource> = [];
    private fileSourceId : number = null;
    private entityTypes = [];
    private credentialTypes = ['http_basic'];
    private entityTypeId : number = null;
    private entityTypeName : string = '';
    public isLoading = false;
    public isLoadingTags = false;
    private selectedTags: Tag[];
    private tags : Tag[];

    private frame: number;
    private keywords;

    private msgs: Message[] = [];


    constructor(
        private containerService: ContainerService,
        private resourceService: ResourceService,
        private entityTypeService: EntityTypeService,
        private resourcetagService: ResourceTagService,

    ) {

      }

    get statusList () {
        return ResourceService.statusList;
    }

    get fixedFileSource () {
        return !!(this.entityTypeName && this.resourceService.isFixedType(this.entityTypeName));
    }

    get fileSource () {
        return !!(this.entityTypeName && this.resourceService.isFileType(this.entityTypeName));
    }

    get fixedType () {
        return this.resource.id > 0 && this.fixedFileSource;
    }

    get createFormType () {
        return this.resource.type.name == 'identifier'
    }

    get noUploadTypes () {
        return this.resourceService.toUpload(this.entityTypeName)
    }

    get displayPreview() {
        if (this.isLoading || !this.resource || !this.entityTypeName) return false;
        return this.resourceService.displayPreview(this.entityTypeName);
    }

    ngOnInit() {
        this.entityTypeId = typeof this.resource.type == 'number'
            ? this.resource.type
            : this.resource.type.id;
        console.log('resource', this.resource);
        // console.log('resource type', this.entityTypeId);

        // Ensure data object set, even if empty
        if (this.resource.data === null) {
            this.resource.data = {};
        }

        this.setup();

    }



    resetToDefaultState() {
        this.ngForm.reset();
        this.setup();
    }

    setup() {
        this.selectedTags = this.resource.tags
        console.log ("Tags here")
        console.log (this.selectedTags)
        this.isLoading = true;

        this.isLoadingTags = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    let types = res.filter(t => this.onlyResourceTypes(t));
                    // Set type name
                    let entityType = types.find(t => t.id == this.entityTypeId);
                    this.entityTypeName = entityType ? entityType.name : '';
                    // Filter out fixed types from options if currently not fixed
                    // (We don't want to allow certain changes that don't make sense)
                    if (this.resource.id > 0 && !this.fixedFileSource) {
                        types = types.filter(t => !this.resourceService.isFixedType(t.name));
                    }
                    else if (this.resource.id <= 0 && !this.fileSource){
                        types = types.filter(t => !this.resourceService.isFileType(t.name));
                    }
                    types.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
                    this.entityTypes = types;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
        // Get file resources of parent container
        if (this.containerId && typeof this.containerId == 'number') {
            this.containerService
                .get(this.containerId)
                .subscribe(
                    (res: any) => {
                        this.fileResources = res['resources'].filter(t => t.type.name == 'file');
                        console.log(this.fileResources);
                        this.updateFileSource();
                    },
                    err => console.log(err),
                )
        }

        this.resourcetagService
            .list()
            .subscribe(
                res => {
                    this.tags = res;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoadingTags = false
            )
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        let entityType = this.entityTypes.find(t => t.id == this.entityTypeId);
        this.entityTypeName = entityType.name;
        this.resource.type = entityType;
        // Remove source URI if fixed
        if (this.fixedFileSource) {
            this.clearFileSource();
        }
        if (this.fileSource) {
            this.resource.status = 'hidden';
        }

        console.log('updated resource', this.resource);
    }

    onFileChange() {
        console.log('file changed', this.fileSourceId);
        let fileSource = this.fileResources.find(r => r.id == this.fileSourceId);
        if (fileSource) {
            let source_uri = fileSource.data['source_uri'];
            // Set source_uri to file's
            this.resource.data['source_uri'] = source_uri;
            if (fileSource.data['source_id']) {
                this.resource.data['source_id'] = fileSource.data['source_id'];
            } else {
                delete this.resource.data['source_id'];
            }
            // Replace main URI if empty or an HTTP URL
            if (!this.resource.uri || /^https?:\/\//.test(this.resource.uri)) {
                this.resource.uri = fileSource.uri;
            }
            // Set format/extension
            let format = fileSource.data['format'] || this.resourceService.getFormat(source_uri);
            this.resource.data['format'] = format;
        } else {
            this.clearFileSource();
        }
        console.log('updated resource', this.resource);
    }

    onSubmit(e) {

        this.msgs = [];

        let valid = true;

        if(this.resource.type.name == 'identifier'){
            if (!('guid' in this.resource) || (!this.resource['guid']) || !/\S/.test(this.resource['guid'])) {
                this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter a GUID for the resource.' });
            valid = false;
            }
        }
        if(!this.resource.uri && this.resource.id < 0){
            if(this.resource.type.name == 'credential'){
                this.resource.uri = `credential.http_basic.${this.containerId}`;
            }
            if(this.resource.type.name == 'identifier'){
                this.resource.uri = `${this.resource.namespace}.${this.resource.guid}`;
            }
        }
        if (valid && this.resource.uri && this.resource.type && this.resource.type.id > 0) {
            this.resource.tags = this.selectedTags;
            console.log('resource submitted', e);
            this.formSubmit.emit({
                resource: this.resource
            });
        } else {
            console.error('required data missing', e);
            this.formFailed.emit({
                resource: this.resource
            });
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onUploadComplete(e) {
        console.log('upload complete', e);
        this.resource.uri = e.asset_url;
        if (e.format) {
            this.resource.data['format'] = e.format;
        }
        if (e.source_uri) {
            this.resource.data['source_uri'] = e.source_uri;
            this.updateFileSource();
        } else {
            delete this.resource.data['source_uri'];
            delete this.resource.data['source_id'];
        }
    }

    onlyResourceTypes(value) {
        return this.resourceService.isResourceType(value.name);
    }

    private clearFileSource() {
        // Remove source URI and format, clear main URI
        delete this.resource.data['source_uri'];
        delete this.resource.data['source_id'];
        delete this.resource.data['format'];
        this.resource.uri = '';
        this.fileSourceId = null;
    }

    private updateFileSource() {
        // TODO: prefer source_id if available
        let fileSource = this.fileResources.find(
            r => r.data['source_uri'] == this.resource.data['source_uri']
        );
        this.fileSourceId = fileSource ? fileSource.id : null;
        console.log('file source', this.fileSourceId);
    }


}
