<ngx-charts-line-chart *ngIf="!isLoading"  class="ui-g-12" style="padding:0;" 
  [scheme]="colorScheme"
  [legend]="legend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="timeline"
  [results]="formattedDataNGX"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  [view]="view"
  >
</ngx-charts-line-chart>
<!--<p-chart type="line" [data]="formattedDataCharts" (onDataSelect)="selectData($event)"></p-chart>
-->