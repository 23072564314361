<spinner *ngIf="isLoading"></spinner>
<form *ngIf="!isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-toolbar class="ui-g-12">
          <div class="ui-g-6">
            <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
          </div>
          <div class="ui-g-6">
            <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-commerce-forms-creative-form-save"></button>
            <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
          </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-g-6">
                  <label for="label">Label</label>
                  <input type="text" class="form-control" [(ngModel)]="container.data['label']" name="label" placeholder="Required" required data-element-id="app-commerce-forms-creative-form-label-text">
              </div>
              <div class="ui-g-6">
                  <label for="origin">Origin</label>
                  <input type="text" class="form-control" readonly [(ngModel)]="container.origin" name="origin">
              </div>
              <div class="ui-g-6">
                  <label for="description">Description</label>
                  <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" placeholder="Required" required data-element-id="app-commerce-forms-creative-form-description-text">
              </div>
              <div class="ui-g-6">
                <label for="subtype">SubType</label>
                <select [(ngModel)]="container.data.subtype" name="subtype" class="form-control">
                  <option *ngFor="let stype of this.subType" [value]="stype">{{ stype }}</option>
                </select>
              </div>
          </div>
      </p-panel>
      <!--
      <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Availability</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-g-6">
                <label for="publishedDate" style="display:block;min-width:90px">Publish Date</label>
                <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
              </div>
              <div class="ui-g-6">
                <label for="expirationDate" style="display:block;">Expiration Date</label>
                  <p-calendar [(ngModel)]="expirationDate" [showTime]="true" [inline]="false" appendTo="body" [disabled]="true" [showIcon]="true" [ngModelOptions]="{standalone: true}"></p-calendar>
              </div>
          </div>
      </p-panel>
      -->
      <p-panel class="ui-g-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
        </p-header>

        <div style="padding:0.5em;overflow:hidden;">
            <div class="ui-g-4">
              <label for="type">Type</label>
              <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type">
            </div>
            <div class="ui-g-4">
                <label for="guid">GUID</label>
                <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-commerce-forms-creative-form-guid-text">
            </div>
        </div>
    </p-panel>
  </div>
</form>
