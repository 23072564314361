import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../content/components/container/container.component';
import { ContainerService } from '../content/services/container/container.service';
import { Container } from '../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../content/services/entitytype/entitytype.service';

@Component({
    selector: 'tools',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'tools.component.html'
})
export class ToolsComponent implements OnInit {

    public containers = [];
    public isLoading = false;
    private displayDialog = false;
    private container: Container;
    public error: string;
    private entityTypes = [];
    private entityTypeId;

    constructor(
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.isLoading = true;

        this.containerService
            .list('minimal', 0)
            .subscribe(
                res => {
                    this.containers = res;

                    console.log(res);

                    this.entityTypeService
                        .list()
                        .subscribe(
                            (res: any) => {
                                this.entityTypes = res;
                                this.entityTypeId = (this.entityTypes.find(t => t.name === 'episode'))
                                    .id;
                                this.containers.filter(container => container.type.id === this.entityTypeId);
                                this.isLoading = false;

                            },
                            err => console.log(err),
                            () => this.isLoading = false
                        );
                },
                err => {
                    console.log(err);
                    // this.error = err.statusText;
                    this.isLoading = false;
                },
                () => this.isLoading = false
            );
    }

    save() {

        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    // this.displayDialog = false;
                },
                err => {
                    console.log(err);
                    this.displayDialog = false;
                },
                () => this.isLoading = false
            );

    }
}
