import { Injectable } from '@angular/core';

@Injectable()
export class CanvasService {
  /**
   * Draws filled rectangle
   *
   * @param canvas CanvasRenderingContext2D
   * @param x The x-axis coordinate of the rectangle's starting point.
   * @param y The y-axis coordinate of the rectangle's starting point.
   * @param width The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height The rectangle's height. Positive values are down, and negative are up.
   * @param color A DOMString parsed as CSS <color> value
   *
   */
   public drawFilledRectangle(canvas, x: number, y: number, width: number, height: number, color: string): void {
    canvas.fillStyle = color;
    canvas.fillRect(x, y, width, height);
  }


  // Render canvas's triangle
  public drawTriangle(canvas, x = 0, y = 0, h = 0, w = 0, color: string): void {
    canvas.fillStyle = color;
    canvas.beginPath();
    canvas.moveTo(x + 0, y + 0);
    canvas.lineTo(x - w / 2, y - h);
    canvas.lineTo(x + w / 2, y - h);
    canvas.fill();
  }

  // Render canvas's a circular arc
  public drawArc(canvas, x: number, y: number, color: string, radius = 3,  stroke = 'fill'): void {
    canvas.fillStyle = color;
    canvas.strokeStyle = color;
    canvas.beginPath();
    if (stroke === 'stroke') {
      canvas.arc(x, y, radius, 0, 3 * Math.PI);
      canvas.stroke();
    } else {
      canvas.arc(x, y, radius, 0, 3 * Math.PI);
      canvas.fill();
    }
  }

    // Render canvas's a stroke
  public borderRect(canvas, x: number, y: number, width: number, height: number, color: string): void {
    canvas.strokeStyle = color;
    canvas.strokeRect(x, y, width, height);
  }

  // Render canvas's rectangle
  public drawRectangle(canvas, x: number, y: number, width: number, height: number, color: string): void {
    canvas.fillStyle = color;
    canvas.fillRect(x, y, width, height);
  }

}
