import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'login-form',
    templateUrl: 'login-form.component.html',
    styleUrls: ['login-form.component.css']
})
export class LoginFormComponent {
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    public username: string;
    public password: string;

    constructor() {}

    onSubmit(e) {
        this.formSubmit.emit({
            username: this.username,
            password: this.password
        });
    }
}
