import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import {genderList, roleList} from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'person-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'person-form.component.html'
})
export class PersonFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityType;
    public isLoading = false;
    private status: any;
    private isProcessing = false;
    private disable_tabular_view = false;

    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    public readonly genderList = genderList;
    public readonly roleList = roleList;

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {

        this.isLoading = true;
        this.entityTypeService
            .getByName('person')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('first_name' in this.container.data) || (!this.container.data['first_name']) || !/\S/.test(this.container.data['first_name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the first name.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.container.data['label'] = this.container.data['first_name']

            if ('middle_name' in this.container.data || this.container.data['middle_name'] || !/\S/.test(this.container.data['middle_name']))
              this.container.data['label'] = this.container.data['label'] + " " + this.container.data['middle_name']

            if ('last_name' in this.container.data || this.container.data['last_name'] || !/\S/.test(this.container.data['last_name']))
              this.container.data['label'] = this.container.data['last_name'] + " " + this.container.data['label']

            this.ngForm.form.markAsPristine();
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }
    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

}
