import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Container, Segment } from '../../models';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'segment-add',
    templateUrl: 'segment-add.component.html',
    styleUrls: ['segment-add.component.css']
})
export class SegmentAddComponent implements OnInit {
    @Input() container: Container;
    @Input() defaultAddAction: string;
    @Input() addActionsList: any;

    public segmentAddTypes: MenuItem[] = [];
    public defaultLabel: string = '';

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {

        this.defaultLabel = this.capitalize(this.defaultAddAction);

        this.addActionsList.forEach(action => {
            this.segmentAddTypes.push({label: this.capitalize(action), icon: 'fa-plus', routerLink: ['/containers', this.container.id, 'segments', action, 'create']})
        })
    }

    addDefaultSegment() {
        return this.router.navigate(['/containers', this.container.id, 'segments', this.defaultAddAction, 'create']);
    }

    capitalize(word) {

        let new_word = word
            .split('_')
            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ');

        return new_word;
    }

}