import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Container } from '../../../content/models/container';
import { ContentGroupService } from '../../services/content-group.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppContext } from '../../../app.context';



@Component({
    selector: 'moment-table',
    templateUrl: 'moment-table.component.html',
    styleUrls: ['moment-table.component.css']
})

export class CAMomentTableComponent implements OnInit {
    @Input() inputContainer: Container;
    @Input() rows: number;
    @Input() totalRecords: number;
    @Input() showRemoveControls = false;
    @ViewChild(Table) itemTable;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @Output() momentRemove: EventEmitter < any > = new EventEmitter();
    public selectedContainer: Container;
    private cgState: any;
    private destroy$ = new Subject();
    public isLoading = true;
    public momentContainers = [];
    private limit: number;
    private count: number;
    private page: number;
    private lastSearch = null;
    private lastSearchTerms = [];
    private error;
    public offset = 0;
    public config = {
        csKey: 'moment_search_view',
        keepSelectionOnReload: true
    }

    constructor(
        private cgService: ContentGroupService,
        private containerService: ContainerService,
        private appContext: AppContext
    ) {}

    ngOnInit() {
        this.destroy$.next(false);
        this.createCgSub();
        if (this.config.csKey in this.appContext.componentState) {
            this.getSearchContextParams();
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                //console.log('set active moment', cgState)
                this.cgState = cgState;
                if (this.cgState['activeMoment']) {
                    this.selectedContainer = this.cgState['activeMoment'];
                    //console.log('set active moment container', cgState['activeMoment'])
                }
            });
    }

    getSearchContextParams() {
        let searchContextParameters = this.appContext.componentState[this.config.csKey];
        this.offset = searchContextParameters['offset'] || 0;
    }

    setSearchContextParams() {
        let selecterContainerGuid;
        if (this.config.csKey in this.appContext.componentState && this.appContext.componentState[this.config.csKey]['guid']) {
            selecterContainerGuid = this.appContext.componentState[this.config.csKey]['guid'];
        }
        this.appContext.componentState[this.config.csKey] = {
            'offset': this.offset || null
        }
        if (this.config.keepSelectionOnReload && (this.selectedContainer || selecterContainerGuid) ) {
            if (this.selectedContainer) {
                this.appContext.componentState[this.config.csKey]['guid'] = this.selectedContainer.guid;
            } else {
                this.appContext.componentState[this.config.csKey]['guid'] = selecterContainerGuid;
            }
        }
    }

    loadMomentData(terms) {
        this.lastSearchTerms = terms;
        if (this.lastSearch) {
            this.lastSearch.unsubscribe();
            this.lastSearch = null;
        }
        this.isLoading = true;
        this.lastSearch = this.containerService
            .getRelatedByTypeAndGuidPaginated('moment', this.inputContainer.guid, this.page, this.count)
            .subscribe(
                res => {
                    this.momentContainers = res;
                },
                err => {
                    this.error = err;
                    console.log('error', this.error)
                },
                () => {
                    this.isLoading = false;
                }
            )
    }

    loadMomentsLazy(event) {
        this.limit = event.rows;
        this.offset = event.first
        if (this.offset == 0) {
            this.page = 1;
        } else {
            this.page = (this.offset / this.limit) + 1
        }
        this.count = this.limit;
        this.loadMomentData(this.lastSearchTerms);
        this.setSearchContextParams();
    }

    onRowSelect() {
        //console.log("Selected Item MT");
        //console.log(this.container);
        this.cgService.updateCgState({
            'activeMoment': this.selectedContainer,
            'source': 'moment-table'
        });
        this.rowSelect.emit({
            container: this.selectedContainer
        });
    }

    unselectTableItems() {
        this.itemTable.selection = null;
    }

    onRemove(container) {
        this.momentRemove.emit({
            'container': container
        });
    }
}
