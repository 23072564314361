import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../app/auth/auth.service';
import { Container } from '../content/models';
import { ContainerService } from '../content/services/container/container.service';


@Component({
    selector: 'landing',
    templateUrl: 'landing.component.html',
    styleUrls: ['landing.component.css']
})
export class LandingComponent implements OnInit, OnDestroy {
    private userSub: any;
    public userName: string;
    public selectedPost: object;
    public allContainerStatuses: Array<string>;

    constructor(
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.allContainerStatuses = ContainerService.statusList;
        this.userSub = this.authService.userState.subscribe(
            res=> {
                if (res && res.email) {
                    let userEmail = res.email
                    this.userName = userEmail.split('@')[0]
                        .split('.')
                        .map(name => name.charAt(0).toUpperCase() + name.slice(1))
                        .join(' ');
                }
            },
            err => {
                console.error(err);
            },
            () => {}
        )
    }

    onPostSelect(post: Container) {
      this.selectedPost = {...post}
    }

    ngOnDestroy() {
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }
}
