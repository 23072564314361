import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerTableComponent } from '../../../content/components/container-table/container-table.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { SelectItem } from 'primeng/api';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { AppContext } from '../../../app.context';

@Component({
    selector: 'settings-tests',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'settings-tests.component.html',
    styleUrls: ['settings-tests.component.css']
})
export class SettingsTestsComponent implements OnInit {
    private searchResult: any = {};
    public containers = [];
    public isLoading = false;
    private displayDialog = false;
    public container: Container;
    public collapsed = false;
    private entityTypes = [];
    private entityTypeId;
    public statusList: Array<SelectItem> = [];
    private filteredStatus = '';
    public selectedStatus: SelectItem = null;
    public columns = [];
    private error: string;
    private limit: number;
    private offset: number;
    private searchTerm = '';
    private orderBy: Array<string> = [];
    private filteredType = '';
    private allStatus: Array<string> = [];

    constructor(
        public containerService: ContainerService,
        private appContext: AppContext,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));

        if (this.appContext.componentState['tests_view']['status'] == null) {
            this.setDefaultParams();
            this.setStatusParams();
        }

        this.getStatusParams();
        this.loadResults();
    }

    getStatusParams() {
        let statusContextParameters = this.appContext.componentState['tests_view'];
        this.filteredStatus = (statusContextParameters['status'] || '').toLowerCase();

        if (this.filteredStatus) {
            if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
                this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
            }
        } else if (this.selectedStatus) {
            this.selectedStatus = null;
        }
    }

    setStatusParams() {
        this.appContext.componentState['tests_view']['status'] =
            this.filteredStatus || '';
    }

    setDefaultParams() {
        this.filteredStatus = 'published';
    }

    statusLabelClass(status) {
        return this.containerService.getStatusLabelClass(status);
    }

    loadResults() {
        this.isLoading = true;
        this.containerService
            .filterByTypeNameAndStatus('test_suite', -1, true,
                this.filteredStatus || ''
            )
            .subscribe(
                res => {
                    this.containers = res;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onChangedFilteredStatus(event) {
        this.filteredStatus = event.value ? event.value.value : '';
        this.loadResults();
        this.setStatusParams();
    }

    onRowSelect(event) {}

}
