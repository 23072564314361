<div class="items" [dragula]="name" [(dragulaModel)]="containers">
    <div class="item" *ngFor="let container of containers; let i = index">
        <div *ngIf="container.id">
            <div class="content">
                <distribution-container [container]="container"></distribution-container>
            </div>
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <button pButton type="button" [routerLink]="['/settings/distributions', container.id, parentContainer.id]" label="Edit" icon="pi pi-pencil"></button>
                </div>
                <div class="p-toolbar-group-right">
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, container)" label="Remove" icon="pi pi-times"></button>
                </div>
            </p-toolbar>
        </div>
    </div>
</div>
