import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpResponse } from '@angular/common/http'
import { Container, Schema } from '../../../content/models';
import { SchemaService } from '../../../content/services/schema/schema.service'

@Component({
  selector: 'schema-container-table',
  templateUrl: 'schema-container-table.component.html',
  styleUrls: ['schema-container-table.component.css']
})
export class SchemaContainerTableComponent implements OnInit {
  @Input() schema: Schema;
  @Input() containers: Array<Container>;
  @Input() title: string;
  @Input() rows: number;
  @Input() isLoading = false;
  @Output() containerRemoved = new EventEmitter <Container> ();

  public container: Container;
  public error: HttpResponse<any>;

  constructor(
    private schemaService: SchemaService,
  ) { }

  ngOnInit(): void {
  }

  onRowSelect(e) {
    console.log(e)
  }

  onRemove(container) {
    this.schemaService
        .unrelate(this.schema, container)
        .subscribe(
          res => {
            this.containers = this.containers.filter(c => c !== container);
            this.containerRemoved.emit(container);
          },
          err => {
            this.error = err.statusText;
          }
        )
  }

}
