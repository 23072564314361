<p-dialog header="Profile"
        [(visible)]="showProfile"
        (onHide)="hideProfile()"
        [modal]="true"
        [minY]="70"
        [baseZIndex]="10000"
        [dismissableMask]="true"
        [blockScroll]="true"
        [responsive]="true"
        styleClass="dialog-max-40em"
        [style]="{'min-width':'200px'}"
        [appendTo]="'body'">
    <div class="ui-g-12" *ngIf="user && profile">
        <account-profile-form  #form
                            [user]="user"
                            [profile]="profile"
                            (formSubmit)="onSubmit($event)"
                            (formFailed)="onFailure($event)"
                            (close)="onClose()">
        </account-profile-form>
    </div>
</p-dialog>