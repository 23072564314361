import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'testing-reports-list',
    templateUrl: 'testing-reports-list.component.html',
    styleUrls: ['testing-reports-list.component.css']
})
export class TestingReportsListComponent implements OnInit {
    @Output() onItemSelected: EventEmitter < any > = new EventEmitter();

    menuItems: MenuItem[];
    public menuLoading = false;
    private testSuiteContainers = [];
    private error: string;
    private selectedContainer;
    private selectedItem: MenuItem;

    suiteSelected = false;
    constructor(
        private containerService: ContainerService,

    ) {}

    ngOnInit() {
        this.menuLoading = true;

        this.menuItems = [];

        this.containerService
        .filterByTypeName('test_suite', 'full', -1)
        .subscribe(
            res => this.testSuiteContainers = res,
            err => {
                console.log(err);
                this.error = err.statusText;
            },
            () => {
                this.menuLoading = false;
                this.buildMenu(this.testSuiteContainers);
                console.log('Reports:', this.testSuiteContainers);
            }
        );
    }

    buildMenu(suiteContainerResults) {
        let suitesDict = {};
        suiteContainerResults.forEach((suite, i) => {
            let category = suite.data['category'] ? suite.data['category'] : 'Other';
            if (category.toLowerCase() != 'system') {
                if (!(category in suitesDict)) {
                    suitesDict[category] = [];
                }
                suitesDict[category].push({
                    label: suite.data['label'],
                    icon: 'fa fa-fw fa-square',
                    guid: suite.guid,
                    command: () => {
                        this.handleSelectTestSuiteItem(suite);
                    }
                });
            }
        });
        for (const [key, value] of Object.entries(suitesDict)) {
            let sortedValue = suitesDict[key].sort((a, b) => (a.label > b.label) ? 1 : -1);
            this.menuItems.push({label: key, items: sortedValue});

        }
        this.menuItems.sort((a, b) => (a.label > b.label) ? 1 : -1);

        if (this.menuItems[0]) {
            this.menuItems[0]['items'][0]['command']();
            this.highlightSelectedItem(null, 0, 0);
        }

    }

    highlightSelectedItem(container = null, menuOrdinal = null, itemOrdinal = null) {
        if (this.selectedItem) {
            delete this.selectedItem['styleClass'];
        }
        if (menuOrdinal !== null && itemOrdinal !== null) {
            this.selectedItem = this.menuItems[menuOrdinal]['items'][itemOrdinal];
            this.menuItems[menuOrdinal]['items'][itemOrdinal]['styleClass'] = 'active-menuitem';
        } else {
            this.menuItems.map(item => {
                console.log(item);
                item['items'].map(subItem => {
                    if (subItem['guid'] === container.guid) {
                        subItem['styleClass'] = 'active-menuitem';
                        this.selectedItem = subItem;
                    }
                });
            });
        }
    }

    handleSelectTestSuiteItem(container) {
        this.selectedContainer = container;
        this.suiteSelected = true; 
        this.highlightSelectedItem(container);
        this.onItemSelected.emit({
            container: container
        });
    }
}
