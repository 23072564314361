<form *ngIf="model" (submit)="onSubmit($event)">
    <div class="form-group">
        <label for="title">Title</label>
        <input type="text" class="form-control" required [(ngModel)]="model.title" name="title">
    </div>

    <div class="form-group">
        <label for="description">Description</label>
        <input type="text" class="form-control" [(ngModel)]="model.description" name="description">
    </div>

    <button type="submit" class="btn btn-default">Save</button>
</form>
