import { Component, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ErrorComponent } from '../ui/error.component';

@Component({
    selector: 'page-header',
    templateUrl: 'page-header.component.html',
    styleUrls: ['page-header.component.css'],
})
export class PageHeaderComponent {
    @Input() title: string;
    @Input() showSpinner = false;
    @Input() error: string;

    constructor() {}
}
