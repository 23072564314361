import { Component, OnInit, Input } from '@angular/core';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../services';


@Component({
  selector: 'through-input',
  templateUrl: './through-input.component.html',
  styleUrls: ['./through-input.component.css']
})
export class ThroughInputComponent implements OnInit {
    @Input() parentContainer: Container;
    @Input() childContainer: Container;
    @Input() containerTypeName: string;
    public containerLabel = "";
    public placeholderLabel = "Add ";
    public containerId = 0;

    constructor(
        private containerService: ContainerService
    ) { }

    ngOnInit() {
        this.placeholderLabel += this.containerTypeName;
        if (this.childContainer['through_container_id']) {
            this.containerId = this.childContainer['through_container_id'];
            this.containerService
                .get(this.containerId)
                .subscribe(
                    res => {
                        this.containerLabel = res['data']['label'];
                    },
                    err => console.log(err)
                )
        }
    }

    removeThrough(event){
        if (confirm("Are you sure you want to remove this relationship?")) {
            this.containerService
                .unrelateById(this.parentContainer.id, this.childContainer.id, this.containerId)
                .subscribe(
                    res => {
                        this.containerId = 0;
                        this.containerLabel = "";
                    },
                    err => console.log(err)
                );
        }
    }

}
