import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityType, Resource } from '../../content/models';
import { Observable } from 'rxjs';
import { AppContext } from '../../app.context';
import { AuthService } from '../../auth/auth.service';
import { PipelineService } from '../../pipeline/pipeline.service';

@Injectable()
export class MetricsService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/templates`;

    constructor(
        private http: HttpClient,
        private appContext: AppContext,
        private authService: AuthService,
        private pipelineService: PipelineService,
    ) {}

    getContentMetrics(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let origin = this.appContext.activeOrigin;

        return this.http
            .get(
                `${this.baseUrl}/metrics/content/?origin=${origin}`,
                { headers: headers }
            );
    }

    getPipelineMetrics(page = 1, bundle_id = '', context_id = '', status=''): Observable < any > {
        return this.pipelineService.getPipelineList(page, bundle_id, context_id, status);
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }
}
