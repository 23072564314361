import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContainerHistory, ResourceHistory, SchemaHistory } from '../../models';
import { HistoryService } from '../../services/history/history.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import * as JSONStableStringify from 'json-stable-stringify';

@Component({
    selector: 'history-diff',
    providers: [
        HistoryService,
    ],

    templateUrl: 'history-diff.component.html',
    styleUrls: ['history-diff.component.css']
})

export class HistoryDiffComponent implements OnInit {

    private isLoading = false;
    private isLoadingFetchObject = false;
    private sub: any;
    private objectId;
    private objectType;
    private historyId;
    private prevHistoryId;
    private currentObject: ContainerHistory | ResourceHistory | SchemaHistory;
    private oldObject: ContainerHistory | ResourceHistory | SchemaHistory;
    private diffChanges: any;
    public currentObjectString: string = '';
    public oldObjectString: string = '';
    private diffField: any;
    private oldValue: any;
    public currentHistoryUser: any;
    public currentHistoryDate: string = '';

    constructor(
        private historyService: HistoryService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.objectId = +params['objectId'];
            this.objectType = params['type'];
            this.historyId = +params['historyId'];
            });
        this.getDiff();     

    }

    getDiff(){

        this.isLoading = true;

        this.historyService
            .getHistoryDiff(this.objectId, this.objectType, this.historyId)
            .subscribe(
                res => {
                    this.prevHistoryId = res.old_history_id;
                    this.diffChanges = res.changes;
                    this.fetchObject();
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false

            );

    }

    fetchObject(){
        this.isLoadingFetchObject = true;

        this.historyService
            .retrieveHistory(this.objectId, this.objectType, this.historyId)
            .subscribe(
                res => {
                    this.currentObject = res;
                    this.currentHistoryUser = this.currentObject['history_user'];
                    this.currentHistoryDate = new Date(this.currentObject['history_date']).toLocaleString();
                    this.removeHistoryFields();
                },
                err => {
                    console.log(err);
                },
                () => this.isLoadingFetchObject = false
            );
    }

    removeHistoryFields() {

        ['history_id','history_date', 'history_type', 'history_user', 'history_user_id', 'history_change_reason'].forEach(element => delete this.currentObject[element]);

        this.createOldContainerState();


    }

    createOldContainerState() {

        this.currentObjectString = JSONStableStringify(this.currentObject, { space: 2});
        this.currentObject = JSON.parse(this.currentObjectString);
        this.oldObject = JSON.parse(this.currentObjectString);

        for (let change of this.diffChanges) {
            let diffField = change['field'];
            let oldValue = change['old'];
            
            if (this.currentObject.hasOwnProperty(diffField)) {
                this.oldObject[diffField] = oldValue;
            }

        }
        this.oldObjectString = JSONStableStringify(this.oldObject, { space: 2 });
    }

}
