import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpResponse as Response } from '@angular/common/http';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service'
import { ProfileService } from '../../../content/services/profile/profile.service';
import { Message } from 'primeng/api';
import { Profile } from '../../../content/models';

@Component({
  selector: 'account-profile-edit',
  templateUrl: 'account-profile-edit.component.html',
  styleUrls: ['account-profile-edit.component.css']
})
export class AccountProfileEditComponent implements OnInit {
  @Input() showProfile: boolean = false;
  @Output() closeProfile: EventEmitter < any > = new EventEmitter();

  public user: any;
  public profile: Profile;
  public isLoading = false;
  public error: Response<any>;
  public msgs: Message[] = [];

  constructor(
    private authService: AuthService,
    private appContext: AppContext,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getAuthenticatedUser();
    this.profile = this.appContext.getProfile();
  }

  hideProfile() {
    this.closeProfile.emit()
  }

  onSubmit(e) {
    let profile = e['profile'];
    this.isLoading = true;
    this.profileService
        .save(profile)
        .subscribe(
          res => {
            this.msgs = [];
            this.appContext.setProfile(this.profile);
          },
          err => this.error = err,
          () => {
            this.isLoading = false;
            this.showProfile = false;
          }
        )
  }

  onFailure(e) {
    console.log(e)
  }

  onClose(e) {
    this.closeProfile.emit();
  }

}
