<div class="layout-wrapper" [ngClass]="{'layout-wrapper-menu-active':sidebarActive,
                                        'layout-overlay-menu':overlay}">
    <app-topbar></app-topbar>

    <app-sidebar *ngIf="isAuthenticated()"></app-sidebar>

    <div class="layout-content">
    	<!--<p-growl key="api"></p-growl> -->
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>
</div>
