import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {             
        if (this.authService.isAuthenticated()) {
            if ('data'in router && 'viewPermissions' in router.data){
                let userPermissions = this.authService.getPermissions()
                // Sets redirect to requested route if permissions arent 
                // ready. Redirects once permissions are loaded and 
                // available in the authService
                if (Object.keys(userPermissions).length === 0){
                    this.authService.setRefreshUrl(state.url);
                    return false;
                }

                let permissions = router.data.viewPermissions as Array<string>
                if (!permissions.includes('admin'))
                {
                    permissions.push('admin')
                }                
                return this.authService.hasPermission(permissions.map(item => `view.show.${item}`))
            }
            else
            {
                return true;
            }

        }

        // store the attempted url for redirecting
        this.authService.setRedirectUrl(state.url);

        // navigate to login page
        this.router.navigate([this.authService.getLoginUrl()]);
        return false;
    }
}
