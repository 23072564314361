<page-header title="Edit Tag" [showSpinner]="isLoading"></page-header>
<div *ngIf="tag" class="card card-w-title top-level-card">
  <h1>Edit Container Tag{{!isLoading && tag ? ' : ' +tag.value : ''}}</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <tag-form #form [tag]="tag" (formSubmit)="onSubmit(tag)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></tag-form>
    </div>
  </div>
    <div class="p-mt-5 p-p-2">
      <object-advancedview [object]="tag" title="Advanced Editing"></object-advancedview>
    </div>
</div>
