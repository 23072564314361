<p-panel>
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">Moments Player</span>
        <div pButton
                label="Save Moment"
                (click)="saveMoment()"
                class="p-button-primary"
                style="float:right"
                icon="p-button-icon-left p-clickable pi pi-check">
        </div>
    </p-header>

    <div *ngIf="isLoading" class="ui-g-12">
        <spinner ></spinner>
    </div>

    <div class="ui-g-12" *ngIf="!isLoading && rawContainer" style="margin-bottom:0; padding-bottom:0">
        <span style="margin-right:1em;">Guid: <strong>{{rawContainer.guid}}</strong></span>
        <span style="margin-right:1em;">Origin: <strong>{{rawContainer.origin}}</strong> </span>
        <span style="margin-right:1em;">Status: <strong>{{rawContainer.status}}</strong></span>
        <span style="margin-right:1em; display:block; float:right;">
            <input type="checkbox" 
                   [(ngModel)]="allowAutoplay" 
                   style="margin:0 5px 0 0; vertical-align:middle;" 
                   (change)="onAutoplayChange()"> Autoplay video
        </span>
    </div>
        <!--
        <div class="ui-g-12" #wrapper *ngIf="!isLoading && lastKeywordLoaded && videoResource && videoResource.data">
            <keyword-frequency [keywords]="termFacetResults"
                               [moment]="frame"
                               [chapterSeconds]="chapters"
                               [duration]="videoResource.data?.duration">
            </keyword-frequency>
        </div>
        -->
    <div class="ui-g-12 ui-g-nopad" *ngIf="!isLoading  && videoResource && videoResource.data">
        <div class="ui-g-12 ui-g-nopad">
            <preview-player 
            class="ui-g-12"
            trackPlayerState="true"
            [resource]="videoResource" 
            [playerStyle]="{'height': '370'}">
        </preview-player>
        </div>
        <div class="ui-g-12 ui-g-nopad">
            <zoom-timeline [duration]="videoResource.data.duration"
                            [colors]="colors"
                            [keywords]="termFacetResults">
            </zoom-timeline>
        </div>
    </div>
</p-panel>


