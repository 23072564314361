<div class="card card-w-title top-level-card">
    <h1>Edit Segment{{!isLoading && segment ? ': ' + segment['label'] : ''}}</h1>
    <div *ngIf="!segment && !isLoading && requiredMsgs">
        <p-messages [(value)]="requiredMsgs"></p-messages>
    </div>

    <page-header title="Edit Document{{!isLoading && segment ? ': ' + segment['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <div *ngIf="segment && !isLoading">
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <div class="ui-g card-content" *ngIf="segment && !isLoading">
        <segment-form #form
                    [segment]="segment"
                    (formSubmit)="onSubmit($event)"
                    (formFailed)="onFailure($event)"
                    (formCancelled)="onCancel($event)">
        </segment-form>

        <hr>

        <div class="ui-g-12">
          <object-advancedview [object]="segment" title="Advanced Editing"></object-advancedview>
        </div>
    </div>

</div>
