import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DocumentFormComponent } from '../../forms/document-form/document-form.component';
import { Message } from 'primeng/api';

@Component({
    selector: 'document-create',
    templateUrl: 'document-create.component.html',
    styleUrls: ['document-create.component.css']
})

export class DocumentCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: DocumentFormComponent;
    private sub: any;
    public container: Container = new Container();
    public error: HttpResponse<any>;
    public isLoading = false;
    private requiredMsgs: Message[] = [];

    constructor(
        private location: Location,
        public containerService: ContainerService,
        private router: Router
    ) {}

    ngOnInit() {
        console.log('document create initialized');
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe;
        }
    }

    onSubmit(e) {
        console.log('submit', e.model);
        console.log(this.container.data);
        this.sub = this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/document', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }
    
    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
