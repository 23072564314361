<div *ngxPermissionsOnly="['view.show.workflow']" class="card card-w-title top-level-card">
    <h1>Workflows</h1>
    <p-toolbar>
        <div class="ui-g">
            <div class="ui-g-4">
                <input pInputText
                  type="text"
                  placeholder="Filter"
                  [(ngModel)]="filterTerm"
                  (ngModelChange)="changedFilter($event)"
                  style="text-align:center;height:100%;width:100%;"
                />
            </div>
            <div class="ui-g-4">
                <p-dropdown [options]="statusList"
                  (onChange)="onChangedFilteredStatus($event)"
                  placeholder="No Status Filter"
                  optionLabel="value"
                  [showClear]="true"
                  [ngModel]="selectedStatus"
                  [style]="{'height':'100%','width':'100%'}">
                </p-dropdown>
            </div>
            <div class="ui-g-4">
                <button pButton type="button" label="Create" [routerLink]="['/settings/workflow', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;" data-element-id="app-settings-components-workflows-component-create"></button>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-toolbar>
    <p-table #dt
            [columns]="columns"
            [value]="containers"
            [autoLayout]="true"
            [globalFilterFields]="['data.name', 'guid', 'data.subtype', 'status']"
            [rows]="10"
            [paginator]="true"
            paginatorPosition="both"
            dataKey="guid"
            selectionMode="single"
            (onRowSelect)="onRowSelect($event)"
            [(selection)]="container"
            [responsive]="true"
            styleClass="p-datatable-gridlines"
            expandableRows="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Guid</th>
                    <th>Subtype</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-workflow>
                <tr>
                    <td>{{workflow.data.name}}</td>
                    <td style="word-break: break-all;">{{workflow.guid}}</td>
                    <td>{{workflow.data.subtype}}</td>
                    <td class="text-center"><div class="label" [ngClass]="statusLabelClass(workflow.status)">{{workflow.status|titlecase}}</div></td>
                    <td>
                        <div class="text-center">
                            <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/settings/workflow', workflow.id]" icon="pi pi-pencil"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
    </p-table>
</div>
