import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ContainerService } from '../../services/container/container.service';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerRelateFormComponent } from '../../forms/container-relate-form/container-relate-form.component';
import { Container } from '../../models';

@Component({
    selector: 'container-relate-through',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-relate-through.component.html',
    styleUrls: ['container-relate-through.component.css']
})
export class ContainerRelateThroughComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container;
    public throughContainer: Container;

    public parentId: Number;
    public childId: Number;

    private isLoading = false;
    public error: string;
    public containerFilterType: string;
    public containerDisplayType: string;
    public processing = false;
    private progress = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            this.parentId = +params['parentId'];
            this.childId = +params['childId']; 
            this.containerFilterType = params['type'] || '';
            this.containerDisplayType = this.containerFilterType.replace('_', ' ');

            console.log('Parent, Child', this.parentId,this.childId);
            console.log('containerFilterType', this.containerFilterType);

            this.isLoading = true;
            this.containerService
                .get(this.parentId, 'flat', -1)
                .subscribe(
                    res => {
                        this.container = res;
                        this.isLoading = false;
                    },
                    err => {
                        console.log(err);
                        this.error = err.statusText;
                    }
                );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
        console.log('relate submit heard', e);
        this.processing = true;
        this.progress = 0;
        this.submitContainerRelateThrough(this.parentId,this.childId,e.containers.id, () => {
            if (this.error){
                alert("Completed, but an error was encountered when relating one or more items: "+ this.error)
                this.location.back();
            }
            else
            {
                this.location.back();
            }
        });
    }

    submitContainerRelateThrough(parentId: Number, containerId: Number, throughId: Number, onComplete) {
        this.containerService
            .relateThroughById(parentId, containerId,throughId)
            .subscribe(
                res => {
                    console.log('Finished Relate Trhough',res);
                    this.progress = 1;
                    onComplete();   
                },
                err => {
                    console.log('FAILURE:', err);
                    this.error = 'Relationship failed or already exists';
                    }   
            );
    }

    onBack(e) {
        this.location.back();
    }
}
