import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models';
import { ContainerService } from '../../../content/services/container/container.service';
import { TagFormComponent } from '../../forms/tag-form/tag-form.component';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'notification-edit',
  templateUrl: './notification-edit.component.html',
  providers: [
    ContainerService
],
})
export class NotificationEditComponent implements OnInit {
  @ViewChild('form') form: TagFormComponent;
  @ViewChild('queryset') querysetEditor: JsonEditorComponent;

  private sub: any;
  public container: Container;
  public isLoading = false;
  public containers: Array<Container>;
  private editorOptionsQueryset: JsonEditorOptions;
  public typeExclude = ['server']
  private defaultAddAction: string = 'media';
  private addActionsList: any = ['channel', 'collection', 'document', 'dynamic', 'episode', 'movie', 'series', 'season', 'query'];
  public containerCount: number;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public containerService: ContainerService,
    private msgService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.sub = this.route.params.subscribe(params => {
      let id = +params['id']
      this.containerService
          .get(id, 'full', 1, 'none')
          .subscribe(
              res => {
                  this.container = res;
                  this.containers = this.container.containers.filter(t => t.type.name === 'server');
                  this.initValues()
              },
              err => this.isLoading = false,
              () => this.isLoading = false
          );
    });
  }

  onSubmit(cnt: Container) {
    this.isLoading = true
    this.containerService.save(cnt).subscribe(
      res => null,
      err => {this.isLoading = false, console.log(err)},
      ()  => {
        this.isLoading = false
        this.container = Object.assign(new Container(), this.container);
        this.msgService.add({severity: 'success', summary:'', detail:'Container updated successfully' })
      }
    )
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  onFail(e) {
    this.msgService.add(e);
  }

  private changeJson(field: string, editor: JsonEditorComponent) {
    try {
        var editorData = editor.get();
    } catch (e) {
        return;
    }
    this.container.data[field] = editorData;
}

  private initValues() {
    this.editorOptionsQueryset = new JsonEditorOptions();
    this.editorOptionsQueryset.mode = 'tree';
    this.editorOptionsQueryset.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptionsQueryset.statusBar = true;
    this.editorOptionsQueryset.expandAll = true;
    this.editorOptionsQueryset.onChange = () => this.changeJson('queryset', this.querysetEditor)
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateCount(event) {
    this.containerCount = event;
  }

}
