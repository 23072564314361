<page-header title="Edit Person{{!isLoading && container ? ': ' + container.data['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Person{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages key="chMsg" [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <person-form #form class="ui-g-12" [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></person-form>
        <div class="ui-g-12">
            <container-paginated-datarow type="cast" itemsPerPage = 20 [parentContainer]="container" title="Cast Roles" filterBy="data.title, data.keywords" (reorder)="onReorder($event)">
              <button pButton type="button" label="Add" [routerLink]="['/containers', container.id, 'relate', 'cast']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </container-paginated-datarow>
        </div>
        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="series"></container-membership-row>
        </div>
        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload" *ngxPermissionsOnly="['view.show.upload']"></button>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource', 'upload']" *ngxPermissionsOnly="['object.write.all', 'object.write.episode']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
            </resource-datarow>
        </div>
        <container-tags-panel [container]=container class="ui-g-12">
            </container-tags-panel>
        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
