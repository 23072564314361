import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../services';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { DragulaService } from 'ng2-dragula';


@Component({
    selector: 'featured',
    providers: [
    ContainerService
  ],
    viewProviders: [
      DragulaService
  ],
    templateUrl: 'featured.component.html',
    styleUrls: ['featured.component.css']
})
export class FeaturedComponent implements OnInit {
    public containers: any;
    private featuredViews: any;
    public isLoading = false;
    public error: string;

    constructor(private containerService: ContainerService) {}

    ngOnInit() {
        this.getContainers();
    }

    getContainers() {
        let name = 'distribution';

        this.isLoading = true;
        this.containerService
            .filterByTypeName('distribution', 'full', 1)
            .subscribe(
                res => {
                    console.log('distr: ', res);
                    this.containers = res;
                    this.featuredViews = [];
                    this.containers.forEach((element, index) => {
                        let featured = element.containers.filter(subelement =>
                            subelement.type.name === 'view' && subelement.data.view_id === 'featured'
                        );
                        if (featured.length > 0) {
                            this.containerService
                                .get(featured[0].id, 'flat', 2)
                                .subscribe(
                                    res => {
                                        this.featuredViews[index] = res;
                                    }
                                );
                        } else {
                            this.featuredViews[index] = null;
                        }
                    });
                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onReorder(e) {
        console.log('reorder', e);

        this.isLoading = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => {
                    this.isLoading = false;
                },
                err => console.log(err)
            );
    }
}
