<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
    <h1>New Series</h1>
    <page-header title="New Series" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <p-messages [(value)]="requiredMsgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <series-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></series-form>
    </div>
</div>
