
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { of as observableOf,  Observable } from 'rxjs';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { Message } from 'primeng/api';
import { MediaFormComponent } from '../../forms/media-form/media-form.component';
import { MessageService } from 'primeng/api';
import { formatError } from '../../../shared/utilities';
import { BreadcrumbService } from '../../../app.breadcrumb.service';

@Component({
    selector: 'media-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'media-edit.component.html',
    styleUrls: ['media-edit.component.css']
})
export class MediaEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: MediaFormComponent;
    private sub: any;
    public container: Container;
    private parentContainer: Container;
    public error: HttpResponse<any>;
    public isLoading: boolean = false;
    private seasons: Array < any > = [];
    private platforms: Array < any > = [];
    private msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    public isProcessing = false;
    private pipelineId = '';
    public reloadNotification:boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private msgSrv: MessageService,
        private breadcrumbService: BreadcrumbService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            let parentId = +params['parentId']; // + converts string to number
            console.log('id', id);
            console.log('parentId', parentId);

            this.isLoading = true;
            this.containerService
                .get(id, 'full', 1, 'none')
                .subscribe(
                    res => {
                        this.container = res instanceof Container ? res : Container.fromJson(res);
                        console.log('container', this.container);
                        this.checkProcessingStatus();

                        let updateBreadcrubMap = {media_title: this.container['data']['title']}
                        this.breadcrumbService.updateTemplatedLabels(updateBreadcrubMap)

                        let resp;

                        if (parentId) {
                            resp = this.containerService.get(parentId);
                        }
                        else if (this.container.data['primary_parent_container_id']) {
                            resp = this.containerService.getByGuid(
                                this.container.data['primary_parent_container_id'],
                                'full', -1, false, true
                            );
                        }
                        else {
                            resp = observableOf(new Container());
                        }

                        resp.subscribe(
                            res => {
                                if (res) {
                                    this.parentContainer = res instanceof Container ? res : Container.fromJson(res);
                                    let updateBreadcrubMap = {series_title: this.parentContainer['data']['title']}
                                    this.breadcrumbService.updateTemplatedLabels(updateBreadcrubMap)
                                } else {
                                    this.parentContainer = null;
                                }
                                console.log('parentContainer', this.parentContainer);

                                this.mapContainersByEntityType();

                            },
                            err => this.error = err,
                            () => this.isLoading = false
                        );

                    },
                    err => {
                        this.error = err;
                        if (err.status == 404 ){
                            this.isProcessing = false;
                            this.requiredMsgs = [];
                            this.requiredMsgs.push({ severity: 'error', summary: err.statusText, detail: 'Media id='+id+' does not exist.' });
                            this.container = null;
                            this.isLoading = false;
                        }
                    },
                    () => this.isLoading = false
                );
        });
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    onPipelineTriggered(event) {
        console.log(event);
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
        if (event && event['pipeline_triggered']) {
            this.refetchContainer();
        }
    }

    getSeasons() {
        if (this.parentContainer && this.parentContainer.guid ) {
            this.containerService
                .getRelatedByTypeAndGuidPaginated('season', this.parentContainer.guid, 1, 100)
                .subscribe(
                    res => {
                        this.seasons = res;
                        console.log('seasons:', this.seasons);
                    },
                    err => {
                        this.error = err;
                        console.error('Error fetching seasons:', err);
                    }
                );
        } else {
            this.seasons = [];
        }
    }

    mapContainersByEntityType() {
        this.getSeasons();
        this.platforms = this.container.containers.filter(t => t.type.name === 'platform');
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id, 'full', 1, 'none')
            .subscribe(
                res => {
                    this.container = res instanceof Container ? res : Container.fromJson(res);
                    this.checkProcessingStatus();
                    this.mapContainersByEntityType();
                },
                err => {
                    this.error = err;
                    if (err.status == 404 ){
                        this.isProcessing = false;
                        this.requiredMsgs = [];
                        this.requiredMsgs.push({ severity: 'error', summary: err.statusText, detail: 'Media id='+this.container.id+' does not exist.' });
                        this.container = null;
                        this.isLoading = false;
                    }
                },
                () => this.isLoading = false
            );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onReorder(e) {
        console.log('reorder', e);

        this.isLoading = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => console.log(res),
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]).*/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    onSubmit(e) {
        this.requiredMsgs = [];
        console.log('submit', this.container);
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    this.onStatusError(err);
                },
                () => this.isLoading = false
            );
    }

    onReparenting(e) {
        this.refetchContainer();
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'success', summary: 'Reparenting done successfully!' });
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onStatusError(error) {
        console.log(error);
        let errorMessages = formatError(error, this.msgSrv, 'meMsg');
        this.msgSrv = errorMessages[0];
        this.requiredMsgs = errorMessages[1];
    }
}
