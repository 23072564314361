import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models/container';
import { CreativeFormComponent } from '../../forms/creative-form/creative-form.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Router } from '@angular/router';



@Component({
  selector: 'tag-create',
  providers: [
    ContainerService
],
  templateUrl: './container-creative-create.component.html',
})
export class CreativeCreateComponent implements OnInit {
  @ViewChild('form') form: CreativeFormComponent;
  public container: Container;
  public isLoading:boolean = false

  constructor(
    private location: Location,
    private router: Router,
    private containerService: ContainerService,
    private msgService: MessageService,
   ) { }

  ngOnInit() {
    this.container = new Container();

  }

  onSubmit(){
    this.isLoading = true
    this.container.status = 'published';
    this.container.published_date = Math.trunc(Date.now() / 1000);

    this.containerService.save(this.container).subscribe(
      res => this.router.navigate(['/commerce/creative', res.id], { replaceUrl: true }),
      err => this.isLoading = false,
      () => this.isLoading = false
    );
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back()
    }
  }

  onFail(e){
    this.msgService.add(e);
  }

}
