import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models/container'
import { EntityTypeService} from '../../../content/services/entitytype/entitytype.service'
import { SegmentService } from '../../../content/services/segment/segment.service';

@Component({
    selector: 'flight-form',
    providers: [
        SegmentService
    ],
    templateUrl: 'flight-form.component.html',
    styleUrls: ['flight-form.component.css']
})

export class FlightFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter <any> = new EventEmitter();
    @Output() formFailed: EventEmitter <any> = new EventEmitter();
    @Output() formCancelled: EventEmitter <any> = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityType;
    public publishDate :Date;
    public rangeStartDate:Date;
    public rangeEndDate:Date;
    public isLoading = false;
    public flightSegmentAlert: string = null
    private msgs: Message[] = [];

    constructor(
        public entityTypeService: EntityTypeService,
        private segmentService: SegmentService,
        private msgService: MessageService,
    ) { }

    ngOnInit(): void {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        this.isLoading = true;
        this.entityTypeService
            .getByName('flight')
            .subscribe(
                (res:any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                },
                err => console.log(err),
                () => this.isLoading = false
            );

        let segmentServiceParams = [
            {'parent':this.container.guid},
                {'type':'flight_segment'},
                {'limit':1}
            ]
            
        this.segmentService
            .listByParameters(segmentServiceParams)
            .subscribe(
                res => {
                    if (res.count > 0)
                    {
                        this.flightSegmentAlert = 'Processing the Flight will replace existing Segments. If you have manually altered segments, those changes may be lost.  Would you like to proceed?'
                    }
                    console.log("Flight Segment Count",res.count)
                },
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSubmit(e) {
        this.msgService.clear()
        this.msgs = [];
        let valid = this.ngForm.form.status === 'VALID';

        if (!('label' in this.container.data) || (!this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the flight label.' });
            valid = false;
        }

        if (!('range_start_date' in this.container.data) || (!this.container.data['range_start_date']) || !/\S/.test(this.container.data['range_start_date'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the flight range start date.' });
            valid = false;
        }

        if (!('range_end_date' in this.container.data) || (!this.container.data['range_end_date']) || !/\S/.test(this.container.data['range_end_date'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the flight range end date.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.ngForm.form.markAsPristine()
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.msgs.unshift({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' })
            this.formFailed.emit(this.msgs);
        }
    }

    onStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onPipelineTriggered(e) {
        this.pipelineTriggered.emit(e);
    }

}
