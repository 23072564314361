import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import { AuthLoginData, AuthProvider } from './auth.types';

export class CognitoAuthProvider implements AuthProvider {
    private authClient: CognitoAuth;
    private authConfig = environment.AWS_COGNITO;
    private jwtHeader: string;
    private jwtExpiry: number;
    private jwtToken: string;
    private refreshInterval: any;
    private pendingLogin: Subject<any>;
    private loginData: BehaviorSubject<AuthLoginData>;
    public loginState: Observable<AuthLoginData>;
    // Constants
    public readonly providerName = 'cognito';
    public readonly providerNameFull = 'Cognito';
    public readonly loginUrl = '/auth/cognito';
    public readonly loginParam = 'code';
    public readonly refreshEvery = 30 * 60.;    // 30 mins -- should make configurable

    constructor(...args : Array<any>) {
        // Note: 'args' unused, just for compatibility
        this.loginData = new BehaviorSubject<AuthLoginData>(null);
        this.loginState = this.loginData.asObservable();
        // Default value of 'JWT' to match content API default
        this.jwtHeader = this.authConfig.JWT_HEADER_PREFIX || 'JWT';
        // Base URL for redirect URIs
        const baseRedirectUri = `${window.location.protocol}//${window.location.host}`;
        console.log(`Base Cognito redirect URI: ${baseRedirectUri}`);
        // Initialize auth client
        this.authClient = new CognitoAuth({
            ClientId: this.authConfig.CLIENT_ID,
            UserPoolId: this.authConfig.USER_POOL_ID,
            IdentityProvider: this.authConfig.IDP_NAME,
            AppWebDomain: this.authConfig.CLIENT_DOMAIN_NAME,
            RedirectUriSignIn: `${baseRedirectUri}${this.loginUrl}`,
            RedirectUriSignOut: `${baseRedirectUri}/`,
            // Fixed-value until and unless we need to parameterize
            TokenScopesArray: ['phone', 'email', 'profile', 'openid'],
        });
        // Always use grant type 'code'
        this.authClient.useCodeGrantFlow();
        // Set callbacks
        this.authClient.userhandler = {
            onSuccess: (this.onAuthSuccess).bind(this),
            onFailure: (this.onAuthFailure).bind(this),
        };
        // Check if already logged in (ie cached session)
        const session = this.authClient.getSignInUserSession();
        if (session && session.isValid()) {
            // Trigger auth success as if returning from login
            this.onAuthSuccess(session);
        }
    }

    private setTokenData(session: any) {
        // Get ID token from session, and get expiry for later checks
        const idToken = session.getIdToken();
        this.jwtToken = idToken.getJwtToken();
        this.jwtExpiry = idToken.getExpiration();
        // Update observable
        const newLoginData : AuthLoginData = {
            provider: this.providerName,
            header: this.getAuthHeader(),
            expiry: this.jwtExpiry,
        };
        this.loginData.next(newLoginData);
        // Set token refresh interval
        if (!this.refreshInterval) {
            this.refreshInterval = setInterval(() => {
                console.log('Checking for Cognito refresh...');
                // Make sure there's a session to refresh
                const session = this.authClient.getSignInUserSession();
                const token = session && session.getRefreshToken().getToken();
                const now = Math.floor(new Date().valueOf() / 1000);
                const refresh = this.jwtExpiry ? this.jwtExpiry - this.refreshEvery : now;
                console.log('Expiry', this.jwtExpiry, 'Refresh', refresh, 'Now', now);
                if (token && now >= refresh) {
                    // Launch token refresh
                    console.log('Refreshing Cognito token');
                    this.authClient.refreshSession(token);
                }
            }, this.refreshEvery * 1000 / 5);   // Check every 6 mins
        }
    }

    private clearTokenData() {
        // Forget token and its expiry
        this.jwtToken = null;
        this.jwtExpiry = null;
        // Update observable
        this.loginData.next(null);
        // Clear refresh interval
        if (this.refreshInterval) {
            console.log('Cancelling Cognito token refresh');
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    }

    private clearAuthSession() {
        // Slightly-manual version of what CognitoAuth does on signOut()
        const logoutUrl = this.authClient.getFQDNSignOut();
        // Empty session instead of null, so we can reuse the client
        this.authClient.signInUserSession = new CognitoAuthSession();
        this.authClient.clearCachedTokensScopes();

        return logoutUrl;
    }

    private onAuthSuccess(session: any) {
        console.log('Cognito session:', session);
        // Store new ID token
        this.setTokenData(session);
        // Trigger subject if present
        if (this.pendingLogin) {
            this.pendingLogin.next(session);
            this.pendingLogin.complete();
            this.pendingLogin = null;
        }
        // TODO: anything else to set?

        return undefined;
    }

    private onAuthFailure(err: any) {
        // Clear stored ID token, log error
        console.error('Cognito error:', err);
        this.clearTokenData();
        this.clearAuthSession();
        // Trigger subject if present
        if (this.pendingLogin) {
            try {
                err = JSON.parse(err);
            } catch (e) {
                // Leave as-is
            }
            if (err && (err.error || err) == 'invalid_grant') {
                // Refresh token expired, try again
                console.log('Retrying sign-in');
                this.authClient.getSession();
            } else {
                // Fission Mailed
                this.pendingLogin.error(err);
                this.pendingLogin = null;
            }
        }
        // TODO: anything else to clear?

        return undefined;
    }

    getAuthHeader() {
        return `${this.jwtHeader} ${this.jwtToken}`;
    }

    isAuthenticated() {
        if (!this.jwtToken) { return false; }
        // Check token expiry
        const now = Math.floor(new Date().valueOf() / 1000);
        const stillValid = (now < this.jwtExpiry);
        // Clear out now-expired token/session if req'd
        if (!stillValid) {
            console.log('Cognito token expired as of:', this.jwtExpiry);
            this.logout();
        }
        return stillValid;
    }

    login({ href }: { href: string }) : Observable<any> {
        if (!this.pendingLogin) {
            this.pendingLogin = new Subject();
            const urlParams = new URL(href).searchParams;
            // Check for 'code' query param
            if (urlParams.has(this.loginParam)) {
                console.log(`Parsing url for ${this.loginParam}, exchanging for token`);
                // Parse given url string for auth code, trigger exchange for token
                this.authClient.parseCognitoWebResponse(href);
            } else if (urlParams.has('error')) {
                // See if there's a better error detail
                let err_desc = urlParams.has('error_description')
                    ? decodeURIComponent(urlParams.get('error_description'))
                    : urlParams.get('error');
                console.log(`Error during login: ${err_desc}`);
                // Trigger error handling
                // Delay a bit to allow returning observable below
                Promise.resolve().then(() => this.onAuthFailure(err_desc));
            } else {
                console.log(`Triggering Cognito cache check/redirect`);
                // Trigger Cognito session redirect/refesh/whichever
                // Delay a bit in case we're already logged in
                Promise.resolve().then(() => this.authClient.getSession());
            }
        }

        return this.pendingLogin.asObservable();
    }

    logout(redirect: boolean = false) {
        // Clear our data
        this.clearTokenData();
        const login_url = this.clearAuthSession();
        console.log('Logged out (Cognito)');
        // Option to redirect, and use login_url
        if (redirect) {
            console.log(`Redirecting to ${login_url}`);
            window.open(login_url, '_self');
        }
    }
}
