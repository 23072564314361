<p-dialog *ngIf="selectedPost" [header]="selectedPost.data.title" [modal]="true" [visible]="showModal" [responsive]="true" (visibleChange)="onCloseModel()" [style]="{ width: '70vw'}">
  <div class="ui-g-12" >
    <p-editor
      [(ngModel)]="selectedPost.data.content"
      [style]="{'height':'320px'}"
      [readonly]="true"
    >
      <ng-template pTemplate="header">
        <span class="ql-formats"></span>
      </ng-template>
    </p-editor>
  </div>
</p-dialog>
