import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MetricsReportsListComponent } from './../metrics-reports-list/metrics-reports-list.component';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'metrics-reports',
    templateUrl: './metrics-reports.component.html',
    styleUrls: ['metrics-reports.component.css']
})
export class MetricsReportsComponent implements OnInit, OnDestroy {
    @ViewChild(MetricsReportsListComponent) worklistTable:  MetricsReportsListComponent;

    private sub: any;
    private containers = [];
    private requiredMsgs = [];
    private selectedContainer: Container;
    public error: any;
    public isLoading = false;
    private defaultSearchTerm: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router

    ) {}

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            if (params['searchterm']) {
                this.defaultSearchTerm = params['searchterm'];
            }

        });
    }

    getContentWorklist() {

    }

    onContainerUpdate(event) {
        this.worklistTable.rowUpdate(this.selectedContainer.guid);
    }


    containerSelected(event) {
        console.log("Container Selected Parent", event);
        this.selectedContainer = event.container;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
    }

}
