import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';

@Component({
    selector: 'settings-integrations',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'settings-integrations.component.html'
})
export class SettingsIntegrationsComponent implements OnInit {

    private serverContainers = [];
    public isLoading = false;
    private displayDialog = false;
    private container: Container;
    public error: string;
    private entityTypes = [];
    private entityTypeId;

    dfpEnabled = true;

    constructor(
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.containerService
        .filterByTypeName('server', 'full', -1)
        .subscribe(
            res => {
                this.serverContainers = res;
                console.log(res);
            },
            err => {
                console.log(err);
                // this.error = err.statusText;
                this.isLoading = false;
            },
            () => this.isLoading = false
        );


    }
}
