import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerRowComponent } from '../container-row/container-row.component';
import { ContainerFormComponent } from '../../forms/container-form/container-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';



@Component({
    selector: 'container-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-edit.component.html',
    styleUrls: ['container-edit.component.css']
})
export class ContainerEditComponent implements OnInit, OnDestroy {

    @ViewChild('form') form: ContainerFormComponent;
    private sub: any;
    public container: Container;
    private platforms: Array<Container>;

    public error: HttpResponse<any>;
    public isLoading = false;
    public isProcessing = false;
    private requiredMsgs: Message[] = [];
    private defaultAddAction: string = 'media';
    private addActionsList: any = ['channel', 'collection', 'document', 'dynamic', 'episode', 'movie', 'series', 'season', 'query'];
    public containerCount: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private msgSrv: MessageService,
    ) { }

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);

            //this.isLoading = true;
            this.loadContainerData(id);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id, 'full', 1, 'none')
            .subscribe(
                res => {
                    this.container = res;
                    this.platforms = this.container.containers.filter(t => t.type.name === 'platform');
                },
                err => {
                    this.error = err;
                    console.log('error', err);
                },
                () => this.isLoading = false
            );
    }

    onReorder(e) {
        console.log('reorder', e);

        this.isLoading = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => console.log(res),
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    onSubmit(e) {
        console.log('submit', e.model);
        this.requiredMsgs = [];
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgSrv.add({ key: 'ctMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })

                    }
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    loadContainerData(id) {
        this.isLoading = true;
        this.containerService
            .get(id, 'full', 0, 'none')
            .subscribe(
                res => {
                    this.container = res;
                    console.log(this.container);
                    this.containerService
                        .getRelatedByTypeAndGuidPaginated('all', this.container.guid, 1, 200)
                        .subscribe(
                            res => {
                                this.isLoading = false
                                this.container.containers = res;
                                this.platforms = this.container.containers.filter(t => t.type.name === 'platform');
                            },
                            err => this.error = err,
                            () => this.isLoading = false

                        )
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    updateCount(event) {
        this.containerCount = event;
    }

}
