import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { Container } from '../../models';
import { ContainerComponent } from '../container/container.component';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';


@Component({
    selector: 'container-tree',
    viewProviders: [DragulaService],
    templateUrl: 'container-tree.component.html',
    styleUrls: ['container-tree.component.css']
})
export class ContainerTreeComponent implements AfterContentInit, OnDestroy {
    @Input() containers: Array < Container > = [];
    @Input() parentContainer: Container;
    @Input() controlsActive = true;
    @Output() reorder: EventEmitter < any > = new EventEmitter();

    name = '';
    private dragulaSub: Subscription;

    constructor(
        private dragulaService: DragulaService,
        private elementRef: ElementRef
    ) {}

    ngAfterContentInit() {
        // assign random names to the bags
        this.name = Math.random()
            .toString(36)
            .substring(7);

        this.dragulaService.createGroup(this.name, {
            moves: (el, source, handle, sibling) => {
                // TODO: get selector directly from component
                let subContainers = source.querySelectorAll('container-tree');

                // ensure the handle is an immediate child and not part of any sub-containers
                let isImmediateChild = Array.prototype.every.call(subContainers, (container, index, arr) => {
                    return !container.contains(handle);
                });

                return handle.className === 'handle' && isImmediateChild;
            }
        });

        this.dragulaSub = this.dragulaService.dropModel(this.name)
            .subscribe((e) => {
                console.log('drop', e);
                this.onDrop(e);
        });
    }

    ngOnDestroy() {
        this.dragulaSub.unsubscribe();
    }

    // checks if children are full objects or just IDs
    hasFullChildren(container): boolean {
        return container.containers.length > 0 && container.containers.every(item => item.id !== undefined);
    }

    private moveUp(index) {
        if (this.parentContainer && index < this.containers.length - 1) {
            console.log('move up', index);

            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: this.containers[index],
                to: this.containers[index + 1]
            });

            let fromContainer = this.containers.splice(index, 1);
            this.containers.splice(index + 1, 0, fromContainer[0]);
        }
    }

    private moveDown(index) {
        if (this.parentContainer && index > 0) {
            console.log('move down', index);
            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: this.containers[index],
                to: this.containers[index - 1]
            });

            let fromContainer = this.containers.splice(index, 1);
            this.containers.splice(index - 1, 0, fromContainer[0]);
        }
    }

    private onDrop(e) {
        if (this.parentContainer) {
            let el = e['el'];
            let droppedItem = e['item'];
            let oldIndex = this.containers.indexOf(droppedItem);
            let newIndex = this.getElementIndex(el);
            // depending on the direction of dragging,
            // previous item can be retrieved by either getting using the new index
            // of dragged item of the old container array, or by using new index - 1
            let prevItemIndex = oldIndex > newIndex ? newIndex - 1 : newIndex;
            let droppedAfterItem = prevItemIndex >= 0 ? this.containers[prevItemIndex] : null;

            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: droppedItem,
                to: droppedAfterItem
            });
        }
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++) {};
        return i;
    }

    // custom events don't bubble so we must do so manually
    private bubbleReorder($event) {
        this.reorder.emit($event);
    }
}
