import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';

@Component({
    selector: 'pipelines',
    providers: [
        ContainerService
    ],
    templateUrl: 'pipelines.component.html',
    styleUrls: ['pipelines.component.css']
})
export class PipelinesComponent implements OnInit {

    public pipelineContainers = [];
    public workerContainers = [];
    public serverContainers = [];
    public profileContainers = [];
    private pipelinesLoading = false;
    private workersLoading = false;
    private serversLoading = false;
    private profilesLoading = false;
    private error: string;
    public isLoading = false;
    public container: any;

    constructor(
        private containerService: ContainerService,
    ) {}

    ngOnInit() {
        this.pipelinesLoading = true;
        this.serversLoading = true;
        this.workersLoading = true;
        this.profilesLoading = true;
        
        this.containerService
            .filterByTypeName('pipeline', 'full', -1)
            .subscribe(
                res => this.pipelineContainers = res,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => {
                    this.pipelinesLoading = false;
                    console.log('pipelines:', this.pipelineContainers);
                }
            );
        this.containerService
            .filterByTypeName('worker', 'full', -1)
            .subscribe(
                res => this.workerContainers = res,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => {
                    this.workersLoading = false;
                    console.log('workers:', this.workerContainers);
                }
            );
        this.containerService
            .filterByTypeName('server', 'full', -1)
            .subscribe(
                res => this.serverContainers = res,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => {
                    this.serversLoading = false;
                    console.log('servers:', this.serverContainers);
                }
            );
        this.containerService
            .filterByTypeName('profile', 'full', -1)
            .subscribe(
                res => this.profileContainers = res,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => {
                    this.profilesLoading = false;
                    console.log('profiles:', this.profileContainers);
                }
            );
    }

    onRowSelect(event) {}
}
