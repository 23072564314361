import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service'
import { AppContext } from '../../../app.context';
import { Profile } from '../../../content/models';
import { Message } from 'primeng/api';

@Component({
  selector: 'account-profile-form',
  templateUrl: 'account-profile-form.component.html',
  styleUrls: ['account-profile-form.component.css']
})
export class AccountProfileFormComponent implements OnInit {
  @Input() user: any;
  @Input() profile: Profile;
  @Output() formSubmit: EventEmitter < any > = new EventEmitter();
  @Output() formFailed: EventEmitter < any > = new EventEmitter();
  @Output() preview: EventEmitter < any > = new EventEmitter();
  @Output() close: EventEmitter < any > = new EventEmitter();
  @ViewChild('ngForm') ngForm: NgForm;

  public profileImage: string;
  public msgs: Message[] = [];

  constructor(
    private authService: AuthService,
    public appContext: AppContext,
  ) {}

  ngOnInit(): void {
    if (this.profile.data == null || (!('image_url' in this.profile.data))) {
      this.profile.data = {};
      this.profile.data['image_url'] = '';
    }
    this.profileImage = this.profile.data['image_url'];
  }

  isUrlValid(url) {
    // Check whitespace in the image url
    let emptySpaceUrl_regex = /^\S*$/;
    if (emptySpaceUrl_regex.test(url)) {
      // Check if the url is valid or invalid image url
      let invalidUrl_regex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg))/i;
      if (invalidUrl_regex.test(url)) {
        return true;
      }
    }
    return false;
  }

  onSubmit(e) {
    this.msgs = [];
    let valid = this.ngForm.form.status === 'VALID';

    // Invalid profile image url (non-image url or has whitespace in the url) is NOT allowed
    if (!(this.isUrlValid(this.profile.data['image_url']))) {
      if ((this.profile.data['image_url'])) {
        this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Invalid URL. Please enter valid URL' });
        valid = false;
      } else {
        valid = true;
      }
    }

    // if (!(this.profile.data['image_url'])) {
    //   valid = true;
    // }

    if (valid) {
      this.formSubmit.emit({
        profile: this.profile
      });
    } else {
      this.formFailed.emit();
    }
  }

  onPreview(e) {
    this.msgs = [];
    if (!(this.profile.data['image_url']) || this.isUrlValid(this.profile.data['image_url']))  {
      this.profileImage = this.profile.data['image_url'];
      this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Please save the change(s)' });
    } else {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Invalid URL. Please enter valid URL' });
    }
  }

  onClose() {
    this.close.emit();
  }

}
