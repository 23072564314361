import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { ContainerRowComponent } from '../container-row/container-row.component';
import { Container } from '../../models';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
    selector: 'container-collection',
    viewProviders: [
        DragulaService,
        ContainerService
    ],
    templateUrl: 'container-collection.component.html',
    styleUrls: ['container-collection.component.css']
})
export class ContainerCollectionComponent implements AfterContentInit, OnDestroy {
    @Input() container: Container;
    @Input() controlsActive = true;
    @Output() reorder: EventEmitter < any > = new EventEmitter();

    private name = 'rows-bag';
    private dragulaSub: Subscription;

    constructor(
        private containerService: ContainerService,
        private dragulaService: DragulaService
    ) {}

    ngAfterContentInit() {
        console.log(this.container);

        this.dragulaService.createGroup(this.name, {
            moves: (el, source, handle, sibling) => {
                console.log(handle.className);
                return handle.classList.contains('handle');
            }
        });

        this.dragulaSub = this.dragulaService.dropModel(this.name)
            .subscribe((e) => {
                console.log('drop', e);
                this.onDrop(e);
        });
    }

    ngOnDestroy() {
        this.dragulaSub.unsubscribe();
    }

    private onDrop(e) {
        let el = e['el'];
            let droppedItem = e['item'];
            let oldIndex = this.container.containers.indexOf(droppedItem);
            let newIndex = this.getElementIndex(el);
            // depending on the direction of dragging,
            // previous item can be retrieved by either getting using the new index
            // of dragged item of the old container array, or by using new index - 1
            let prevItemIndex = oldIndex > newIndex ? newIndex - 1 : newIndex;
            let droppedAfterItem = prevItemIndex >= 0 ? this.container.containers[prevItemIndex] : null;

        this.reorder.emit({
            target: this,
            parent: this.container,
            from: droppedItem,
            to: droppedAfterItem
        });
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++) {};
        return i;
    }

    // custom events don't bubble so we must do so manually
    private bubbleReorder($event) {
        this.reorder.emit($event);
    }

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this row?')) {
            console.log('remove', e, container);

            let i = this.container.containers.indexOf(container);
            this.container.containers.splice(i, 1);

            this.containerService
                .unrelate(this.container, container)
                .subscribe(
                    res => console.log(res),
                    err => console.log(err)
                );
        }

    }
}
