import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EntityType, Container } from '../../models';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class CAPIService {

    private baseUrl = `${environment.PROXY_API_BASE_URL}/`;
    private baseAPIUrl = `${environment.PROXY_API_BASE_URL}/api/container/v1/`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private messageService: MessageService,
        private appContext: AppContext,
    ) { }

    getContainerWithSearchParams(searchTerm, parameters = "", searchParam = "search", limit = 1000): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let paramsStr = '';
        if (!searchParam) {
            searchParam = 'search';
        }
        if (searchTerm) {
            paramsStr += `${searchParam}=${searchTerm}&`;
        }
        if (parameters) {
            paramsStr += parameters;
        }

        return this.http
            .get(
                `${this.baseAPIUrl}?${paramsStr}&limit=${limit}`, { headers: headers }
            );
    }
}
