<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
          <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-content-forms-server-form-cancel"></button>
          </div>
          <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-server-form-save"></button>
                <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
          </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['name']" name="title" placeholder="Required" data-element-id="app-content-forms-server-form-name">
                </div>
                <div class="ui-g-6">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-content-forms-server-form-description">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['export']" *ngxPermissionsOnly="['object.write.all', 'object.write.server']"></trigger-pipeline-modal-form>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-content-forms-server-form-type">
                </div>
                <div class="ui-g-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-content-forms-server-form-guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Server Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2">
                    <label for="type">Server Type</label>
                    <select [(ngModel)]="container.data.server_type" name="server_type" class="form-control" data-element-id="app-content-forms-server-form-server-type">
                        <option *ngFor="let stype of serverType" [value]="stype">{{stype}}</option>
                    </select>
                </div>
                <div class="ui-g-2">
                    <label for="url">Protocol</label>
                    <select [(ngModel)]="container.data.protocol_type" name="protocol_type" class="form-control" data-element-id="app-content-forms-server-form-protocol">
                            <option *ngFor="let ptype of protocolType" [value]="ptype">{{ptype}}</option>
                        </select>
                </div>
                <div class="ui-g-4">
                    <label for="url">Location</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.location" name="storageURL" data-element-id="app-content-forms-server-form-location" >
                </div>
                <div class="ui-g-4">
                        <label for="url">Path</label>
                        <input type="text" class="form-control" [(ngModel)]="container.data.path" name="path" data-element-id="app-content-forms-server-form-path">
                </div>
                <div class="ui-g-2">
                        <label for="url">Role</label>
                        <select [(ngModel)]="container.data.role" name="role" class="form-control" data-element-id="app-content-forms-server-form-role">
                                <option *ngFor="let role of roleTypes" [value]="role">{{role}}</option>
                            </select>
                    </div>
                <div class="ui-g-4">
                        <label for="format">Formats</label>
                        <input type="text" class="form-control" [(ngModel)]="container.data.formats" name="formats" data-element-id="app-content-forms-server-form-formats">
                </div>
                <div class="ui-g-3">
                    <label for="auth_ref">Authentication Reference</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.auth_ref" name="auth_ref" data-element-id="app-content-forms-server-form-authentication-reference">
                </div>
                <div class="ui-g-3">
                    <label for="privateKey">Private Key Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.priv_key_id" name="privateKey" data-element-id="app-content-forms-server-form-private-key-id">
                </div>
            </div>
        </p-panel>
    </div>
</form>

