import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { interval as observableInterval, fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { IntegrationTestService } from '../../services/its.service';
import { ContainerService } from '../../../content/services/container/container.service';


@Component({
    selector: 'testing-report-panel',
    templateUrl: 'testing-report-panel.component.html',
    styleUrls: ['testing-report-panel.component.css'],
    providers: [IntegrationTestService, ContainerService],
})
export class TestingReportPanelComponent implements OnInit, OnChanges, OnDestroy {
    private testRunContainers: Array<any> = [];

    @Input() selectedContainer: Container;
    @Output() onTestDataLoaded: EventEmitter<any> = new EventEmitter();

    private testContainers: Array<Container>;
    private testSuiteServiceServer: Container;
    private requiredMsgs = [];
    private error: any;
    public isLoading = false;

    public autoRefreshEnabled = false;
    public autoRefreshSliderActive = false;

    private sub: any;
    private refreshObservableSubscription: Subscription;
    private refreshInterval: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private integrationTestService: IntegrationTestService,
        private containerService: ContainerService
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.refreshServer();
    }

    setAutoRefresh() {

        this.refreshInterval = 5000;
        this.setRefreshInterval(this.refreshInterval);
        this.autoRefreshSliderActive = true;
        this.autoRefreshEnabled = true;
    }

    refreshServer()
    {
        this.containerService
        .getRelatedByTypeAndGuidPaginated('server', this.selectedContainer.guid, 1, 1, '-created_date', '', 'published')
        .subscribe(
            res => {
                this.isLoading = false;
                let servers = res;
                this.testSuiteServiceServer = servers[0];
                console.log("Test Server", this.testSuiteServiceServer, servers)
            },
            err => this.error = err,
            () => this.isLoading = false
        );
    }

    public runTestSuite() {

        this.integrationTestService.runTestSuite(this.selectedContainer.guid, this.testSuiteServiceServer).subscribe(
            res => {
                console.log("ITS Run Test Results:", res)
            },
            err => {
                this.error = err.statusText;
            },
            () => {
                this.onTestDataLoaded.emit({
                    test_run_data: this.testRunContainers,
                });
            }
        );
    }

    handleModalViewStateChange(event) {
        let modalViewState = event['modal_show_view']
        console.log("handleModalViewStateChange", event)
        if (modalViewState) {
            this.autoRefreshEnabled = false
        }
        else {
            this.autoRefreshEnabled = true
        }
    }

    setRefreshInterval(refreshInterval) {
        this.refreshObservableSubscription = observableInterval(refreshInterval).subscribe(() => {
            if (this.autoRefreshEnabled) {
                this.fetchTestResults();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('changes', changes);
        if (changes['selectedContainer'].previousValue !== changes['selectedContainer'].currentValue) {
            this.refreshServer();
            this.fetchTestResults();
            if (this.refreshObservableSubscription) {
                this.refreshObservableSubscription.unsubscribe();
            }
            this.setAutoRefresh();
        }
    }

    fetchTestResults() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.sub = this.containerService.getRelatedByTypeAndGuidPaginated('test_run', this.selectedContainer.guid, 1, 200,undefined,undefined,'published')
            .subscribe(
                res => {
                    this.testRunContainers = res
                },
                err => {

                    this.error = err.statusText;
                },
                () => {
                    this.onTestDataLoaded.emit({
                        test_run_data: this.testRunContainers,
                    });
                }
            );

    }

    ngOnDestroy() {
        if (this.refreshObservableSubscription) {
            this.refreshObservableSubscription.unsubscribe();
        }
    }
}
