import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Container} from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'container-lock',
    providers: [ContainerService],
    templateUrl: 'container-lock.component.html'
})

export class ContainerLockComponent implements OnInit {
  @Input() container: Container | number;
  @Output() containerLocked: EventEmitter < boolean > = new EventEmitter();
  private lockOwnerId: number;
  private authUserID: number;
  private isAdmin: Boolean = false;
  public lockId: string;
  public isLoading = false;

  constructor(
    private containerService: ContainerService,
    private authService: AuthService,
    private msgService: MessageService,
    private appContext: AppContext,
  ) { }

  ngOnInit() {
    this.isLoading = true
    let permissions = this.authService.getAuthenticatedUser()['permissions']
    let origin = this.appContext.activeOrigin
    this.authUserID = this.authService.getAuthenticatedUserId()
    this.isAdmin = permissions[origin].view?.superuser?? this.isAdmin

    this.containerService.getLockByContainerId(this.container).subscribe(
      res => {
        this.lockId  = res.guid
        this.lockOwnerId = res.user_id
      },
      err => {
        this.isLoading = false
        this.showError(err)
      },
      () => {
        this.isLoading = false
        this.containerLocked.emit(
          this.lockId && this.authUserID !== this.lockOwnerId && !this.isAdmin ? true: false
        )
      }
    );
  }

  onClick() {
    this.isLoading = true
    this.msgService.clear()
    let req= this.lockId ? this.containerService.deleteLockByContainerId(this.container)
                        :this.containerService.createLockByContainerId(this.container)
    req.subscribe(
      res => {
        this.lockId = res.lock_id
        this.authUserID = this.lockOwnerId
      },
      err => {
        this.isLoading = false
        this.lockId = err.error.lock_id ?? this.lockId;
        this.showError(err)
      },
      () => {
        this.isLoading = false
        this.containerLocked.emit(
          this.lockId && this.authUserID !== this.lockOwnerId && !this.isAdmin ? true: false
        )
      }
    )
  }

  private showError(err, summary = 'Error') {
    const detail = (err.error && err.error.detail) || (err.error && err.error.msg) || err.statusText;
    // If no lock found, no need to display "Lock not found" error
    if (err.status === 404) {
      this.lockId = ''
    } else {
      this.msgService.add({severity: 'error', summary, detail });
    }
    this.containerLocked.emit(this.lockId && !this.isAdmin ? true: false)
  }

}
