<div *ngIf="schema && !isLoading" class="card card-w-title top-level-card">
  <h1>New Schema</h1>
  <page-header title="New Schema" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

  <p-messages [(value)]="requiredMsgs"></p-messages>

  <div class="ui-g card-content" *ngIf="schema && !isLoading">
    <schema-form class="ui-g-12" #form [schema]="schema"
                                       [isDisabled]="isDisabled"
                                       (formSubmit)="onSubmit(schema)"
                                       (formCancelled)="onCancel($event)"
                                       (formFailed)="onFail($event)">
    </schema-form>
  </div>
</div>
