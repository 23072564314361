<page-header [showSpinner]="isLoading" [error]="error?.statusText">
</page-header>
<div *ngIf="!isLoading" class="worklist-component">

    <div class="ui-g-7 worklist-table-view strong-highlight">
        <worklist-table
          [defaultSearchTerm]="defaultSearchTerm"
          [containers]="this.containers"
          (rowSelect)="containerSelected($event)">
        </worklist-table>
    </div>

    <div class="ui-g-5 worklist-form-view" *ngIf="selectedContainer">
        <worklist-episode-form
          *ngIf="['episode', 'media', 'movie'].includes(selectedContainer.type)"
          [containerId]="selectedContainer.id"
          [seasons]="[]"
          (formSubmit)="onSubmit($event)"
          (onContainerUpdate)="onContainerUpdate($event)"
          (formCancel)="onCancel($event)">
        </worklist-episode-form>

        <worklist-series-form
          *ngIf="selectedContainer.type == 'series'"
          [containerId]="selectedContainer.id"
          [seasons]="[]"
          (formSubmit)="onSubmit($event)"
          (onContainerUpdate)="onContainerUpdate($event)"
          (formCancel)="onCancel($event)">
        </worklist-series-form>

        <worklist-collection-form
          *ngIf="selectedContainer.type == 'collection'"
          [containerId]="selectedContainer.id"
          [seasons]="[]"
          (formSubmit)="onSubmit($event)"
          (onContainerUpdate)="onContainerUpdate($event)"
          (formCancel)="onCancel($event)">
        </worklist-collection-form>

        <worklist-generic-form
          *ngIf="!['episode', 'media', 'movie', 'series', 'collection'].includes(selectedContainer.type)"
          [containerId]="selectedContainer.id">
        </worklist-generic-form>
    </div>

</div>
