import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../content/models/container';
import { ContainerService } from '../../content/services/container/container.service';
import { containerTypeRoutes } from '../../shared/enum';

@Component({
    selector: 'landing-content-activity',
    templateUrl: 'landing-content-activity.component.html',
    styleUrls: ['landing-content-activity.component.css']
})
export class LandingContentActivityComponent implements OnInit {
    @Input() label: string;
    @Input() itemCount: number;
    @Input() ordering: string;
    @Input() type: string[];
    @Input() status: string[];

    private contentSub: any;
    public containers: Container;

    constructor(
        public containerService: ContainerService,
        private router: Router
    ) { }

    ngOnInit(){
        this.contentSub = this.containerService
            .clientSearch(this.type, this.status, [this.ordering], '', this.itemCount, 0)
            .subscribe(
                res => {
                    this.containers = res.results;
                },
                err => console.error(err)
            )
    }

    navigateToContent(container, event: Event) {
        event.preventDefault();
        if (containerTypeRoutes.hasOwnProperty(container.type)) {
            this.router.navigate(['/' + containerTypeRoutes[container.type], container.id])
        } else {
            this.router.navigate(['/'])
        }
      }

    ngOnDestroy() {
        if (this.contentSub) {
            this.contentSub.unsubscribe();
        }
    }

}
