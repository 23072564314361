<div *ngxPermissionsOnly="['view.show.segment']">
    <div *ngIf="segment && !isLoading" class="card card-w-title top-level-card">

        <h1>New Workflow State Segment</h1>

        <div *ngIf="segment && !isLoading">
            <p-messages [(value)]="requiredMsgs"></p-messages>
            <p-messages [(value)]="msgs"></p-messages>
        </div>

        <div class="ui-g card-content" *ngIf="segment && !isLoading">
            <workflow-state-segment-form *ngIf="!isLoading" [segment]="segment" 
                (formSubmit)="onSubmit($event)"
                (formFailed)="onFailure($event)" 
                (formCancelled)="onCancel($event)">
            </workflow-state-segment-form>
        </div>
    </div>
</div>