<div class="card card-w-title top-level-card">
    <h1>Edit Moment{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>
    <div *ngIf="container && !isLoading && requiredMsgs">
        <p-messages [(value)]="requiredMsgs"></p-messages>
    </div>

    <page-header title="Edit Moment{{!isLoading && container ? ': ' + container.data['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <container-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onCancel($event)"></container-form>

        <hr>

        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="content_group"></container-membership-row>
        </div>

        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="episode"></container-membership-row>
        </div>

        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="movie"></container-membership-row>
        </div>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
