import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Location } from '@angular/common';
import { UploadComponent } from '../../../upload/upload.component';
import { ContainerService } from '../../services/container/container.service';
import { ResourceService } from '../../services/resource/resource.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { UploadService } from '../../../upload/upload.service';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { Resource } from '../../models';
import { Container } from '../../models/container';
import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';


@Component({
    selector: 'upload-files-form',
    providers: [
        ContainerService,
        ResourceService,
        PipelineService,
        UploadService,
        EntityTypeService
    ],
    templateUrl: 'upload-files-form.component.html',
    styleUrls: ['upload-files-form.component.css']
})
export class UploadFilesFormComponent implements OnInit {
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @Input() container: Container;
    @ViewChild('ngForm') ngForm: NgForm;
    @ViewChild('uploadComponent') uploadComponent: FileUpload;
    private entityTypes = [];

    private entityTypeId;
    public isLoading = false;
    private isCreatingResources = false;
    public isUploading = false;
    private error: string;
    private uploadId;
    public msgs: Message[] = [];

    items: MenuItem[];
    pipelines: any[] = [];
    uploadingFiles: any[] = [];
    uploadedFiles: any[] = [];
    deferredFiles: any[] = [];
    resourceUrls: any[] = [];
    resources: any[] = [];

    constructor(
        private containerService: ContainerService,
        private resourceService: ResourceService,
        private pipelineService: PipelineService,
        private uploadService: UploadService,
        private entityTypeService: EntityTypeService,
        private location: Location
    ) { }

    ngOnInit() {
        this.uploadId = this.containerService.getRandomId();
        this.getEntityData();
    }

    getEntityData() {
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onUpload(event, deferFiles = true) {
        for (let file of event.files) {
            if (this.uploadService.getFileExtension(file.name) === 'mrss' && deferFiles && event.files.length > 1) {
                this.deferredFiles.push(file)
            } else {
                this.isUploading = true;

                // TODO: once container is created beforehand, use its
                // guid as file path prefix instead of this.uploadId

                this.uploadService
                    .upload(file, this.uploadId)
                    .subscribe(
                        res => {
                            file.progress = res.progress;
                            file.complete = res.complete;
                            let url = res.url;
                            let asset_url = res.asset_url;
                            var fileFound = false;

                            for (let f of this.uploadingFiles) {
                                if (f.name === file.name) {
                                    f.progress = file.progress;
                                    fileFound = true;
                                    if (file.complete === true) {
                                        f.complete = file.complete;
                                        if (f.complete === true) {
                                            this.uploadedFiles.push(file);
                                            this.attachNextResource(url, asset_url, file);
                                        }
                                    }
                                }
                            }

                            if (fileFound === false) {
                                file.complete = false;
                                this.uploadingFiles.push(file);
                            }

                            if (this.uploadedFiles.length === this.uploadingFiles.length) {
                                var completeEvent = {};
                                completeEvent['files'] = this.deferredFiles;
                                this.isUploading = false;
                                this.onUpload(completeEvent, false);
                                if (this.deferredFiles.length === 0) {
                                    this.uploadingFiles = [];
                                }
                                this.deferredFiles = [];
                            }

                        },
                        err => {
                            console.log(err);
                            this.isUploading = false;
                        }

                    );
            }
        }
    }

    onBeforeUploadFile(event) {
        console.log('on before uplaod');
        console.log(event);
    }

    onUploadComplete(e) {
        console.log('upload complete', e);
    }

    onProgress(e) {
        console.log('upload event progress', e.progress);
    }

    onSubmit(e) {
        this.msgs = [];

        var valid = this.ngForm.form.status === 'VALID';
        valid = true;
        if (valid) {
            console.log('upload submitted', this.container);
            // this.resetUploadData();
            // this.formSubmit.emit({
            //     container: this.container
            // });
        } else {
            this.formFailed.emit();
        }
    }

    attachNextResource(url: string, asset_url: string, file) {
        if (!this.container.id) {
            console.error('No container id available');
            return;
        }

        this.isCreatingResources = true;
        this.isLoading = true;

        let resource = new Resource();
        resource.uri = asset_url;
        resource.type = this.entityTypes.find(t => t.name === 'file');
        resource.data = {};

        const s3_uri = this.uploadService.getS3UriFromUrl(url);
        if (s3_uri) {
            resource.data['source_uri'] = s3_uri;
        }

        const file_ext = this.uploadService.getFileExtension(url);
        if (file_ext) {
            resource.data['format'] = file_ext;
        }

        if (file) {
            console.log('file type', file.type);
            if (this.uploadService.isImage(file)) {
                resource.data['content_type'] = 'image';
            } else if (this.uploadService.isVideo(file)) {
                resource.data['content_type'] = 'video';
            }
            resource.tags = file.tags;
        }

        // Add to list to match up later
        this.resourceUrls.push(url);

        this.resourceService
            .save(resource, this.container.id)
            .subscribe(
                res => {
                    console.log(res);
                    console.log('Resource created:', resource);
                    this.resources.push(resource);
                    if (this.resourceUrls.length <= this.resources.length) {
                        this.isCreatingResources = false;
                    }
                },
                err => {
                    console.error(err);
                },
                () => this.isLoading = false
            );
    }

    onStatusComplete() {
        this.resetUploadData();
        this.formSubmit.emit({
            container: this.container
        });
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
        this.location.back();
    }

    resetUploadData() {
        this.isUploading = false;
        this.uploadId = this.containerService.getRandomId();

        this.uploadingFiles = [];
        this.uploadedFiles = [];
        this.deferredFiles = [];
        this.resourceUrls = [];
        this.resources = [];
    }

    removeFile(file: File) {
        const index = this.uploadComponent.files.indexOf(file);
        this.uploadComponent.remove(null,index);
    }

    getSizeInMegaBytes(file: File) {
        return file ? file.size / (1024 * 1024) : 0;
    }

}
