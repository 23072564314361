import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ResourceTag } from '../../../content/models';
import { ResourceTagService } from '../../../content/services';
import { ResourceTagFormComponent } from '../../forms/resource-tag-form/resource-tag-form.component';

@Component({
  selector: 'resource-tag-edit',
  templateUrl: './tag-edit.component.html',
  providers: [
    ResourceTagService
],
})
export class ResourceTagEditComponent implements OnInit {
  @ViewChild('form') form: ResourceTagFormComponent;
  public tag: ResourceTag;
  public isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public resourcetagService: ResourceTagService,
    private msgService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.route.params.subscribe(params => {
      let id = +params['id'];
      this.resourcetagService.getResourceTagById(id)
          .subscribe(
              res => {this.tag = res; this.tag.data = this.tag.data ?? {}},
              err => this.isLoading=false,
              () => this.isLoading = false
          );
    });
  }

  onSubmit(tag: ResourceTag) {
    this.isLoading = true
    this.resourcetagService.save(this.tag).subscribe(
      res => null,
      err => {
        this.isLoading = false
        this.msgService.add({severity: 'error', summary:'', detail:err.error?.error })
      },
      ()  => {
        this.isLoading = false
        this.msgService.add({severity: 'success', summary:'', detail:'Updated successfully' })
      }
    )
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  onFail(e){
      this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the tag value.' });
  }
}
