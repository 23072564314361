import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityType, Container } from '../../../content/models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { ResourceUploadService } from '../../../upload/resource-upload.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription,  Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContainerTagsTabComponent } from '../../../content/components/container-tags-tab/container-tags-tab.component';
import { CuePointEditComponent } from '../../../content/components/cue-point-edit/cue-point-edit.component';
import { UploadService } from '../../../upload/upload.service';
import { MessageService } from 'primeng/api';
import { formatMessageServicesError } from '../../../shared/utilities';


@Component({
    selector: 'worklist-series-form',
    providers: [
        EntityTypeService,
        ContainerService,
        UploadService
    ],
    templateUrl: 'worklist-series-form.component.html',
    styleUrls: ['worklist-series-form.component.css']
})
export class WorklistSeriesFormComponent implements OnInit {
    @Input() containerId: Number;
    @Input() seasons: Container[];
    @Output() onContainerUpdate: EventEmitter < any > = new EventEmitter();
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancel: EventEmitter < any > = new EventEmitter();
    @ViewChild('maintags') mainTags: ContainerTagsTabComponent;
    @ViewChild('syndicationtags') syndicationTags: ContainerTagsTabComponent;
    @ViewChild('cuepoints') cuePoints: CuePointEditComponent;

    public container: Container;
    private templateContainer: Container;

    public isProcessing = false;

    private entityTypes = [];
    private entityTypeId;
    private selectedSeasonGuid;
    private policies: Array < any > = [];
    private platforms: Array < any > = [];
    public isLoading = false;
    public isLoadingContainer = false;
    public isLoadingTemplate = false;
    publishDate: Date;

    private fileUploadStatusSubscription: Subscription;
    private isUploading = false;

    private tagTypeFilterList = [];
    private tagTypeHiddenList = [];

    private pipelineId: string;

    private keywordChips: string[];

    public containerStatus: string;

    private genre = genreList;

    public msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        public containerService: ContainerService,
        private resourceUploadService: ResourceUploadService,
        private pipelineService: PipelineService,
        private uploadService: UploadService,
        private authService : AuthService,
        private msgSrv: MessageService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['containerId'].previousValue != changes['containerId'].currentValue && this.containerId) {
            console.log('unsub');
            this.msgs = [];
            this.pipelineId="";
            this.isProcessing = false;
            console.log(this.fileUploadStatusSubscription);
            this.isUploading = false;
            if (this.fileUploadStatusSubscription){
                this.fileUploadStatusSubscription.unsubscribe();
            }
            console.log(this.fileUploadStatusSubscription);
            //this.resourceUploadService.getUploadProgressForContainer(changes['containerId'].previousValue).
            this.fileUploadStatusSubscription = this.getFileUploadSubscription(this.containerId);

            this.refetchContainer();
        }
    }

    refetchContainer() {
        this.isLoadingContainer = true;
        this.containerService
        .get(this.containerId, 'full', 1, 'none')
        .subscribe(
            res => {
                console.log('res', res);
                this.container = res instanceof Container
                    ? res
                    : Container.fromJson(res);
                //this.container = res;
                this.containerStatus = this.container.status;
                this.policies = this.container.containers.filter(t => t.type.name === 'policy');
                this.platforms = this.container.containers.filter(t => t.type.name === 'platform');
                this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
                if (this.container['data'] && 'template_profile_guid' in this.container['data']) {
                    this.getTemplateByGUID(this.container['data']['template_profile_guid']);
                } else {
                    this.clearTemplate();
                }
                this.isLoadingContainer = false;
                //if (this.container['data'] && 'keywords' in this.container['data']) {
                    //this.keywordChips = this.container['data']['keywords'].split(',').map(k => k.trim());
                //}

                this.checkProcessingStatus();

                this.onContainerUpdate.emit({
                    container: this.container
                });
            }
        );
    }

    refetchResources(): Observable<any> {
        return this.containerService
                .get(this.containerId).pipe(
                tap(
                    res => {
                        let container = res instanceof Container
                            ? res
                            : Container.fromJson(res);
                        if (this.container && container.id == this.container.id) {
                            // Replace resource list
                            this.container.resources = container.resources;
                        }
                    }
                ));
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
    }

    ngOnInit() {
        this.fileUploadStatusSubscription = this.getFileUploadSubscription(this.containerId);
        console.log(this.fileUploadStatusSubscription);
        this.entityTypeId = 9; // this.container.type.id;

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    console.log(res);
                },
                err => console.log(err),
                () => {
                    this.isLoading = false;
                }
            );
    }

    ngOnDestroy() {
        if (this.fileUploadStatusSubscription){
            this.fileUploadStatusSubscription.unsubscribe();
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    getContainerTagTypes() {
        return this.container.tags ? this.container.tags.map(tag => tag['type']) : [];
    }

    getTemplateByGUID(guid) {
        this.containerService
        .getByGuid(guid)
        .subscribe(
            res => {
                this.isLoadingTemplate = true;
                console.log('res', res);
                this.templateContainer = res instanceof Container
                    ? res
                    : Container.fromJson(res);
                console.log("GET TEMPLATE");

                if (this.templateContainer['data']['payload']['tag_type_filter']) {
                    this.tagTypeFilterList = this.templateContainer['data']['payload']['tag_type_filter'];
                } else {
                    this.tagTypeFilterList = this.getContainerTagTypes();
                }
                this.isLoadingTemplate = false;
            },
            err => console.log(err),
            () => {
                this.isLoadingTemplate = false;
            }
        );
    }

    clearTemplate() {
        this.templateContainer = null;
        this.tagTypeFilterList = this.getContainerTagTypes();
    }

    getFileUploadSubscription(id) {
        return this.resourceUploadService
                .getUploadProgressForContainer(id)
                .subscribe(data => {
                    if ((!data)) {
                        this.isUploading = false
                        return
                    }

                    if ((data['status'] === 'uploading') && (data['container']['id'] === this.containerId)) {
                        this.isUploading = true;
                    } else if ((data['status'] === 'complete') && (data['container']['id'] === this.containerId)) {
                        if (this.isUploading) {
                            this.refetchResources()
                                .subscribe(
                                    res => {
                                        console.log('Updated resources', res.resources);
                                    },
                                    err => {
                                        console.error('Error refetching resources:', err);
                                    },
                                    () => {
                                        this.isUploading = false;
                                    }
                                );
                        }
                    } else if ((data['status'] === 'error') && (data['container']['id'] === this.containerId)) {
                        this.isUploading = false;
                        //this.msgs = [];
                        //this.msgs.push({ severity: 'error', summary: 'Error', detail: 'There was a problem uploading the files.' });
                        this.msgSrv.add({ key: 'wlMsg', severity: 'error', summary: 'Error', detail: 'There was a problem uploading the files.' })
                    }
                });
    }

    onSeasonChange() {
        console.log('selected season', this.selectedSeasonGuid);
        this.container.data['secondary_parent_container_id'] = this.selectedSeasonGuid;
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    }

    onSubmit(e) {
        console.log('container submitted', this.container);
        this.msgSrv.clear()
        var season = this.seasons.find(t => t.guid === this.selectedSeasonGuid);

        if (this.publishDate) {
            this.container.published_date = Math.trunc(this.publishDate.getTime() / 1000);
        }
        if ((season) && ('season_number' in season.data)) {
            this.container.data['season_number'] = season.data['season_number'];
        }

        this.containerService
        .save(this.container)
        .subscribe(
            res => {
                console.log(res);
                //this.msgs = [];
                //this.msgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                this.msgSrv.add({ key: 'wlMsg', severity: 'success', summary: 'Changes Saved', detail: '' });
                this.container = Object.assign(new Container(), this.container);
                this.onContainerUpdate.emit({
                    container: this.container
                });
            },
            err => {
                this.onStatusError(err)
            },
            () => this.isLoading = false
        );

       this.formSubmit.emit({
            container: this.container
        });

    }

    onSimpleStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        console.log("simple status transition", event);
        this.onContainerUpdate.emit({
            container: this.container
        });
    }

    onComplexStatusChange(event) {
        console.log("pipeline triggered successfully");
        this.refetchContainer();
        this.onContainerUpdate.emit({
            container: this.container
        });
    }

    onStatusTransitionFailure(e) {
        this.msgSrv.add(e);
    }

    getThumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }

    updateMainTags(newTags) {
        console.log(newTags);
        if (newTags && this.mainTags) {
            this.mainTags.updateTags(newTags);
        }
    }

    updateSyndicationTags(newTags) {
        console.log(newTags);
        if (newTags && this.syndicationTags) {
            this.syndicationTags.updateTags(newTags);
        }
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }

    onStatusError(event) {
        this.msgSrv = formatMessageServicesError(event, this.msgSrv);
    }

}
