import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Container, EntityType } from '../../../content/models';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'creative-form',
  providers: [
    EntityTypeService
  ],
  templateUrl: './creative-form.component.html',
  styleUrls: ['creative-form.component.css']
})
export class CreativeFormComponent implements OnInit {
  @Input() container: Container;
  @Output() formSubmit: EventEmitter < any > = new EventEmitter();
  @Output() formCancelled: EventEmitter < any > = new EventEmitter();
  @Output() formFailed: EventEmitter < any > = new EventEmitter();
  @ViewChild('ngForm') ngForm: NgForm;

  private entityType;
  public selectedType: EntityType = null;
  public subType = ["CL3"]
  public publishDate :Date;
  public isLoading = false;

  constructor(
    private entityTypeService: EntityTypeService,
    private appContext: AppContext,
    private msgService: MessageService,
   ){ }

  ngOnInit(): void {
    this.isLoading = true;
    this.container.origin = this.container.origin ? this.container.origin : this.appContext.activeOrigin
    this.container.data['subtype'] = this.container.data['subtype']? this.container.data['subtype'] : this.subType[0]
    this.publishDate = this.getDateFromTimeNumber(this.container.published_date);

    this.entityTypeService.getByName('creative').subscribe(
      (res: any) => {
          this.entityType = res;
          this.container.type = this.entityType;

      },
      err => this.isLoading = false,
      () => this.isLoading = false
    );

  }

  onSubmit() {
    this.msgService.clear()
    let errmsg = [];
    let valid = this.ngForm.form.status === 'VALID';

    if (!('label' in this.container.data) || (!this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
      errmsg.push({ severity: 'error', summary: 'Error', detail: 'Please enter value for the label field' });
      valid = false;
    }

    if (!('description' in this.container.data) || (!this.container.data['description']) || !/\S/.test(this.container.data['description'])) {
      errmsg.push({ severity: 'error', summary: 'Error', detail: 'Please enter value for the description field' });
      valid = false;
    }

    if (valid) {
      this.ngForm.form.markAsPristine()
      this.formSubmit.emit(this.container);
    } else {this.formFailed.emit(errmsg)}

}
  resetToDefaultState() {
    this.ngForm.reset();
  }

  isPristine() {
    return this.ngForm.form.pristine;
  }

  onCancel(){
    this.formCancelled.emit()
  }

  getDateFromTimeNumber(time) {
    if (!time) return null;
    var date = new Date(0);
    date.setUTCSeconds(time);
    return date;
}

}
