import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../content/services/container/container.service';
import { AppContext } from '../../app.context';
import { Message } from 'primeng/api';

@Component({
    selector: 'landing-notification',
    templateUrl: 'landing-notification.component.html',
    styleUrls: ['landing-notification.component.css']
})
export class LandingNotificationComponent implements OnInit {
    public containers;
    public msgs: Message[] = [];

    private readonly severityPriority = {
        error: 1, // Highest priority
        warn: 2,
        info: 3,
        success: 4 // Lowest priority
    };

    constructor(
        public containerService: ContainerService,
        private appContext: AppContext,
    ) { }

    ngOnInit(){
        this.getDocumentContainers();
    }

    // Retrieve documents from both the current active origin and the system origin
    getDocumentContainers() {
        this.containerService
            .clientSearch('document', 'published', ['-updated_date'],  `${this.appContext.activeOrigin},system`, 1000, 0, [], [], 'toast')
            .subscribe(
                res => {
                    this.containers = res;
                    if (this.containers && this.containers.results) {
                        let filteredDocuments = this.filterDocuments(this.containers.results);
                        this.sortDocuments(filteredDocuments);
                        // Limit notification message to maximum of 5
                        let documents = filteredDocuments.slice(0, 5);
                        documents.forEach(document => {
                            this.createMessage(document)
                        });
                    }
                }
            ),
            err => console.log(err),
            () => {}
    }

    // Filter out documents that lack the necessary information to display a message
    filterDocuments(documents) {
        return documents.filter(document => {
            let title = document.data.title ? document.data.title.trim() : '';
            let description = document.data.description ? document.data.description.trim() : '';
            if (!title || !description) {
                return false;
            }

            let highestPriorityTag = null;
            let highestPriority = 5;
            document.tags.forEach(tag => {
                if (tag.type === 'notification_severity_decorator') {
                    const severity = tag.value.split('_')[1].trim();
                    const priority = this.severityPriority[severity];
                    // Compare the severity and select the highest one (lowest number)
                    if (priority < highestPriority) {
                        highestPriority = priority;
                        highestPriorityTag = tag;
                    }
                }
            });
            // Select the highest priority severity tag if multiple tags exist
            if (highestPriorityTag) {
                document.tags = [highestPriorityTag];
                return true;
            }

            return false;
        });
    }

    // Sort documents based on origin and tag severity level
    sortDocuments(filteredDocuments) {
         filteredDocuments.sort((a, b) => {
            // Sort By origin (System origin is the highest priority)
            if (a.origin === 'system' && b.origin !== 'system') return -1;
            if (a.origin !== 'system' && b.origin === 'system') return 1;

            // Sort by tag severity priority (error is the highest priority)
            let severityA = this.getTagSeverityPriority(a.tags);
            let severityB = this.getTagSeverityPriority(b.tags);

            return severityA - severityB;
        });
    };

    getTagSeverityPriority(tags) {
        return this.severityPriority[tags[0].value.split('_')[1].trim()];
    }

    createMessage(document) {
        this.msgs.push({
            severity: document.tags[0].value.split('_')[1].trim(),
            summary: document.data.title,
            detail: document.data.description
        });
    }
}
