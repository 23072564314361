import { Component, OnInit, Input } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { ViewAPIService } from '../../services/view.api.service';


@Component({
    selector: 'preview-featured-content',
    providers: [
        ViewAPIService
    ],
    viewProviders: [
        DragulaService
    ],
    templateUrl: 'preview-featured-content.component.html',
    styleUrls: ['preview-featured-content.component.css']
})
export class PreviewFeaturedContentComponent implements OnInit {
    public containers: any;
    private bannerContainers: any;
    private featuredContainers: any;
    private featuredContainersAggr: any;

    public isLoading = false;
    public error: string;

    display = false;

    constructor(private viewAPIService: ViewAPIService) { }

    ngOnInit() {
        this.getContainers();
    }

    showDialog() {
        this.display = true;
    }

    getContainers() {
        this.isLoading = true;
        this.viewAPIService
            .getContainerByType('distribution')
            .subscribe(
                res => {
                    this.containers = res;
                    this.featuredContainers = [];
                    this.featuredContainersAggr = []
                    this.bannerContainers = [];

                    if (this.containers.length === 0) { this.isLoading = false }
                    this.containers.forEach((element, index) => {

                        this.viewAPIService
                            .getContainersByTypeAndParentGUID('view', element.guid)
                            .subscribe(
                                res => {
                                    let featured = res.filter(subelement =>
                                        subelement.data.view_id === 'featured'
                                    );

                                    if (featured.length > 0) {
                                        this.viewAPIService
                                            .getContainersByTypeAndParentGUID('collection&type=rotator', featured[0].guid)
                                            .subscribe(
                                                res => {
                                                    this.featuredContainers[index] = res.sort(function (a, b) { return a.order - b.order; })
                                                    let containers = []
                                                    this.featuredContainers[index].forEach((element2, index2) => {
                                                        this.viewAPIService
                                                            .getContainersByParentGUID(element2.guid)
                                                            .subscribe(
                                                                res => {
                                                                    if (element2.type === 'rotator') {
                                                                        this.bannerContainers[index] = res.sort(function (a, b) { return a.order - b.order; })
                                                                    }
                                                                    else {
                                                                        containers.push({ "label": element2.label, "order": element2.order, "containers": res.sort(function (a, b) { return a.order - b.order; }) })
                                                                        containers = containers.sort(function (a, b) { return a.order - b.order; })
                                                                        this.isLoading = false
                                                                    }

                                                                }
                                                            );
                                                    }
                                                    )
                                                    this.featuredContainersAggr[index] = containers.sort((a, b) => (a.order > b.order) ? 1 : -1)
                                                });
                                    } else {
                                        this.featuredContainers[index] = null;
                                    }

                                },
                                err => {
                                    console.log('err', err);
                                    this.error = err.statusText;

                                },
                                () => { }
                            );

                    })
                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => { })
    }


    getThumbnailUrl(type, resources) {
        let url = '';

        let thumbnailResource = resources.find ? resources.find(r => r.type && r.type === type) : undefined;
        url = thumbnailResource ? thumbnailResource.uri : '';
        url = url ? url : '';
        return url;
    }

}
