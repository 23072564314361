import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EntityType, Container } from '../../models';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';
import { defaultPaginationLimit } from '../../../shared/enum';

@Injectable()
export class ContainerService {
    public static readonly statusList = [
        'new',
        'waiting',
        'processing',
        'error',
        'ready',
        'approved',
        'rejected',
        'scheduled',
        'published',
        'unpublished',
        'hidden',
    ];

    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;
    private baseSearchUrl = `${environment.PROXY_API_BASE_URL}/search/content`;
    private baseTemplateUrl = `${environment.PROXY_API_BASE_URL}/templates`;
    private baseClientSearchUrl = `${environment.PROXY_API_BASE_URL}/api`;
    private baseContainerLockUrl=`${this.baseUrl}/containers/lock`;
    private clientSearchLimit = defaultPaginationLimit['clientSearch'];

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private messageService: MessageService,
        private appContext: AppContext,
    ) { }

    _appendHeaders() {
      return new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json')
          .append('Authorization', this.authService.getAuthHeader())
    }

    public constructOriginParameter(ignoreContext = false, overrideOrigin = '') {
        if (ignoreContext) {
            let originStr = ''
            for (let origin of overrideOrigin.split(',')) {
                originStr += `&origin=${origin}`
            }
            return originStr;
        }

        return this.appendOriginQueryParamFromContext(ignoreContext);

    }

    public appendOriginQueryParamFromContext(ignore = false) {
        if (!ignore) {
            return '&origin=' + this.appContext.activeOrigin
        }

        return ''

    }

    get(id, nest = 'full', depth = -1, filter = 'none'): Observable<any> {
        return this.http
            .get(
                `${this.baseUrl}/containers/${id}/?nest=${nest}&depth=${depth}&filter=${filter}` + this.appendOriginQueryParamFromContext(),
                { headers: this._appendHeaders() }
            );
    }



    getByGuid(guid, nest = 'full', depth = -1, searchResultsMode = false, all = false, ignoreOrigin = false): Observable<any> {
        let filter = all ? '&filter=none' : ''
        let originStr = ignoreOrigin
            ? this.constructOriginParameter(true, '*')
            : this.appendOriginQueryParamFromContext();

          if (searchResultsMode) {
            return this.http
              .get(
                  //`${this.baseSearchUrl}/detailed/?guid=${guid}&limit=1&0ffset=0`,
                  `${this.baseClientSearchUrl}/container/v1/?guid=${guid}&limit=1&0ffset=0`,
                  { headers: this._appendHeaders() }
              );
          }

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?guid=${guid}&nest=${nest}&depth=${depth}${filter}` + originStr,
                { headers: this._appendHeaders() }
            ).pipe(
            map(
                (res: Array<any>) => {
                    if (!res && !res.length) {
                        throw new Error(`No container found with guid ${guid}`);
                    }
                    return res[0];
                }
            ));
    }

    getByName(name, nest = 'full', depth = 2): Observable<any> {
        name = encodeURIComponent(name);

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?name=${name}&nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(),
                { headers: this._appendHeaders()  }
            );
    }

    getByTypeAndName(entityType: EntityType, name): Observable<any> {
        return Observable.create(new Container());
    }

    getRelatedByTypeAndGuidPaginated(typeName, guid, page, count, ordering = 'to_container__order,-created_date', origin='', status='', typeExclude=[], throughContainerId:number=-1): Observable<any> {
        let url = `${this.baseTemplateUrl}/relatedcontainers/${guid}/?type=${typeName}&page=${page}&count=${count}&ordering=${ordering}`;

        if (origin.length > 0) {
          url += `&origin=${origin}`;
        }

        if (status.length > 0) {
          url += `&status=${status}`;
        }

        if (typeExclude.length > 0) {
          url += `&type__exclude=${typeExclude}`;
        }

        if (throughContainerId > 0 ) {
          url += `&related_through=${throughContainerId}`
        }

        return this.http.get(url, {headers: this._appendHeaders()});
    }

    list(nest = 'full', depth = 1): Observable<any> {
        return this.http.get(
              `${this.baseUrl}/containers/search/?nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(),
              { headers: this._appendHeaders()  }
        );
    }

    getRelatedFromContainers(id, typeName, nest = 'full', depth = -1, overrideOrigin = true, includeAll = false, throughContainerId:Number=-1): Observable<any> {
      let url = `${this.baseUrl}/containers/search/?related_from=${id}&type=${typeName}&nest=${nest}`

      url += throughContainerId > 0 ? `&parent_through=${throughContainerId}`: ''

      let originStr = overrideOrigin
          ? this.constructOriginParameter(true, '*')
          : this.constructOriginParameter(false);

      let filterStr = includeAll ? '&filter=none' : '';
      url += `&depth=${depth}${originStr}${filterStr}`


      return this.http.get(url,
            // `${this.baseUrl}/containers/search/?related_from=${id}&type=${typeName}&nest=${nest}&depth=${depth}${originStr}${filterStr}`,
            { headers: this._appendHeaders()  }
      );
    }

    filterByTypeName(typeName: string, nest = 'full', depth = 1, all = false, status: string | Array<string> = []): Observable<any> {
        let filter = all ? '&filter=none' : ''
        let query = `${this.baseUrl}/containers/search/?type=${typeName}&nest=${nest}&depth=${depth}${filter}` + this.appendOriginQueryParamFromContext();

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];

            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        return this.http.get(query, {headers: this._appendHeaders()});
    }

    filterByType(entityType: EntityType, nest = 'full', depth = 1): Observable<any> {
        return this.http.get(
              `${this.baseUrl}/containers/search/?type=${entityType.name}&nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(),
              { headers: this._appendHeaders()  }
        );
    }

    save(container: Container, validateSchema = true): Observable<any> {
        console.log('save', container);
        let dateKeys = ['available_date', 'expiration_date', 'release_date', 'pub_date', 'published_date', 'reference_date', 'public_window_end_date', 'original_air_date', 'local_air_date', 'added_date', 'range_start_date', 'range_end_date'];

        for (let key of Object.keys(container.data)) {
          if (dateKeys.includes(key) && typeof(container.data[key])==='string' && container.data[key].length <= 19 && container.data[key].includes('T') && !container.data[key].endsWith('+00:00') && !container.data[key].endsWith('Z')) {
            container.data[key] += '+00:00';
          }
        }

        let origin;
        if (container.id === -1) {
            origin = this.appContext.activeOrigin;
        } else {
            origin = container.origin;
        }

        let resp;
        let data = JSON.stringify({
            data: container.data,
            type: container.type.id,
            guid: container.guid,
            tags: container.tags,
            origin: origin,
            status: container.status,
            published_date: container.published_date,
            is_enabled: container.is_enabled
        });

        let query = 'schema_validation=' + validateSchema;

        // container doesn't exist yet
        if (container.id === -1) {
            console.log('post');
            console.log(data);
            resp = this.http
                .post(
                    `${this.baseUrl}/containers/?${query}`,
                    data, { headers: this._appendHeaders()  }
                ).pipe(
                tap(
                    res => {
                        container.id = res['id'];
                        container.guid = res['guid'];
                        container.origin = res['origin'];
                    }
                ));
        } else {
            console.log('patch');
            resp = this.http
                .patch(
                    `${this.baseUrl}/containers/${container.id}/?${query}`,
                    data, { headers: this._appendHeaders() }
                );
        }

        return resp.pipe(tap(
            // At some point it might be worth moving all the "changes saved" messages
            // to here instead of per-form versions
            null,
            // Log error message from API
            err => this.showError(err),
        ));
    }

    delete(container: Container): Observable<any> {
        return Observable.create(true);
    }

    deleteById(id): Observable<any> {
      return Observable.create(true);
    }

    // TODO: search params
    // search(): Observable < any > {
    //     let headers = new HttpHeaders()
    //         .append('Accept', 'application/json')
    //         .append('Authorization', this.authService.getAuthHeader());

    //     return Observable.create(new Array < Container > ());
    // }

    search(limit: number, offset: number, terms: Array<string> = [], typeName = ''): Observable<any> {
        var query = 'limit=' + limit + '&offset=' + offset;
        if (terms && terms.length > 0) {
            query += '&search=' + terms[0];
            for (var i = 1; i < terms.length; i++) {
                query += '|' + terms[i];
            }
        }
        if (typeName && typeName !== '') {
            query += '&type=' + typeName;
        }

        return this.http.get(`${this.baseSearchUrl}/?${query}` + this.appendOriginQueryParamFromContext(),
                            { headers: this._appendHeaders()  }
        );
    }

    detailedSearch(limit: number, offset: number, terms: Array<string> = [],
        typeName = '', status: string | Array<string> = [], orderBy: Array<string> = [],
        origin: string = ''): Observable<any> {

        var query = 'limit=' + limit + '&offset=' + offset;
        if (terms && terms.length > 0) {
            query += '&search=' + terms[0];
            for (var i = 1; i < terms.length; i++) {
                query += '|' + terms[i];
            }
        }
        if (typeName && typeName !== '') {
            query += `&type__in=${typeName}`;
        }

        var overrideOrigin = false

        if (origin && origin !== '') {
            overrideOrigin = true
        }

        // if (status) {
        //     query += `&status=${status}`;
        // }

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];

            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        if (orderBy && orderBy.length > 0) {
            for (let ord of orderBy) {
                query += `&ordering=${ord}`;
            }
        }

        return this.http.get(
              `${this.baseSearchUrl}/detailed/?${query}` + this.constructOriginParameter(overrideOrigin, origin),
              { headers: this._appendHeaders()  }
        );
    }

    clientSearch( typeName: string | Array<string> = [], status: string | Array<string> = [], orderBy: Array<string> = [],
        origin: string = '', limit: number = this.clientSearchLimit, offset: number = 0, terms: Array<string> = [],
        tags: string | Array<string> = [], subtypeName: string | Array<string> = [], searchFilter: string = ''): Observable<any> {

        if (limit + offset > 10000) {
        // ES does not support a result window larger than 10,000
            limit = 10000 - offset;
        }

        let query = 'limit=' + limit + '&offset=' + offset;
        let searchParam = searchFilter ? '&search_filter_query=' : '&search=';

        if (terms && terms.length > 0) {
            query += searchParam + terms[0];
            for (var i = 1; i < terms.length; i++) {
                query += '|' + terms[i];
            }
        }
        if (searchFilter) {
            query += '&search_filter=' + searchFilter;
        }
        if (typeName && typeName.length > 0) {
            typeName = Array.isArray(typeName) ? typeName : [typeName];
            query += `&type__in=${typeName[0]}`;

            for (let i=1; i < typeName.length ; i++) {
                query += `__${typeName[i]}`;
            }

        }

        if (subtypeName && subtypeName.length > 0) {
            subtypeName = Array.isArray(subtypeName) ? subtypeName : [subtypeName];
            query += `&subtype__in=${subtypeName[0]}`;

            for (let i=1; i < subtypeName.length ; i++) {
                query += `__${subtypeName[i]}`;
            }

        }

        var overrideOrigin = false

        if (origin && origin !== '') {
            overrideOrigin = true
        }

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];
            query += `&status__in=${status[0]}`;

            for (let i=1; i < status.length ; i++) {
                query += `__${status[i]}`;
            }
        }

        if (orderBy && orderBy.length > 0) {
            for (let ord of orderBy) {
                query += `&ordering=${ord}`;
            }
        }

        if (tags && tags.length > 0) {
            tags = Array.isArray(tags) ? tags : [tags];
            query += `&tags.value__in=${tags[0]}`;

            for (let i=1; i < tags.length ; i++) {
                query += `__${tags[i]}`;
            }
        }

        return this.http.get(
              `${this.baseClientSearchUrl}/container/v1/?${query}` + this.constructOriginParameter(overrideOrigin, origin),
              { headers: this._appendHeaders() }
        );
    }

    relate(fromContainer: Container, toContainer: Container, append = false): Observable<any> {
        let orderStr = append ? '?order=-1' : '';

        return this.http
            .post(
                `${this.baseUrl}/containers/${fromContainer.id}/relate/${toContainer.id}/${orderStr}`,
                JSON.stringify({}), { headers: this._appendHeaders()  }
            ).pipe(tap(
                null,
                err => {
                    if (err.status != '409'){
                        this.showError(err);
                    }
                    console.log('This is the error: ', err.status);

                }
            ));
    }

    relateById(fromContainerId: Number, toContainerId: Number, append = false): Observable<any> {
        let orderStr = append ? '?order=-1' : '';

        return this.http.post(
              `${this.baseUrl}/containers/${fromContainerId}/relate/${toContainerId}/${orderStr}`,
              JSON.stringify({}), { headers: this._appendHeaders()  }
              ).pipe(tap(
                null,
                err => this.showError(err),
        ));
    }

    setRelationTagsById(fromContainerId: Number, toContainerId: Number, tags: Array<any> = []): Observable<any> {
        let payload = {"tags": tags};

        return this.http.put(
              `${this.baseUrl}/containers/${fromContainerId}/relate/${toContainerId}/`,
              JSON.stringify(payload), { headers: this._appendHeaders()  }
          ).pipe(tap(
              null,
              err => this.showError(err),
          ));
    }

    unrelate(fromContainer: Container, toContainer: Container, throughContainer?: Container): Observable<any> {
      let url = `${this.baseUrl}/containers/${fromContainer.id}/relate/${toContainer.id}/`

      if (throughContainer) {
        url += `through/${throughContainer.id}/`
      }

      let res = this.http.delete(url, {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
              }))
    }

    unrelateById(fromContainerId: Number, toContainerId: Number, throughContainerId?: Number): Observable<any> {
      let url = `${this.baseUrl}/containers/${fromContainerId}/relate/${toContainerId}/`

      if (throughContainerId) {
        url += `through/${throughContainerId}/`
      }

      let res = this.http.delete(url, {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
              }))
    }

    reorder(parentContainer: Container, fromContainer: Container, toContainer: Container): Observable<any> {
        let toId = toContainer ? toContainer.id : 0;

        return this.http.put(
              `${this.baseUrl}/containers/${parentContainer.id}/reorder/${fromContainer.id}/${toId}/`,
              JSON.stringify({}), { headers: this._appendHeaders()  }
              ).pipe(tap(
                null,
                err => this.showError(err, 'Failed to reorder'),
        ));
    }

    // ********************** Methods to support container through relationship ****************************
    getContainerByThroughContainer(fromContainerId: Number, toContainer: Number, ThroughContainer: Number) {
      let res = this.http.get(`${this.baseUrl}/containers/${fromContainerId}/relate/` +
                              `${toContainer}/through/${ThroughContainer}/`,
                              {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
      }))
    }

    saveThroughContainer(fromContainer: Container, toContainer: Container, ThroughContainer: Container) {
      let res = this.http.post(`${this.baseUrl}/containers/${fromContainer.id}/relate/` +
                                `${toContainer.id}/through/${ThroughContainer.id}/`, JSON.stringify({}),
                                {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
        }))
    }

    relateThroughContainer(fromContainer: Container, toContainer: Container, ThroughContainer: Container) {
      let res = this.http.patch(`${this.baseUrl}/containers/${fromContainer.id}/relate/` +
                                `${toContainer.id}/through/${ThroughContainer.id}/`, JSON.stringify({}),
                                {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
        }))
    }

    relateThroughById(fromContainerId: Number, toContainerId: Number, ThroughContainerId: Number) {
      let res = this.http.patch(`${this.baseUrl}/containers/${fromContainerId}/relate/` +
                                `${toContainerId}/through/${ThroughContainerId}/`, JSON.stringify({}),
                                {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
        }))
    }

    getRelatedThroughContainer(fromContainer: Container, throughContainer: Container) {
      let res = this.http.get(`${this.baseUrl}/containers/search/?related_to=${fromContainer.id}` +
                              `&child_through=${throughContainer.id}`,
                            {headers: this._appendHeaders()})
      return res.pipe(tap({next: null,
                  error: (err) => this.showError(err),
                  complete: null,
        }))
    }
    // ************************ Methods to support container locking  **************************************
    getAllLocks(): Observable<any> {
      return this.http.get(`${this.baseContainerLockUrl}/0000/?all=true&limit=-1`,
                            { headers: this._appendHeaders() })
    }

    getLockByContainerId(container: Object | number): Observable<any> {
      let id = container instanceof Object? container['id'] : container
      return this.http.get(`${this.baseContainerLockUrl}/${id}/`,
                            { headers: this._appendHeaders() })
    }

    createLockByContainerId(container: Object | number) : Observable < any > {
      let id = container instanceof Object? container['id'] : container
      return this.http.post(`${this.baseContainerLockUrl}/${id}/`,
                              '', { headers: this._appendHeaders() })
      }

    deleteLockByContainerId(container: Object | number) : Observable < any > {
      let id = container instanceof Object? container['id'] : container
      return this.http.delete(`${this.baseContainerLockUrl}/${id}/`,
                                { headers: this._appendHeaders() })
      }

    // *****************************************************************************************************

    getRandomId(): string {
      // No, not a UUID, just something to prefix things with
      // Should probably use the 'uuid' module at some point
      return [
          Math.random().toString(36).substring(2, 12),
          Math.random().toString(36).substring(2, 12),
      ].join('-');
    }

    getStatusLabelClass(status: string) {
        if (!status) {
            return '';
        }
        switch (status.toLowerCase()) {
            case 'waiting':
            case 'processing':
                return 'label-info';
            // case 'incomplete':
            case 'error':
            case 'rejected':
            case 'unpublished':
                return 'label-danger';
            // case 'warning':
            case 'ready':
            case 'approved':
                return 'label-primary';
            case 'scheduled':
                return 'label-warning';
            // case 'complete':
            case 'published':
                return 'label-success';
            default:
                return 'label-default';
        }
    }

    private showError(err, summary = 'Error') {
        const detail = (err.error && err.error.detail) || err.statusText;
        this.messageService.add({ key: 'api', severity: 'error', summary, detail });
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }

    filterByTypeNameAndStatus(typeName: string, depth = -1, all = false, status: string | Array<string> = []): Observable<any> {
      let filter = all ? '&filter=none' : ''

      var query = '';

      if (status && status.length > 0) {
          status = Array.isArray(status) ? status : [status];
          for (let st of status) {
              query += `&status=${st}`;
          }
      }

      return this.http.get(`${this.baseUrl}/containers/search/?type=${typeName}&depth=${depth}${filter}${query}` +
              this.appendOriginQueryParamFromContext(),
              {headers: this._appendHeaders()}
             );
    }

    getTotalItemCount(parentGuid:string, typeName: string | Array<string> = [], status: string | Array<string> = []): Observable<any>  {

        let query = 'parent=' + parentGuid + '&limit=1&offset=0';

        typeName = Array.isArray(typeName) ? typeName : [typeName];

        // _all has been depracated since ES 6.0.0
        if (typeName.includes('all')) {
            typeName = []
        }

       if (typeName && typeName.length > 0) {
            query += `&type__in=${typeName[0]}`;

            for (let i=1; i < typeName.length ; i++) {
                query += `__${typeName[i]}`;
            }
        }

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];
            query += `&status__in=${status[0]}`;

            for (let i=1; i < status.length ; i++) {
                query += `__${status[i]}`;
            }
        }

        return this.http.get<any>(`${this.baseClientSearchUrl}/container/v1/?${query}`, { headers: this._appendHeaders()});

    }
}
