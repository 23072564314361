import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, AfterContentInit, AfterViewChecked, AfterContentChecked, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container, EntityType } from '../../models';
import { AppContext } from '../../../app.context';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';

@Component({
    selector: 'container-relate-form',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-relate-form.component.html',
})
export class ContainerRelateFormComponent implements OnInit, AfterViewChecked {
    @ViewChild('org') originRef: ElementRef<HTMLElement>;

    @Input() parentContainer?: Container;
    @Input() parent?: any;
    @Input() filterType?: string;
    @Input() reload:Boolean;
    @Input() selectionMode:string = 'multiple';

    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() cntSelected: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();

    public searchText:string;
    public isLoading = false;
    private limit: number;
    public offset: number = 0;
    public results = [];
    public resultsCount: number;
    public selected: any;
    private lastSearch = null;
    private lastSearchTerms = [];
    public selectedStatus: string;
    public selectedOrigin: string = '';
    public originList = [];
    public statusList = [];
    // For type filtering
    public entityTypes = [];
    public selectedEntityTypes: Array<EntityType> = [];
    // SelectAll
    public selectedContainers:Array<any> = [];
    private lastAllSelected:Array<any> = []

    constructor(
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService,
        private appContext: AppContext
    ) {}

    ngOnChanges(changes: SimpleChanges){
      if (typeof changes.reload?.currentValue !== 'undefined') {
        this.selectedContainers = []
        this.ngOnInit()
      }
    }

    ngOnInit() {
        // *** Reinitializing filters ***
        this.searchText = ''
        if (this.selectionMode == 'multiple')
        {
          this.selected=[]
        }
        else
        {
          this.selected=null;
        }

        console.log("selectionMode",this.selectionMode)
        console.log("parent",this.parent)
        console.log("parentContainer",this.parentContainer)
        this.selectedOrigin = this.appContext.activeOrigin

        this.search([],this.selectedOrigin);
        this.selectedStatus = 'published'
        this.statusList = ContainerService.statusList.map(s => ({ label: s, value: s }));

        if (!this.parentContainer && !this.parent) {
          this.entityTypeService.list().subscribe(
            (res: any) => this.entityTypes = res.sort(
              (a,b) => a.name > b.name ? 1 : (a.name < b.name) ? -1 : 0),
            err => console.log(err),
            () => this.isLoading = false
          );
        }
    }

    ngAfterViewChecked() {
      let originContainers = this.appContext.originContainers
      if (originContainers && this.originList.length === 0 ) {
        this.appContext.originContainers.forEach((element, index) => {
          this.originList.push({label: element['data']['label'], value:element['data']['origin_id']});
        })
      }
      if (this.originList.length ) {
        let origin = this.originList.find(item => item.value === this.appContext.activeOrigin)['label']
        const originRef = this.originRef.nativeElement.firstChild.childNodes[0]['children']
        for (let i = 0; i < originRef.length; i++) {
          if(originRef[i].tagName === "SPAN" && originRef[i].innerHTML === 'Select an Origin') {
            let innerSpan = "<span style=“vertical-align: middle; margin-left: .5em;” class=“ng-star-inserted”>"+ origin +"</span>"
            originRef[i].innerHTML = innerSpan
          }
        }
      }
    }

    search(terms, origin, status = 'published', selectedEntityTypes?:Array<EntityType>) {
        this.lastSearchTerms = terms;
        if (this.lastSearch) {
            this.lastSearch.unsubscribe();
            this.lastSearch = null;
        }
        this.isLoading = true;
        let typeNames = Array.isArray(selectedEntityTypes) ? selectedEntityTypes.map(obj => obj.name) : selectedEntityTypes;
        this.lastSearch = this.containerService
            .clientSearch(
              typeNames && typeNames.length > 0 ? typeNames : this.filterType,
              status, ['label'], origin,
              this.limit, this.offset, terms
            )
            .subscribe(
                res => {
                    // ES does not support a result window larger than 10,000
                    this.resultsCount = res.count <= 10000 ? res.count : 10000;
                    this.results = this.parentContainer ? res.results.filter(
                      c => c.id !== this.parentContainer.id):res.results
                },
                err => console.log(err),
                () => {
                  this.isLoading = false
                  if (!this.parentContainer && !this.parent) {
                    // ***** For Bulk Assign view, select checkbox(es) for containers added in the cart *****
                    this.selected = this.selectedContainers?.filter(cnt=>this.results.find(rcnt=>cnt.id===rcnt.id))
                    this.lastAllSelected = this.selected
                  }
                }
            );
      }

    onAttemptSearch(e) {
        if (e.keyCode === 13) {
            this.onSearch(e);
        }
        else if (this.searchText.length === 1 && e.keyCode === 8 && this.lastSearchTerms !== []) {
            this.searchText = '';
            this.onSearch(e);
        }
    }

    onChangedOrigin(e) {
        this.selectedOrigin = e.value;
        this.onSearch(e);
    }

    onChangedFilteredStatus(e) {
        this.selectedStatus = e.value;
        this.onSearch();
    }

    onSearch(e=null) {
        this.offset = 0
        if (this.searchText !== '') {
            this.search(this.searchText.split(' ,.<>/?`~!*;:"\''), this.selectedOrigin, this.selectedStatus, this.selectedEntityTypes);
        }
        else {
            this.search([],this.selectedOrigin, this.selectedStatus, this.selectedEntityTypes);
        }
    }

    onLazyLoad(event) {
      this.limit = event.rows;
      this.offset = event.first;
      this.search(this.lastSearchTerms,this.selectedOrigin, this.selectedStatus, this.selectedEntityTypes)
    }

    onSubmit(e) {
      if (!this.parentContainer && !this.parent) {
        this.formSubmit.emit({ containers: this.selectedContainers });
      } else {
        this.formSubmit.emit({ containers: this.selected });
      }
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onRowSelect($event){
      this.selectedContainers = [...this.selectedContainers, $event.data]
      this._onSelectedContainersUpdate()
    }

    onRowUnSelect(event?: any) {
      this.selectedContainers = this.selectedContainers.filter(obj=> obj.id !== event.data.id)
      this._onSelectedContainersUpdate()
    }

    onUnSelectContainer(event?: any) {
      this.selected = this.selected.filter(obj => obj.id !== event.data.id)
      this._onSelectedContainersUpdate()
    }

    handleHeaderCheckboxToggle(event: any) {
      if (event.checked) {
        this.lastAllSelected = this.selected
        let objToAdd = this.selected.filter(obj => this.selectedContainers.every(scnt=> obj.id!==scnt.id))
        this.selectedContainers = [...this.selectedContainers, ...objToAdd]
      } else {
        this.selectedContainers = this.selectedContainers.filter(obj=> !this.lastAllSelected.includes(obj))
      }
      this._onSelectedContainersUpdate()
    }

    _onSelectedContainersUpdate() {
      this.selectedContainers? this.cntSelected.emit(this.selectedContainers): null
  }

}
