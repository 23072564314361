<page-header title="Edit Test - {{container?.data.label}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Test</h1>

    <div class="ui-g" *ngIf="container && !isLoading">
        <p-messages class="ui-g-12" [(value)]="requiredMsgs"></p-messages>
        <p-messages class="ui-g-12" [(value)]="msgs"></p-messages>
        <test-form #form [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)" style="width:100%;"></test-form>
        <hr>
        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
