import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { PersonFormComponent } from '../../forms/person-form/person-form.component';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { handleError } from '../../../shared/utilities';

@Component({
    selector: 'cast-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'cast-create.component.html',
    styleUrls: ['cast-create.component.css']
})
export class CastCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: PersonFormComponent;
    private sub: any;
    public container: Container = new Container();
    public parentContainer: any;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    public errorMsg: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private msgSrv: MessageService,
    ) {}

    ngOnInit() {
        console.log('cast creation initialized');
        this.sub = this.route.params.subscribe(params => {
            let parentId = +params['parentId']; // + converts string to number
            console.log('personId', parentId);

            this.isLoading = true;
            this.containerService
                .get(parentId)
                .subscribe(
                    res => {
                        console.log('res', res);
                        this.parentContainer = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
        this.container.is_enabled = false;
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        console.log('submit', e.model);
        console.log(this.container.data);
         // add person name into cast metadata from the parent container
         this.container.data['person_name'] = this.parentContainer.data['label']

        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.container = res;
                    this.containerService
                        .relate(this.parentContainer, this.container)
                        .subscribe(
                            res => {
                                console.log(res);
                            },
                            err => {
                                console.log(err);
                                this.errorMsg = 'Relationship already exists';
                            }
                        );

                    this.router.navigate(['/cast', res.id], { replaceUrl: true });
                },
                err => {
                  console.log(err);
                  this.requiredMsgs = [];
                  let errorMessages = handleError(err);
                  errorMessages.forEach(errorMessage => {
                      if (err.status && err.status == 403) {
                          this.msgSrv.add({ key: 'chMsg', severity: 'error', summary: 'Error', detail: errorMessage });
                      } else {
                          this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: errorMessage });
                      }
                  })
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }
    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }
    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
