import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Container } from '../../../content/models'
import { AppContext } from '../../../app.context';

@Component({
    selector: 'origin-select',
    templateUrl: 'origin-select.component.html',
    styleUrls: ['origin-select.component.css']
})
export class OriginSelectComponent implements OnInit {
    @Input() container: Container;
    @Output() onOriginChange: EventEmitter <any> = new EventEmitter();

    private currentOrigin: string;
    public originList: any;
    public originListSub: any;

    public selectedOrigins = [];

    constructor(
        private appContext: AppContext
    ) {}

    ngOnInit() {
        this.selectedOrigins = this.container['data']['origins'];
        this.originListSub = this.appContext.originContainersLoaded$
            .subscribe((origins) => {
                console.log('origins loaded', origins);
                this.originList = Object.entries(origins).map(([key, origin]) => ({ label: origin['data']['label'], value: origin['data']['origin_id'] }));
                this.originList.sort((a, b) => a.label.localeCompare(b.label));
            },
                err => {
                    console.log(err);

            });
    }

    onChangedOrigin(event) {
        // Get selected origins and save these values
        this.selectedOrigins = event.value;
        this.container['data']['origins'] = this.selectedOrigins;
        // Only display message when editing origins for existing container
        if (this.container['guid']) { 
            this.onOriginChange.emit({
                requiredmsgs: {severity:'warn', summary: 'Warning', detail: 'Origin change(s) must be saved'}
            })
        }
    }

    ngOnDestroy() {
        this.originListSub.unsubscribe();
    }

}