<page-header title="Create Person" [showSpinner]="isLoading"></page-header>
<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
    <h1>New Person</h1>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages key="chMsg" [(value)]="msgs"></p-messages>


    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <person-form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onCancel($event)"></person-form>
    </div>

</div>
