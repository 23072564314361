import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, NgStyle } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { Message } from 'primeng/api';

@Component({
    selector: 'user-profile-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'user-profile-create.component.html'
})
export class UserProfileCreateComponent implements OnInit{
    public container: Container;
    private error: HttpResponse<any>;
    public isLoading = false;
    private sub: any;
    private parentContainer: Container;
    private requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('user profile create initialized');
        this.container = new Container();

        this.sub = this.route.params.subscribe(params => {
            let id = +params['parentId']; // + converts string to number
            console.log('parentId', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.parentContainer = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    onSubmit(e) {

        console.log('submit', e.model);
        let newContId;
        this.containerService
            .save(this.container)
            .subscribe(
                function (res) {
                    console.log("Created: ", res);
                    newContId = res.id;
                    this.containerService
                        .relate(this.parentContainer, res)
                        .subscribe(
                            res => {
                                console.log("Related: ", res);
                                this.router.navigate(['/user/profiles', newContId], { replaceUrl: true });
                            },
                            function (err) {
                                console.log("Failed to relate: ", err);
                                this.error = err;
                                this.requiredMsgs = [];
                                this.requiredMsgs.push({ severity: 'error', summary: 'Eror', detail: 'User profile was created, but could not be related to parent.' });
                            }.bind(this)
                        );
                }.bind(this),
                err => {
                    this.error = err;
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'error', summary: 'Eror', detail: 'Failed to create user profile due to unknown error.' });
                    console.log(err);
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
