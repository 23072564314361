import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { Table } from 'primeng/table';
import { interval, Subscription,  } from 'rxjs';
import { startWith, switchMap,  distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'flight-table',
  viewProviders: [
    DragulaService
  ],
  providers: [
    ContainerService,
  ],
  templateUrl: './flight-table.component.html',
  styleUrls: ['./flight-table.component.css']
})
export class FlightTableComponent implements OnInit {
  @Input() parentContainer: Container;
  @Input() title: string;
  @Input() rows: number;
  @Input() isLoading = false;

  @ViewChild(Table) itemTable;
  public containers: Array<Container>;
  public container: Container;
  private error;
  private allFlights: Subscription;
  private timeInterval = 5000;  // updates every 5s


  constructor(
    public containerService: ContainerService,
  ) { }

  ngOnInit() {
    this.loadFlights();
  }

  loadFlights() {
    this.isLoading = true;
    this.allFlights = interval(this.timeInterval).pipe(
      startWith(0),
      switchMap(() => this.containerService
        .getRelatedByTypeAndGuidPaginated('flight', this.parentContainer.guid, 1, 200)),
      distinctUntilChanged(
          (prev, curr) => this.noChangesDetected(prev, curr)),
    ).subscribe(
      res => {
        this.containers = res;
        console.log("related flights", res)
      },
      err => this.error = err,
      () => this.isLoading = false
    );
  }

  noChangesDetected(prev, curr) {
    return JSON.stringify(prev) === JSON.stringify(curr)
  }

  onRowSelect(event) { }

  onRemove(event, flight) {
    if (confirm('Are you sure you want to remove this context?')) {
      let i = this.containers.indexOf(flight);
      this.containers.splice(i, 1);
      this.containerService
        .unrelate(this.parentContainer, flight)
        .subscribe(
          res => {
            console.log(res);
          },
          err => console.log(err)
        );
    }

  }

  ngOnDestroy() {
    this.allFlights.unsubscribe();
  }

}