import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import { UploadService } from '../upload/upload.service';
import { ContainerService } from '../content/services/container/container.service';
import { ResourceService } from '../content/services/resource/resource.service';
import { Resource, Container } from '../content/models';
import { Observable } from 'rxjs';


export class ResourceUploadContext {

    private uploadContextId;

    private isUploading = false;
    private error: string;
    private resourceEntityType: any;
    private uploadingFiles: any[] = [];
    public uploadedFiles: any[] = [];
    private resourceUrls: any[] = [];
    private resources: any[] = [];
    private uploadService: UploadService;
    private containerService: ContainerService;
    private resourceService: ResourceService;
    private container: Container;
    private filesToUpload: any[];

    constructor(
        container: Container,
        filesToUpload: any[],
        uploadService: UploadService,
        containerService: ContainerService,
        resourceService: ResourceService,
    ) {
        this.container = container;
        this.uploadService = uploadService;
        this.containerService = containerService;
        this.resourceService = resourceService;
        this.uploadContextId = this.containerService.getRandomId();
    }

    getUploadingFiles() {
        return this.uploadedFiles;
    }

    getUploadContextId() {
        return this.uploadContextId;
    }

    getUploadStatus() {
        let status = "uploading";

        if (this.uploadedFiles.length === this.uploadingFiles.length) {
            status = "complete";
        }

        if (this.error) {
            status = "error";
        }

        let statusPayload = {
            upload_context:this.uploadContextId,
            container:this.container,
            status:status,
            files_in_progress:this.uploadingFiles,
            files_uploaded:this.uploadedFiles,
            files:this.filesToUpload,
        }

        return statusPayload;
    }

    uploadFiles(filesToUploadList, resourceEntityType) {
        this.filesToUpload = filesToUploadList;
        for (let file of filesToUploadList) {
            this.isUploading = true;

            this.uploadService
                .upload(file, this.uploadContextId)
                .subscribe(
                    res => {
                        file.progress = res.progress;
                        file.complete = res.complete;
                        let url = res.url;
                        let asset_url = res.asset_url || url;
                        var fileFound = false;

                        for (let f of this.uploadingFiles) {
                            if (f.name === file.name) {
                                f.progress = file.progress;
                                fileFound = true;
                                if (file.complete === true) {
                                    f.complete = file.complete;
                                    if (f.complete === true) {
                                        this.uploadedFiles.push(file);
                                        this.createResource(url, asset_url, resourceEntityType, file);
                                    }
                                }
                            }
                        }
                        if (fileFound === false) {
                            file.complete = false;
                            this.uploadingFiles.push(file);
                        }
                    },
                    err => {
                        this.error = "Error uploading content.";
                        console.log("upload content", this.error)
                    }
                );
        }
    }

    createResource(url, asset_url, resourceEntityType, file) {
        if (!this.container) {
            console.error('No container id available');
            return;
        }

        let resource = new Resource();
        resource.uri = asset_url;
        resource.type = resourceEntityType;
        // Set status so triggered pipeline can find uploaded resources
        resource.status = 'new';
        // Possibly add relevant things
        const s3_uri = this.uploadService.getS3UriFromUrl(url);
        if (s3_uri) {
            resource.data['source_uri'] = s3_uri;
        }
        // TODO: allow overriding format somehow?
        const file_ext = this.uploadService.getFileExtension(url);
        if (file_ext) {
            resource.data['format'] = file_ext;
        }
        if (file) {
            console.log('file type', file.type);
            if (this.uploadService.isImage(file)) {
                resource.data['content_type'] = 'image';
            } else if (this.uploadService.isVideo(file)) {
                resource.data['content_type'] = 'video';
            }
            resource.tags = file.tags;
        }

        this.resources.push(resource);
        this.resourceService
            .save(resource, this.container.id)
            .subscribe(
                res => {
                    console.log(res);
                    console.log('Resource created:', resource);
                },
                err => {
                    this.error = err;
                    console.log(err)
                },
                () => {}
            );
    }
}
