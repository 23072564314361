<button pButton type="button" label="Process" icon="pi pi-arrow-right" style="float:right;" (click)="showDialog()" [(disabled)]="isDisabled"></button>

<p-dialog header="Process Resources" [modal]="true" [(visible)]="displayDialog" responsive ="true" >
       
<spinner *ngIf="isLoading"></spinner>
<div class="ui-g">
        <div class="ui-g-12">
            <label for="title">Select Processing Profile:</label>
            <select [(ngModel)]="selectedPipelineId" name="pipelines" class="form-control" (ngModelChange)="onPipelineChange()">
                <option *ngFor="let pipeline of pipelines" [value]="pipeline.id">{{pipeline.data.name}}</option>
            </select>
        </div>
</div>

<p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="triggerPipeline()" label="Start"></button>
        <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false" label="Close" class="p-button-secondary"></button>
</p-footer>

</p-dialog>