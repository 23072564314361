import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { AppContext } from '../../../app.context';
import { ContainerService } from '../../../content/services/container/container.service';
import { SchemaService } from '../../../content/services/schema/schema.service';

@Component({
    selector: 'settings-schemas',
    templateUrl: './schemas.component.html',
    styleUrls: ['./schemas.component.css']
})
export class SettingsSchemasComponent implements OnInit {
    @ViewChild('dt') dataTable: Table;
    public isLoading = false;
    private filteredStatus = '';
    public statusList: Array<SelectItem> = [];
    public selectedStatus: SelectItem = null;
    public filterTerm = '';
    public schemas = [];
    public schema: any;
    public entityTypes = [];
    public columns = [];
    public collapsed = false;
    public rows = 20;
    public activeOrigin = '';

    private allColumns = [
        { default: true, header: 'Title', style: { }, field: 'label', transform: [this.toBold.bind(this)] },
        { default: true, header: 'GUID', field: 'guid', style: { "white-space": "nowrap",  overflow: "hidden"} },
        { default: true, header: 'Type', field: 'type', style: { }, transform: [this.toEntityTypeLabel.bind(this)] },
        { default: true, header: 'Status', field: 'status', style: { }, transform: [this.toStatusLabel.bind(this)] },
    ]    


    constructor(
        public schemaService: SchemaService,
        private appContext: AppContext,
        private containerService: ContainerService,
    ) { 
        this.columns = this.allColumns.filter(col => col.default);
    }

    ngOnInit() {
        this.isLoading = true;
        this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));
        this.activeOrigin = this.appContext['activeOrigin'];
        
        if (!this.appContext.componentState['schemas_view']['status']) {
            this.setDefaultParams();
            this.setStatusParams();
        }

        this.getParams();
        this.loadResults();
    }

    setStatusParams() {
        this.appContext.componentState['schemas_view']['status'] = this.filteredStatus || '';
    }

    setDefaultParams() {
        this.filteredStatus = 'published';
    }

    getParams() {
        let statusContextParameters = this.appContext.componentState['schemas_view'];
        this.filteredStatus = (statusContextParameters['status'] || '').toLowerCase();
        this.filterTerm = (statusContextParameters['filter'] || '').toLowerCase();

        if (this.filteredStatus) {
            if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
                this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
            }
        } else if (this.selectedStatus) {
            this.selectedStatus = null;
        }
    }

    loadResults() {
        this.isLoading = true;
        this.schemaService
            .list(this.filteredStatus, 'type')
            .subscribe(
                res => {
                    this.schemas = res.results;
                    if (this.appContext.componentState['schemas_view']['filter']) {
                        this.dataTable.filterGlobal(this.filterTerm.toLowerCase(), 'contains');
                    }
                },
                err => {
                    console.log(err);
                    //this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onChangedStatus(event) {
        this.filteredStatus = event.value ? event.value.value : '';
        this.dataTable.first = 0;
        this.loadResults();
        this.setStatusParams();
    }

    getFieldData(container, column) {
        let data = container;
        let fields = column.field.split('.');
        for (let field of fields) {
            data = data[field];
        }
        data = data || '';
        if (column.transform) {
            for (let i = 0; i < column.transform.length; i++) {
                data = column.transform[i](data);
            }
        }
        return data;
    }

    changedFilter(event){
        this.dataTable.filterGlobal(event.toLowerCase(), 'contains');
        this.appContext.componentState['schemas_view']['filter'] = event;
    }

    toBold(data) {
        return '<b>' + data + '</b>';
    }

    toEntityTypeLabel(data) {
        if (!data) {
            return '';
        }
        return '<div class="label label-default">'
            + (data['name'] || '')
            + '</div>';
    }

    toStatusLabel(status) {
        if (!status) {
            return '';
        }
        return '<div class="label ' +
               this.containerService.getStatusLabelClass(status) +
               '">' +
               this.toTitleCase(status) +
               '</div>';
    }

    toTitleCase(data) {
        let txt = data || '';
        if (!txt) {
            return txt;
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

}
