<p-panel>
    <p-header>
        <span class="p-panel-title" style="font-size:16px;width:100%;margin-left:5px;margin-right:5px;">{{container.data['title']}}</span>
    </p-header>

    <div class="ui-g-6" style="padding-right:0.25em;padding-bottom:0;">
        <div class="ui-g-12 label label-default">Season: {{ container.data['season_number'] }}</div>
    </div>
    <div class="ui-g-6" style="padding-left:0.25em;padding-bottom:0;">
        <div class="ui-g-12 label label-default">Episode: {{ container.data['episode_number'] }}</div>
    </div>
    <img class="ui-g-12" [src]="thumbnailUrl()">
    <div class="ui-g-12" style="padding-top:0;">
        <div *ngIf="container.data['metadata_ingest_workflow_status'] == 'Incomplete'" class="ui-g-12 label label-danger">Mandatory data missing</div>
        <div *ngIf="container.data['metadata_ingest_workflow_status'] == 'Warning'" class="ui-g-12 label label-warning">Some data may be missing</div>
        <div *ngIf="container.data['metadata_ingest_workflow_status'] == 'Complete'" class="ui-g-12 label label-success">Episode data OK</div>
    </div>
</p-panel>
