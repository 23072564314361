<p-panel>
    <p-header>
        <div class="ui-g-12" style="padding:0;">
            <span class="p-panel-title" style="font-size:16px;">{{title}}</span>
        </div>
    </p-header>

    <json-editor  *ngxPermissionsExcept="['object.write.all']" [options]="editorOptionsRead" (change)="changeData($event)" [data]="data" ></json-editor>
    <json-editor  *ngxPermissionsOnly="['object.write.all']" [options]="editorOptionsEdit" (change)="changeData($event)" [data]="data" ></json-editor>

</p-panel>
