<p-dataView #dv [value]="containers" 
                [rows]="rows"
                [paginator]="true"
                paginatorPosition="both"
                [alwaysShowPaginator]="true"
                [filterBy]="filterBy" 
                styleClass="borderless"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                layout="grid">
    <p-header>
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-4 ui-g-nopad">
                    <input type="text" pInputText placeholder="Filter" (keyup)="dv.filter($event.target.value)" class="filter-bar"/>
                </div>
                <div class="ui-g-4 ui-g-nopad">
                    <p-dropdown 
                    [options]="statusList" 
                    placeholder="No Status Filter" 
                    [(ngModel)]="selectedStatusOption"  
                    (ngModelChange)="onStatusChange()"  
                    appendTo="body"
                    showClear="true"
                    class="sort-bar"
                    [style]="{'height':'100%','width':'100%','margin-left':'1em'}"></p-dropdown>
                </div>
                <div class="ui-g-4 ui-g-nopad">
                    <ng-content></ng-content>
                </div>
            </div>
            <spinner *ngIf="isLoading" class="ui-g-12" style="padding:0"></spinner>
        </div>
    </p-header>
    <ng-template let-container pTemplate="gridItem">
        <div style="width:200px;padding:0.25em;">
            <container [container]="container" [parentContainer]="parentContainer"></container>
        </div>
    </ng-template>
</p-dataView>
