<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="wfMsg" styleClass="app-commerce-forms-campaign-form-workflow-execution-toast-message"></p-toast>
        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button" (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" icon="fa fa-thumbs-up" style="float:right;"></button>
                <button  pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-commerce-forms-campaign-form-button-save"></button>
                <!-- <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit> -->
                <container-workflow-status [container]="container" (onSimpleStatusChange)="onSimpleStatusChange($event)" (onComplexStatusChange)="onComplexStatusChange($event)"(onStatusTransitionFailure)="onStatusTransitionFailure($event)" style="float:right;margin-right:4px;" data-element-id="app-commerce-forms-campaign-form-check-status"></container-workflow-status>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="label">Label</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['label']" name="label" placeholder="Required" data-element-id="app-commerce-forms-campaign-form-input-label">
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="container.guid" name="guid" data-element-id="app-commerce-forms-campaign-form-input-guid">
                </div>
                <div class="ui-g-6">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-commerce-forms-campaign-form-input-description">
                </div>
                <div class="ui-g-6">
                    <label for="orderId">Order Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.order_id" name="orderId" data-element-id="app-commerce-forms-campaign-form-input-order-id">
                </div>
            </div>
        </p-panel>

        <ng-content></ng-content>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div *ngIf="container.id > 0" class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <container-tags-panel [container]=container viewName="campaign" class="ui-g-12"></container-tags-panel>
   </div>
</form>
