import { Output, EventEmitter, TemplateRef, AfterViewInit, AfterContentInit,
    ContentChildren, QueryList, ViewChild, ElementRef, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


@Injectable()
export class UploadService {
    private baseUrl = environment.PROXY_API_BASE_URL;
    @Output() onProgress: EventEmitter<any> = new EventEmitter();

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    public upload(file, path = ""): Observable < any > {
        console.log('upload', file);

        return this.getSignature(file, path).pipe(
            mergeMap(
                (res: any) => {
                    console.log('got signature', res);

                    return Observable.create(observer => {
                        let data = res;
                        console.log('data', data);

                        let formData: FormData = new FormData();
                        for (let key in data.fields) {
                            formData.append(key, data.fields[key]);
                        }
                        formData.append('file', file);

                        let url = data.url;
                        if (!url.endsWith('/')) {
                            url += '/';
                        }
                        url += data.fields.key;
                        let asset_url = data.asset_url || url;

                        let asset_data = {
                            url: data.url,
                            key: data.fields.key,
                            'Content-Type': data.fields['Content-Type'],
                        }

                        let xhr: XMLHttpRequest = new XMLHttpRequest();
                        xhr.upload.addEventListener("progress", this.uploadProgress, false);
                        xhr.upload.onprogress = (event) => {
                            var progress = Math.round(event.loaded / event.total * 100);
                            observer.next({
                                progress: progress,
                                complete: false,
                                url: url,
                                asset_url: asset_url,
                                data: asset_data,
                            });
                        };

                        xhr.upload.onabort = (event) => {
                            console.log('Upload Aborted', event);
                            observer.error("Upload Aborted");
                            observer.complete();
                        };

                        xhr.upload.onerror = (event) => {
                            console.log('Upload Error', event);
                            observer.error('Upload Error');
                            observer.complete();
                        };

                        xhr.upload.ontimeout = (event) => {
                            console.log('Upload Timedout', event);
                            observer.error('Upload Timedout');
                            observer.complete();
                        };

                        // xhr.addEventListener("load", uploadComplete, false);
                        // xhr.addEventListener("error", uploadFailed, false);
                        // xhr.addEventListener("abort", uploadCanceled, false);

                        xhr.onreadystatechange = () => {
                            console.log('readystate', xhr.readyState);
                            if (xhr.readyState === 4) {
                                // s3 can return either status, 204 is empty response
                                if (xhr.status === 200 || xhr.status === 204) {
                                    console.log('File complete:', asset_url);
                                    console.log('Asset data:', asset_data);
                                    observer.next({
                                        progress: 100,
                                        complete: true,
                                        url: url,
                                        asset_url: asset_url,
                                        data: asset_data,
                                    });
                                } else {
                                    observer.error(xhr.response);
                                }
                                observer.complete();
                            }
                        };

                        xhr.open('POST', data.url, true);
                        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
                        xhr.send(formData);
                    });
                }
            ));
    }

    public getS3UriFromUrl(url: string): string {
        // Adapted from https://stackoverflow.com/a/48543994
        let bucket = '';
        let key = '';

        url = decodeURIComponent(url);
        let match;

        // http://s3.amazonaws.com/bucket/key1/key2
        match = url.match(/^https?:\/\/s3.amazonaws.com\/([^\/]+)\/?(.*?)$/);
        if (match) {
            bucket = match[1];
            key = match[2];
        }

        // http://s3-aws-region.amazonaws.com/bucket/key1/key2
        match = url.match(/^https?:\/\/s3-([^.]+).amazonaws.com\/([^\/]+)\/?(.*?)$/);
        if (match) {
            bucket = match[2];
            key = match[3];
        }

        // http://bucket.s3.amazonaws.com/key1/key2
        match = url.match(/^https?:\/\/(.+).s3.amazonaws.com\/?(.*?)$/);
        if (match) {
            bucket = match[1];
            key = match[2];
        }

        // http://bucket.s3-aws-region.amazonaws.com/key1/key2
        match = url.match(/^https?:\/\/(.+).s3-([^\.]+).amazonaws.com\/?(.*?)$/);
        if (match) {
            bucket = match[1];
            key = match[3];
        }

        if (bucket && key) {
            return `s3://${bucket}/${key}`
        }

        return null;
    }

    public isImage(file: File) {
        return /^image\//.test(file.type);
    }

    public isVideo(file: File) {
        return /^video\//.test(file.type);
    }

    public getFileExtension(url: string): string {
        const fileName = url.split('/').pop();
        const pieces = fileName.split('.');
        return pieces.length > 1 ? pieces[pieces.length - 1] : '';
    }

    private uploadProgress(evt) {
        if (evt.lengthComputable) {
            var percentComplete = Math.round(evt.loaded * 100 / evt.total);
            // console.log(percentComplete); //_handle_progress(percentComplete);
        }
    }

    private getSignature(file, path = '') {
        let headers = new HttpHeaders()
            .append('Authorization', this.authService.getAuthHeader());

        let params: HttpParams = new HttpParams();

        var fileName;
        if (path !== '') {
            fileName = path + '/' + file.name;
        } else {
            fileName = file.name;
        }

        params = params.set('file_name', fileName);

        if (file.type.length === 0) {
            params = params.set('file_type', 'application/octet-stream');
        } else {
            params = params.set('file_type', file.type);
        }

        console.log(params);
        return this.http
            .get(
                `${this.baseUrl}/aws/sign/s3/`, {
                    headers,
                    params
                }
            );
    }
    /*
    function uploadComplete(evt) {
      if (evt.target.responseText == "") {
        _handle_success(_file_name);
      } else {
        _handle_error(evt.target.responseText);
      }
    }

    function uploadFailed(evt) {
      _handle_error("There was an error attempting to upload the file." + evt);
    }

    function uploadCanceled(evt) {
      _handle_error("The upload has been canceled by the user or the browser dropped the connection.");
    }
    */

    private buildTimestamp() {
        var date = new Date();
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1))
            .slice(-2);
        var day = ('0' + date.getDate())
            .slice(-2);

        var timestamp = year + month + day;
        return timestamp;
    }
}
