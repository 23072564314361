<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">

        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-12 ui-md-6">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-content-forms-platform-form-cancel"></button>
            </div>
            <div class="ui-g-12 ui-md-6">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-platform-form-save"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['name']" name="name" data-element-id="app-content-forms-platform-form-name">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12 ui-md-4">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-content-forms-platform-form-type">
                </div>
                <div class="ui-g-12 ui-md-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" [(ngModel)]="container.guid" name="guid" readonly data-element-id="app-content-forms-platform-form-guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Platform Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12  ui-md-8 ui-lg-4">
                    <label for="bundle_id">Bundle Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.bundle_id" name="bundle_id" data-element-id="app-content-forms-platform-form-bundle-id">
                </div>
                <div class="ui-g-12  ui-md-4 ui-lg-2">
                    <label for="platform_type">Platform Type</label>
                    <input required type="text" class="form-control" [(ngModel)]="container.data.platform_type" name="platform_type" data-element-id="app-content-forms-platform-form-platform-type"/>
                </div>
                <div class="ui-g-12  ui-md-4 ui-lg-2">
                    <label for="url_scheme">URL Scheme</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.url_scheme" name="url_scheme" data-element-id="app-content-forms-platform-form-url-scheme">
                </div>
                <div class="ui-g-12  ui-md-4 ui-lg-2">
                    <label for="play_action_url">Play Action URL</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.play_action_url" name="play_action_url" data-element-id="app-content-forms-platform-form-play-action-url">
                </div>
                <div class="ui-g-12  ui-md-4 ui-lg-2">
                    <label for="open_action_url">Open Action URL</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.open_action_url" name="open_action_url" data-element-id="app-content-forms-platform-form-open-action-url">
                </div>
            </div>
        </p-panel>
    </div>
</form>
