import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Container, Resource, History, Schema } from '../../models';
import { HistoryService } from '../../services/history/history.service';

@Component({
    selector: 'history',
    providers: [
        HistoryService
    ],

    templateUrl: 'history.component.html',
    styleUrls: ['history.component.css']
})

export class HistoryComponent implements OnInit {
    @Input() inputType: string;
    @Input() inputObject: Container | Resource | Schema;

    private isLoading: boolean;
    private history: Array<History>;
    private lastModifiedByUser = '';;
    private lastModifiedByDatetime = '';
    private lastModifiedByInfo = '';
    private limit: number;
    private offset: number = 0;
    private inputObjectId: number;

    constructor(
        private historyService: HistoryService,
    ) {}

    ngOnInit() {}      


    ngOnChanges(changes: SimpleChanges) {
        if (changes['inputObject'] && changes['inputObject'].previousValue != changes['inputObject'].currentValue && this.inputObject) {
           this.getObjectHistory();
        }
    }

    getObjectHistory() {

        this.isLoading = true;
        this.limit = 1;
        this.inputObjectId = this.inputObject.id

        this.historyService
            .getHistoryFromId(this.inputObjectId, this.inputType, this.limit, this.offset)
            .subscribe(
                res => {
                    this.history = res['results'];
                    this.lastModifiedByUser = this.history[0]['history_user'];
                    this.lastModifiedByDatetime = new Date(this.history[0]['history_date']).toLocaleString();
                    this.lastModifiedByInfo = this.lastModifiedByUser + ", " + this.lastModifiedByDatetime
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            ); 
    }

}