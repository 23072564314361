export class Segment {
  id = -1;
  status = 'new';
  origin = '';
  data  = {};
  parent = '';
  label = '';
  value = '';
  start_time_code = '';
  end_time_code = '';
  type = '';
  namespace = '';
  created_date = '';
  published_date = '';
  expiration_date = '';
  updated_date='';
}
