import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DragulaService } from 'ng2-dragula';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';

@Component({
    selector: 'policy-table',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'policy-table.component.html',
    styleUrls: ['policy-table.component.css']
})
export class PolicyTableComponent implements OnInit {
    @Input() parentContainer: Container;
    @Input() isLoading = false;
    @Input() modifyPermissions: Array <any> = [];
    public containers: Array<Container>;
    public container: Container;
    private prevContainers: any;

    constructor(
        public containerService: ContainerService
    ) {}

    ngOnInit() {
      this.containerService
      .getRelatedByTypeAndGuidPaginated('policy', this.parentContainer.guid, 1, 200)
      .subscribe(
        res => this.containers = res,
        err => console.log(err),
        () => {
          this.isLoading = false
          this.prevContainers = Array.from(this.containers)
        }
      )
    }

    onRowReorder(e) {
        if (this.parentContainer) {
            let dragIndex = e['dragIndex'];
            let dropIndex = e['dropIndex']

            let dropItemIndex = dropIndex < dragIndex ? dropIndex-1 : dropIndex;

            let dragItem = this.prevContainers[dragIndex]
            let dropItem = dropIndex > 0 ? this.prevContainers[dropItemIndex] : null;

            this.prevContainers = Array.from(this.containers)

            this.isLoading = true;
            this.containerService
            .reorder(this.parentContainer, dragItem, dropItem)
            .subscribe(
                res => {
                    console.log(res)
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        }
    }


    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this policy?')) {
            console.log('remove', e, container);
            let i = this.containers.indexOf(container);
            this.containers.splice(i, 1);
            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                    },
                    err => console.log(err)
                );
        }
    }

    statusLabelClass(status) {
      return this.containerService.getStatusLabelClass(status);
    }
}
