<page-header title="Create Test Suite" [showSpinner]="isLoading" [error]="error"></page-header>
<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">

    <h1>New Test Suite</h1>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <test-suite-form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onCancel($event)"></test-suite-form>
    </div>

</div>
