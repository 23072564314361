<div class="card card-w-title top-level-card">
    <h1>Edit Version{{container ? ': ' + (container.data['name'] ? container.data['name'] : '') + container.data['number'] : ''}}</h1>

    <page-header title="Edit Version{{container ? ': ' + (container.data['name'] ? container.data['name'] : '') + container.data['number'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <version-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></version-form>

        <hr>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title ui-g-8" style="font-size:16px;padding:0;padding-left:10px;">Related Configuration</span>
                <div class="ui-g-4" style="padding:0;">
                    <button *ngIf="!configuration" pButton type="button" label="Relate Configuration" icon="pi pi-plus" [routerLink]="['/containers', container?.id, 'relate', 'configuration']" style="float:right;margin-left:4px;"></button>
                    <button *ngIf="configuration" pButton type="button" label="Unrelate Configuration" class="p-button-danger" icon="pi pi-times" (click)="unrelateConfiguration($event)" style="float:right;margin-left:4px;"></button>
                </div>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div *ngIf="configuration">
                    <div class="ui-g-6">
                        <label for="title">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="configuration.data['name']" name="name" placeholder="Required" disabled>
                    </div>
                    <div class="ui-g-6">
                        <label for="title">Version</label>
                        <input type="text" class="form-control" [(ngModel)]="configuration.data['version']" name="name" placeholder="Required" disabled>
                    </div>
                </div>
                <div *ngIf="!configuration">
                    No Related Configuration
                </div>
            </div>
        </p-panel>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
