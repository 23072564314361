import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthLoginData, AuthProvider } from './auth.types';

export class TokenAuthProvider implements AuthProvider {
    private tokenName = 'proxy_auth_token';
    private baseUrl = environment.PROXY_API_BASE_URL;
    private loginData: BehaviorSubject<AuthLoginData>;
    public loginState: Observable<AuthLoginData>;
    // Constants
    public readonly providerName = 'token';
    public readonly providerNameFull = 'Django token';
    public readonly loginUrl = '/auth/login';
    public readonly loginParam = '';

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.http = http;
        this.router = router;
        this.loginData = new BehaviorSubject<AuthLoginData>(null);
        this.loginState = this.loginData.asObservable();
        // Check if authenticated on initialization
        if (this.isAuthenticated()) {
            // Trigger as if logging in
            this.setToken(this.getToken());
        }
    }

    private makeAuthHeader(token: string) : string {
        return `Token ${token}`;
    }

    private getToken() {
        const token = localStorage.getItem(this.tokenName);
        // console.log('get token', token);
        return token;
    }

    private setToken(token) {
        // console.log('set token', token);
        localStorage.setItem(this.tokenName, token);
        // Update observable
        const newLoginData : AuthLoginData = {
            provider: this.providerName,
            header: this.makeAuthHeader(token),
            expiry: null,
        };
        this.loginData.next(newLoginData);
    }

    private clearToken() {
        localStorage.removeItem(this.tokenName);
        // Update observable
        this.loginData.next(null);
    }

    getAuthHeader() {
        const token = this.getToken();
        return this.makeAuthHeader(token);
    }

    isAuthenticated() {
        const token = this.getToken();
        return token !== null;
    }

    login({username, password}: {username: string, password: string}) : Observable<any> {
        return this.http
            .post(
                `${this.baseUrl}/api-token-auth/`, {
                    username: username,
                    password: password
                }
            ).pipe(
            tap(
                (res: any) => {
                    console.log('Token auth data:', res);
                    this.setToken(res.token);
                }
            ));
    }

    logout(redirect: boolean = false) {
        this.clearToken();
        console.log('Logged out (token)');
        // Redirect to /auth/login
        if (redirect) {
            console.log(`Redirecting to ${this.loginUrl}`);
            this.router.navigate([this.loginUrl]);
        }
    }
}
