<spinner *ngIf="containers.length === 0"></spinner>

<p-table [value]="containers" [rows]="10" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="header">
        <tr>
            <th [style]="{'width':'20%'}">Name</th>
            <th [style]="{'width':'10%'}">Platform</th>
            <th [style]="{'width':'10%'}">Status</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-container>
        <tr>
            <td>{{container.data['name']}}</td>
            <td><div class="label label-default">{{container.data.platform_type}}</div></td>
            <td><div class="label label-success">published</div></td>
        </tr>
    </ng-template>
</p-table>
