<spinner *ngIf="isLoading"></spinner>
<form *ngIf="!isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-toolbar class="ui-g-12">
          <div class="ui-g-6">
            <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
          </div>
          <div class="ui-g-6">
              <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
              <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
          </div>
        </p-toolbar>
        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
          </p-header>

          <div class="ui-g-12" style="padding:0.5em;overflow:hidden;">
              <div class="ui-g-6">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" name="name" placeholder="Required" required [(ngModel)]="container.data.name">
              </div>
              <div class="ui-g-6">
                <label for="origin">Origin</label>
                <input type="text" class="form-control" name="origin" readonly [(ngModel)]="container.origin">
              </div>
          </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-g-4">
                  <label for="type">Type</label>
                  <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                      <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{entityType.name}}</option>
                  </select>
              </div>
              <div class="ui-g-8">
                  <label for="guid">GUID</label>
                  <input type="text" class="form-control" [(ngModel)]="container.guid" readonly name="guid">
              </div>
          </div>
        </p-panel>

        <!-- ***** Info Section ****** -->
        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Notification Information</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-g-6 ui-md-4 ui-lg-2">
                <label for="mode">Mode</label>
                <select [(ngModel)]="container.data.mode" name="mode" class="form-control">
                  <option *ngFor="let mode of this.modes" [value]="mode">{{ mode }}</option>
                </select>
              </div>

              <div class="ui-g-6 ui-md-4 ui-lg-2">
                <label for="notification_type">Notification Type</label>
                <select [(ngModel)]="container.data.notification_type" name="notification_type" class="form-control">
                  <option *ngFor="let notification_type of this.notification_types" [value]="notification_type">{{ notification_type }}</option>
                </select>
              </div>

              <div class="ui-g-6 ui-md-4 ui-lg-2">
                  <label for="namespace">Namespace</label>
                  <input type="text" class="form-control" name="namespace" [(ngModel)]="container.data.namespace" placeholder="Required" required>
              </div>

              <div class="ui-g-6 ui-md-4 ui-lg-3">
                <label for="subtype">Sub Type</label>
                <p-dropdown name="cnttype"
                            [options]="filteredType"
                            [(ngModel)]="container.data.subtype"
                            placeholder="No SubType"
                            appendTo="body"
                            [style]="{'height':'100%','width':'100%'}"
                            >
                </p-dropdown>
              </div>

              <div class="ui-g-6 ui-md-4 ui-lg-3">
                <label for="frequency">Frequency</label>
                <input type="text" class="form-control" name="frequency" [(ngModel)]="container.data.evaluate_frequency">
              </div>
          </div>

        </p-panel>
    </div>
</form>
