import { Component, OnInit, OnDestroy } from '@angular/core';
import { Container } from '../../../content/models';


@Component({
    selector: 'testing-reports',
    templateUrl: './testing-reports.component.html',
    styleUrls: ['testing-reports.component.css']
})
export class TestingReportsComponent implements OnInit, OnDestroy {
    private containers = [];
    private requiredMsgs = [];

    private selectedContainer: Container;

    public error: any;
    public isLoading = false;

    constructor() { }

    ngOnInit() { }

    containerSelected(event) {
        console.log("Container Selected", event);
        this.selectedContainer = event.container;
    }

    ngOnDestroy() {
    }

}
