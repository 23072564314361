<div *ngIf="!isLoadingTags">        
    <div *ngFor="let tagType of visibleTagsTypes" class="ui-g-4 ui-md-4 ui-lg-4">
        <label *ngIf="!hideTagLabel" for="tagType">{{tagType | labelToDisplay}} Tags</label>
        <span class="p-float-label">
            <p-multiSelect *ngIf="!hideTagChips"
                            class="tags-multi-select" 
                            [options]="tagsDict[tagType]" 
                            [(ngModel)]="selectedTagsDict[tagType]" 
                            name="tagType" optionLabel="label" 
                            maxSelectedLabels="0" 
                            appendTo="body" 
                            [defaultLabel]="'Tag(s)'"
                            [selectedItemsLabel]="'{0} selected'" 
                            [panelStyle]="{minWidth:'20em'}" 
                            (onChange)="onChangeTags()">
                <ng-template let-tag pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{ tag.label ? tag.label : tag.value.value | labelToDisplay }}</div>
                </ng-template>
            </p-multiSelect>
            <p-multiSelect *ngIf="hideTagChips"
                            class="tags-multi-select tags-multi-select-hide-chips"  
                            [options]="tagsDict[tagType]" 
                            [(ngModel)]="selectedTagsDict[tagType]" 
                            name="tagType" optionLabel="label" 
                            maxSelectedLabels="1" 
                            [selectedItemsLabel]="'{0} selected'" 
                            appendTo="body" 
                            [panelStyle]="{minWidth:'20em'}" 
                            (onChange)="onChangeTags()">
                <ng-template let-tag pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{ tag.label ? tag.label : tag.value.value | labelToDisplay }}</div>
                </ng-template>
            </p-multiSelect>
        </span>
    </div>
    <p-chips class="ui-g-8" *ngIf="visibleTags.length > 0 && !hideTagChips" [(ngModel)]="visibleTags" [max]="visibleTags.length" field="label" (onRemove)="updateChips($event)"></p-chips>
</div>
 
<p-progressSpinner *ngIf="isLoadingTags == true" [style]="{width: '25px', height: '25px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>