<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
    <h1>New Season</h1>
    <page-header title="New Season" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <p-messages [(value)]="requiredMsgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <season-form [container]="container" [parentContainer]="parentContainer" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)"></season-form>
    </div>
</div>
