<div>

    <page-header title="Tools" [showSpinner]="isLoading" [error]="error"></page-header>

    <h3>Metadata Tools</h3>

    <p-table #dt [value]="containers" 
            [rows]="20" 
            [paginator]="true" 
            [globalFilterFields]="['data.title', 'type', 'data.houseId', 'data.description', 'data.metadata_ingest_workflow_status', 'data.keywords', 'guid']"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="caption">
            <div class="p-grid" style="margin-top:0">      
                <div class="p-col" style="padding:4px 10px;border-bottom: 0 none;text-align:left">
                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                    <input #gb type="text" 
                            pInputText size="50"
                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Global Filter">
                </div>
                <div class="p-col" style="text-align:right">
                    <button type="button" pButton icon="fa fa-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()" style="float:left"></button>
                </div>  
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Title</th>
                <th>Type</th>
                <th>House Number</th>
                <th>Description</th>
                <th>Status</th>
                <th>Keywords</th>
                <th>GUID</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-container>
            <tr>
                <td>{{container.data?.label}}</td>
                <td>{{container.type}}</td>
                <td>{{container.data?.houseId}}</td>
                <td>{{container.data?.description}}</td>
                <td>{{container.data?.metadata_ingest_workflow_status}}</td>
                <td>{{container.data?.keywords}}</td>
                <td>{{container.guid}}</td>
            </tr>
        </ng-template>
    </p-table>

</div>
