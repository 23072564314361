import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerComponent } from '../container/container.component';
import { Container } from '../../models';
import { ContainerService } from '../../services/container/container.service';

@Component({
    selector: 'episode-container-policy-table',
    providers: [
        ContainerService
    ],
    templateUrl: 'episode-container-policy-table.component.html',
    styleUrls: ['episode-container-policy-table.component.css']
})
export class EpisodeContainerPolicyTableComponent implements OnInit {
    @Input() containers: any;
    @Input() parentContainer: any;

    constructor(
        private router: Router,
        private containerService: ContainerService
    ) {}

    ngOnInit() {}

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this policy?')) {
            console.log('remove', e, container);
            let i = this.containers.indexOf(container);
            this.containers.splice(i, 1);
            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                    },
                    err => console.log(err)
                );
        }
    }
}
