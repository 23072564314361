import { Component, OnInit, ViewChild } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';

//declare let L;
//declare let HeatmapOverlay;

@Component({
    selector: 'metrics-dashboard',
    providers: [
        ContainerService
    ],
    templateUrl: 'metrics-dashboard.component.html',
    styleUrls: ['metrics-dashboard.component.css']
})
export class MetricsDashboardComponent implements OnInit {

    public dashboardContainers = [];
    //public selectedDashboard: Container;
    public selectedDashboardId: string;
    public reportContainers = [];
    private error: string;
    public reportsLoading = false;


    constructor(
        private containerService: ContainerService,
    ) {

    }

    ngOnInit() {
        this.getDashboards();
    }

    getDashboards () {
        this.reportsLoading = true;
        this.containerService
            .filterByTypeName('view', 'full', -1)
            .subscribe(
                res => {
                    this.dashboardContainers = res.filter(container => 
                        container.data.view_id === 'dashboard'
                    );
                    if (this.dashboardContainers.length > 0) {
                        this.selectedDashboardId = this.dashboardContainers[0].guid;
                        this.loadDashboardReports();
                    } 
                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.reportsLoading = false
            );
    }

    loadDashboardReports() {   
        //TODO: Add origin constrain ...might be in there already...lol
        //TODO: Add Report dashboard size var and ordering
        //TODO: Filter container to dashboard type
        this.reportsLoading = true;
        this.containerService
        //.filterByTypeName('report', 'full', -1)
        .getRelatedByTypeAndGuidPaginated('report', this.selectedDashboardId, 1, 200, 'to_container__order,-created_date', '', 'published')
        .subscribe(
            res => {
                this.reportContainers = res;
                this.reportContainers.forEach((report) => {
                    report.layoutClass = this.setLayoutClass(report);
                } )
            },
            err => {
                console.log(err);
                this.error = err.statusText;
            },
            () => {
                this.reportsLoading = false;
                console.log('Reports:', this.reportContainers);
            }
        );
    }

    setLayoutClass(report) {
        let layoutClass = 'ui-g-12 ui-md-6';
        let tagToClass = {
            'render_layout_column_12': 'ui-g-12',
            'render_layout_column_9': 'ui-g-12 ui-md-9',
            'render_layout_column_6': 'ui-g-12 ui-md-6',
            'render_layout_column_3': 'ui-g-12 ui-md-3',
        }
        if ('relation_tags' in report && report['relation_tags'].length > 0){
            report['relation_tags'].forEach(tagObj =>{
                if (tagObj['type'] === 'render_layout_column') {
                    layoutClass = tagToClass[tagObj['value']];
                }
            })
        } 
        return layoutClass;
    }

    ngOnDestroy()
    {
    }
}




// MAP CODE

// TODO: map
// this.compileSessionBreakdown();
// this.compileDeviceBreakdown();

// var testData = {
//     max: 8,
//     data: [{lat: 56.1, lng:-106.3, count: 5},{lat: 43.65, lng:-79.38, count: 3}]
// };

// let baseLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// });

// var cfg = {
//     // radius should be small ONLY if scaleRadius is true (or small radius is intended)
//     // if scaleRadius is false it will be the constant radius used in pixels
//     "radius": 2,
//     "maxOpacity": .8,
//     // scales the radius based on map zoom
//     "scaleRadius": true,
//     // if set to false the heatmap uses the global maximum for colorization
//     // if activated: uses the data maximum within the current map boundaries
//     //   (there will always be a red spot with useLocalExtremas true)
//     "useLocalExtrema": true,
//     // which field name in your data represents the latitude - default "lat"
//     latField: 'lat',
//     // which field name in your data represents the longitude - default "lng"
//     lngField: 'lng',
//     // which field name in your data represents the data value - default "value"
//     valueField: 'count',
//     gradient: {0: 'yellow', 1.0: 'rgb(255,0,0)'}
//   };

// var heatmapLayer = new HeatmapOverlay(cfg);

// var map = new L.Map('map', {
//     center: new L.LatLng(56.1, -106.3),
//     zoom: 3,
//     layers: [baseLayer, heatmapLayer]
// });

// heatmapLayer.setData(testData);