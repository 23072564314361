<page-header title="Edit Workflow" [showSpinner]="isLoading"></page-header>
<div *ngIf="container" class="card card-w-title top-level-card">
  <h1>Edit Workflow{{!isLoading && container ? ' : ' +container.data.name : ''}}</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <workflow-form #form [container]="container" (formSubmit)="onSubmit()" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></workflow-form>
    </div>
  </div>
  <hr>
  <div class="ui-g-12">
    <p-panel>
        <p-header>
            <div class="ui-g">
              <div class="p-panel-title ui-g-12 ui-md-3"><span class="view-title">Segments</span></div>
              <div class="ui-g-12 ui-md-9 ui-lg-9 ui-g-nopad">
                <segment-add [container]="container" [defaultAddAction]="defaultAddAction" [addActionsList]="addActionsList" style="float:right;" data-element-id="app-settings-components-workflow-add-segment"></segment-add>
              </div>
            </div>
        </p-header>
        <segment-paginated-datarow [type]="['workflow_state']" [itemsPerPage]=20 [parentContainer]="container" [filterBy]="['type', 'value', 'namespace', 'start_time_code', 'end_time_code']">
        </segment-paginated-datarow>
    </p-panel>
  </div>
  <hr>
  <div class="p-mt-5 p-p-2">
    <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
  </div>
</div>
