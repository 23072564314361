<div class="card card-w-title top-level-card">
    <h1>Add {{ containerDisplayType }}{{ schema?.label? ' to ' + schema.label : '' }}</h1>

    <page-header title="Add {{ containerDisplayType }} to {{ schema?.label }}" [showSpinner]="false" [error]="error"></page-header>

    <p-toolbar>
        <div class="ui-g" *ngIf="!processing">
            <div class="ui-g-12" style="text-align:center;">Select all of the <strong>{{ containerDisplayType }}</strong>(s) you would like to add to <strong>{{ schema?.label? schema.label:'this schema' }}</strong>, and then click 'Confirm' below.</div>
        </div>
        <div class="ui-g" *ngIf="processing">
            <div class="ui-g-12" style="text-align:center;">Processing.... Please wait...</div>
        </div>
    </p-toolbar>

    <br />

    <div *ngIf="!processing">
        <container-relate-form *ngIf="schema" [parent]="schema" [filterType]="containerFilterType" (formSubmit)="onSubmit($event)" (formCancelled)="onBack($event)"></container-relate-form>
    </div>

    <div *ngIf="processing" style="margin:0.5em;">
        <p-progressBar [value]="progress"></p-progressBar>
    </div>
</div>