import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Tag } from '../../../content/models/tag';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: []
})
export class TagFormComponent implements OnInit {
  @Input() tag: Tag;
  @Output() formSubmit: EventEmitter < any > = new EventEmitter();
  @Output() formCancelled: EventEmitter < any > = new EventEmitter();
  @Output() formFailed: EventEmitter < any > = new EventEmitter();
  @ViewChild('ngForm') ngForm: NgForm;
  public isLoading = false;

  constructor(
    private appContext: AppContext,
    private msgService: MessageService,
   ){ }

  ngOnInit(): void {
    this.tag.origin = this.tag.origin ? this.tag.origin : this.appContext.activeOrigin
  }

  onSubmit() {
    this.msgService.clear()
    if (!(this.tag.value) || !/\S/.test(this.tag.value)) {
      this.formFailed.emit(null);
    } else {
      this.ngForm.form.markAsPristine();
      this.formSubmit.emit(this.tag);
    }

}
  resetToDefaultState() {
    this.ngForm.reset();
  }

  isPristine() {
    return this.ngForm.form.pristine;
  }

  onCancel(){
    this.formCancelled.emit()
  }

}
