import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerRowComponent } from '../container-row/container-row.component';
import { ContainerFormComponent } from '../../forms/container-form/container-form.component';
import { ContainerService } from '../../services/container/container.service';
import { TagService } from '../../services/tag/tag.service';
import { Container } from '../../models/container';
import { Tag } from '../../models/tag';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { CollectionFormComponent } from '../../forms/collection-form/collection-form.component';
import { MessageService } from 'primeng/api';
import { formatError } from '../../../shared/utilities';
import { BreadcrumbService } from '../../../app.breadcrumb.service';



@Component({
    selector: 'collection-edit',
    providers: [
        ContainerService,
        TagService
    ],
    templateUrl: 'collection-edit.component.html',
    styleUrls: ['collection-edit.component.css']
})
export class CollectionEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: CollectionFormComponent;
    private sub: any;
    private id: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading: boolean = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private isReordering = false;
    private defaultAddAction: string = 'media';
    private addActionsList: any = ['channel', 'collection', 'document', 'dynamic', 'episode', 'movie', 'series', 'season', 'query', 'advertisement'];
    public containerCount: number;

    public isProcessing = false;
    private pipelineId = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private tagService: TagService,
        private msgSrv: MessageService,
        private breadcrumbService: BreadcrumbService
    ) { }

    ngOnInit() {
        console.log('container edit initialized');
        this.sub = this.route.params.subscribe(params => {
            this.id = +params['id']; // + converts string to number
            console.log('id', this.id);
            this.loadContainerData(this.id);
        });
    }

    refetchContainer() {
        this.loadContainerData(this.id);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onReorder(e) {
        console.log('reorder', e);

        this.requiredMsgs = [];
        this.isReordering = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => console.log(res),
                err => {
                    console.log(err);
                    this.error = err.statusText;
                    // TODO: undo the last drop
                },
                () => this.isReordering = false
            );

    }

    onPinAction(e) {
        console.log('onPinAction', e);
        this.loadContainerData(this.container.id);

    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    this.onStatusError(err);
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    loadContainerData(id) {
        this.isLoading = true;
        this.containerService
            .get(id)
            .subscribe(
                res => {
                    this.container = res;
                    let updateBreadcrubMap = {collection_title: this.container['data']['title']}
                    this.breadcrumbService.updateTemplatedLabels(updateBreadcrubMap)
                    console.log(this.container);
                    this.checkProcessingStatus();
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }



    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    onPipelineTriggered(event) {
        console.log(event);
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
        if (event && event['pipeline_triggered']) {
            this.refetchContainer();
        }
    }

    updateCount(event) {
        this.containerCount = event;
    }

    onStatusError(error) {
        console.log(error);
        let errorMessages = formatError(error, this.msgSrv, 'clMsg');
        this.msgSrv = errorMessages[0];
        this.requiredMsgs = errorMessages[1];
    }
}
