<div class="card card-w-title top-level-card">
    <h1>Add {{containerDisplayType}}{{ container?.data.title ? ' to ' + container.data['title'] : '' }}</h1>

    <page-header title="Add {{containerDisplayType}} to {{ container?.data.title }}" [showSpinner]="false" [error]="error"></page-header>

    <p-toolbar>
        <div class="ui-g" *ngIf="!processing">
            <div class="ui-g-12" style="text-align:center;">Select all of the <strong>{{containerDisplayType}}</strong>(s) you would like to add to <strong>{{container?.data.title?container.data['title']:'this container'}}</strong>, and then click 'Confirm' below.</div>
        </div>
        <div class="ui-g" *ngIf="processing">
            <div class="ui-g-12" style="text-align:center;">Processing.... Please wait...</div>
        </div>
    </p-toolbar>

    <br />

    <div *ngIf="!processing">
        <container-relate-form *ngIf="container" [parentContainer]="container" [filterType]="containerFilterType" (formSubmit)="onSubmit($event)" (formCancelled)="onBack($event)"></container-relate-form>
    </div>

    <div *ngIf="processing" style="margin:0.5em;">
        <p-progressBar [value]="progress"></p-progressBar>
    </div>
</div>