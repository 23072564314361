import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';

@Component({
    selector: 'roles',
    providers: [
        ContainerService
    ],
    templateUrl: 'roles.component.html',
    styleUrls: ['roles.component.css']
})
export class RolesComponent implements OnInit {

    public roleContainers = [];
    private rolesLoading = false;
    private error: string;
    public container: any;

    constructor(
        private containerService: ContainerService,
    ) {}

    ngOnInit() {
        this.rolesLoading = true;

        this.containerService
            .filterByTypeName('role', 'full', 0)
            .subscribe(
                res => this.roleContainers = res,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => {
                    this.rolesLoading = false;
                    console.log('roles:', this.roleContainers);
                }
            );
    }

    onRowSelect(event) {}
}
