import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { Tag } from '../../../content/models/tag';
import { Status } from '../../../content/models/status';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../../../content/components/container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { ProviderFormComponent } from '../../forms/provider-form/provider-form.component';

@Component({
    selector: 'provider-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'provider-edit.component.html',
    styleUrls: ['provider-edit.component.css']
})
export class ProviderEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: ProviderFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    private workers: Array<Container>;
    private resourceSortOptions: Array<any>;
    public isProcessing = false;
    private pipelineId = ''

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        //console.log('pipeline edit initialized');

        this.resourceSortOptions = [
            {label: 'Stored Order', value: ''},
            {label: 'Creation Order', value: '!created_date'},
            {label: 'Reverse Creation Order', value: 'created_date'},
        ];

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                        this.checkProcessingStatus();
                        this.validateFormData();
                        console.log("provider", this.container);
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                    this.checkProcessingStatus();
                    this.validateFormData();
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    validateFormData() {
        this.msgs = [];
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        this.validateFormData();
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    onPipelineTriggered(event) {
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
    }

}
