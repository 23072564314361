<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Policies</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-4 button-wrapper" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers"
            [rows]="20"
            [paginator]="true"
            paginatorPosition="both"
            styleClass="p-datatable-gridlines  p-datatable-nested"
            [responsive]="true"
            (onRowReorder)="onRowReorder($event)">
        <ng-template  pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th>Title</th>
                <th>Policy Type</th>
                <th>Policy Id</th>
                <th>Status</th>
                <th *ngxPermissionsOnly=modifyPermissions style="width:42px;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-policy let-index="rowIndex">
            <tr [pReorderableRow]="index" >
                <td>
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                </td>
                <td>{{policy.data.title}}</td>
                <td><div class="label label-default">{{policy.data.policy_type}}</div></td>
                <td style="word-break: break-all;">{{policy.data.policy_id}}</td>
                <td><div class="label" [ngClass]="statusLabelClass(policy.status)">{{policy.status|titlecase}}</div></td>
                <td *ngxPermissionsOnly=modifyPermissions>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, policy)" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
