import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models';
import { ContainerService } from '../../../content/services/container/container.service';
import { WorkflowFormComponent } from '../../forms/workflow-form/workflow-form.component';

@Component({
  selector: 'workflow-edit',
  providers: [
    ContainerService
  ],
  templateUrl: './workflow-edit.component.html',
})
export class WorkflowEditComponent implements OnInit {
  @ViewChild('form') form: WorkflowFormComponent;
  public container: Container;
  public isLoading = false;

  private defaultAddAction: string = 'workflow_state';
  private addActionsList: any = [];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public containerService: ContainerService,
    private msgService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.route.params.subscribe(params => {
      let id = +params['id'];
      this.containerService.get(id)
          .subscribe(
              res => {this.container = res; this.container.data = this.container.data ?? {}},
              err => this.isLoading=false,
              () => this.isLoading = false
          );
    });
  }

  onSubmit() {
    this.isLoading = true
    this.containerService.save(this.container).subscribe(
      res => this.container = Object.assign(new Container(), this.container),
      err => {
        this.isLoading = false
        console.log(err)
        if (err.error) {
          let msg = err.error[0] || err.error.detail || err.error
          this.msgService.add({severity: 'error', summary:'Error', detail: msg })
        }
      },
      ()  => {
        this.isLoading = false
        this.msgService.add({severity: 'success', summary:'', detail:'Updated successfully' })
      }
    )
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  onFail(e){
    this.msgService.add(e);
  }
}
