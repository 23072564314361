import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { TestFormComponent } from './../../forms/test-form/test-form.component';
import { ContainerService } from '../../../content/services/container/container.service'
import { Container } from '../../../content/models/container';
import { Message } from 'primeng/api';

@Component({
    selector: 'test-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'test-edit.component.html',
    styleUrls: ['test-edit.component.css']
})
export class TestEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: TestFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    private isProcessing = false;

    // private dataSet: Array<any>;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id'];
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    toIterable(dict: Object): Array<any> {
        var a = [];
        for (var key in dict) {
            if (dict.hasOwnProperty(key)) {
                a.push({ key: key, val: dict[key] });
            }
        }
        return a;
    }

    validateFormData() {
        this.msgs = [];

        if (!('label' in this.container.data) || (!this.container.data['label'])) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Label is required.' });
        }
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        this.validateFormData();
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }

}
