
<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-2 ui-lg-3 ui-xl-3 view-title" style="padding:0;text-align:left">{{title}}</span>

            <div class="ui-g-12 ui-md-9 ui-lg-9 ui-xl-6" style="padding-top:0; display:flex; gap: 0.5rem; flex-flow:row wrap;">
              <div class="ui-g-12 ui-md-3" style="padding:0;">
                <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" [(ngModel)]="activeFilter" (ngModelChange)="onFilter()" class="filter-bar"/>
              </div>
              <div>
                  <p-dropdown [options]="orderingOptions" [(ngModel)]="selectedOrderingOption" (ngModelChange)="onSortChange()" appendTo="body"></p-dropdown>
              </div>
              <div>
                  <p-dropdown class="custom-clear-icon" [disabled]="filterByTypeOptions.length===0" [options]="filterByTypeOptions" placeholder="Choose Type" [showClear]="true" [(ngModel)]="selectedTypeOption" (ngModelChange)="onFilter()" appendTo="body"></p-dropdown>
              </div>
              <div>
                  <p-dropdown class="custom-clear-icon" [disabled]="filterByTagOptions.length===0" [options]="filterByTagOptions" placeholder="Choose Tag" [showClear]="true" [(ngModel)]="selectedTagOption" (ngModelChange)="onFilter()" appendTo="body"></p-dropdown>
              </div>
            </div>
            <div class="ui-g-12 ui-xl-3 button-wrapper" style="padding:0;">
                <ng-content></ng-content>
                    <button pButton type="button" label="Manage Files" (click)="showManageDialog()" *ngxPermissionsOnly="['view.show.upload']"></button>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>
    <div class="items">
        <div *ngFor="let resource of shownResources">
            <resource *ngIf="resource.show" class="item" [resource]="resource" [parentContainer]="parentContainer" (onRemove)="resourceRemoved($event)"></resource>
        </div>
    </div>
</p-panel>
<!-- <button pButton type="button" label="Manage Files" class="p-button-rounded p-button-secondary" (click)="showManageDialog()" style="float:right;"></button> -->
<p-dialog header="Manage Files" [modal]="true" [(visible)]="displayManageDialog" [responsive]="true" [blockScroll]="true" [style]="{'max-width': '90%', 'max-height': '75%','min-height': '360px'}">
    <p-table [value]="fileResources"
            dataKey="uri"
            [scrollable]="true"
            scrollHeight="360px"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Source URI</th>
                <th style="width:9em">Tags</th>
                <th style="width:6em">Status</th>
                <th style="width:5em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td>{{(rowData.data && rowData.data['source_uri']) || rowData.uri}}</td>
                <td style="width:9em">
                    <div *ngFor="let tag of rowData.tags">
                        <span>{{tag.value}}</span>
                    </div>
                </td>
                <td style="width:6em">{{rowData.status}}</td>

                <td style="width:5em">
                    <button pButton type="button" label="Edit" class="p-button-primary panel-button" [routerLink]="['/containers', parentContainer.id, 'resources', rowData.id]"></button>
                    <!--
                    <button pButton type="button" disabled="true" label="Copy" class="p-button-rounded p-button-secondary"></button>
                    <button pButton type="button" disabled="true" label="Move" class="p-button-rounded p-button-secondary"></button>
                    <button pButton type="button" disabled="true" label="Delete" class="p-button-rounded p-button-secondary"></button>
                     -->
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
