import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { CommerceService } from '../../services/commerce.service';
import { ContentGroupService } from '../../services/content-group.service';
import { CASegmentMediaTableComponent } from '../segment-media-table/segment-media-table.component';
import { CAMomentTableComponent } from '../moment-table/moment-table.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'content-group-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'content-group-edit.component.html',
    styleUrls: ['content-group-edit.component.css']
})
export class CAContentGroupEditComponent implements OnInit, OnDestroy {
    public container: Container;
    public error: HttpResponse<any>;
    public isEditingMoment: boolean = false;
    public isLoading = false;
    public isMomentLoading = false;
    public isReLoading = false;
    public segmentKeywords = [];
    public segmentOrigins = [];
    public selectedMediaContainer: Container;
    public totalRecords;
    private activeMediaContainer: Container;
    private activeMediaMoments;
    private activeMoment: any;
    private activeTimecode = 0;
    private allMediaWithMoments = {};
    private allMomentsWithChapters: Array<string> = [];
    private cgState: any;
    private destroy$ = new Subject();
    private entityTypeSubscr: Subscription;
    private getRawContSubscr: Subscription;
    private initSubscr: any;
    private momentEntityType;
    private parentMediaGuid;
    private relateContainers$: Subscription;
    private removeMomentSubscr: Subscription;
    private saveContSubscr: Subscription;
    private saveMomentSubscr: Subscription;
    private saveValidatedMediaSubscr: Subscription;
    private unRelateContainers$: Subscription;

    @ViewChild(CASegmentMediaTableComponent) mediaTableComponent;
    @ViewChild(CAMomentTableComponent) momentTableComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private commerceService: CommerceService,
        private cgService: ContentGroupService,
        private entityTypeService: EntityTypeService,
        private msgSrv: MessageService,
    ) {}

    ngOnInit() {
        console.log('group edit initialized');
        this.initSubscr = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            this.isLoading = true;
            this.containerService
                .get(id, 'full', 0, 'none')
                .subscribe(
                    res => {
                        this.container = res;
                        this.cgService.updateCgState({
                            'contentGroup': this.container,
                            'source': 'content-group-edit-2'
                        });
                        this.totalRecords = this.container.containers.length;
                    },
                    err => this.error = err,
                    () => {
                        this.isLoading = false;
                        this.getSegmentKeywords();
                        this.getSegmentOrigins();
                        this.getTypes();
                        this.initSubscr.unsubscribe();
                    }
                );
        });
    }

    ngAfterViewInit(): void {
        this.destroy$.next(false);
        this.createCgSub();
    }

    ngOnDestroy() {
        this.cgService.updateCgState({
            'autoplay': null,
            'activeMediaContainer': null,
            'source': 'cg-destroy'
        });
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
            });
    }

    getSegmentKeywords() {
        if ('segment_keywords' in this.container.data && this.container.data['segment_keywords']) {
            this.segmentKeywords = this.container.data['segment_keywords'];
            this.segmentKeywords = this.segmentKeywords.map(k => k.value);
        } else {
            this.segmentKeywords = [];
        }
    }

    getSegmentOrigins() {
        if ('origins' in this.container.data) {
            this.segmentOrigins = this.container.data['origins']
        } else {
            this.segmentOrigins = [];
        }
    }

    getTypes() {
        this.entityTypeSubscr = this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    let entityTypes = res;
                    this.momentEntityType = (entityTypes.find(t => t.name === 'moment'));
                    this.entityTypeSubscr.unsubscribe();
                },
                err => console.log(err),
                () => {}
            );

    }

    getRelatedMomentsFromSelectedMedia() {
        if (this.isEditingMoment) {
            this.isMomentLoading = true;
        }
        this.commerceService
            .filterByPrimaryAndSecondaryParent(this.container.guid, this.selectedMediaContainer.guid, 'published')
            .subscribe(
                res => {
                    let momentContainers = res;
                    for (let i = 0; i < momentContainers.length; i++) {
                        let parentId = momentContainers[i]['data']['secondary_parent_container_id'];
                        let activeChapter = momentContainers[i]['data']['active_chapter_number'];
                        let startTimeCode = momentContainers[i]['data']['start_time_code'];
                        if (this.isEditingMoment) {
                            for (let j = 0; j < this.allMediaWithMoments[parentId].length; j++) {
                                if (!this.allMomentsWithChapters.includes(parentId + "_" + this.allMediaWithMoments[parentId][j])) {
                                    this.allMomentsWithChapters.push(parentId + "_" + this.allMediaWithMoments[parentId][j]);
                                }
                                this.addMomentToDictionary(parentId, this.allMediaWithMoments[parentId][j]);
                            }
                        } else {
                            if (!this.allMomentsWithChapters.includes(parentId + "_" + activeChapter)) {
                                this.allMomentsWithChapters.push(parentId + "_" + activeChapter);
                            }
                            this.addMomentToDictionary(parentId, startTimeCode);
                        }
                    }
                },
                err => {
                    this.error = err;
                },
                () => {
                    this.getRawContainer(this.selectedMediaContainer.guid, 'handleMediaRowSelect');
                    this.isEditingMoment = false;
                    this.isMomentLoading = false;
                }
            )
    }

    getRelatedMomentsFromSelectedMoment() {
        this.commerceService
            .filterByPrimaryAndSecondaryParent(this.container.guid, this.parentMediaGuid, 'published')
            .subscribe(
                res => {
                    let momentContainers = res;
                    for (let i = 0; i < momentContainers.length; i++) {
                        let parentId = momentContainers[i]['data']['secondary_parent_container_id'];
                        let activeChapter = momentContainers[i]['data']['active_chapter_number'];
                        let startTimeCode = momentContainers[i]['data']['start_time_code'];
                        if (!this.allMomentsWithChapters.includes(parentId + "_" + activeChapter)) {
                            this.allMomentsWithChapters.push(parentId + "_" + activeChapter);
                        }
                        this.addMomentToDictionary(parentId, startTimeCode);
                    }
                },
                err => {
                    this.error = err;
                },
                () => {
                    this.getRawContainer(this.parentMediaGuid, 'handleMomentRowSelect');
                }
            );
    }


    handleMediaRowSelect(e) {
        console.log("media Row Selected", e);
        this.selectedMediaContainer = e['container'];
        this.activeTimecode = 0;
        const currentCgState = this.cgService.getCurrentCgState();
        if (currentCgState['skipPlay']) {
            this.activeTimecode = currentCgState['graphTimecode'];
        }
        this.activeMoment = null;
        this.getRelatedMomentsFromSelectedMedia();
        this.momentTableComponent.unselectTableItems();
    }

    handleMomentRowSelect(e) {
        console.log("moment Row Selected", e);
        if (this.mediaTableComponent) {
            this.mediaTableComponent.unselectTableItems();
        }
        this.activeMoment = e['container'];
        this.activeTimecode = this.activeMoment['data']['start_time_code'];
        this.parentMediaGuid = this.activeMoment['data']['secondary_parent_container_id'];
        this.getRelatedMomentsFromSelectedMoment();
    }

    handleMomentCreate() {
        let momentKey = this.selectedMediaContainer.guid + "_" + this.cgState['activeChapterNumber'];
        //if (this.cgState['userTimecode'] === -1 && this.cgState['playerTimecode'] > 0) {
        if (this.cgState['userTimecode'] === -1) {
            this.activeTimecode = this.cgState['playerTimecode'];
        } else {
            this.activeTimecode = this.cgState['userTimecode'];
        }

        if (this.allMomentsWithChapters.includes(momentKey)) {
            if (!confirm('A moment for this chapter has already been saved. Are you sure you want to save another?')) {
                return;
            }
        }

        let momentContainer = new Container();
        momentContainer.status = 'published';
        momentContainer.type = this.momentEntityType;
        momentContainer.data['label'] = "Moment at "+this.activeTimecode +" sec - "+ this.selectedMediaContainer.data['title'] +" - " + this.selectedMediaContainer.data['show_title'];
        momentContainer.data['start_time_code'] = this.activeTimecode;
        momentContainer.data['active_chapter'] = this.cgState['activeChapter'];
        momentContainer.data['active_chapter_number'] = this.cgState['activeChapterNumber'];
        momentContainer.data['primary_parent_container_id'] = this.container.guid;
        momentContainer.data['secondary_parent_container_id'] = this.selectedMediaContainer.guid;
        this.isEditingMoment = true;
        this.saveMomentSubscr = this.containerService
            .save(momentContainer)
            .subscribe(
                res => {
                    let moment = res;
                    console.log('Container created response:', res);
                    this.totalRecords += 1;
                    this.msgSrv.add({ key: 'ceMsg', severity: 'success', summary: 'Moment Saved', detail: ''});
                    this.allMomentsWithChapters.push(momentKey);
                    this.addMomentToDictionary(momentContainer.data['secondary_parent_container_id'], momentContainer.data['start_time_code']);
                    this.cgService.updateCgState({
                        //'activeMoment': res,
                        'activeMoment': null,
                        'source': 'content-group-edit-3',
                        'userTimecode': this.activeTimecode,
                        'playerTimecode': 0,
                        'activeMediaMoments': this.allMediaWithMoments[momentContainer.data['secondary_parent_container_id']]
                    });
                    const relateContainers = combineLatest([
                        this.containerService.relateById(this.container.id, moment.id),
                        this.containerService.relateById(this.selectedMediaContainer.id, moment.id)
                    ]);
                    this.relateContainers$ = relateContainers.subscribe(([res1, res2]) => {
                        this.getRelatedMomentsFromSelectedMedia();
                        // Save secondary parent container of moment when a new moment is created
                        this.containerService
                            .save(this.selectedMediaContainer)
                            .subscribe(
                                res => {},
                                err => {
                                    console.error(err);
                                }
                            )
                    });
                },
                err => {
                    console.error(err);
                },
                () => {
                    this.isLoading = false;
                    this.saveMomentSubscr.unsubscribe();
                    this.addValidatedMedia();
                }
            );

    }

    handleMomentRemove(e) {
        let moment = e['container'];
        let parentContainer = this.container;
        let secondaryParentGuid = moment.data['secondary_parent_container_id'];
        let index = this.allMomentsWithChapters.indexOf(moment.data['secondary_parent_container_id']+"_"+moment.data['active_chapter_number']);
        if (index > -1) {
            this.allMomentsWithChapters.splice(index, 1);
            this.removeMomentFromDictionary(moment.data['secondary_parent_container_id'], moment.data['start_time_code'])
            if (this.selectedMediaContainer && moment.data['secondary_parent_container_id'] == this.selectedMediaContainer.guid) {
                this.cgService.updateCgState({
                    'activeMediaMoments': this.allMediaWithMoments[moment.data['secondary_parent_container_id']]
                });
            }
        }
        if (confirm('Are you sure you want to remove this moment?')) {
            this.isEditingMoment = true;
            this.removeMomentSubscr = this.containerService
                .unrelate(parentContainer, moment)
                .subscribe(
                    res => {
                        this.totalRecords -= 1;
                        this.getRelatedMomentsFromSelectedMedia();
                        // Save secondary parent container of moment when a moment is removed
                        this.containerService
                            .getByGuid(secondaryParentGuid, 'full', -1, false, true, true)
                            .subscribe(
                                res => {
                                    this.containerService
                                        .save(res)
                                        .subscribe(
                                            res => {},
                                            err => {
                                                console.log(err);
                                            }
                                        )
                                },
                                err => {
                                    console.log(err);
                                }
                            )
                    },
                    err => {
                        console.log(err);
                    },
                    () => {
                        this.removeMomentSubscr.unsubscribe();
                        this.removeValidatedMedia(e);
                        this.msgSrv.add({ key: 'ceMsg', severity: 'success', summary: 'Moment Removed', detail: '' });
                    }
                );
        }
    }

    getRawContainer(guid, source) {
        console.log('getRawContainer guid', guid);
        console.log('getRawContainer source', source);
        this.getRawContSubscr = this.containerService
            .getByGuid(guid, 'full', -1, false, true, true)
            .subscribe(
                res => {
                    this.activeMediaContainer = res;
                    this.activeMediaMoments = [];
                    this.selectedMediaContainer = this.activeMediaContainer;
                    if (this.allMediaWithMoments[guid]) {
                        this.activeMediaMoments = this.allMediaWithMoments[guid];
                    }
                    this.cgService.updateCgState({
                        'activeMediaContainer': this.activeMediaContainer,
                        'activeMoment': this.activeMoment,
                        'source': 'content-group-edit-1',
                        'userTimecode': this.activeTimecode,
                        'playerTimecode': 0,
                        'activeMediaMoments': this.activeMediaMoments,
                        'skipPlay': false
                    });
                },
                err => this.error = err,
                () => {
                    this.getRawContSubscr.unsubscribe();
                }
            );
    }

    onSubmit(e) {
        console.log('submit', e.model);
        this.isReLoading = true;
        this.saveContSubscr = this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.msgSrv.add({ key: 'ceMsg', severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                    this.msgSrv.add({ key: 'ceMsg', severity: 'error', summary: 'Error', detail: err.statusText });
                },
                () => {
                    this.isLoading = false;
                    this.saveContSubscr.unsubscribe();
                    this.getSegmentKeywords();
                    this.getSegmentOrigins();
                    this.isReLoading = false;
                }
            );
    }

    addMomentToDictionary(container_id, timecode) {
        if (this.allMediaWithMoments[container_id] && !this.allMediaWithMoments[container_id].includes(timecode)) {
            this.allMediaWithMoments[container_id].push(timecode);
        }
        if (!this.allMediaWithMoments[container_id]) {
            this.allMediaWithMoments[container_id] = [timecode];
        }
    }

    removeMomentFromDictionary(container_id, timecode) {
        if (this.allMediaWithMoments[container_id]) {
            let index = this.allMediaWithMoments[container_id].indexOf(timecode);
            if (index > -1) {
                this.allMediaWithMoments[container_id].splice(index, 1);
            }
        }
    }

    onCancel(e) {
        this.location.back();
    }

    onFailure(e) {
        this.msgSrv.add({ key: 'ceMsg', severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    addValidatedMedia() {
        // Save media_guid and validataion_status = true to the content group when creating a new moment
        let validatedMediaItemIndex = this.container['data']['validated_media'].findIndex(item => item['media_guid'] == this.selectedMediaContainer.guid);
        if (validatedMediaItemIndex < 0) {
            this.container['data']['validated_media'].push({'media_guid': this.selectedMediaContainer.guid, 'validation_status': true});
        } else {
            this.container['data']['validated_media'][validatedMediaItemIndex]['validation_status'] = true;
        }
        this.mediaTableComponent.validationStatus[this.selectedMediaContainer.guid] = true;
        this.saveValidatedMediaSubscr = this.containerService
            .save(this.container)
            .subscribe(
                res => {},
                err => {
                    console.error(err);
                },
                () => {
                    this.saveValidatedMediaSubscr.unsubscribe();
                }
            )
    }
    // When a last moment of selected media is removed, validation_status = false,
    // Otherwise, validation_status remains the same (validation_status = true)
    removeValidatedMedia(e) {
        let momentContainer = e['container']
        let validatedMediaItemIndex = this.container['data']['validated_media'].findIndex(item => item['media_guid'] == momentContainer['data']['secondary_parent_container_id']);
        if (validatedMediaItemIndex > -1) {
            this.commerceService
            .filterByPrimaryAndSecondaryParent(this.container.guid, momentContainer['data']['secondary_parent_container_id'])
            .subscribe(
                res => {
                    let moments = res;
                    let momentsCount = moments.length;
                    let momentsGuid = moments.map(moment => moment.guid);
                    if (momentsGuid.includes(momentContainer.guid)) {
                        momentsCount -=1;
                    }
                    if (momentsCount == 0) {
                        this.container['data']['validated_media'][validatedMediaItemIndex]['validation_status'] = false;
                        this.mediaTableComponent.validationStatus[momentContainer['data']['secondary_parent_container_id']] = false;
                    }
                },
                err => {
                    console.error(err);
                },
                () => {
                    this.saveValidatedMediaSubscr = this.containerService
                    .save(this.container)
                    .subscribe(
                        res => {},
                        err => {
                            console.error(err);
                        },
                        () => {
                            this.saveValidatedMediaSubscr.unsubscribe();
                        }
                    )
                }
            )
        }
    }

}
