import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Segment } from '../../models';
import { WorkflowStateSegmentFormComponent } from '../../forms/workflow-state-segment-form/workflow-state-segment-form.component';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
    selector: 'workflow-state-segment-create',
    providers: [
        SegmentService
    ],
    templateUrl: 'workflow-state-segment-create.component.html',
    styleUrls: ['workflow-state-segment-create.component.css']
})
export class WorkflowStateSegmentCreateComponent implements OnInit {
    @ViewChild('form') form: WorkflowStateSegmentFormComponent;

    public segment: Segment;
    public error: string;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private sub: any;
    private parentId: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private segmentService: SegmentService,
        private msgSrv: MessageService,
    ) { }

    ngOnInit(): void {
        this.segment = new Segment();
        this.segment.start_time_code = '0.0'
        this.segment.end_time_code = '0.0'
        this.sub = this.route.params.subscribe(params => {
            this.parentId = +params['containerId'];
        });
    }

    onSubmit(e) {
        if (!this.segment.data['indicator']) {
            this.segment.data['indicator'] = '';
        }
        this.segmentService
            .save(this.segment, this.parentId)
            .subscribe(
                res => {
                    console.log("segment submitted", this.segment)
                    this.clearMessages();
                    console.log(res);
                    this.router.navigate(['/containers', this.parentId, 'segments','workflow_state', res.id], { replaceUrl: true });
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgSrv.add({ key: 'ctMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })
                    }
                    console.log(err);
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: 'Error', detail: err.statusText });
                }
            );
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.clearMessages();
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });

    }

    clearMessages() {
        this.msgs = [];
        this.requiredMsgs = [];
    }

}