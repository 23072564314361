import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginFormComponent } from './login-form.component';
import { PageHeaderComponent } from '../ui/page-header.component';

@Component({
    selector: 'login',
    providers: [],
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
    private error: string;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit() {}

    onSubmit(e) {
        console.log('login submit', e);
        this.authService
            .login('token', e)
            .subscribe(
                res => {
                    this.authService.goToRedirectUrl();
                },
                err => {
                    console.log('error', err);

                    if (err.status === 400) {
                        this.error = 'Invalid username or password';
                    } else {
                        this.error = err.statusText;
                    }
                }
            );
    }
}
