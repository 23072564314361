import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Segment } from '../../models';
import { SegmentFormComponent } from '../../forms/segment-form/segment-form.component';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
    selector: 'segment-create',
    providers: [
        SegmentService
    ],
    templateUrl: 'segment-create.component.html',
    styleUrls: ['segment-create.component.css']
})
export class SegmentCreateComponent implements OnInit {
    @ViewChild('form') form: SegmentFormComponent;

    public segment: Segment = new Segment();
    private sub: any;
    private containerId;
    private segmentType;
    public error: string;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private segmentService: SegmentService,
        private msgSrv: MessageService,
    ) { }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.containerId = +params['containerId'];
            this.segmentType = params['segmentType'];
        });
        this.segment.type = this.segmentType;
    }

    onSubmit(e) {
        console.log('segment create submit', e);

        this.segmentService
            .save(e.segment, this.containerId)
            .subscribe(
                res => {
                    this.msgs = [];
                    this.requiredMsgs = [];
                    console.log(res);
                    this.router.navigate(['/containers', this.containerId, 'segments', res.id ], { replaceUrl: true });
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgSrv.add({ key: 'ctMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })
                    }
                    console.log(err);
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: 'Error', detail: err.statusText });
                }
            );
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
          }
    }

    onFailure(e) {
        this.msgs = [];
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

}