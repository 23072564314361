<div class="login-body">
    <div class="login-panel p-fluid">
        <div class="ui-g login-container" style="padding-bottom: 20px;">
            <div class="ui-g-12 logo-container">
                <img src="../assets/layout/images/vms-logo-green.png" class="morpheus-logo" />
            </div>
            <div *ngIf="greeting" class="ui-g-12 login-external-greeting">
                <p>{{greeting}}</p>
            </div>
            <div *ngIf="error" class="ui-g-12 login-external-error">
                <p>Error: {{error}}</p>
            </div>
        </div>
    </div>
</div>
