<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="csMsg"></p-toast>
        <p-toast key="caMsg"></p-toast>

        <p-toolbar class="ui-g-12">
          <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left"
                    style="margin-right:4px;"></button>
          </div>
          <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-catalog-form-save"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="name">Label</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['label']" name="label" placeholder="Required" required data-element-id="app-content-forms-catalog-form-name">
                </div>
            </div>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="name">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-content-forms-catalog-form-description">
                </div>
            </div>

        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-content-forms-catalog-form-type">
                </div>
                <div class="ui-g-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-content-forms-catalog-form-guid">
                </div>
                <div class="ui-g-4">
                    <label for="type">Catalog Type</label>
                    <select [(ngModel)]="container.data.catalog_type" name="catalogtype" class="form-control" data-element-id="app-content-forms-catalog-form-catalog-type">
                        <option *ngFor="let catalogType of catalogTypes" [value]="catalogType">{{ catalogType }}</option>
                    </select>
                </div>
                <div class="ui-g-8">
                    <label for="origin">Bundle Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.bundle_id" name="bundle" placeholder="Required" required data-element-id="app-content-forms-catalog-form-bundle-id">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2">
                    <label for="enable_http_basic_authentication">Enable Http Basic Authentication&emsp;</label>
                    <p-checkbox name="checkbox" [(ngModel)]="container.data.requires_basic_authentication" binary="true"></p-checkbox>
                    </div>
                <div class="ui-g-10">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>


        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Parameters</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
            </div>
            <div class="ui-g-2">
                <label for="type">Query Type</label>
                <select [(ngModel)]="container.data.query_type" name="querytype" class="form-control" data-element-id="app-content-forms-catalog-form-query-type">
                    <option *ngFor="let queryType of queryTypes" [value]="queryType">{{ queryType }}</option>
                </select>
            </div>
            <div class="ui-g-2">
                <label for="origin">Page Size</label>
                <input type="text" class="form-control" [(ngModel)]="container.data.page_size" name="Paging" data-element-id="app-content-forms-catalog-form-page-size">

            </div>
            <div class="ui-g-2">
                <label for="origin">Total Query Count</label>
                <input type="checkbox" class="form-control" [(ngModel)]="container.data.total_query_count" name="TotalQueryCount" data-element-id="app-content-forms-catalog-form-total-query-count">

            </div>
            <div class="ui-g-3">
                <label for="origin">Ordering</label>
                <input type="text" class="form-control" [(ngModel)]="container.data.ordering" name="Ordering" data-element-id="app-content-forms-catalog-form-ordering">
            </div>
            <div class="ui-g-3">
                    <label for="type">Endpoint Content Type</label>
                    <select [(ngModel)]="container.data.content_type" name="contenttype" class="form-control" data-element-id="app-content-forms-catalog-form-endpoint-content-type">
                        <option *ngFor="let contentType of contentTypes" [value]="contentType">{{ contentType }}</option>
                    </select>
            </div>
        </p-panel>
    </div>
</form>
