<div (window:resize)="onResize()" class="ui-g-12 ui-g-nopad ml-7">
    <div class="keywords-container">
      <div *ngIf="keywords" class="keywords">
        <button type="button" class="filterButton" icon="fa fa-filter" (click)="showAllKeywords()"><i class="fa fa-eye"></i></button>
        <span *ngFor="let item of keywords; let i=index"
              class="text-white ml-1 pill {{item.level}}"
              [class.inactive]="!item.active"
              (click)="filterKeywords(item)"
              [ngStyle]="{'background-color': item.active ? item.color : '#cdcdcd'}">
              {{item.key|lowercase}}
        </span>
      </div>
    </div>
</div>
<div #canvasWrapper class="ui-g-12">
    <section [ngStyle]="{'width':'100%', 'display':'block', 'overflow': 'hidden'}" class="timeline">
      <spinner *ngIf="isLoading"></spinner>
      <div [ngClass]="{'hidden' : isLoading}">
        <canvas  #canvas 
            [attr.width]="config.ctxWidth"
            [attr.height]="config.ctxHeight"
            (click)="moveToFrame($event)"
            class="mr-1">
        </canvas>
      </div>
    </section>   
</div>
<ng-content></ng-content>



