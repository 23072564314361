import { environment } from '../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { LastUpdateService } from '../lastupdate/lastupdate.service';
import { CacheService } from '../cache/cache.service';
import { PageHeaderComponent } from '../ui/page-header.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { EpochDatePipe } from './epochdate.pipe';

// webpack_hash set by webpack but must declare to prevent error
//declare var __webpack_hash__: any;

@Component({
    selector: 'status',
    providers: [
        LastUpdateService,
        CacheService
    ],
    templateUrl: 'status.component.html'
})
export class StatusComponent implements OnInit {
    public lastUpdate: any;
    private isLoading = false;
    public error: string;
    //private hash: string = __webpack_hash__;
    // private environment: string = environment.ENV;
    // private buildDate: Date = new Date(environment.BUILD_DATE);

    constructor(
        public lastUpdateService: LastUpdateService,
        private cacheService: CacheService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.lastUpdateService
            .get()
            .subscribe(
                (res) => {
                    this.lastUpdate = res;
                    console.log(res);
                },
                (err) => {
                    this.error = err.statusText;
                    console.log(err);
                },
                () => this.isLoading = false
            );
    }

    onForceUpdate($event) {
        if (confirm('Are you sure you want to force an update?')) {
            console.log('forcing update');

            this.isLoading = true;
            this.lastUpdateService
                .force()
                .subscribe(
                    (res) => {
                        console.log(res);
                        this.lastUpdate = res;
                    },
                    (err) => {
                        this.error = err.statusText;
                        console.log(err);
                    },
                    () => this.isLoading = false
                );
        }
    }

    onClearCache($event) {
        if (confirm('Are you sure you want to clear the cache?')) {
            console.log('clearing cache');

            this.isLoading = true;
            this.cacheService
                .clear()
                .subscribe(
                    (res) => console.log(res),
                    (err) => {
                        this.error = err.statusText;
                        console.log(err);
                    },
                    () => this.isLoading = false
                );
        }
    }
}
