import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { SelectItem } from 'primeng/api';


@Component({
  selector: 'notifications',
  providers: [
    ContainerService
],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  public notificationContainers = [];
  public sortField: string;
  public sortOrder: number;
  private statusList: Array<SelectItem> = [];
  private selectedStatus: SelectItem = null;
  public filteredStatus = 'published';
  public isLoading:boolean

  constructor(private containerService: ContainerService) {}

  ngOnInit() {
    this.isLoading = true;
    this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }))
    if (this.filteredStatus) {
      this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
    }
    this.loadResults()
  }

  loadResults() {
    this.containerService.filterByTypeName('notification', 'full', -1, false, this.filteredStatus).subscribe(
      res => this.notificationContainers = res,
      err => this.isLoading = false,
      () =>  this.isLoading = false
    )
  }

  onChangedFilteredStatus(event) {
    this.filteredStatus = event.value ? event.value.value : '';
    this.loadResults();
  }
}
