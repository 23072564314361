<div clss="ui-g-12" style="padding:0;margin:0;width:calc(100% + 40px);height:30px;position:relative">
    <div style="padding:0;margin:0;width:100%;height:50px;position:absolute;top:-4px;left:-20px">
        <ngx-charts-area-chart-stacked
                [scheme]="colorScheme"
                [legend]="legend"
                [animations]="false"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [timeline]="timeline"
                [results]="chartData"
                (select)="onSelect($event)">
        </ngx-charts-area-chart-stacked>
    </div>
</div>