import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'keyvalue-form',
    templateUrl: 'keyvalue-form.component.html'
})
export class KeyValueFormComponent {
    @Input() model: any = {
        title: '',
        description: ''
    };
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();

    constructor() {}

    onSubmit(e) {
        console.log('submit');
        this.formSubmit.emit({
            model: this.model
        });
    }
}
