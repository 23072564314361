import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'object-advancedview-grid',
    templateUrl: 'object-advancedview-grid.component.html',
    styleUrls: ['object-advancedview-grid.component.css']
})
export class ObjectAdvancedViewGridComponent implements OnInit {
    @Input() object: Object;
    @Input() title: string;
    @Input() toggleable = true;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    public iterableData: Array<any>;
    private error: string;
    private sortKey: string;
    private sortField: string;
    private sortOrder: number;

    constructor() { }

    ngOnInit() {
        this.iterableData = this.mapObjectToIterable(this.object);
    }

    mapObjectToIterable(object: Object): Array<any> {
        var a = [];
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                a.push({ key: key, value: object[key] });
            }
        }
        return a;
    }

    addProperty(newProperty: string) {
        if (newProperty && !this.object.hasOwnProperty(newProperty)) {
            console.log('Adding new property to policy:', newProperty);
            this.object[newProperty] = '';
            this.iterableData = this.mapObjectToIterable(this.object);
        }
    }


    deleteProperty(property: string) {
        console.log('deleting')
        console.log('property')
        if (confirm('Are you sure you want delete this field?')) {


            if (property && this.object.hasOwnProperty(property)) {
                console.log('removing propery:', property);
                delete this.object[property];
                this.iterableData = this.mapObjectToIterable(this.object);
            }
        }
    }

    onEditComplete(e) {
        this.object[e.data.key] = e.data.value;
        this.onChange.emit({
            object: this.object
        });
    }
}
