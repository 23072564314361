import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Segment } from '../../models';
import { ContainerService } from '../../../content/services';
import { Message } from 'primeng/api';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
    selector: 'segment-form',
    templateUrl: 'segment-form.component.html',
    providers: [
        DatePipe
    ],
    styleUrls: ['segment-form.component.css']
})
export class SegmentFormComponent implements OnInit {
    @Input() segment: Segment;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    public isLoading = false;
    public statusList = ContainerService.statusList
    public segmentTypes = SegmentService.types

    private expirationDate :Date;
    private publishDate :Date;
    private msgs: Message[] = [];

    constructor(
        private datePipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.publishDate = this.getDateFromDateString(this.segment.published_date);
        this.expirationDate = this.getDateFromDateString(this.segment.expiration_date);
      }

    getDateFromDateString(dateString) {
        if (!dateString) {
            return null;
        }
        let date = new Date (dateString);
        return date
    }

    // Backend expects published_date and expiration_date to be saved in certain date formats
    // TODO: we may need to normalize the dates at some point
    formatDate(date) {
        if (!date) {
            return null;
        }
        let dateString = date.toISOString();
        const format = "yyyy-MM-dd'T'HH:mm:ss.SSSSSSZ";
        let formattedDate = this.datePipe.transform(dateString, format);
        return formattedDate
    }

    onSubmit(e) {
        this.segment.published_date = this.formatDate(this.publishDate);
        this.segment.expiration_date = this.formatDate(this.expirationDate);

        console.log('segment submitted', this.segment);
        this.msgs = [];
        var valid = this.ngForm.form.status === 'VALID';

        if (!/\S/.test(this.segment.label)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment label.' });
            valid = false;
        }

        if (!/\S/.test(this.segment.value)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment value.' });
            valid = false;
        }

        if (!/\S/.test(this.segment.start_time_code)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment start time code.' });
            valid = false;
        }

        if (!/\S/.test(this.segment.end_time_code)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment end time code.' });
            valid = false;
        }

        if (!/\S/.test(this.segment.type)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the segment type.' });
            valid = false;
        }

        if (valid) {
            this.ngForm.form.markAsPristine();
            this.formSubmit.emit({
                segment: this.segment
            });
        } else {
            this.formFailed.emit();
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

}
