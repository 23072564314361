<div class="card card-w-title top-level-card">
    <h1>Edit Distribution{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>

    <page-header title="Edit Distribution{{!isLoading && container ? ': ' + container.data['name'] : ''}}" [showSpinner]="isLoading"
        [error]="error?.statusText"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <distribution-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)"
            (formCancelled)="onBack($event)"></distribution-form>

        <hr>

        <div class="ui-g-12">
                <platform-table [containers]="platforms" [parentContainer]="container">
                    <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'platform']" icon="pi pi-plus"
                        style="float:right;height:100%;"></button>
                </platform-table>
            </div>

        <hr>

        <div class="ui-g-12">
            <container-paginated-datarow type="view" itemsPerPage=20 [parentContainer]="container" status="" title="Views" filterBy="data.name, data.view_id" (reorder)="onReorder($event)">
                <button pButton type="button" label="Create" icon="pi pi-plus" [routerLink]="['/settings/view', 'create', container?.id]" style="float:right;margin-left:4px;height:100%;"></button>
                <button pButton type="button" label="Relate" icon="pi pi-plus" [routerLink]="['/containers', container?.id, 'relate', 'view']"
                    style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow (onUpdate)="refetchContainer()" type="configuration" itemsPerPage=20 [parentContainer]="container" status="" title="Configurations"
                filterBy="data.name, data.max_version, data.min_version" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" icon="pi pi-plus" [routerLink]="['/configurations', 'create', container?.id]" style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow (onUpdate)="refetchContainer()" type="version" itemsPerPage=20 [parentContainer]="container" status="" title="Versions"
                filterBy="data.name, data.number" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" icon="pi pi-plus" [routerLink]="['/versions', 'create', container?.id]" style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <policy-table [parentContainer]="container">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'policy']" icon="pi pi-plus"
                    style="float:right;height:100%;"></button>
            </policy-table>
        </div>

        <hr>

        <div class="ui-g-12">
          <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
