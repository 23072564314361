<page-header title="New Notification" [showSpinner]="isLoading"></page-header>
<div *ngIf="container" class="card card-w-title top-level-card">
  <h1>New Notification</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <notification-form #form [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></notification-form>
    </div>
  </div>
</div>


