<div class="card card-w-title top-level-card">
    <h1>Featured Content</h1>
    <page-header [showSpinner]="isLoading" [error]="error"></page-header>

    <div class="ui-g card-content">
        <p-tabView class="ui-g-12 ui-md-12">
            <p-tabPanel *ngFor="let container of containers; let i = index"
                        [header]="container.data['name']" 
                        leftIcon="pi pi-check"
                        [selected]="i==0">

                <!-- <p-header>
                    <div class="ui-g-10 ui-md-10"></div>
                    <div class="label label-default ui-g-2">{{container.data.supported_platforms}}</div>
                </p-header> -->

                <div *ngIf="featuredViews[i]">
                    <p-toolbar>
                        <div class="ui-g-4">
                            <div class="label label-default ui-g-2">{{container.data.supported_platforms}}</div>
                        </div>
                        <div class="ui-g-8">
                            <button pButton type="button" label="Edit" [routerLink]="['/settings/view', featuredViews[i].id]" icon="pi pi-pencil" style="float:right;margin-left:4px;"></button>
                            <button pButton type="button" label="Collection" [routerLink]="['/containers', featuredViews[i].id, 'relate' ,'collection']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                            <button pButton type="button" label="Rotator" [routerLink]="['/containers', featuredViews[i].id, 'relate' ,'rotator']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                            <button pButton type="button" label="Series" [routerLink]="['/containers', featuredViews[i].id, 'relate' ,'series']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        </div>
                    </p-toolbar>
                </div>

                <div *ngIf="featuredViews[i]" class="card-content">
                    <container-collection [container]="featuredViews[i]" [controlsActive]="!isLoading" (reorder)="onReorder($event)"></container-collection>
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
</div>
