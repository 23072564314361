import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';     // May use in future revisions
import { AuthService } from './auth.service';
import { PageHeaderComponent } from '../ui/page-header.component';  // Required?

@Component({
    selector: 'login-external',
    providers: [],
    templateUrl: 'login-external.component.html',
    styleUrls: ['login.component.css', 'login-external.component.css']
})
export class ExternalLoginComponent implements OnInit {
    public error: string;
    public greeting: string;

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        const providerName = this.route.snapshot.paramMap.get('provider');
        if (this.authService.isValidProvider(providerName)) {
            const providerNameFull = this.authService.getAuthFullName(providerName);
            this.greeting = `Logging in via ${providerNameFull}...`;
            // Trigger redirect or exchange for token
            // After success (presuming we don't redirect offsite), goto desired/default page
            this.authService
                .login(providerName, { href: window.location.href })
                .subscribe(
                    res => {
                        this.authService.goToRedirectUrl();
                    },
                    err => {
                        try {
                            err = JSON.parse(err);
                        } catch (e) {
                            // Leave as-is
                        }
                        console.log(`${providerNameFull} login error:`, err);
                        this.error = err.statusText || err.error || err;
                    }
                );
        } else {
            console.log(`Auth provider "${providerName}" not found or disabled`);
            this.error = `Unknown authentication provider "${providerName}"`;
        }

    }
}
