<spinner *ngIf="isLoading"></spinner>
<p-messages [(value)]="msgs"></p-messages>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">

    <div class="ui-g">
        <div class="ui-g-12">
            <textarea [rows]="2" class="formTitleInputText" [(ngModel)]="container.data['label']" name="label" pInputTextarea autoResize="autoResize"></textarea>
        </div>

        <div class="ui-g-12">
            <label for="description">Description</label>
            <textarea [rows]="3" class="form-control"  pInputTextarea [(ngModel)]="container.data.description" name="description" autoResize="autoResize"></textarea>
        </div>
        
        <div class="ui-g-6">
            <label for="author">Author</label>
            <input type="text" class="form-control" [(ngModel)]="container.data.author" name="author">
        </div>

        <div class="ui-g-6">
            <label for="guid">GUID</label>
            <input type="text" class="form-control" [(ngModel)]="container.guid" name="guid" placeholder="Required" disabled="disabled">
        </div>

        <div class="ui-g-12">
            <label for="keywords" style="display:block;">Keywords</label>
            <segment-keyword-autocomplete [(container)]=container (onKeywordChange)="onKeywordChange($event)"></segment-keyword-autocomplete>
        </div>
        <div class="ui-g-12">
            <label for="Origins">Origins</label>
            <origin-select [(container)]=container (onOriginChange)="onOriginChange($event)"></origin-select>
        </div>
        <div *ngIf="container.id > 0" class="ui-g-12">
            <history [inputObject]="container" inputType='container'></history>
        </div>
    </div>


    <div class="ui-g">
        <p-accordion class="ui-g-12">
            <!--
            <p-accordionTab header="Availability" [selected]="false">
                <div class="ui-g">
                    <div class="ui-g-6">
                        <label for="availableDate" style="display:block;">Available Date</label>
                        <p-calendar [(ngModel)]="container.availableDate" name="availableDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="expirationDate" style="display:block;">Expiration Date</label>
                        <p-calendar [(ngModel)]="container.expirationDate" name="expirationDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="publishDate" style="display:block;">Publish Date</label>
                        <p-calendar [(ngModel)]="publishDate" name="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                </div>
            </p-accordionTab>
            -->
            <p-accordionTab header="Tags">
                <div class="ui-g">
                    <div class="ui-g-12">
                        <label for="tags">Tags</label>
                        <container-tags-tab #maintags [container]="container" [tagTypeFilterList]=tagTypeFilterList [tagTypeExcludeList]="['syndication']"></container-tags-tab>    
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <p-toolbar class="ui-g-12">
        <div class="ui-g-12" style="z-index: 0">
            <button pButton type="submit" label="Save" [disabled]="container.status == 'waiting' || container.status == 'processing'" style="font-size:13px;float:left;margin-right:.5em"></button>       
            <button pButton type="button" label="Edit" [disabled]="container.status == 'waiting' || container.status == 'processing'" [routerLink]="['/commerce/contentgroup', container.id]" style="font-size:13px;float:left;"></button>
            <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
        </div>
    </p-toolbar>
    <div class="ui-g-12">
            <moment-table [rows] = 10
                          [inputContainer]="container"
                          [totalRecords]="container.containers.length">
            </moment-table>
    </div>

</form>
