import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { SegmentFormComponent } from '../../forms/segment-form/segment-form.component';
import { SegmentService } from '../../services/segment/segment.service';
import { Segment } from '../../models/segment';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'segment-edit',
    providers: [
        SegmentService
    ],
    templateUrl: 'segment-edit.component.html',
    styleUrls: ['segment-edit.component.css']
})
export class SegmentEditComponent implements OnInit {
    @ViewChild('form') form: SegmentFormComponent;

    public isLoading = false;
    public segment: Segment;
    public error: HttpResponse<any>;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];

    private sub: any;
    private parentId: number;

    constructor(
        private route: ActivatedRoute,
        public segmentService: SegmentService,
        private location: Location,
        private msgSrv: MessageService,
    ) { }

    ngOnInit(): void {
        this.sub= this.route.params.subscribe(params => {
            let id = +params['segmentId'];
            this.parentId = +params['parentId'];

            this.loadSegmentData(id);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    loadSegmentData(id) {
        this.isLoading = true;
        this.segmentService
            .get(id)
            .subscribe(
                res => {
                    this.segment = res;
                    this.isLoading = false;
                }
            ),
            err => this.error = err,
            () => {}
    }

    onSubmit(e) {
        this.segmentService
            .save(this.segment, this.parentId)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.segment = Object.assign(new Segment(), this.segment);
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgSrv.add({ key: 'ctMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })
                    }
                    console.log(err);
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: 'Error', detail: err.statusText });
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }

    onFailure(e) {
        this.msgs = [];
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

}
