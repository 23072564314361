<page-header title="Edit Creative Container" [showSpinner]="isLoading"></page-header>
<div *ngIf="container" class="card card-w-title top-level-card">
  <h1>Edit Creative Container{{!isLoading && container ? ' : ' +container.data.label : ''}}</h1>
  <div class="ui-g card-content">
    <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    <div class="ui-g-12">
      <creative-form #form [container]="container" (formSubmit)="onSubmit(container)" (formCancelled)="onCancel($event)" (formFailed)="onFail($event)"></creative-form>
    </div>
  </div>
    <div class="p-mt-5 p-p-2">
      <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
    </div>
</div>
