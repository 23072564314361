<div *ngIf="container" [dragula]="name" [(dragulaModel)]="container.containers" class="collection ui-g">
    <p-panel *ngFor="let child of container?.containers; let i = index" class="ui-g-12">
        <p-header>
            <div class="ui-g">
                <div class="ui-g-8" style="padding:0;">
                    <div class="header__handle" style="display:inline-block;margin-left:10px;">
                        <div class="handle glyphicon glyphicon-th">
                        </div>
                    </div>
                    <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:0px;margin-left:10px;line-height:16px;">{{child.data.title}}</span>
                </div>

                <div class="ui-g-4" style="padding:0;">
                    <div class="label label-default">{{child.type}}</div>
                    <button *ngIf="child.type == 'series'" pButton type="button" class="p-button-success" [routerLink]="['/series', child.id]" label="Edit" icon="pi pi-pencil" style="float:right;margin-left:4px;"></button>
                    <button *ngIf="child.type == 'collection'" pButton type="button" class="p-button-success" [routerLink]="['/collections', child.id]" label="Edit" icon="pi pi-pencil" style="float:right;margin-left:4px;"></button>
                    <button *ngIf="child.type == 'rotator'" pButton type="button" class="p-button-success" [routerLink]="['/containers', child.id]" label="Edit" icon="pi pi-pencil" style="float:right;margin-left:4px;"></button>
                    <button *ngIf="child.type != 'rotator'" pButton type="button" class="p-button-danger" (click)="onRemove($event, child)" label="Remove" icon="pi pi-times" style="float:right;margin-left:4px;"></button>
                </div>
            </div>
        </p-header>

        <collection-row  *ngIf="child" [containers]="child.containers" [parentContainer]="child" (reorder)="bubbleReorder($event)"></collection-row>

        <!-- <container-row [containers]="child.containers" [parentContainer]="child" (reorder)="bubbleReorder($event)"></container-row>
        -->
    </p-panel>
</div>
