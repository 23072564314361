import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'epochDate'
})
export class EpochDatePipe implements PipeTransform {
    transform(value: any): Date {
        let milliseconds = parseInt(value, 10) * 1000;
        return new Date(milliseconds);
    }
}
