import { Component, AfterContentInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DragulaService } from 'ng2-dragula';
import { TagService } from '../../services/tag/tag.service';
import { Tag } from '../../models/tag';
import { Subscription } from 'rxjs';


@Component({
    selector: 'collection-row',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        TagService
    ],
    templateUrl: 'collection-row.component.html',
    styleUrls: ['collection-row.component.css']
})
export class CollectionRowComponent implements AfterContentInit, OnDestroy {
    @Input() containers: Array < any > = [];
    @Input() parentContainer: Container;
    @Output() reorder: EventEmitter < any > = new EventEmitter();
    @Output() pin: EventEmitter < any > = new EventEmitter();

    public isPinned: boolean = false;
    // TODO: implement controlsActive check

    private name: string = '';
    private dragulaSub: Subscription;

    constructor(
        private dragulaService: DragulaService,
        private containerService: ContainerService,
        private tagService: TagService
    ) {}

    ngAfterContentInit() {
        console.log('container row initialized', this.dragulaService);

        this.isPinned = false;

        this.name = Math.random()
            .toString(36)
            .substring(7);

        this.dragulaService.createGroup(this.name, {});

        this.dragulaSub = this.dragulaService.dropModel(this.name)
            .subscribe((e) => {
                console.log('drop', e);
                this.onDrop(e);
        });
    }

    ngOnDestroy() {
        this.dragulaSub.unsubscribe();
    }

    private onDrop(e) {
        if (this.parentContainer) {
            let el = e['el'];
            let droppedItem = e['item'];
            let oldIndex = this.containers.indexOf(droppedItem);
            let newIndex = this.getElementIndex(el);
            // depending on the direction of dragging,
            // previous item can be retrieved by either getting using the new index
            // of dragged item of the old container array, or by using new index - 1
            let prevItemIndex = oldIndex > newIndex ? newIndex - 1 : newIndex;
            let droppedAfterItem = prevItemIndex >= 0 ? this.containers[prevItemIndex] : null;

            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: droppedItem,
                to: droppedAfterItem
            });
        }
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++);
        return i;
    }

    private onRemove(container) {
        console.log('remove', container);
        let i = this.containers.indexOf(container);
        this.containers.splice(i, 1);

        // this.containerService
        //     .unrelate(this.parentContainer, container)
        //     .subscribe(
        //         res => console.log(res),
        //         err => console.log(err)
        //     );
    }

    private onUnPin(e, container) {
        if (confirm('Are you sure you want to unpin this container?')) {

            this.isPinned = true
            this.tagService
                .deleteById(container['data']['source_status'])
                .subscribe(
                    res => {
                        console.log(res);
                        this.pin.emit(e);
                    },
                    err => console.log(err),
                    () => this.isPinned = false
                );
        }
    }

    private onPin(e, container) {
        if (confirm('Are you sure you want to pin this container?')) {
            this.isPinned = true
            var pinnedTag = new Tag();
            pinnedTag.id = -1;
            pinnedTag.value = 'pinned';

            this.tagService
                .saveTagOnRelationship(pinnedTag, this.parentContainer.id, container.id, 'relationship')
                .subscribe(
                    res => {
                        console.log(res);
                        this.pin.emit(e);
                    },
                    err => {
                        console.log(err);
                        // this.error = err.statusText;
                    },
                    () => this.isPinned = true
                );

        }
    }

}
