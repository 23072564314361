<page-header [showSpinner]="isLoading" [error]="error?.statusText">
</page-header>
<div *ngIf="!isLoading" class="worklist-component">  
    <div class="ui-g-7 worklist-table-view">
        <contextual-ads-campaign-search-table 
            [defaultSearchTerm]="defaultSearchTerm" 
            [containers]="this.containers"
            [selectedContainer]="selectedContainer" 
            (rowSelect)="containerSelected($event)">
    </contextual-ads-campaign-search-table>
    </div>  
    <div class="ui-g-5 worklist-form-view" *ngIf="selectedContainer">
        <contextual-ads-campaign-form 
            [containerId]="selectedContainer.id" 
            [seasons]="[]" 
            (formSubmit)="onSubmit($event)" 
            (onContainerUpdate)="onContainerUpdate($event)"></contextual-ads-campaign-form>
    </div>
</div>