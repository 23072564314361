import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UploadService } from './upload.service';

@Component({
    selector: 'uploader',
    providers: [
        UploadService
    ],
    templateUrl: 'upload.component.html',
    styleUrls: ['upload.component.css']
})
export class UploadComponent implements OnInit {
    @Output() uploadComplete: EventEmitter<any> = new EventEmitter();
    public uploading: boolean = false;
    public error: boolean = false;
    public errorDesc: any;
    public disabled: false;
    constructor(
        private uploadService: UploadService
    ) { }

    ngOnInit() { }

    onSubmit(e) {
        this.error = false
        this.uploading = true
        e.preventDefault();
        console.log('upload submit', e);
        // e.srcElement doesn't work in firefox
        var srcElement = e.srcElement || e.target;

        var file = srcElement[0].files[0];
        if (!file) { this.uploading = false; return }
        return this.uploadService
            .upload(file)
            .subscribe(
                res => {
                    console.log('upload component', res);
                    this.uploading = false;
                    let ev = {
                        url: res.url,
                        asset_url: res.asset_url,
                        format: this.uploadService.getFileExtension(res.url),
                    };
                    let source_uri = this.uploadService.getS3UriFromUrl(res.url);
                    if (source_uri) {
                        ev['source_uri'] = source_uri;
                    }
                    this.uploadComplete.emit(ev);
                },
                err => {
                    console.log(err)
                    this.error = true;
                    this.uploading = false;
                    this.errorDesc = err;
                }
            );
    }

    onChange(e) {
        console.log('upload change', e);
    }
}
