import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';
//import { MenuItem } from 'primeng/api';
//import { registerLocaleData } from '@angular/common';

@Component({
    selector: 'movies',
    providers: [
        ContainerService
    ],
    templateUrl: 'movies.component.html',
    styleUrls: ['movies.component.css']
})
export class MoviesComponent implements OnInit {
    public containers: Array<Container> = [];
    public isLoading = false;
    private error: string;
    //private sortOptions: Array<any>;
    public sortField: string;
    public sortOrder: number;
    //breadcrumbItems: MenuItem[];
    private status: string = 'published';

    constructor(
        private containerService: ContainerService
    ){}

    ngOnInit() {
        //this.breadcrumbItems = [];
        //this.breadcrumbItems.push({ label: 'Movies' });

        // this.sortOptions = [
        //     {label: 'Title A-to-Z', value: 'title'},
        //     {label: 'Title Z-to-A', value: '-title'},
        //     {label: 'Newest to Oldest', value: '-created_date'},
        //     {label: 'Oldest to Newest', value: 'created_date'},
        // ];

        this.sortField = 'label';
        this.sortOrder = 1;
        this.loadPage();
    }

    loadPage() {
        this.isLoading = true;
        this.containerService
            .clientSearch('movie', this.status, [this.sortField])
            .subscribe(
                res => {
                    console.log(res.results);
                    this.containers = res.results;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onStatusSelectionChange($event) {
        this.status = $event.status;
        this.loadPage();
    }
}
