import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType } from '../../../content/models';
import { SchemaService } from '../../../content/services/schema/schema.service';
import { Message } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'entitytype-edit',
  templateUrl: './entitytype-edit.component.html',
  styleUrls: ['./entitytype-edit.component.css']
})
export class EntitytypeEditComponent implements OnInit {
  public isLoading = false;
  public entityType: any;
  private sub: any;
  private requiredMsgs: Message[] = [];
  public schema: string = '';

  constructor(
    private route: ActivatedRoute,
    private entityTypeService: EntityTypeService,
    private schemaService: SchemaService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.sub = this.route.params.subscribe(params => {
      let id = +params['id']; // + converts string to number
      this.entityTypeService
        .get(id)
        .subscribe(
          res => {
            this.entityType = res
            this.getAssignedSchema();
          },
          err => console.log(err),
          () => this.isLoading = false
        );
    });
  }

  getAssignedSchema() {
    this.isLoading = true;
    this.schemaService
      .list('', 'label', 'false') // loads assigned schemas
      .subscribe(
        (res: any) => {
          for (var key in res.results) {
            if (res.results[key]['type']['name'] == this.entityType['name']) {
              this.schema = res.results[key]['label']
            }
          }
        },
        err => console.log(err),
        () => this.isLoading = false
      );
  }

  onSubmit(e) {
    this.entityTypeService
      .update(this.entityType)
      .subscribe(
        res => {
          console.log(res);
          this.requiredMsgs = [];
          this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
          this.entityType = Object.assign(new EntityType(), this.entityType);
        },
        err => {
          console.log(err);
        },
        () => this.isLoading = false
      );

  }

  onCancel(e) {
    this.location.back();
  }
}
