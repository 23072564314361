<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container">
    <div class="ui-g">
        <div class="ui-g-8">
          <textarea [rows]="2"
            class="formTitleInputText"
            [(ngModel)]="container.data['label'] ? container.data['label'] : container.data['name'] ? container.data['name'] : container.data['title']"
            name="container.data['label'] ? 'label' : container.data['name']? 'name':'title'"
            pInputTextarea
            autoResize="autoResize" readonly>
          </textarea>
        </div>
        <div class="ui-g-4" style="text-align:center">
            <img *ngIf="getThumbnailUrl()" style="max-height:8em;width:auto;max-width:100%" src="{{getThumbnailUrl()}}">
            <img *ngIf="!getThumbnailUrl()"  style="max-height:8em;width:auto;max-width:100%" src="/assets/layout/images/player-poster.png"/>
        </div>

        <div class="ui-g-12" *ngIf="container.data.hasOwnProperty('name')">
          <label for="name">Name</label>
          <input type="text" class="form-control" [(ngModel)]="container.data.name" name="name" placeholder="Required" required readonly>
        </div>

        <div class="ui-g-12" *ngIf="container.data.hasOwnProperty('label')">
            <label for="label">Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data.label" name="label" placeholder="Required" required readonly>
        </div>

        <div class="ui-g-12" *ngIf="container.data.hasOwnProperty('title')">
          <label for="title">Title</label>
          <input type="text" class="form-control" [(ngModel)]="container.data.title" name="title" placeholder="Required" required readonly>
      </div>

        <div class="ui-g-12">
            <label for="typeName">Type</label>
            <input type="text" class="form-control" [(ngModel)]="container.type.name" name="typeName" disabled>
        </div>


        <div class="ui-g-12">
            <label for="guid">GUID</label>
            <input type="text" class="form-control" [(ngModel)]="container.guid" name="guid" placeholder="Required" readonly>
        </div>

        <div class="ui-g-6">
          <label for="origin">Origin</label>
          <input type="text" class="form-control" [(ngModel)]="container.origin" name="origin" placeholder="Required" readonly>
        </div>

        <div class="ui-g-6" style="margin: left 15em;">
          <label for="publishDate" style="display:block;">Publish Date</label>
          <p-calendar [(ngModel)]="publishDate" name="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" [ngModelOptions]="{standalone: true}"></p-calendar>
        </div>


        <div *ngIf="container.id > 0" class="ui-g-12">
            <history [inputObject]="container" inputType='container'>
            </history>
        </div>


    </div>
    <p-toolbar class="ui-g-12">
        <div class="ui-g-12" style="z-index: 0">
            <button pButton type="submit" label="Save" disabled style="font-size:13px;float:left;margin-right:.5em"></button>
            <button *ngIf="container.type.name == 'rotator'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
            <button *ngIf="container.type.name == 'view'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/view', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
            <button *ngIf="container.type.name == 'document'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/document', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>

            <div *ngxPermissionsOnly="['view.show.content']">
              <button *ngIf="container.type.name == 'season'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
            </div>

            <div *ngxPermissionsOnly="['view.show.commerce']">
              <button *ngIf="container.type.name == 'campaign'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/campaign', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'content_group'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/contentgroup', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'moment'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/moment', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'flight'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/flights', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
            </div>

            <div *ngxPermissionsOnly="['view.show.settings']">
              <button *ngIf="container.type.name == 'pipeline'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/pipelines', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'worker'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/workers', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'server'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/server', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'profile'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/profiles', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'application'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/applications', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'catalog'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/catalog', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'configuration'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/configurations', container.id, '']" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'distribution'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/distributions', container.id, '']" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'platform'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/platform', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'policy'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/policy', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'role'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/roles', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'version'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/versions', container.id, '']" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'origin'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/origins', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'provider'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/providers', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'user_profile'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/user/profiles', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'report'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/reports', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'workflow'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/workflow', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="container.type.name == 'creative'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/creative', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
              <button *ngIf="dedicatedEditForms.includes(container.type.name) == false" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="float:right;margin-right:4px;"></button>
            </div>
        </div>
    </p-toolbar>

</form>
