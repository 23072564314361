import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { generateListOfYears } from '../../../shared/utilities';

@Component({
  selector: 'container-year-pulldown',
  templateUrl: './container-year-pulldown.component.html',
})
export class ContainerYearPulldownComponent implements OnInit {

    public yearList: number[] = [];
    public selectedYear: number;

    @Input() ngModel;
    @Input() descending:boolean = false;

    constructor() { }

    ngOnInit(): void {
        this.selectedYear = this.ngModel;
        this.yearList = generateListOfYears(this.descending);
    }

    onChangeSelectedValue(event) {
        this.ngModel = this.selectedYear;
    }
}
