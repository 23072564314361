export const genre = [
    {'label':'<None>','value':''},
    {'label':'Action','value':'action'},
    {'label':'Adventure','value':'adventure'},
    {'label':'Animation','value':'animation'},
    {'label':'Anime','value':'anime'},
    {'label':'Biography','value':'biography'},
    {'label':'Bollywood','value':'bollywood'},
    {'label':'Classics','value':'classics'},
    {'label':'Comedy','value':'comedy'},
    {'label':'Crime','value':'crime'},
    {'label':'Documentary','value':'documentary'},
    {'label':'Drama','value':'drama'},
    {'label':'Educational','value':'educational'},
    {'label':'Fantasy','value':'fantasy'},
    {'label':'Game Show','value':'game_show'},
    {'label':'History','value':'history'},
    {'label':'Holiday','value':'holiday'},
    {'label':'Horror','value':'horror'},
    {'label':'Independent','value':'independent'},
    {'label':'Kids and Family','value':'kids_and_family'},
    {'label':'LGBT (deprecated)','value':'lgbt'},
    {'label':'LGBTQ','value':'lgbtq'},
    {'label':'Music','value':'music'},
    {'label':'Musical','value':'musical'},
    {'label':'Mystery','value':'mystery'},
    {'label':'News','value':'news'},
    {'label':'Novelas','value':'novelas'},
    {'label':'Reality','value':'reality'},
    {'label':'Romance','value':'romance'},
    {'label':'Sci-Fi','value':'sci_fi'},
    {'label':'Short Films','value':'short_films'},
    {'label':'Special Interest','value':'special_interest'},
    {'label':'Sports','value':'sports'},
    {'label':'Talk Show','value':'talk_show'},
    {'label':'Teens','value':'teens'},
    {'label':'Thriller','value':'thriller'},
    {'label':'Travel','value':'travel'},
    {'label':'Variety','value':'variety'},
    {'label':'War','value':'war'},
    {'label':'Western','value':'western'},
];

export const pipelineTypes = {
    'all': 'All',
    'ingest': 'Ingest',
    'upload': 'Upload',
    'export': 'Export',
    'media': 'Media',
    'resource': 'Resource',
    'availability': 'Availability',
    'policy': 'Policy',
    'relation': 'Relation',
    'testing': 'Testing',
    'other': 'Other',
    'admin': 'Admin',
    'commerce': 'Commerce',
}

export const viewTags = [
  {'name':'view', tags:['application']},
  {'name':'advertisement', tags: ['availability', 'marketing_decorator','action_decorator', 'decorator']},
  {'name':'campaign', tags: ['availability', 'marketing_decorator','action_decorator', 'decorator']},
  {'name':'contentgroup', tags: ['availability', 'marketing_decorator','action_decorator', 'decorator']},
  {'name':'channel', tags: ['availability', 'marketing_decorator','action_decorator', 'decorator']},
  {'name':'series', tags: ['availability', 'marketing_decorator','action_decorator', 'decorator']},
  {'name':'movie', tags: ['availability', 'marketing_decorator','action_decorator', 'decorator']},
  {'name':'episode', tags: ['availability', 'syndication']},
  {'name':'season', tags: ['availability']},
  {'name':'media', tags: ['availability', 'syndication']},
]

export const ratings : any = {
    'us-tv': [
        {'code':'TV-Y','description':'Appropriate for all children'},
        {'code':'TV-Y7','description':'Designed for children age 7 and above'},
        {'code':'TV-G','description':'Suitable for all ages'},
        {'code':'TV-PG','description':'Parental guidance suggested'},
        {'code':'TV-14','description':'Unsuitable for children under 14'},
        {'code':'TV-MA','description':'Mature audience only'}
    ],
    'ca-tv': [
        {'code':'C','description':'Children'},
        {'code':'C8','description':'Children 8 and over'},
        {'code':'G','description':'General audience'},
        {'code':'PG','description':'Parental guidance'},
        {'code':'14+','description':'14 years and older'},
        {'code':'18+','description':'Adult'},
        {'code':'E','description':'Exempt'}
    ],
    'ca-chvrs': [
        {'code':'G','description':'Suitable for viewing by all ages'},
        {'code':'PG','description':'Parental guidance advised'},
        {'code':'NR','description':'Not Rated'},
        {'code':'UR','description':'Unrated'},
        {'code':'14A','description':'Suitable for people 14 years of age or older'},
        {'code':'18A','description':'Suitable for people 18 years of age or older'},
        {'code':'R','description':'Restricted to 18 years and over'},
        {'code':'E','description':'Exempt'}
    ]
};

export const dedicatedEditForms: any =[
    'media',
    'season',
    'episode',
    'series',
    'collection',
    'rotator',
    'channel',
    'view',
    'movie',
    'document',
    'advertisement',
    'campaign',
    'content_group',
    'moment',
    'pipeline',
    'worker',
    'server',
    'profile',
    'application',
    'catalog',
    'configuration',
    'distribution',
    'platform',
    'policy',
    'role',
    'version',
    'origin',
    'provider',
    'user_profile',
    'report',
    'workflow',
    'flight',
    'creative',
    'person',
    'cast',
    'test',
    'test_suite'
];

export const subGenre = [
    {'label': 'Animals & Nature', 'value': 'animals_&_nature'},
    {'label': 'Award Show', 'value': 'award_show'},
    {'label': 'Baking', 'value': 'baking'},
    {'label': 'Competition', 'value': 'competition'},
    {'label': 'Cooking', 'value': 'cooking'},
    {'label': 'Crime', 'value': 'crime'},
    {'label': 'DIY', 'value': 'diy'},
    {'label': 'Documentary', 'value': 'documentary'},
    {'label': 'Factual', 'value': 'factual'},
    {'label': 'Family', 'value': 'family'},
    {'label': 'Food & Travel', 'value': 'food_&_travel'},
    {'label': 'Home Improvement', 'value': 'home_improvement'},
    {'label': 'Investigative', 'value': 'investigative'},
    {'label': 'Real Estate', 'value': 'real_estate'},
    {'label': 'Reality TV', 'value': 'reality_tv'},
    {'label': 'Reality', 'value': 'reality'},
    {'label': 'Lifestyle', 'value': 'lifestyle'},
];

export const defaultPaginationLimit = {
    "entityType" : 1000,
    "clientSearch": 1000,
    "schema": 1000,
};

export const workflowStateSegmentTransitionTypes: any = [
    'simple',
    'complex'
];

export const indicatorIconTypes: any = [
    {'label':'None','value':''},
    {'label':'Thumbs Up','value':'fa fa-thumbs-up'},
    {'label':'X Mark','value':'fa fa-times'},
    {'label':'Check Mark','value':'fa fa-check'},
    {'label':'Check Mark in Circle','value':'fa fa-check-circle'},
    {'label':'Arrow Down in Circle','value':'fa fa-arrow-circle-down'},
    {'label':'Calendar','value':'fa fa-calendar'},
    {'label':'Minus in Square','value':'fa fa-minus-square-o'}
];

export const containerTypeRoutes: any  = {
    'media': 'media',
    'season': 'seasons',
    'episode': 'episodes',
    'series': 'series',
    'collection': 'collections',
    'channel': 'channels',
    'view': 'view',
    'movie': 'movie',
    'document': 'document',
    'pipeline': 'pipelines',
    'worker': 'workers',
    'server': 'server',
    'profile': 'profiles',
    'application': 'applications',
    'catalog': 'catalog',
    'distribution': 'distributions',
    'platform': 'platform',
    'policy': 'policy',
    'role': 'roles',
    'origin': 'origins',
    'provider': 'providers',
    'report': 'reports',
    'workflow': 'workflow',
};

export const genderList = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

export const roleList = [
  { label: 'Actor', value: 'actor' },
  { label: 'Director', value: 'director' },
];

export const ContentLockTypes = [
  { label: 'Soft', value: 'soft' },
  { label: 'Hard', value: 'hard' },
];

export const pipelineStatusList = [
    'waiting',
    'pending',
    'running',
    'success',
    'failure',
    'cancelled',
];
