import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppContext } from '../../../app.context';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'pipeline-origin-multiselect',
  templateUrl: './pipeline-origin-multiselect.component.html',
  styleUrls: ['./pipeline-origin-multiselect.component.css']
})
export class PipelineOriginMultiselectComponent implements OnInit {

    @Output() onOriginListUpdate: EventEmitter <any> = new EventEmitter();

    public originList: any;
    public originListSub: any;
    public selectedOrigins: Array<SelectItem> = [];
    public originValues: Array<SelectItem> = [];

    constructor(
      private appContext: AppContext
  ) {}

    ngOnInit() {
      this.originListSub = this.appContext.originContainersLoaded$
      .subscribe((origins) => {
        console.log('origins loaded', origins);
        this.originList = Object.entries(origins).map(([key, origin]) => ({ label: origin['data']['label'], value: origin['data']['origin_id'] }));
        this.originList.sort((a, b) => a.label.localeCompare(b.label));
        this.originValues = this.originList.map(origin => origin.value);
        this.onOriginListUpdate.emit(this.originValues);
      },
        err => {
          console.log(err);

      });
    }

    onSelectedOriginChange(event) {
      this.selectedOrigins = event.value ? event.value : [];
      const originsToEmit = this.selectedOrigins.length > 0 ? this.selectedOrigins : this.originValues;
      this.onOriginListUpdate.emit(originsToEmit);
    }

    ngOnDestroy() {
        this.originListSub.unsubscribe();
    }
}
