import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'movie-form',
    templateUrl: 'movie-form.component.html'
})
export class MovieFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @Output() statusError: EventEmitter<any> = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityType;
    private selectedSeasonGuid;
    public isLoading = false;

    private publishDate :Date;

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];
    private genre = genreList;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        this.isLoading = true;
        this.entityTypeService
            .getByName('movie')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSubmit(e) {
        console.log('container submitted', this.container);
        
        this.ngForm.form.markAsPristine()
        this.formSubmit.emit({
            container: this.container
        });
    }

    onSimpleStatusChange(event) {
        console.log('simple status transition', event);
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onComplexStatusChange(event) {
        console.log('pipeline triggered successfully');
        this.pipelineTriggered.emit(event);
    }

    onStatusTransitionFailure(e) {
        this.messageService.add(e);
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onPipelineTriggered(e) {
        this.pipelineTriggered.emit(e);
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }

    onError(error) {
        this.statusError.emit(error);
    }
}
