import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'season-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'season-form.component.html'
})
export class SeasonFormComponent implements OnInit {
    @Input() container: Container;
    @Input() parentContainer: Container;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @Output() statusError: EventEmitter<any> = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();

    @ViewChild('ngForm') ngForm: NgForm;

    private entityType;
    public isLoading = false;

    private publishDate :Date;
    private genre = genreList;


    constructor(
        private entityTypeService: EntityTypeService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }

        if (this.parentContainer) {
            this.container.data['primary_parent_container_id'] = this.parentContainer.guid;
            this.container.data['show_title'] = this.parentContainer.data['title'];
        }

        if (!this.container.data['content_listing']) {
            this.container.data['content_listing'] = 'Season';
        }

        this.isLoading = true;
        this.entityTypeService
            .getByName('season')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date = new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSimpleStatusChange(event) {
        console.log('simple status transition', event);
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onComplexStatusChange(event) {
        console.log('pipeline triggered successfully');
        this.pipelineTriggered.emit(event);
    }

    onStatusTransitionFailure(e) {
        this.messageService.add(e);
    }

    onSeasonNumberChange() {
        // Set default title and/or description
        if (this.container.data['season_number']) {
            this.container.data['title'] = `Season ${this.container.data['season_number']}`;
            this.container.data['description'] = `Season ${this.container.data['season_number']}`;
            if (this.container.data['show_title']) {
                this.container.data['description'] += ` of ${this.container.data['show_title']}`;
            }
        }
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.ngForm.form.markAsPristine();
        this.formSubmit.emit({
            container: this.container
        });
    }
    
    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onError(error) {
        this.statusError.emit(error);
    }
}
