import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';
import { AppContext } from '../../../app.context';

@Component({
    selector: 'container-parent',
    providers: [
        ContainerService,
    ],
    templateUrl: 'container-parent.component.html',
    styleUrls: ['container-parent.component.css']
})

export class ContainerParentComponent implements OnInit {
    @Input() container: Container;
    @Input() childContainer: Container;
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();

    public typeString: string;
    public isProcessing = false;

    constructor(
        private containerService: ContainerService,
        public appContext: AppContext,
    ) { }

    ngOnInit() {
        if (typeof this.container.type === 'string') {
            this.typeString = this.container.type;
        } else if (typeof this.container.type.name === 'string') {
            this.typeString = this.container.type.name;
        } else {
            this.typeString = '';
        }
    }

    // TODO: default thumbnail
    thumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }

    update(e) {
        this.onUpdate.emit({});
    }

    remove(e, container) {
        var parentTitle = this.container.data['title'] || this.container.data['name'];
        var thisTitle = this.childContainer.data['title'] || this.childContainer.data['name'] || ('this ' + this.typeString);

        if (confirm('Are you sure you want to remove ' + thisTitle + ' from ' + parentTitle + '?')) {
            console.log('remove', e, container);
            this.isProcessing = true;
            this.containerService
                .unrelate(container, this.childContainer)
                .subscribe(
                    res => {
                        console.log(res);
                        this.onRemove.emit({
                            container: this.container
                        });
                        this.isProcessing = false;
                    },
                    err => {
                        console.log(err);
                        this.isProcessing = false;
                    }
                );
        }
    }

    statusLabelClass() {
        return this.containerService.getStatusLabelClass(this.container.status);
    }

}
