import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityType } from '../../models';
import { AuthService } from '../../../auth/auth.service';
import { defaultPaginationLimit } from '../../../shared/enum';
import { map } from 'rxjs/operators';

@Injectable()
export class EntityTypeService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;
    private limit = defaultPaginationLimit['entityType'];

    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) { }

    _appendHeaders() {
        return new HttpHeaders()
        .append('Accept', 'application/json')
        .append('Content-Type', 'application/json')
        .append('Authorization', this.authService.getAuthHeader());
      }

    get(id) {
        return this.http
            .get(
                `${this.baseUrl}/entitytypes/${id}/`, { headers: this._appendHeaders() }
            );
    }

    getByName(name, limit = 1) {
        return this.http
            .get(
                `${this.baseUrl}/entitytypes/?limit=${limit}&name=${name}`, { headers: this._appendHeaders() }
            )
            .pipe(map(res=>res['results'].length ? res['results'][0]: null))
    }

    list() {
        return this.http
            .get(
                `${this.baseUrl}/entitytypes/?limit=${this.limit}`, { headers: this._appendHeaders() }
            )
            .pipe(
                map(res => Array.isArray(res) ? res : res['results']
            ));
    }

    update(entityType: EntityType) {
        return this.http.patch(
            `${this.baseUrl}/entitytypes/${entityType.id}/`,
            JSON.stringify(entityType), { headers: this._appendHeaders() }
        );
    }
}
