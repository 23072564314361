import { Component, OnInit, Input } from '@angular/core';
import { Container, Resource, EntityType } from '../../models';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'resource-datarow',
    templateUrl: 'resource-datarow.component.html',
    styleUrls: ['resource-datarow.component.css']
})
export class ResourceDatarowComponent implements OnInit {
  @Input() set resources(resources: Array<Resource>) {
      this.allResources = resources;
      resources.forEach(element => {
        if (element.type.name === 'file') {
            this.fileResources.push(element);
        }
    });
      this.filter();
  }
  @Input() parentContainer: Container;
  @Input() title: string;
  @Input() rows: number;
  @Input() filterBy: string;
  @Input() sortOptions: Array<any>;
  @Input() isLoading: boolean;

  private error: string;
  private allResources: Array<Resource> = [];
  public shownResources: Array<Resource> = [];
  public fileResources: Array<Resource> = [];
  private sortField: string='created_date';
  private sortOrder: number= 1;
  private activeFilter: string;
  private filterByFields: Array<any>;
  public displayManageDialog: boolean = false;
  private rowGroupMetadata: any;
  public collapsed = false;

  public selectedOrderingOption: any;
  public orderingOptions = [
    { label: 'Created Date', value: "created_date" },
    { label: 'Created Date Desc', value: "!created_date" },
    { label: 'Published Date', value: "published_date" },
    { label: 'Published Date Desc', value: "!published_date" },
    { label: 'Updated Date', value: "updated_date" },
    { label: 'Updated Date Desc', value: "!updated_date" },
    { label: 'Expiration Date', value: "expiration_date" },
    { label: 'Expiration Date Desc', value: "!expiration_date" },
  ]

  public filterByTypeOptions:Array<any>=[];
  public filterByTagOptions:Array<any>=[];
  public filteredResources: Array<Resource> = [];
  public selectedTypeOption: any;
  public selectedTagOption: any;

  constructor() {}

  ngOnInit() {
      let filters = this.filterBy.split(', ');
      this.filterByFields = [];
      filters.forEach(element => {
          this.filterByFields.push(element.split('.'));
      });
      this.filterByFields.push('file');
      this.generateTypeAndTagFilterOptions()
  }

  showManageDialog() {
      this.displayManageDialog = true;
  }

  closeManageDialog() {
      this.displayManageDialog = false;
  }

  onFilter() {
    this.filter();
  }

  onSortChange() {
    let value = this.selectedOrderingOption
    if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
    } else {
        this.sortOrder = 1;
        this.sortField = value;
    }
    this.sort();
  }

  generateTypeAndTagFilterOptions() {
    // build list of types and tags of available resources
    const typesMap = new Map();
    const tagsMap = new Map();
    this.shownResources.forEach(resource => {
      const typeKey = resource.type.name;
      typesMap.set(typeKey, {"label": typeKey, "value": typeKey});
      resource.tags.forEach(tag => {
        const tagKey = tag.value; // each tag has a unique 'value' property
        tagsMap.set(tagKey, { "label": tag.label, "value": tagKey });
      })
    })
    this.filterByTypeOptions = Array.from(typesMap.values());
    this.filterByTagOptions = Array.from(tagsMap.values());
  }

  filter() {
    this.shownResources = this.allResources.filter(resource => {
      if (this.activeFilter && this.activeFilter !== '') {
          let matchesFilter = false;
          this.filterByFields.forEach(filter => {
              let result = resource;
              if (typeof(filter) === 'object') {
                  filter.forEach(element => {
                      result = result[element];
                  });
              }
              if (String(result).toLowerCase().includes(this.activeFilter.toLowerCase())) {
                  matchesFilter = true;
              }
          });
          return matchesFilter && resource.type.name !== 'file';
      } else {
          return resource.type.name !== 'file';
      }
    });
    // Ensure all remaining resources are visible
    this.shownResources.forEach(resource => resource['show'] = true);

    // Identify tags shared amoung resources of same type
    this.findDuplicateTags();

    // filter resources based on selected Resource Type option
    this.shownResources = this.shownResources.filter(resource => {
      if (!this.selectedTypeOption || this.selectedTypeOption === "") {
          return true; // Include all resources if no type is selected
      } else {
          return resource.type.name === this.selectedTypeOption;
        }
    })

    // filter resources based on selected Resource Tag option
    this.shownResources = this.shownResources.filter(resource => {
      if (!this.selectedTagOption || this.selectedTagOption === "") {
          return true; // Include all resources if no tag is selected
      } else {
          return resource.tags.some(tag => tag.value === this.selectedTagOption);
        }
    })

    this.sort();
  }

  findDuplicateTags() {
    const tagMap = new Map();

    // First pass: Populate tagMap with tags associated with each type.name
    this.shownResources.forEach(resource => {
      const typeName = resource.type.name;
      resource.tags.forEach(tag => {
        const key = `${typeName}-${tag.value}`;
        if (!tagMap[key]) {
            tagMap[key] = [];
        }
        tagMap[key].push(resource);
      });
    });

    // Second pass: Identify duplicates and build list of duplicate_tags for a resource
    this.shownResources.forEach(resource => {
      const typeName = resource.type.name;
      resource['duplicate_tag'] = new Set();
      resource.tags.forEach(tag => {
          const key = `${typeName}-${tag.value}`;
          if (tagMap[key].length > 1) {
              resource['duplicate_tag'].add(tag.value);
          }
      });
    });
  }

  sort() {
      if (!this.sortField || this.sortField === '') {
          this.shownResources = [];
          this.allResources.forEach(element => {
              this.shownResources.push(element);
          });
          this.filter();
          return;
      }

      this.shownResources.sort((a, b) => {
          let value1 = this.resolveObjectField(a, this.sortField);
          let value2 = this.resolveObjectField(b, this.sortField);

          // Convert to Date objects if sorting by 'date'
          if (!isNaN(Date.parse(value1)) && !isNaN(Date.parse(value2)) ) {
            value1 = new Date(value1)
            value2 = new Date(value2)
          }

          let result = null;
          if (value1 == null && value2 != null) {
              result = -1;
          } else if (value1 != null && value2 == null) {
              result = 1;
          } else if (value1 == null && value2 == null) {
              result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          } else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (this.sortOrder * result);
      });
  }

  resolveObjectField(object: Object, value: string) {
      let result;
      let tokens = value.split('.');
      tokens.forEach(element => {
          result = object[element];
      });
      return result;
  }

  resourceRemoved(e) {
      let i = this.allResources.indexOf(e.resource);
      this.allResources.splice(i, 1);

      this.shownResources = [];
      this.allResources.forEach(element => {
          this.shownResources.push(element);
      });

      this.filter();
      this.sort();
  }

}
