import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Container, EntityType } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { dedicatedEditForms as dedicatedEditFormsList } from '../../../shared/enum';
import { TagService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'container-table',
    providers: [
        ContainerService,
        EntityTypeService,
        TagService
    ],
    templateUrl: 'container-table.component.html',
    styleUrls: ['container-table.component.css']
})
export class ContainerTableComponent implements OnInit {
    public searchResult: any = {};
    public containers: Array<any> = [];
    private limit: number;
    public offset: number;
    public searchTerm = '';
    private filteredType = '';
    private filteredStatus = '';
    private orderBy: Array<string> = [];
    public isLoading = true;
    public entityTypes = [];
    public selectedType: EntityType = null;
    public statusList: Array<SelectItem> = [];
    public selectedStatus: SelectItem = null;
    private viewRestrictions: any = {};
    private error: string;

    public selectedTags: any;
    private selectedTagsList: Array<string> = [];
    private dedicatedEditForms = dedicatedEditFormsList

    public viewRestrictionsAllowedStatus = []
    public viewRestrictionsAllowedType = []
    public viewRestrictionsAllowedTagCategories = []
    public viewRestrictionsSearchFilter = ''
    private viewRestrictionsAllowedTags = []
    private browserRefresh: boolean = false
    private clientSearchSubscription: Subscription;


    private allColumns = [
        { default: true, header: 'Title', style: { minwidth: "15em", "white-space": "wrap", "word-break":"break-all"}, field: 'label', transform: [this.toBold.bind(this)] },
        { default: true, header: 'Show Title', style: { "white-space": "nowrap", overflow: "hidden"}, field: 'data.show_title', transform: [this.toBold.bind(this)] },
        { default: true, header: 'GUID', field: 'guid', style: { "white-space": "nowrap",  overflow: "hidden"} },
        { default: true, header: 'Type', field: 'type', style: { "white-space": "nowrap", overflow: "hidden" }, transform: [this.toEntityTypeLabel.bind(this)] },
        { default: true, header: 'Status', field: 'status', style: { "white-space": "nowrap", overflow: "hidden" }, transform: [this.toStatusLabel.bind(this)] },
        { default: false, header: 'Season Number', field: 'season_number' },
        { default: true, header: 'Tags', sort: false, field: 'tags', style: {"word-wrap": "break-word", overflow: "hidden"}, transform: [this.toTagsLabel.bind(this)]  }

    ];

    public columns = [];

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService,
        private appContext: AppContext,
        private tagService: TagService
    ) {
        this.columns = this.allColumns.filter(col => col.default);
    }

    ngOnInit() {
        this.isLoading = true;
        this.statusList = ContainerService.statusList.map(s => ({ label: s, value: s }));

        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityTypes.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1;
                        } else if (a.name < b.name) {
                            return -1;
                        }
                        return 0;
                    });
                    if (this.filteredType && !this.selectedType) {
                        this.selectedType = this.entityTypes.find(t => t.name == this.filteredType);
                    }
                    this.setSearchParams();
                },
                err => console.log(err),
                () => this.isLoading = false
            );
        // Update on query param change
        this.getSearchContextParameters();

        //Update viewrestrictions from authService
        this.authService.viewRestrictionsUpdates.subscribe(
            res => this.browserRefresh = !this.browserRefresh,
            err => console.log(err),
            () => this.getViewRestrictions()
        )

    }
    getViewRestrictions()
    {
        this.viewRestrictions = this.appContext.getViewRestrictions('search_view');

        if (this.viewRestrictions && ('allowed_statuses' in this.viewRestrictions) && this.viewRestrictions['allowed_statuses'])
        {
            this.viewRestrictionsAllowedStatus = this.viewRestrictions['allowed_statuses']
        }

        if (this.viewRestrictions && ('allowed_types' in this.viewRestrictions) && this.viewRestrictions['allowed_types'])
        {
            this.viewRestrictionsAllowedType = this.viewRestrictions['allowed_types']
        }

        if (this.viewRestrictions && ('allowed_tag_categories' in this.viewRestrictions) && this.viewRestrictions['allowed_tag_categories'])
        {
            this.viewRestrictionsAllowedTagCategories = this.viewRestrictions['allowed_tag_categories']
        }

        if (this.viewRestrictions && ('search_filter' in this.viewRestrictions) && this.viewRestrictions['search_filter'])
        {
            this.viewRestrictionsSearchFilter = this.viewRestrictions['search_filter']
        }

        // ***** Upon page load, get tags related to allowedTagCategories in view restrictions *****
        if (this.viewRestrictionsAllowedTagCategories.length) {
          let tags;
          this.tagService.list().subscribe(
              res => tags = res.filter(tag => this.viewRestrictionsAllowedTagCategories.indexOf(tag.type) !== -1),
              err => console.log("error loading allowed category tags", err),
              ()  => {
                tags.forEach(tag => this.viewRestrictionsAllowedTags.push(tag.value))
                this.loadResults()
              }
          )
        } else {
            // ***** On page reload, execute load results once view restrictions are set  *****
            if (this.browserRefresh) { this.loadResults() }}
    }

    getSearchContextParameters() {
        let searchContextParameters = this.appContext.componentState['search_view'];

        this.searchTerm = searchContextParameters['search'] || '';

        // Set type
        this.filteredType = (searchContextParameters['type'] || '').toLowerCase();
        if (this.filteredType) {
            if (!this.selectedType || this.selectedType.name !== this.filteredType) {
                this.selectedType = this.entityTypes.find(t => t.name == this.filteredType);
                // console.log('Selected Type', this.selectedType);
            }
        } else if (this.selectedType) {
            this.selectedType = null;
        }

        // Set status
        this.filteredStatus = (searchContextParameters['status'] || '').toLowerCase();
        if (this.filteredStatus) {
            if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
                this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
                // console.log('Selected Status', this.selectedStatus);
            }
        } else if (this.selectedStatus) {
            this.selectedStatus = null;
        }

        // Set tags
        this.selectedTags = (searchContextParameters['selectedTags'] || []);

        if (this.selectedTags)
        {
            this.selectedTagsList = []
            this.selectedTags.forEach(element => {
                this.selectedTagsList.push(element.value)
            });
        }

        // Set offset
        this.offset = searchContextParameters['offset'] || 0;
    }

    setSearchParams(resetOffset: boolean = false) {
        if (resetOffset) {
            this.offset = 0;
        }
        this.appContext.componentState['search_view'] = {
            'type': this.filteredType || null,
            'search': this.searchTerm || null,
            'status': this.filteredStatus || null,
            'offset': this.offset || null,
            'selectedTags': this.selectedTags || [],
        };
        console.log("Search Preferences", this.appContext.componentState['search_view'])
    }

    onChangedFilteredTag(event)
    {
        this.selectedTags = event;
        this.selectedTagsList = []

        event.forEach(element => {
            this.selectedTagsList.push(element.value)
        });
        this.loadResults();
        this.setSearchParams(true);
    }


    loadResults() {
        if (this.clientSearchSubscription) { this.clientSearchSubscription.unsubscribe() }
        this.isLoading = true;
        this.clientSearchSubscription=this.containerService
            .clientSearch(
                this.filteredType || this.viewRestrictionsAllowedType.join('__') || '',
                this.filteredStatus || this.viewRestrictionsAllowedStatus.join('__') || '',
                this.orderBy.length > 0 ? this.orderBy : ['-created_date'],
                '',
                this.limit,
                this.offset,
                this.searchTerm ? [this.searchTerm.trim()] : [],
                this.selectedTagsList.length ? this.selectedTagsList : this.viewRestrictionsAllowedTags,
                null,
                this.viewRestrictionsSearchFilter
            )
            .subscribe(
                res => {
                    this.searchResult = res;
                    if (this.searchResult.count > 10000) {
                        // ES does not support a result window larger than 10,000
                        this.searchResult.count = 10000;
                    }
                    this.containers = this.searchResult.results;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
            //console.log(res);
    }

    loadResultsLazy(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.loadResults();
        this.setSearchParams();
    }

    changedSearchTerm(event) {
        this.loadResults();
        this.setSearchParams(true);
    }

    findParentContainerId(container) {
        console.log('clicked on container', container);
        console.log('all containers', this.containers);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];

        console.log('clicked on container parent', parentContainer);

        this.router.navigate(['/episodes', container.id, parentContainer.id]);
    }

    toBold(data) {
        return '<b>' + data + '</b>';
    }

    toEntityTypeLabel(data) {
        return '<div class="label label-default">'
            + (data || '')
            + '</div>';
    }

    toTagsLabel(tags) {
        if ((!tags) || (tags.length == 0)) {
            return '';
        }
        let fieldText = ''
        tags.forEach(element => {
            fieldText =fieldText + '<div class="label label-default">' + element['label'] + '</div>';
        });
        return fieldText;
    }

    toStatusLabel(status) {
        if (!status) {
            return '';
        }
        return '<div class="label ' +
               this.containerService.getStatusLabelClass(status) +
               '">' +
               this.toTitleCase(status) +
               '</div>';
    }

    toTitleCase(data) {
        let txt = data || '';
        if (!txt) {
            return txt;
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    extractThumbnail(container) {
        let thumbnail_uri = '';
        if(container.resources && container.resources.length > 0){
            container.resources.forEach(res => {
                if(res.type && res.type == 'thumbnail_small'){
                    thumbnail_uri = res.uri;
                }
            });
        }
        return thumbnail_uri;
    }

    getFieldData(container, column) {
        let data = container;
        let fields = column.field.split('.');
        for (let field of fields) {
            data = data[field];
        }
        data = data || '';
        if (column.transform) {
            for (let i = 0; i < column.transform.length; i++) {
                data = column.transform[i](data);
            }
        }
        return data;
    }

    onChangedFilteredType(event) {
        this.filteredType = event.value ? event.value.name : '';
        this.loadResults();
        this.setSearchParams(true);
    }

    onChangedFilteredStatus(event) {
        this.filteredStatus = event.value ? event.value.value : '';
        this.loadResults();
        this.setSearchParams(true);
    }

    ngOnDestroy() {
      if (this.clientSearchSubscription) { this.clientSearchSubscription.unsubscribe() }
  }
}
