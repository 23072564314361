<p-multiSelect *ngIf="groupedTags"
                [options]="groupedTags"
                [group]="true"
                [(ngModel)]="selectedTags"
                name="tagType"
                optionLabel="label"
                maxSelectedLabels=100
                [style]="{'min-width':'100%', 'max-width':'100%', 'min-height':'27px'}"
                [defaultLabel]="'Choose Tag(s)'"
                (onChange)="onChangeTags()"
                [appendTo]="'body'">
    <ng-template let-item pTemplate="item">
        <div style="font-size:14px">{{item.label}}</div>
    </ng-template>
</p-multiSelect>
