<div class="card card-w-title top-level-card">
    <h1>History Diff</h1>
    <div class="p-grid">
        <div class="p-col">
            <label>User</label>
            <div>
                <input type="text" class="form-control" [(ngModel)]="currentHistoryUser" [readonly]="true">
            </div>
        </div>
        <div class="p-col">
            <label>Datetime</label>
            <div>
                <input type="text" class="form-control" [(ngModel)]="currentHistoryDate" [readonly]="true">
            </div>
        </div>
    </div>
        
    <p-panel>
        <p-header>
            <span class="p-panel-title" style="font-size:14px">Changes</span>
        </p-header>
        <inline-diff [oldText]=oldObjectString [newText]=currentObjectString [lineContextSize]=35 data-element-id="app-content-components-history-diff-changes-text"></inline-diff>
    </p-panel>

</div>