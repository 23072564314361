<form #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g-12">
        <img [src]="profileImage" id="profileImage" class="profileImage" *ngIf="profileImage" data-element-id="app-account-profile-form-profile-image">
        <i class="topbar-icon animated swing fa fa-fw fa-user-circle" id="defaultImage" *ngIf="!profileImage"></i>
    </div>
    <div class="ui-g-12">
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="ui-g-12">
        <label for="label">Username</label>
        <input type="text" class="form-control" name="userName" disabled  [ngModel]="user.username">
    </div>
    <div class="ui-g-12">
        <label for="label">Email</label>
        <input type="text" class="form-control" name="userEmail" disabled [ngModel]="user.email">
    </div>
    <div class="ui-g-12">
        <label for="label">Profile Image URL</label>
        <input type="text" class="form-control" name="profileImageUrl" [(ngModel)]="profile.data['image_url']" placeholder="Please add profile image URL" data-element-id="app-account-profile-form-profile-image-url">
    </div>
    <div class="ui-g-12">
        <div class="ui-g-8">
            <button type="button" pButton label="Preview" icon="fa fa-eye" (click)="onPreview($event)" style="float:right" data-element-id="app-account-profile-form-profile-preview"></button>
        </div>
        <div class="ui-g-2">
            <button type="submit" pButton label="Save" icon="pi pi-check" style="float:right" data-element-id="app-account-profile-form-profile-save"></button>
        </div>
        <div class="ui-g-2">
            <button type="button" pButton label="Close" icon="fa fa-close" (click)="onClose()" class="p-button-secondary" style="float:right" data-element-id="app-account-profile-form-profile-close"></button>
        </div>
    </div>
</form>