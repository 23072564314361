
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourceTag} from '../../models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';
import { MessageService } from 'primeng/api';


@Injectable()
export class ResourceTagService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext,
        private msgService: MessageService,
    ) {}

    _appendHeaders() {
      return new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json')
          .append('Authorization', this.authService.getAuthHeader())
    }

    list(origin?: string, limit?: number, offset?:number, order_by?:string, filter_by?:string, filter_value?:string): Observable < any > {
      let filters = origin ? `origin=${origin}` : `origin=${this.appContext.activeOrigin},system`
      filters += limit >= 0 ? `&limit=${limit}&offset=${offset}`:`&limit=-1`
      filters += order_by ? `&ordering=${order_by}`: ''
      filters += filter_by ? `&${filter_by}=${filter_value}`: ''

      return this.http.get(`${this.baseUrl}/resourcetags/?${filters}`,
                          {headers: this._appendHeaders()});
    }

    getTagsByResource(resourceId): Observable < any > {
      resourceId = encodeURIComponent(resourceId);
      return this.http.get(`${this.baseUrl}/resources/${resourceId}/tags/`,
                            {headers: this._appendHeaders()});
    }

    getResourceTagById(id:number): Observable < any > {
      return this.http.get(`${this.baseUrl}/resourcetags/${id}`,
                          {headers: this._appendHeaders()});
    }

    save(resourceTag: ResourceTag): Observable < any > {
      // create new resource
      if (resourceTag.id === -1) {
        return this.http.post(`${this.baseUrl}/resourcetags/`,
                            JSON.stringify(resourceTag),
                            { headers: this._appendHeaders() }
          )
        } else {
          return this.http.put(`${this.baseUrl}/resourcetags/${resourceTag.id}/`,
                            JSON.stringify(resourceTag),
                            { headers: this._appendHeaders() }
            )
        }
    }

    delete(resourceTag: ResourceTag): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/resourcetags/${resourceTag.id}`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    deleteById(id: number): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/resourcetags/${id}`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    // ***** To handle errors received in API response *****
    _handleRes(res): Observable < any > {
      return res.pipe(tap({
        next: null,
        error: (err) => this._handleError(err),
        complete: null,
      }))
    }

    _handleError(err) {
      let errMsg = ''
      if (err.error instanceof Object && err.status!== 0) {
        Object.keys(err.error).map(key => {
          errMsg = key.charAt(0).toUpperCase() + key.slice(1) +' : '+err.error[key]
        })
      } else{ errMsg = err.status !== 0 ? err.status +'  '+err.statusText: err.statusText }
      this._showMsg(errMsg, 'error')
    }

    _showMsg(msg, severity) {
      this.msgService.add({severity: severity, summary:'', detail:msg });
    }
}
