
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tag } from '../../models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';
import { MessageService } from 'primeng/api';


@Injectable()
export class TagService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext,
        private msgService: MessageService,
    ) {}

    _appendHeaders() {
      return new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json')
          .append('Authorization', this.authService.getAuthHeader())
    }

    get(id): Observable < any > {
      return this.http.get(`${this.baseUrl}/tags/${id}/`,
                            {headers: this._appendHeaders()}
                          );
    }

    getTagsFromContainerRelationships(containerId): Observable < any > {
      containerId = encodeURIComponent(containerId);
      return this.http.get(`${this.baseUrl}/containers/${containerId}/relationship/tags/`,
                            {headers: this._appendHeaders()}
                          );
    }

    list(origin?: string , limit?:number, offset?:number, order_by?:string, filter_by?:string, filter_value?:string): Observable < any > {
      let filters = origin ? `origin=${origin}` : `origin=${this.appContext.activeOrigin},system`
      filters += limit >= 0 ? `&limit=${limit}&offset=${offset}`:`&limit=-1`
      filters += order_by ? `&ordering=${order_by}`: ''
      filters += filter_by ? `&${filter_by}=${filter_value}`: ''

      let res = this.http.get(`${this.baseUrl}/tags/?${filters}`,
                              { headers: this._appendHeaders()})
      return this._handleRes(res)
    }

    save(tag: Tag): Observable < any > {
      let res;
      if (tag.id === -1) {
        res = this.http.post(`${this.baseUrl}/tags/`,JSON.stringify(tag),
                          { headers: this._appendHeaders()})
      } else {
        res = this.http.put(`${this.baseUrl}/tags/${tag.id}/`,JSON.stringify(tag),
                          { headers: this._appendHeaders()})
      }
      return this._handleRes(res)
    }


    saveTagOnRelationship(tag: Tag, parentContainerId, childContainerId, type): Observable < any > {
      console.log('tag service save', tag);
      let resp;
      let data = JSON.stringify({
          value: tag.value,
      });
      // create new resource
      if (tag.id === -1) {
          resp = this.http.post(
              `${this.baseUrl}/containers/${parentContainerId}/${type}/${childContainerId}/tags/`,
              data, { headers: this._appendHeaders() }
          );
      } else {
          resp = this.http.put(
              `${this.baseUrl}/tags/${tag.id}/`,
              data, { headers: this._appendHeaders() }
          );
      }
      return resp;
   }

    delete(tag: Tag): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/tags/${tag.id}/`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    deleteById(tagId): Observable < any > {
      let res = this.http.delete(`${this.baseUrl}/tags/${tagId}/`,
                           { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }

      // ***** Methods for Tag assignment *****
    getTagByContainerId(cntId:number): Observable < any > {
      let res = this.http.get(`${this.baseUrl}/containers/${cntId}/tags/`,
                              { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    relateTagByContainerId(cntId:number, tags:Tag[]):Observable < any > {
      let data = JSON.stringify({tag_ids:Array.from(tags, (tag, i) => tag.id)})
      let res = this.http.post(`${this.baseUrl}/containers/${cntId}/tags/`,data,
                              { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    unrelateTagByContainerId(cntId:number, tags:Tag[]): Observable < any > {
      let data = JSON.stringify({tag_ids:Array.from(tags, tag => tag.id)})
      const options = {headers: this._appendHeaders(), body:data}
      let res = this.http.delete(`${this.baseUrl}/containers/${cntId}/tags/`,options);
      return this._handleRes(res)
    }

    relateBulkTagByContainerId(cntIds:[], tagIds:[]):Observable < any > {
      let data = JSON.stringify({container_ids:cntIds, tag_ids:tagIds})
      let res = this.http.post(`${this.baseUrl}/containers/tags/bulk/`,data,
                              { headers: this._appendHeaders()});
      return this._handleRes(res)
    }

    unrelateBulkTagByContainerId(cntIds:[], tagIds:[]): Observable < any > {
      let data = JSON.stringify({container_ids:cntIds, tag_ids:tagIds})
      const options = {headers: this._appendHeaders(), body:data}
      let res = this.http.delete(`${this.baseUrl}/containers/tags/bulk/`,options);
      return this._handleRes(res)
    }


    // ***** To handle errors received in API response *****

    _handleRes(res): Observable < any > {
      return res.pipe(tap({
        next: null,
        error: (err) => this._handleError(err),
        complete: null,
      }))
    }

    _handleError(err) {
      let errMsg = ''
      if (err.error instanceof Object && err.status!== 0) {
        Object.keys(err.error).map(key => {
          errMsg = key.charAt(0).toUpperCase() + key.slice(1) +' : '+err.error[key]
        })
      } else{ errMsg = err.status !== 0 ? err.status +'  '+err.statusText: err.statusText }
      this._showMsg(errMsg, 'error')
    }

    _showMsg(msg, severity) {
      this.msgService.clear()
      this.msgService.add({severity: severity, summary:'', detail:msg });
    }
}
