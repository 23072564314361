import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, Type } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@angular/common';
import { EntityType } from '../../models';

@Component({
    selector: 'container-membership-row',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-membership-row.component.html',
    styleUrls: ['container-membership-row.component.css']
})
export class ContainerMembershipRowComponent implements OnInit, AfterContentInit {
    @Input() container: Container;
    @Input() containerTypeName: string;

    public isLoading = true;
    private error: string;

    private parentContainers = [];

    constructor(
        private containerService: ContainerService,
    ) { }

    ngAfterContentInit() {
        console.log('parent membership row initialized');
    }

    ngOnInit() {
        this.isLoading = true;
        this.containerService
            .getRelatedFromContainers(this.container.id, this.containerTypeName, 'full', -1, true, true)
            .subscribe(
                (res: any) => {
                    this.parentContainers = res;
                    console.log('parent membership request complete');
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++);
        return i;
    }

    private onRemove(e, container) {
        console.log('remove', e, container);
        let i = this.parentContainers.indexOf(container);
        this.parentContainers.splice(i, 1);
    }

}
