<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
        
    <h1>New Platform</h1>

    <div *ngIf="container && !isLoading">
        <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <div class="ui-g card-content">
        <platform-form #form [container]="container" (formSubmit)="onSubmit($event)" (formCancel)="onCancel($event)" (formFailed)="onFailure($event)"></platform-form>
    </div>
</div>
