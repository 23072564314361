<div [dragula]="name" [(dragulaModel)]="containers">
    <div *ngFor="let container of containers; let i = index">
        <div *ngIf="container.id">
            <div class="controls">
                <div class="handle" *ngIf="parentContainer"></div>
                <button class="btn" (click)="moveDown(i)" *ngIf="parentContainer && containers.length > 1 && i > 0" [disabled]="!controlsActive">-</button>
                <button class="btn" (click)="moveUp(i)" *ngIf="parentContainer && containers.length > 1 && i < containers.length - 1" [disabled]="!controlsActive">+</button>
            </div>

            <div class="content">
                <container [container]="container"></container>
            </div>

            <container-tree class="children" *ngIf="hasFullChildren(container)" [parentContainer]="container" [containers]="container.containers" [controlsActive]="controlsActive" (reorder)="bubbleReorder($event)"></container-tree>
        </div>
    </div>
</div>
