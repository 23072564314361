<p-dialog header="Tags"
        [(visible)]="displayDialog"
        [dismissableMask]="true"
        [closeOnEscape]="true"
        [responsive]="true"
        [closable]="true"
        [minY]="70"
        modal="true"
        [style]="{'min-width':'410px', 'max-width':'410px'}"
        [ngClass]="'p-dialog-header'"
        (onHide)="onDialogHide()"
        (onShow)="onDialogShow()"
        [contentStyle]="{'overflow':'auto'}">

    <div *ngIf="!isLoadingTags" class="ui-g-12 ui-md-12 ui-lg-12 myoveride" style="padding-bottom:30px;"
        [ngStyle]="{'display':!selectedTags?'none':null}">
      <div class="p-m-1">
        <span *ngFor="let tag of selectedTags" >
          <div class="label tag-pills" [ngClass]="tag.all? 'label-warning':(viewName? 'label-warning':'tag-all')"> {{tag.all=='n'? '*'+tag.label:tag.label}}</div>
        </span>
      </div>
      <div class="p-field-checkbox list-toggle" *ngIf="selectedViewTag?.length && selectedViewTag!='all'">
        <p-checkbox [(ngModel)]="showTags" binary="true" inputId="binary" (onChange)="showAll()" data-element-id="app-content-components-container-tags-panel-button-showAll"></p-checkbox>
        <label for="binary">{{showTags? 'Show Preferred': 'Show All'}}</label>
      </div>
      <div *ngFor="let tagType of tagsTypes" style="padding:0.3em; clear:both;">
          <label for="tagType">{{tagType | labelToDisplay}}</label>
          <span class="p-float-label">
              <p-multiSelect 
                [attr.data-element-id]="'app-content-components-container-tags-panel-choose-tags-'+(tagType || labelToDisplay||'tagTypeNotFound').toLowerCase()"
                [options]="tagsDict[tagType]"
                [showToggleAll]="false"
                [(ngModel)]="selectedTags"
                [autoWidth]="false"
                name="tagType"
                optionLabel="label"
                [style]="{'min-width':'100%', 'max-width':'100%'}"
                [defaultLabel]="'Choose Tag(s)'"
                (onChange)="onChangeTags()"
                [styleClass]="myoverride"
                panelStyleClass="app-content-components-container-tags-panel-choose-tags"
                appendTo="body">
                <ng-template let-tag pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                </ng-template>
              </p-multiSelect>
          </span>
      </div>
    </div>
    <p-progressSpinner *ngIf="isLoadingTags == true" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</p-dialog>

<p-panel class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Tags</span>
            <button pButton type="button" label="Edit" icon="pi pi-arrow-right" style="float:right;"(click)="showDialog()" data-element-id="app-content-components-container-tags-panel-button-edit"></button>

        </p-header>
        <div style="padding:0.5em;overflow:hidden;">
        <span *ngFor="let tag of selectedTags">
          <div class="label tag-pills" [ngClass]="tag.all? 'label-warning':(viewName? 'label-warning':'tag-all')" data-element-id="app-content-components-container-tags-panel-label-warning"> {{tag.all=='n'? '*'+tag.label:tag.label}}</div>
        </span>
    </div>
</p-panel>
