import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { EntityType } from '../../../content/models';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { MessageService } from 'primeng/api';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'entity-type',
  providers: [
    EntityTypeService
],
  templateUrl: './entitytype.component.html',
  styleUrls: ['./entitytype.component.css']
})
export class EntityTypeComponent implements OnInit {
  public eTypes:EntityType[];
  public isLoading:boolean;
  public selectedOrigin: string;


  constructor(private eTypeService: EntityTypeService,
              private appContext: AppContext) {}

  ngOnInit() {
    this.isLoading = true
    this.selectedOrigin = this.appContext.activeOrigin
    this.eTypeService.list().subscribe(
      res => this.eTypes = res,
      err => this.isLoading = false,
      () => this.isLoading= false
    );
  }
}
