<page-header [showSpinner]="isLoading" [error]="error">
</page-header>

<div class="ui-g">
    <p-tabView class="ui-g-12">

        <p-tabPanel *ngFor="let container of containers; let i = index"
        [header]="container.data['name']" 
        leftIcon="pi pi-check" 
        [selected]="i==0">

            <div *ngIf="!isLoading && this.bannerContainers[i]">
                <owl-carousel [options]="{items: 1.5, dots: true, navigation: false, stagePadding: 0, margin: 0, center:true, loop:true,  autoPlay : 3000, stopOnHover : true }"
                    [carouselClasses]="['owl-theme', 'row', 'sliding']">
                    <div class="item" [style.height]="500" *ngFor="let in_container of this.bannerContainers[i]; let j = index">
                        <a [routerLink]="['preview/series', in_container.guid]">
                            <div class="thumbnail-image container" [style.height]="500" [ngStyle]="{'background-image':'url('+in_container.resources['background']+')','background-repeat': 'no-repeat','background-size': 'cover','background-position': 'center'}">

                                <div class="text-block">

                                    <h1>{{in_container.data['title']}}</h1>
                                    <p>{{in_container.data['description']}}</p>

                                </div>
                                <img class="thumbnail-image right-block" [src]="getThumbnailUrl('hero',in_container.resources)">
                            </div>
                        </a>
                    </div>
                </owl-carousel>
            </div>

            <div *ngIf="!isLoading && this.featuredContainersAggr[i]">
                <div *ngFor="let container of this.featuredContainersAggr[i]; let j = index" class="ui-g-12">
                    <div *ngIf="container.length !== 0">
                        <div class="card card-w-title">
                            <h1>{{container.label}}</h1>

                            <owl-carousel [options]="{items: 5, dots: false, navigation: false, stagePadding: 0, margin: 10}"
                                [carouselClasses]="['owl-theme', 'row', 'sliding']">
                                <div class="item" *ngFor="let in_container of container.containers; let k = index">

                                    <div *ngIf="in_container.type == 'series'">
                                        <a [routerLink]="['preview/series', in_container.guid]">
                                            <div class="thumbnail-image">
                                                <img class="thumbnail-image" [src]="getThumbnailUrl('thumbnail_small',in_container.resources)">
                                                <div class="text-block">
                                                    <p>{{in_container['data']['title']}}
                                                        <p>
                                                            <!--     <button type="text" (click)="showDialog()" pButton icon="fa fa-external-link-square" label="Play"></button>-->
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div *ngIf="in_container.type != 'series'">
                                        <div class="thumbnail-image">
                                            <img class="thumbnail-image" [src]="getThumbnailUrl('thumbnail_small',in_container.resources)">
                                            <div class="text-block">
                                                <p>{{in_container['data']['title']}}
                                                    <p>
                                                        <!--     <button type="text" (click)="showDialog()" pButton icon="fa fa-external-link-square" label="Play"></button>-->
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </owl-carousel>
                        </div>
                    </div>
                </div>
            </div>

        </p-tabPanel>
    </p-tabView>
</div>