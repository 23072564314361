<div class="items">
    <div *ngFor="let container of containers; let i = index" class="item">

        <div class="content">
            <container [container]="container"></container>
        </div>


        <p-toolbar>
            <div class="p-toolbar-group-left">
                <button *ngIf="container.type == 'channel'" label="Edit" class="p-button-success" pButton type="button" [routerLink]="['/channels', container.id]" icon="pi pi-pencil"></button>
                <button *ngIf="container.type == 'series'" label="Edit" class="p-button-success" pButton type="button" [routerLink]="['/series', container.id]" icon="pi pi-pencil"></button>
            </div>
        </p-toolbar>


    </div>
</div>
