<p-panel>
    <p-header>
        <div class="ui-g-12 ui-md-4 ui-lg-4" style="padding:0;">
            <input type="text" pInputText placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" class="filter-bar"
            />
        </div>
        <div class="ui-g-12 ui-md-4 ui-lg-4" style="padding:0;">
            <span class="p-panel-title view-title">{{title}}</span>
        </div>
        <div class="ui-g-12 ui-md-4 ui-lg-4 p-inputgroup" style="padding:0;">
            <input #newProperty newProperty.value="" type="text" pInputText placeholder="New Property" style="width:100%">
            <button pButton label="Add" icon="pi pi-plus" (click)="addProperty(newProperty.value)"></button>
        </div>
    </p-header>
    <p-table #dt styleClass="w-100 h-100" 
            [value]="iterableData" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both"
            styleClass="p-datatable-gridlines p-datatable-nested" 
            [pageLinks]="3"
        (onEditComplete)="onEditComplete($event)" [globalFilterFields]="['key','value']">
        <ng-template pTemplate="header">
            <tr>
                <th>Key</th>
                <th>Value</th>
                <th style="width: 4%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td>{{data.key}}</td>
                <td [pEditableColumn]="data" [pEditableColumnField]="data.key">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input type="text" [(ngModel)]="data.value">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{data.value}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <button class="p-button-danger panel-button" pButton type="button" (click)="deleteProperty(data.key)" icon="pi pi-times"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>