import { Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { ContentGroupService } from '../../services/content-group.service';
import { Subscription, Subject } from 'rxjs';
import { pluck, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'segment-timeline-player',
    templateUrl: 'segment-timeline-player.component.html',
    styleUrls: ['segment-timeline-player.component.css']
})


export class CASegmentTimelimePlayerComponent implements OnInit, OnDestroy {
    @Input() isLoading = false;
    @Output() onSaveMoment: EventEmitter<any> = new EventEmitter();
    public termFacetResults : any[] = [];
    public allowAutoplay = true;
    private error: any;
    public rawContainer: any;
    public videoResource: any;
    private colors = ['rgb(89,196,41)','orange', 'crimson', 'teal', 'hotpink', 'indigo',' blue', 'red', 'green', 'cornflowerblue'];
    private destroy$ = new Subject();
    private segmentsSub: Subscription;

    constructor(
        private cgService: ContentGroupService
        ) {
            this.createSub();
        }


    ngOnInit() {
        console.log("INIT Player");
        const currentCgState = this.cgService.getCurrentCgState();
        if ('allowAutoplay' in currentCgState) {
            this.allowAutoplay = currentCgState['allowAutoplay'];
        }
        this.cgService.updateCgState({
            'allowAutoplay': this.allowAutoplay,
            'autoplay': null, // must be neither true nor false for first (page) load
            'source': 'segment-timeline-init'
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private createSub(): void {
        this.cgService.getCgState()
            .pipe(pluck('activeMediaContainer'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((amContainer) => {
                //console.log('activeMediaContainer this.awContainer', amContainer);
                //console.log('activeMediaContainer change', amContainer);
                // Only watch activeMediaContainer, otherwise will be unnecessarily relaoding
                // Only reload player if GUID is different.
                if (amContainer && (!this.rawContainer || this.rawContainer['guid'] != amContainer['guid'])) {
                    this.rawContainer = amContainer;
                    this.loadPlayer();
                }
          });
    }

    saveMoment() {
        this.onSaveMoment.emit();
    }

    loadPlayer() {
        this.isLoading = true;
        this.termFacetResults = [];
        this.videoResource = this.getVideoResource();
        this.segmentsSub = this.cgService.getSegmentsWithTimestamps()
            .subscribe((segments) => {
                this.termFacetResults = this.colorCodeKeywords(segments);
                this.cgService.updateCgState({
                    'activeKeywords': this.termFacetResults,
                    'segments': this.termFacetResults,
                    'source': 'segment-timeline-player',
                });
                this.segmentsSub.unsubscribe();
                this.isLoading = false;
            }
        );
    }

    getVideoResource() {
      //TODO: do at some point player needs to support DRM videos
      //TODO: make sure that resourse found is based on platform (safari(hls),chrome(mpd)

      // Non DRM playback
      // video = resource needed for video duration info
      // videoResource = actual resource needed for non DRM playback (contains non DRM uri)
      if (this.rawContainer && this.rawContainer.resources) {
        let video = this.rawContainer.resources.find ? this.rawContainer.resources.find(r => r.type.name === 'video') : undefined;  
        let fileResource = this.rawContainer.resources.find ? this.rawContainer.resources.find(r => r.data.video_type === 'manifest_nodrm' && r.status === 'hidden') : undefined;

        if (!video || !video.data.duration || !fileResource) {
          return null;
        }
        // Detect the user browser (assume either Chrome or Safari)
        // If both the user-agents of Chrome and Safari are in the user-agent,
        // it means the browser is Chrome thus, Safari is discarded
        let userAgent = window.navigator.userAgent;
        let chromeAgent = userAgent.indexOf("Chrome") > -1;
        let firefoxAgent = userAgent.indexOf("Firefox") > -1;
        let microsoftEdgeAgent = userAgent.indexOf("Edge") > -1;
        let safariAgent = userAgent.indexOf("Safari") > -1;
        if ((chromeAgent) && (safariAgent)) safariAgent = false;

        // Create a new videoResource object, and assign video duration to this object
        let videoResource = {
          'data': {
            'resources': {
              'streaming_url': ''
            },
            'duration': 0
          }
        };
        videoResource.data.duration = video.data.duration;
        // Depending on the browser, different video uri is assigned to the resource
        if (chromeAgent || firefoxAgent || microsoftEdgeAgent){
          videoResource.data.resources.streaming_url = fileResource.uri + '/.mpd';
        } else if (safariAgent) {
          videoResource.data.resources.streaming_url = fileResource.uri + '/.hls';
        }

        return videoResource;
      }

      return null;
  }

    // Add UI metadata
    private colorCodeKeywords(arr) {
        if (arr.length > this.colors.length ){
            // create additional darker colours
            let missingColorCount = arr.length - this.colors.length;
            for (let i = 0; i < missingColorCount; i++) {
                let col = [];
                col.push(Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255));
                col.sort((a,b) => a - b);
                let i = 1;
                while (col[0] + col[1] + col[2] > 300 && i < 3) {
                    col[2] = 255 - col[2];
                    col.sort((a, b) => a - b);
                    i++;
                }
                col = col.map(a => [Math.random(), a])
                        .sort((a, b) => a[0] - b[0])
                        .map(a => a[1]);
                this.colors.push('rgb(' + col[0] + ',' + col[1] + ',' + col[2] + ')');
              }
        }

        arr.forEach((keyword, i) => { 
            keyword['color'] = this.colors[i];
            keyword['level'] = i;
            keyword['active'] = true;
        });

        let filteredArr = arr.filter(keyword => keyword['timestamps'].length > 0);

        return filteredArr;
    }

    onAutoplayChange() {
        const currentCgState = this.cgService.getCurrentCgState();
        // If checkbox is being unchecked, need to reset
        // `userTimecode` to -1, or else player may start autoplay.
        if (this.allowAutoplay) {
            this.cgService.updateCgState({
                'allowAutoplay': this.allowAutoplay,
            });
        } else {
            this.cgService.updateCgState({
                'allowAutoplay': this.allowAutoplay,
                'userTimecode': -1,
                'playerTimecode': currentCgState['userTimecode']
            });
        }
    }

}
