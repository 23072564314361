<p-panel class="ui-g-12 ui-g-nopad">
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">{{ containerTypeName | titlecase }} Membership</span>
        <ng-content></ng-content>
        <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Add to {{ containerTypeName | titlecase }}" [routerLink]="['/containers', container?.id, 'relatetoparents', containerTypeName]"
            icon="pi pi-plus" style="float:right;margin-left:4px;margin-right:4px;"></button>

    </p-header>

    <div class="items" *ngIf="!isLoading">
        <div class="item" *ngFor="let parentContainer of parentContainers; let i = index">
            <div *ngIf="container.id">
                <div class="content">
                    <container-parent [container]="parentContainer" [childContainer]="this.container" (onRemove)="onRemove($event, parentContainer)"></container-parent>
                </div>
            </div>
        </div>
    </div>

</p-panel>
