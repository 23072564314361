import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { Container } from '../../models';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'pipeline-status',
    templateUrl: 'pipeline-status.component.html'
})

export class PipelineStatusComponent implements OnInit {
    @Input() container: Container;
    @Input() pipelineId: string;
    @Output() onStatusUpdate: EventEmitter < any > = new EventEmitter();

    private pipelineStatusSubscription: any;
    private pipelineStatusRetrySubscription: any;
    private isBusy: boolean;
    private currentStatus = '';
    private previousStatus = '';
    private previousCount = 0;
    public currentCount = 0;
    public statusMessages: Message[] = [];

    private error: string;

    constructor(private pipelineService:PipelineService) {}

    ngOnInit() {
        console.log(this.container);
        if (this.pipelineId || this.container.status === 'waiting' || this.container.status === 'processing') {
            this.getPipelineStatusUpdate();
        } else {
            this.onStatusUpdate.emit({
                status:'none',
                count:0,
                previous_status:'none'  
           });
        }
    }

    ngOnDestroy() {
        this.pipelineStatusSubscription.unsubscribe();
        if (this.pipelineStatusRetrySubscription) {
            this.pipelineStatusRetrySubscription.unsubscribe();
        }
    }

    getPipelineStatusUpdate() {
        // TODO: pipeline-based job check
        let pipelineStatusList = ['running', 'waiting', 'pending'];
        console.log('Job status check enabled');
        let observable, retryObservable;
        if (this.pipelineId) {
            observable = this.pipelineService
                .getPipelineIdAndStatusObservable(this.pipelineId, this.container.origin, pipelineStatusList);
            retryObservable = this.pipelineService
                .getPipelineIdAndStatusObservable(this.pipelineId, 'system', pipelineStatusList);
        } else {
            observable = this.pipelineService
                .getPipelineContextAndStatusObservable(this.container.guid, this.container.origin, pipelineStatusList);
            retryObservable = this.pipelineService
                .getPipelineContextAndStatusObservable(this.container.guid, 'system', pipelineStatusList);
        }

        this.pipelineStatusSubscription = observable
            .subscribe(
                res => {
                    console.log(res)
                    this.isBusy = true;
                    let pipelineStatus = '';
                    let pipelineCount = 0;

                    if ('count' in res && res['count'] === 0) {
                        this.pipelineStatusSubscription.complete();
                        this.pipelineStatusRetrySubscription = retryObservable
                            .subscribe(
                                retryRes => {
                                    console.log(retryRes);
                                    this.isBusy = true;
                                    pipelineStatus = '';
                                    pipelineCount = 0;
                                    if ('count' in retryRes && retryRes['count'] === 0) {
                                        this.statusMessages = [];
                                        if (this.container.status === "processing") {
                                            this.error = "Error Processing Content.  Please contact you system admin.";
                                            this.statusMessages.push({ severity: 'error', summary: 'Error Processing Content', detail: "Please contact you system admin." });
                                            this.isBusy = false;
                                            pipelineStatus = 'none';
                                        } else if (this.container.status === "waiting") {
                                            this.statusMessages.push({ severity: 'warn', summary: 'Waiting for Content Processing', detail: "Waiting for Processing" });
                                            pipelineStatus = 'wait';
                                        } else {
                                            this.isBusy = false;
                                            pipelineStatus = 'none';
                                        }

                                        if (!this.isBusy) {
                                            this.pipelineStatusRetrySubscription.unsubscribe();
                                        }
                                    } else if ('count' in retryRes && retryRes['count'] > 0) {
                                        pipelineStatus = 'busy';
                                        pipelineCount = retryRes['count'];
                                    }
                                    this.updateStatusAndEmitEvent(pipelineStatus, pipelineCount);
                                },
                                err => {
                                    this.error = err;
                                    this.isBusy = false;
                
                                    this.pipelineStatusRetrySubscription.unsubscribe();
                                },
                                () => {}
                            );
                    } else if ('count' in res && res['count'] > 0) {
                        pipelineStatus = 'busy';
                        pipelineCount = res['count'];
                    }

                    this.updateStatusAndEmitEvent(pipelineStatus, pipelineCount);
                },
                err => {
                    this.error = err;
                    //this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: "Jobs Service API offline" });
                    this.isBusy = false;

                    this.pipelineStatusSubscription.unsubscribe();
                },
                () => {}
            );
    }

    updateStatusAndEmitEvent(pipelineStatus, pipelineCount) {
        this.currentStatus = pipelineStatus;
        this.currentCount = pipelineCount;

        if (!this.previousStatus) {
            this.previousStatus = this.currentStatus;
        }

        console.log(this.isBusy, this.previousCount, this.currentCount, pipelineStatus);

        if (this.previousCount !== this.currentCount || pipelineStatus == 'wait') {
            this.previousCount = this.currentCount;
        
            this.onStatusUpdate.emit({
                status: pipelineStatus,
                previous_status: this.previousStatus,
                count: pipelineCount,
            });
            this.previousStatus = this.currentStatus;
        }
    }
}
