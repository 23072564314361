<p-autoComplete [(ngModel)]="selectedValues"
                (ngModelChange) ="onChange()"
                [suggestions]="suggestedValues"
                (completeMethod)="getValueSuggestions($event)"
                (onBlur)="onDeselect($event)"
                [minLength]="1"
                placeholder="Search"
                field="label"
                name="keywords"
                [multiple]="true"
                appendTo="body"
                forceSelection="true"
                [autoHighlight]="true"
                delay="0"
                emptyMessage="No match found."
                unique="true">
</p-autoComplete>