<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">{{ title }}</span>
            <div class="ui-g-12 ui-lg-8" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" [rows]="rows" [paginator]="true" paginatorPosition="bottom" selectionMode="single"
        (onRowSelect)="onRowSelect($event)" [(selection)]="container"
        styleClass="p-datatable-gridlines p-datatable-nested" [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Label</th>
                <th>GUID</th>
                <th>Description</th>
                <th style="width:6em">
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-container>
            <tr [pSelectableRow]="container">
                <td style="word-break: break-all;">{{ container.data?.label }}</td>
                <td style="word-break: break-all;">{{ container.guid }}</td>
                <td style="word-break: break-all;">{{ container.data?.description }}</td>
                <td>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove(container)" icon="pi pi-times" style="margin:auto;display:block;font-size:13px"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>

</p-panel>