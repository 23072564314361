<landing-notification></landing-notification>
<div class="card card-w-title top-level-card">
    <h1>Welcome, {{ userName || 'User' }}</h1>

    <div class="ui-g">
        <div class="ui-g-12 ui-md-8">
          <landing-whatsnew (postSelect)="onPostSelect($event)"></landing-whatsnew>
          <landing-postmodal [selectedPost]="selectedPost"></landing-postmodal>
        </div>

        <div class="ui-g-12 ui-md-4">
            <landing-content-activity
                [label]="'Recently Modified Content'"
                [itemCount]=5
                [ordering]="'-updated_date'"
                [type]="['media', 'episode', 'movie', 'series', 'season', 'channel']"
                [status]=allContainerStatuses
                data-element-id="app-landing-recently-modified-content">
            </landing-content-activity>
            <landing-content-activity
                [label]="'Recently Added Content'"
                [itemCount]=5
                [ordering]="'-created_date'"
                [type]="['media', 'episode', 'movie', 'series', 'season', 'channel']"
                [status]=allContainerStatuses
                data-element-id="app-landing-recently-added-content">
            </landing-content-activity>
            <landing-quick-links></landing-quick-links>
        </div>

    </div>
</div>
