<p-panel>
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">App View Metadata</span>
    </p-header>

    <div style="padding:0.5em;overflow:hidden;">
        <div class="ui-sm-12 ui-md-6 ui-lg-3 ui-g-3">
            <label for="superLabel">Super Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data['super_label']" name="superLabel" data-element-id="app-content-forms-view-metadata-form-panel-super-label">
        </div>
        <div class="ui-sm-12 ui-md-6 ui-lg-3 ui-g-3">
            <label for="displayLabel">Display Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data['display_label']" name="displayLabel" data-element-id="app-content-forms-view-metadata-form-panel-display-label">
        </div>
        <div class="ui-sm-12 ui-md-6 ui-lg-3 ui-g-3">
            <label for="subLabel">Sub Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data['sub_label']" name="subLabel" data-element-id="app-content-forms-view-metadata-form-panel-sub-label">
        </div>
        <div class="ui-sm-12 ui-md-6 ui-lg-3 ui-g-3">
          <container-default-ordering [container]="container"></container-default-ordering>
      </div>
    </div>
</p-panel>
