import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Container } from '../../content/models';
import { ContainerService } from '../../content/services/container/container.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'landing-whatsnew',
    templateUrl: 'landing-whatsnew.component.html',
    styleUrls: ['landing-whatsnew.component.css'],
    providers: [ContainerService],
})
export class WhatsNewComponent implements OnInit, OnDestroy {
    public posts: Array<Container>;
    private fetchPostsSubscription: Subscription;
    public isLoading: Boolean = false;
    @Output() postSelect: EventEmitter < any > = new EventEmitter();

    constructor(
        private containerService: ContainerService
    ) {}

    ngOnInit() {
      this.isLoading = true;
      this.fetchPostsSubscription = this.containerService.clientSearch(
        'document', 'published', ['-updated_date'], 'system', 5, 0, [], [], 'post'
        ).subscribe(
        res =>this.posts = res.results,
        err => console.error(err),
        () => this.isLoading = false
      )
    }

    sanitize(html: string) {
      // Regular expression to remove HTML tags
      return html?.replace(/<[^>]*>/g, '');
    }

    onSelect(post: Container) {
      this.postSelect.emit(post)
    }

    ngOnDestroy() {
      this.fetchPostsSubscription?.unsubscribe();
    }
}
