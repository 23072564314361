<page-header [showSpinner]="isLoading" [error]="error?.statusText">

</page-header>

<div *ngIf="!isLoading">
    <div id="largeImage" class="container" [ngStyle]="{'background-image':'url('+this.backgroundImageURL+')'}">

        <img class="right-block" [src]="container.resources['hero']">
        <div class="description-box">

            <h1>{{container?.title}}</h1>
            <p>{{container?.description}} </p>
            <div class="button1">Play All</div>
            <div class="button2">Info</div>
        </div>
    </div>


    <div *ngIf="!isLoading">
        <div *ngFor="let container of this.container.collections; let i = index" class="ui-g-12">
            <div *ngIf="container.containers.length !== 0">
                <div class="card card-w-title">
                    <h1>{{container.title}}</h1>
                    <owl-carousel [options]="{items: 5, dots: false, navigation: false, stagePadding: 0, margin: 10}" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                        <div class="item" *ngFor="let in_container of container.containers; let i = index">
                            <div class="thumbnail-image">
                                <img class="thumbnail-image" [src]="in_container['image_small']">
                                <div class="text-block">
                                    <p>{{in_container['title']}}
                                        <p>
                                            <button type="text" (click)="showDialog(in_container['video'])" pButton icon="fa fa-external-link-square" label="Play"></button>
                                </div>
                            </div>
                        </div>
                    </owl-carousel>
                </div>
            </div>
        </div>
    </div>
</div>
