import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';


@Component({
    selector: 'view-metadata-form-panel',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'view-metadata-form-panel.component.html'
})
export class ViewMetadataFormPanelComponent implements OnInit {
    @Input() container: Container;

    ngOnInit() {}

}
