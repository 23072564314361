import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppContext } from '../../../app.context';
import { ContainerService } from '../../services/container/container.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Container } from '../../models';
import { Segment } from '../../models';

@Component({
    selector: 'segment',
    providers: [
        ContainerService,
        SegmentService,
    ],
    templateUrl: 'segment.component.html',
    styleUrls: ['segment.component.css']
})
export class SegmentComponent implements OnInit {
    @Input() segment: Segment;
    @Input() parentContainer: Container;
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    public typeString: string;

    private segmentId;
    private sub;
    private editRoute: Array<any>;

    constructor(
        public appContext: AppContext,
        private containerService: ContainerService,
        private segmentService: SegmentService,
    ) { }

    ngOnInit(): void {
        if (typeof this.segment.type === 'string') {
            this.typeString = this.segment.type;
        } else {
            this.typeString = '';
        }
        // Segment needs to be refetched as segment ID is not available
        this.sub = this.segmentService
            .getByGuid(this.segment['guid'])
            .subscribe(
                res => {
                    this.segmentId = res.id
                    this.editRoute = this.getEditRoutes();
                },
                err => console.log(err),
            () => {
                this.sub.unsubscribe();
        })
    }

    statusLabelClass() {
        return this.containerService.getStatusLabelClass(this.segment.status);
    }

    remove(e, segment) {
        let parentTitle = this.parentContainer.data['title'] || this.parentContainer.data['name'];
        let thisTitle = this.segment.label;
        if (confirm('Are you sure you want to remove ' + thisTitle + ' from ' + parentTitle + '?')) {
            console.log('remove', e, segment);
            this.segmentService
                .deleteSegmentbyGuid(segment.guid)
                .subscribe(
                    res => {
                        console.log(res);
                        this.onRemove.emit({
                            segment: this.segment
                        });
                    },
                    err => console.log(err)
                );
        }
    }

    getEditRoutes() {
        // Add editRoutes here
        let editRoutes = {
          'workflow_state': ['/containers', this.parentContainer.id, 'segments', 'workflow_state', this.segmentId],
        }

        if (this.typeString in editRoutes) {
            return editRoutes[this.typeString];
        } else {
            return ['/containers', this.parentContainer.id, 'segments', this.segmentId];
        }
    }

}
