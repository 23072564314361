<div class="card card-w-title top-level-card">
    <h1>Edit Media{{!isLoading && container ? ': ' + container.data['title'] : ''}}</h1>
    <div *ngIf="!container && !isLoading && !isProcessing && requiredMsgs">
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <button pButton type="button" label="Back" (click)="onBack($event)" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
    </div>

    <page-header title="Edit Media{{!isLoading && container ? ': ' + container.data['title'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <div *ngIf="container && !isLoading && !isProcessing">
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <media-form #form [container]="container" [seasons]="seasons" (formReparent)="onReparenting($event)" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)" (pipelineTriggered)="onPipelineTriggered($event)" (statusError)="onStatusError($event)"></media-form>

        <hr>

        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload"  *ngxPermissionsOnly="['view.show.upload']"></button>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource', 'upload']" *ngxPermissionsOnly="['object.write.all', 'object.write.media']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus" data-element-id="app-content-components-media-edit-create"></button>
            </resource-datarow>
        </div>

        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="collection"></container-membership-row>
        </div>

        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="episode">
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource']" *ngxPermissionsOnly="['object.write.all', 'object.write.media']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
            </container-membership-row>
        </div>

        <div class="ui-g-12">
            <policy-table [parentContainer]="container" [modifyPermissions]="['view.show.admin']">
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['policy']" *ngxPermissionsOnly="['object.write.all', 'object.write.media']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <button *ngxPermissionsOnly="['view.show.admin']" pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'policy']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </policy-table>
        </div>

        <div class="ui-g-12">
            <platform-table [containers]="platforms" [parentContainer]="container">
                <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'platform']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </platform-table>
        </div>

        <div class="ui-g-12">
          <container-paginated-datarow [reloadNotification]=reloadNotification type="notification" itemsPerPage = 20 [parentContainer]="container" title="Notifications" filterBy="data.name, data.notification_type">
            <container-notification-edit (addNotification)="reloadNotification= !reloadNotification" [container]="container" style="float:right;margin-right:4px;" *ngxPermissionsOnly="['view.show.beta']"></container-notification-edit>
          </container-paginated-datarow>
        </div>

        <hr>

        <div class="ui-g-12">
          <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
