<spinner *ngIf="containers.length === 0"></spinner>


<p-table [value]="containers" [rows]="10" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="header">
        <tr>
            <th [style]="{'width':'20%'}">Name</th>
            <th [style]="{'width':'10%'}">Type</th>
            <th [style]="{'width':'4%'}">Edit</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-container>
        <tr>
            <td>{{container.data['name']}}</td>
            <td><div class="label label-default">{{container.data.policy_type}}</div></td>
            <td><button pButton type="button" class="p-button-danger" (click)="onRemove($event, container)" icon="pi pi-times"></button>
            </td>
        </tr>
    </ng-template>
</p-table>
