import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ContentGroupService } from '../../services/content-group.service';
import { CommerceService } from '../../services/commerce.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'contextual-ads-graph',
  providers: [
    CommerceService
  ],
  templateUrl: 'contextual-ads-graph.component.html',
})

export class CAGraphComponent implements OnInit, OnDestroy {

    @Input() inputContainer: any;
    @Input() segmentKeywords:any;
    private cgState: any;
    private destroy$ = new Subject();
    public skipPlay = true;
    public keywordsLength:number;
    public error: string;
    public chartData = [];

    view: any[] = [120, 60];

    // options
    legend: boolean = false;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = false;
    yAxis: boolean = false;
    showYAxisLabel: boolean = false;
    showXAxisLabel: boolean = false;
    xAxisLabel: string = '';
    yAxisLabel: string = '';
    timeline: boolean = false;

    colorScheme = {
      domain: ['#E53A74', '#F76C40', '#F7A224', '#F8C428', '#F7E758','#FFFFFF']
    };

    constructor(
      private cgService: ContentGroupService,
      private commerceService: CommerceService,
    ) {}

    ngOnInit() {
        this.keywordsLength = this.segmentKeywords.length;
        if (this.segmentKeywords.length > 5) {
            this.keywordsLength = 5;
        }
        this.getChartData();
    }

    ngAfterViewInit(): void {
      this.destroy$.next(false);
      this.createCgSub();
    }

    ngOnDestroy() {
      this.destroy$.next(true);
      this.destroy$.complete();
    }

    private createCgSub(): void {
      this.cgService.getCgState().pipe(takeUntil(this.destroy$))
          .subscribe((cgState) => {
              this.cgState = cgState;
          });
    }

    getChartData() {
      let guid = this.inputContainer.guid
      let duration = this.inputContainer.data['duration']
      let data = ''
      this.commerceService
          .getFacetCountsForSegmentTermByParent(guid, this.segmentKeywords)
          .subscribe(
              res => {
                  let result = res;
                  data = result['facets']['_filter_parent_term_histogram']['parent_term_histogram']['buckets'];
              },
              err => {
                  this.error = err;
              },
              () => {
                this.processData(data, duration);
              }
          )
    }

    processData(data, duration) {
      // Get and set media duration
      let maxTime = duration
      data[0]['term_histogram']['buckets'].forEach(timeBucket => {
        if (timeBucket['key'] > maxTime) {
          maxTime = timeBucket['key']
        }
      })
      // Get keywords count
      let differentKeywordsCountMap = new Map();
      let totalKeywordsCountMap = new Map();
      data[0]['term_histogram']['buckets'].forEach(timeBucket => {
        if (timeBucket['value']['buckets'] && timeBucket['value']['buckets'].length > 0) {
          // Find number of different keywords count
          differentKeywordsCountMap.set(timeBucket['key'], timeBucket['value']['buckets'].length);
           // Find total number of keywords count
           let totalDocCount = 0;
           timeBucket['value']['buckets'].forEach(record => {
               totalDocCount += record['doc_count']
           })
           totalKeywordsCountMap.set(timeBucket['key'], totalDocCount);
        }
      });
      // Use the episode duration (or from fall back option)
      // to fill in missing time intervals
      for (let time = 0; time <= maxTime; time += 120) {
        if (!(differentKeywordsCountMap.has(time))) {
            differentKeywordsCountMap.set(time, 0)
        }
        if (!(totalKeywordsCountMap.has(time))) {
            totalKeywordsCountMap.set(time, 0)
        }
      }
      // momentStrength array consists of a scale of strength which represents number of different keywords count (impacts graph color)
      // from 'very_strong' being the highest strength to 'very_weak' being the lowest strength
      // The total number of keywords count is assigned to each time interval for each strength (impacts graph height)
      // The toal number of keywords count is 0 at default
      let inputData = [];
      let momentStrength = ['very_strong','strong', 'moderate', 'slightly_moderate', 'weak', 'very_weak'];
      momentStrength.forEach(strength => {
          let momentStrengthData = {}
          momentStrengthData['name'] = strength;
          momentStrengthData['series'] = [];
          for (let time = 0; time <= maxTime; time += 120) {
            let seriesData = {};
            let strengthValue = differentKeywordsCountMap.get(time) / this.keywordsLength;
            seriesData['name'] = time;
            seriesData['value'] = 0;

            if (strengthValue >= 1.0 && strength == 'very_strong') {
                seriesData['value'] = totalKeywordsCountMap.get(time);
            }
            if (strengthValue >= 0.8 && strengthValue < 1.0 && strength == 'strong') {
                seriesData['value'] = totalKeywordsCountMap.get(time);
            }
            if (strengthValue >= 0.6 && strengthValue < 0.8 && strength == 'moderate') {
                seriesData['value'] = totalKeywordsCountMap.get(time);
            }
            if (strengthValue >= 0.4 && strengthValue < 0.6 && strength == 'slightly_moderate') {
                seriesData['value'] = totalKeywordsCountMap.get(time);
            }
            if (strengthValue >= 0.2 && strengthValue < 0.4 && strength == 'weak') {
                seriesData['value'] = totalKeywordsCountMap.get(time);
            }
            if (strengthValue < 0.2 && strength == 'very_weak' ) {
                seriesData['value'] = totalKeywordsCountMap.get(time);
          }
          momentStrengthData['series'].push(seriesData);
        }
        inputData.push(momentStrengthData);
      })
      this.chartData = inputData;
    }

    onSelect(event) {
      this.cgService.updateCgState({
        'graphTimecode': event.name,
        'skipPlay': true,
        'source': 'contextual-ads-graph',
      });
    }

}