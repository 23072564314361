<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Servers</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                  
            </div>
                 <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                    <!-- <button pButton type="button" label="Create" [routerLink]="['/policy', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button> -->
                    <button pButton type="button" label="Add" [routerLink]="['/containers', parentContainer?.id, 'relate', 'server']" icon="pi pi-plus" style="float:right;height:100%;"></button>

                </div>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.name', 'data.location', 'data.path']" 
            [rows]="10" 
            [paginator]="false" 
            paginatorPosition="bottom" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines  p-datatable-nested"
            [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Name</th>
                <th style="width:6%">Protocol</th>
                <th>Location</th>
                <th>Path</th>
                <th style="width:8%">Role</th>
                <th style="width:8%">Type</th>
                <th style="width:8%">Origin</th>
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-server>
            <tr>
                <td>{{server.data.name}}</td>
                <td>{{server.data.protocol_type}}</td>
                <td>{{server.data.location}}</td>
                <td>{{server.data.path}}</td>
                <td>{{server.data.role}}</td>
                <td>{{server.data.server_type}}</td>
                <td>{{server.origin}}</td>
                <td>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, server)" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>