import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Profile } from '../../models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { MessageService } from 'primeng/api';


@Injectable()
export class ProfileService {
  private baseUrl = `${environment.PROXY_API_BASE_URL}/content/user/profiles`;

  constructor(
      private http: HttpClient,
      private authService: AuthService,
      private msgService: MessageService,
  ) {}

  _appendHeaders() {
    return new HttpHeaders()
        .append('Accept', 'application/json')
        .append('Content-Type', 'application/json')
        .append('Authorization', this.authService.getAuthHeader())
  }

  list(): Observable < any > {
    let res = this.http.get(`${this.baseUrl}`,
                            { headers: this._appendHeaders()})
    return this._handleRes(res)
  }

  get(id): Observable < any > {
    let res = this.http.get(`${this.baseUrl}/${id}/`,
                          {headers: this._appendHeaders()})
    return this._handleRes(res)
  }

  save(profile: Profile): Observable < any > {
    let res = this.http.put(`${this.baseUrl}/${profile.id}/`,JSON.stringify(profile),
                          { headers: this._appendHeaders()})
    return this._handleRes(res)
  }

 // ***** To handle errors received in API response *****
  _handleRes(res): Observable < any > {
    return res.pipe(tap({
      next: null,
      error: (err) => this._handleError(err),
      complete: null,
    }))
  }

  _handleError(err) {
    let errMsg = ''
    if (err.error instanceof Object && err.status!== 0) {
      Object.keys(err.error).map(key => {
        errMsg = key.charAt(0).toUpperCase() + key.slice(1) +' : '+err.error[key]
      })
    } else{ errMsg = err.status !== 0 ? err.status +'  '+err.statusText: err.statusText }
    console.log(errMsg)
    this._showMsg(errMsg, 'error')
  }

  _showMsg(msg, severity) {
    this.msgService.add({severity: severity, summary:'', detail:msg });
  }

}
