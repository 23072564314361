import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { CatalogFormComponent } from '../../forms/catalog-form/catalog-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'catalog-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'catalog-edit.component.html',
    styleUrls: ['catalog-edit.component.css']
})
export class CatalogEditComponent implements OnInit, OnDestroy {
  private sub: any;
  public container: Container;
  public error: HttpResponse<any>;
  public isLoading: boolean = false;
  public msgs: Message[] = [];

  // parameters moved out of the form component
  @ViewChild('queryset') querysetEditor: JsonEditorComponent;
  @ViewChild('filters') filtersEditor: JsonEditorComponent;
  @ViewChild('excludes') excludesEditor: JsonEditorComponent;
  private editorOptionsQueryset: JsonEditorOptions;
  private editorOptionsFilters: JsonEditorOptions;
  private editorOptionsExcludes: JsonEditorOptions;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private msgSrv: MessageService,
    ) {}

    ngOnInit() {
        console.log('catalog edit initialized');
        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('container id-->', id);
            this.isLoading = true;
            this.containerService.get(id)
            .subscribe(
                    res => {
                        console.log('res based on container id-->', res);
                        this.container = res
                        this.initValues()
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.msgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: 'Success', detail: 'Catalog saved.' });
                    this.container = Object.assign(new Container(), this.container);
                    //this.location.back();
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgSrv.add({ key: 'caMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })
    
                    }
                    console.log(err);
                    this.error = err.statusText;
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Catalog could not be saved.' });

                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        this.location.back();
    }

    private changeJson(field: string, editor: JsonEditorComponent) {
      try {
          var editorData = editor.get();
      } catch (e) {
          // Keep current value
          return;
      }
      this.container.data[field] = editorData;
  }

    private initValues() {
      this.editorOptionsQueryset = new JsonEditorOptions();
      this.editorOptionsQueryset.mode = 'tree';
      this.editorOptionsQueryset.modes = ['code', 'text', 'tree', 'view'];
      this.editorOptionsQueryset.statusBar = true;
      this.editorOptionsQueryset.expandAll = true;
      this.editorOptionsQueryset.onChange = () => this.changeJson('queryset', this.querysetEditor);

      this.editorOptionsFilters = new JsonEditorOptions();
      this.editorOptionsFilters.mode = 'tree';
      this.editorOptionsFilters.modes = ['code', 'text', 'tree', 'view'];
      this.editorOptionsFilters.statusBar = true;
      this.editorOptionsFilters.expandAll = true;
      this.editorOptionsFilters.onChange = () => this.changeJson('queryset_q_object_list', this.filtersEditor);

      this.editorOptionsExcludes = new JsonEditorOptions();
      this.editorOptionsExcludes.mode = 'tree';
      this.editorOptionsExcludes.modes = ['code', 'text', 'tree', 'view'];
      this.editorOptionsExcludes.statusBar = true;
      this.editorOptionsExcludes.expandAll = true;
      this.editorOptionsExcludes.onChange = () => this.changeJson('queryset_exclude_list', this.excludesEditor);
    }
}
