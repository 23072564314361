import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../auth/auth.service';
import { Container } from '../../content/models';

@Injectable()
export class IntegrationTestService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private authService: AuthService
    ) {}

    _appendHeaders(server: Container) {
        return new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', server.data['priv_key_id']) //TODO:replace with server ref
    }

    private showError(err, summary = 'Error') {
        const detail = (err.error && err.error.detail) || err.statusText;
        this.messageService.add({ key: 'api', severity: 'error', summary, detail });
    }

    runTestSuite(testSuiteId: String, server: Container): Observable<any> {

        let url = server.data['protocol_type'] + "://" + server.data['location'] + server.data['path']
        let body = {};

        body["transaction_type"] = "start",
        body["guid"] = testSuiteId
        body["initiator"] = "VMS ("+ this.authService.getAuthenticatedUserName() + ")";
        
        console.log('Calling its service:',url)
        return this.http
            .post(
                `${url}`,
                JSON.stringify(body), { headers: this._appendHeaders(server)  }
            ).pipe(tap(
                null,
                err => {
                    if (err.status != '409'){
                        this.showError(err);
                    }
                    console.log('This is the error: ', err.status);

                }
            ));
    }

}

