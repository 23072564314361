import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models';
import { dedicatedEditForms as dedicatedEditFormsList } from '../../../shared/enum';


@Component({
    selector: 'worklist-generic-form',
    providers: [
        ContainerService,
    ],
    templateUrl: 'worklist-generic-form.component.html',
    styleUrls: ['worklist-generic-form.component.css']
})
export class WorklistGenericFormComponent implements OnInit {
    @Input() containerId: Number;
    public container: Container;
    public isLoading = false;
    public publishDate: Date;
    private dedicatedEditForms = dedicatedEditFormsList

    constructor(private containerService: ContainerService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['containerId'].previousValue != changes['containerId'].currentValue && this.containerId) {
          this.isLoading = true
          this.containerService
          .get(this.containerId)
          .subscribe(
              res => {
                  this.container = res instanceof Container
                      ? res
                      : Container.fromJson(res);
                  this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
              },
              err => console.log(err),
              ()  => this.isLoading = false
          )
        }
    }

    ngOnInit() {}

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    getThumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }
}
