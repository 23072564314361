import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Container, EntityType } from '../../models';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { MenuItem } from 'primeng/api';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
    selector: 'container-datarow',
    viewProviders: [
        DragulaService
    ],
    templateUrl: 'container-datarow.component.html',
    styleUrls: ['container-datarow.component.css']
})
export class ContainerDatarowComponent implements OnInit, OnDestroy {
    @Input() set containers(containers: Array<Container>) {
        console.log(containers);
        this.allContainers = containers;

        this.shownContainers = [];
        containers.forEach(element => {
            this.shownContainers.push(element);
            element['show'] = true;
        });

        this.filter();
    }
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() rows: number;
    @Input() filterBy: string;
    @Input() sortOptions: Array<any>;
    @Input() isLoading: boolean;
    @Output() reorder: EventEmitter<any> = new EventEmitter();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();

    private error: string;
    public allContainers: Array<Container> = [];
    public shownContainers: Array<Container> = [];
    private sortKey: string;
    private sortField: string;
    private sortOrder: number;
    private activeFilter: string;
    private filterByFields: Array<any>;
    public name: string = '';
    private dragulaSub: Subscription;
    public collapsed = false;


    constructor(
        private dragulaService: DragulaService
    ) { }

    ngOnInit() {
        let filters = this.filterBy.split(', ');
        this.filterByFields = [];
        filters.forEach(element => {
            this.filterByFields.push(element.split('.'));
        });

        this.name = Math.random()
            .toString(36)
            .substring(7);

        this.dragulaService.createGroup(this.name, {});

        this.dragulaSub = this.dragulaService.dropModel(this.name)
            .subscribe((e) => {
                console.log('drop', e);
                this.onDrop(e);
        });
    }

    ngOnDestroy() {
        this.dragulaSub.unsubscribe();
    }

    private onDrop(e) {
        if (this.parentContainer) {
            let el = e['el'];
            let droppedItem = e['item'];
            let oldIndex = this.allContainers.indexOf(droppedItem);
            let newIndex = this.getElementIndex(el);
            // depending on the direction of dragging,
            // previous item can be retrieved by either getting using the new index
            // of dragged item of the old container array, or by using new index - 1
            let prevItemIndex = oldIndex > newIndex ? newIndex - 1 : newIndex;
            let droppedAfterItem = prevItemIndex >= 0 ? this.allContainers[prevItemIndex] : null;

            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: droppedItem,
                to: droppedAfterItem
            });
        }
    }

    private update(e) {
        this.onUpdate.emit({});
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++);
        return i;
    }

    onFilter(value) {
        this.activeFilter = value;

        this.filter();
    }

    filter() {
        this.shownContainers.forEach(container => {
            if (this.activeFilter && this.activeFilter !== '') {
                container['show'] = false;
                this.filterByFields.forEach(filter => {
                    if (!container['show']) {
                        var result = container;
                        filter.forEach(element => {
                            result = result[element];
                        });
                        if (String(result).toLowerCase().includes(this.activeFilter.toLowerCase())) {
                            container['show'] = true;
                        }
                    }
                });
            } else {
                container['show'] = true;
            }
        })
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }

        this.sort();
    }

    sort() {
        if (!this.sortField || this.sortField === '') {
            this.shownContainers = [];
            this.allContainers.forEach(element => {
                this.shownContainers.push(element);
            });

            this.filter();
            return;
        }

        this.shownContainers.sort((a, b) => {
            var value1 = this.resolveObjectField(a, this.sortField);
            var value2 = this.resolveObjectField(b, this.sortField);

            var result = null;
            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            return (this.sortOrder * result);
        });
    }

    resolveObjectField(object: Object, value: string) {
        var tokens = value.split('.');
        var result = object;
        tokens.forEach(element => {
            result = result[element];
        });
        return result;
    }

    containerRemoved(e) {
        let i = this.allContainers.indexOf(e.container);
        this.allContainers.splice(i, 1);

        this.shownContainers = [];
        this.allContainers.forEach(element => {
            this.shownContainers.push(element);
        });

        this.filter();
        this.sort();
    }
}
