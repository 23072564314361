<p-multiSelect [options]="originList" 
               [(ngModel)]="selectedOrigins"
               (onChange)="onSelectedOriginChange($event)"
               [style] = "{width:'100%', height:'100%'}"
               [selectedItemsLabel]="'{0} selected'"
               [defaultLabel]="'Select Origins'"  
               appendTo="body">
               <ng-template let-item pTemplate="item">
                    <div style="font-size:14px;float:right;margin-top:4px">{{item.label}}</div>
               </ng-template>
</p-multiSelect>