<button pButton type="button" class="p-button-success" icon="pi pi-eye" style="float:right; margin-left:4px;"
    (click)="showResultsModal()" [disabled]="container.data['test_run_status'] == 'running' || container.data['test_run_status'] == 'timedout'"> </button>

<p-dialog header="Test Run Results" [modal]="true" [(visible)]="displayDialog" (visibleChange)="closeDialog()"
    responsive="true">
    <spinner *ngIf="isLoading"></spinner>
    <div class="ui-g">
        <div class="card card-w-title top-level-card">
            <h1>{{resultsLabel}}</h1>
            <div class="ui-g card-content">
                <div class="ui-g-6 ui-g-nopad">
                    <ngx-charts-advanced-pie-chart *ngIf="!isLoading" class="ui-g-12" style="padding:0;"
                        [scheme]="colorScheme" [results]="formattedDataNGX" [gradient]="gradient"
                        (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"
                        [view]="view">
                    </ngx-charts-advanced-pie-chart>
                </div>
                <div class="ui-g-6">
                    <div class="ui-g-6">
                        <p-card header="Duration">
                            <p class="m-0">
                                {{duration/1000}}s
                            </p>
                        </p-card>
                    </div>
                    <div class="ui-g-6">
                        <p-card header="Created By">
                            <p class="m-0">
                                {{createdBy}}
                            </p>
                        </p-card>
                    </div>
                </div>
                <div class="ui-g-6">

                    <p-table #dt [value]="resultPassDetails" [paginator]="false"
                        styleClass="p-datatable-gridlines  p-datatable-nested" [responsive]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Label</th>
                                <th>Result</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-result>
                            <tr>
                                <td>{{result.title}}</td>
                                <td>
                                    <div class="label label-success">Passed</div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>

                <div class="ui-g-6">

                    <p-table #dt [value]="resultFailDetails" [paginator]="false"
                        styleClass="p-datatable-gridlines  p-datatable-nested" [responsive]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Label</th>
                                <th>Code</th>
                                <th>Result</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-result>
                            <tr>
                                <td>{{result.title}}</td>
                                <td>
                                    <div *ngIf="result.error && result.error.code">{{result.error.code}}</div>
                                </td>
                                <td>
                                    <div class="label label-danger">Failed</div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>

            </div>
        </div>
    </div>
    <p-footer>

        <button type="button" pButton icon="fa fa-close" (click)="closeDialog()" label="Close"
            class="p-button-secondary"></button>
    </p-footer>


    <p-dialog>