import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerComponent } from '../container/container.component';
import { Container } from '../../models';

@Component({
    selector: 'episode-container-platform-table',
    templateUrl: 'episode-container-platform-table.component.html',
    styleUrls: ['episode-container-platform-table.component.css']
})
export class EpisodeContainerPlatformTableComponent implements OnInit {
    @Input() containers: any;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {}
}
