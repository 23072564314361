import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UXAnalyticsHotJarProvider } from './ux-analytics.provider.hotjar';
import { UXAnalyticsProvider, UXAnalyticsUserAttributes } from './ux-analytics.types';
import { environment } from "../../environments/environment"

type UXAnalyticsProviderMap = {
    [providerName: string]: UXAnalyticsProvider;
};
type UXAnalyticsProviderMapReadonly = Readonly<UXAnalyticsProviderMap>;

@Injectable()
export class UXAnalyticsService {
    private pathSub: Subscription;
    private userSub: Subscription;
    private providerMap: UXAnalyticsProviderMapReadonly;
    private provider: string = 'hotjar';

    constructor(private router: Router, private authService: AuthService) {
        this.providerMap = {
            hotjar: new UXAnalyticsHotJarProvider(environment.HOTJAR_ID).init(),
        };
        this.userSub = this.authService.userState.subscribe(res =>
            this.getProvider().identifyUser(this.authService.getAuthenticatedUserId())
        );
    }

    getProvider(providerName: string = this.provider) {
        return this.providerMap[providerName];
    }

    recordEvent(eventName: string) {
        this.getProvider().recordEvent(eventName);
    }

    recordUserAttributes(attributes: UXAnalyticsUserAttributes, userId: number | string = undefined) {
        if (userId === undefined && !this.authService.getAuthenticatedUserId()) throw new Error('Cannot record user attributes without id');
        this.getProvider().identifyUser(userId || this.authService.getAuthenticatedUserId(), attributes);
    }

    recordPathChange(path: string) {
        this.getProvider().recordPathChange(path)
    }

    startTrackingPathChanges() {
        this.pathSub = this.router.events.subscribe((e: RouterEvent) => {
            if (e instanceof NavigationEnd) {
                this.getProvider().recordPathChange(e.url).identifyUser(this.authService.getAuthenticatedUserId());
            }
        });
    }

    stopTrackingPathChanges() {
        if (this.pathSub) this.pathSub.unsubscribe();
    }

    ngOnDestroy() {
        if (this.pathSub) this.pathSub.unsubscribe();
        if (this.userSub) this.userSub.unsubscribe();
    }
}
