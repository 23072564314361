
<p-card styleClass="p-card-shadow">
    <p-messages [(value)]="statusMessages"></p-messages>

    <div style="width:100%;font-size:16px;text-align:left;padding-bottom:0.5em"><b>Processing {{currentCount}} job(s) for {{container.guid}}</b></div>

    <div class="progress">
        <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
        </div>
    </div>

</p-card>