import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { ContainerDataviewComponent } from '../../../content/components/container-dataview/container-dataview.component'

@Component({
    selector: 'views',
    providers: [
        ContainerService
    ],
    templateUrl: 'views.component.html',
    styleUrls: ['views.component.css']
})

export class ViewsComponent implements OnInit {
    public containers = [];
    private views = [];
    private isLoading = false;
    private error: string;
    private sortField: string;
    private sortOrder: number;
    private status: string;
    private parentGuid: string;
    @ViewChildren(ContainerDataviewComponent) cdComponents: QueryList<ContainerDataviewComponent>

    constructor(
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        this.sortField = 'label';
        this.sortOrder = 1;
        this.status = 'published';
        this.containerService
            .clientSearch('distribution', 'published', ['label'])
            .subscribe(
                res => {
                    console.log('distr', res.results);
                    this.containers = res.results;
                    this.parentGuid = this.containers[0].guid;
                    this.loadPage();
                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );    
    }

    loadPage() {
        this.isLoading = true;
        this.containerService
            .getRelatedByTypeAndGuidPaginated('view', this.parentGuid, 1, 200, 'to_container__order,-created_date', '', this.status)
            .subscribe(
                res => {
                    this.isLoading = false;
                    this.views = res;
                    console.log('views', this.views);
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    onStatusSelectionChange($event) {
        this.status = $event.status ? $event.status : '';
        this.loadPage();
    }

    onTabChange($event) {
        this.parentGuid = this.containers[$event.index].guid;
        this.cdComponents.forEach(component => component.selectedStatusOption=this.status);
        this.loadPage();
    }
}
