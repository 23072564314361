import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation, ViewChild } from '@angular/core';
import { Container, Tag } from '../../models';
import { MenuItem } from 'primeng/api';
import { ContainerService } from '../../services/container/container.service';
import { ResourceTagService } from '../../services/resourcetag/resourcetag.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'container-resource-tags',
    providers: [
        ContainerService,
        ResourceTagService
    ],
    templateUrl: 'container-resource-tags.component.html',
    styleUrls: ['container-resource-tags.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ContainerResourceTagsComponent implements OnInit {
    @Input() container: Container;
    @Input() tagTypeFilterList: any[];
    @Input() tagTypeExcludeList: any[];
    @Input() hideTagChips: boolean;
    @Input() hideTagLabel: boolean;
    @Input() fileToUpload: any;
    @Output() onTagsChange: EventEmitter < any > = new EventEmitter();

    displayDialog: boolean = false;

    public isLoadingTags = false;
    private selectedTags: Tag[];
    private visibleTags: Tag[];
    private tags: Tag[];
    private tagChips: any[];

    private tagsDict = {};
    private selectedTagsDict = {};
    private tagsTypes: any;
    private visibleTagsTypes: any;
    private hiddenTagsTypes: any;

    private resourceTags: any[];

    constructor(
        private containerService: ContainerService,
        private resourcetagService: ResourceTagService
    ) { }

    ngOnInit() {
        this.selectedTags = [];
        this.visibleTags = [];
        this.resourceTags = [];
        this.fetchResourceTags();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['container'] && changes['container'].previousValue != changes['container'].currentValue && this.container) {
            this.selectedTags = this.container.tags;
            this.tags = [];
            this.tagsDict = {};
            this.selectedTagsDict = {};
            this.fetchResourceTags();
        }
        else if (changes['tagTypeFilterList'] && changes['tagTypeFilterList'].previousValue != changes['tagTypeFilterList'].currentValue && this.container) {
            this.fetchResourceTags();
        }
        else if (changes['tagTypeExcludeList'] && changes['tagTypeExcludeList'].previousValue != changes['tagTypeExcludeList'].currentValue && this.container) {
            this.fetchResourceTags();
        }
    }

    capitalize(word) {
        word = word.toLowerCase()
            .replace(/_/gi, ' ')
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        return word;
    }

    showDialog() {
        this.displayDialog = true;
    }

    onChangeTags(emitEvent: boolean = true) {
        this.selectedTags = [];
        if (Array.isArray(this.tagTypeFilterList) && this.tagTypeFilterList[0] === 'all') {
            this.tagsTypes.forEach(tagType => {
                this.selectedTagsDict['all'].forEach(tag => {
                    if (tag.type === tagType) {
                        this.selectedTags.push(tag);
                    }
                });
            });
        } else {
            this.tagsTypes.forEach(tagType => {
                if (this.selectedTagsDict[tagType]) {
                    this.selectedTagsDict[tagType].forEach(tag => {
                        this.selectedTags.push(tag);
                    });
                }
            });
        }

        this.setVisibleTags();

        if (emitEvent) {
            this.onTagsChange.emit(this.selectedTags);
        }

        this.resourceTags = this.selectedTags.map(tag => tag['value']);
        this.fileToUpload['tags'] = this.resourceTags;

        console.log('File to upload (with tags): ');
        console.log(this.fileToUpload);
    }

    updateTags(tagsArray : Tag[] = null) {
        if (tagsArray) {
            this.selectedTags = tagsArray;
        }
        this.processTags();
        this.setVisibleTags();
    }

    fetchResourceTags() {
        this.isLoadingTags = true;
        this.resourcetagService
        .list()
        .subscribe(
            res => {
                this.tags = res;
                this.updateTags();
            },
            err => console.log(err),
            () => this.isLoadingTags = false
        )
    }

    processTags() {
        this.tags.forEach(tag => {
            if (tag.label === '') {
                tag.label = this.capitalize(tag.value);
            }
        });

        this.tagsTypes = this.getTagTypes(this.tags);
        this.selectedTagsDict = this.splitTagsByType(this.selectedTags);
        this.tagsDict = this.splitTagsByType(this.tags);

        if (Array.isArray(this.tagTypeFilterList)) {
            this.visibleTagsTypes = this.filterTagTypes(this.tagTypeFilterList, this.tagsTypes);
        } else {
            this.visibleTagsTypes = this.tagsTypes;
        }

        if (Array.isArray(this.tagTypeExcludeList)) {
            this.visibleTagsTypes = this.excludeTagTypes(this.tagTypeExcludeList, this.visibleTagsTypes);
        }

        this.hiddenTagsTypes = new Set(Array.from(this.tagsTypes).filter(t => !this.visibleTagsTypes.has(t)));
    }

    setVisibleTags() {
        if (this.tagTypeExcludeList) {
            this.visibleTags = this.selectedTags.filter(t => !this.tagTypeExcludeList.includes(t.type));
        } else {
            this.visibleTags = this.selectedTags.slice();
        }
    }

    getTagTypes(tagArray) {
        let tagsTypes = new Set(tagArray.map(tag => tag.type));
        return tagsTypes;
    }

    filterTagTypes(tagFilterList, tagTypesSet) {
        let filteredTagSet = new Set(tagFilterList);
        return filteredTagSet;
    }

    excludeTagTypes(tagExcludeList, tagTypesSet) {
        let filteredTagSet = new Set(tagTypesSet);
        tagExcludeList.forEach(t => filteredTagSet.delete(t));
        return filteredTagSet;
    }

    splitTagsByType(tagsArray) {
        var tagsDict = {};
        tagsDict['all'] = [];
        tagsArray.forEach(tag => {
            if (!(tag.type in tagsDict)){
                tagsDict[tag.type] = [];
            }
            tagsDict[tag.type].push(tag);
            tagsDict['all'].push(tag);
        });
        return tagsDict;
    }

    updateChips(event) {
        let tag = event.value;
        if (Array.isArray(this.tagTypeFilterList) && this.tagTypeFilterList[0] === 'all') {
            this.selectedTagsDict['all'] = this.selectedTagsDict['all'].filter(t =>  t.value != tag.value);
        } else {
            if (this.selectedTagsDict[tag.type]) {
                this.selectedTagsDict[tag.type] = this.selectedTagsDict[tag.type].filter(t => t.value != tag.value);
            }
        }
        this.onChangeTags();
    }
}
