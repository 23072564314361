<div class="top-level-multicard">
    <div class="card card-w-title stacked-card" *ngIf="!reportsLoading">
        <h1>Metrics Dashboard</h1>
        <div *ngIf="reportContainers.length > 0" class="ui-g card-content">

            
            <div *ngFor="let report of reportContainers" [ngClass]="report.layoutClass"> 
                <metrics-report-panel  
                    [selectedContainer]="report"
                    [allowCustomHeight]=false 
                    [showReportFilters]=false 
                    [showReportDetails]=false>
                </metrics-report-panel>
            </div>
        </div>
    </div>
    <div style="padding-top:1em;">
        <div class="card card-w-title stacked-card">
            <h1>Dashboard Options</h1>
            <div class="ui-g">
                <div class="ui-g-nopad ui-g-12 ui-md-3 ui-xl-2">
                    <label for="selected_dashboard">Selected Dashboard</label>
                    <select [(ngModel)]="selectedDashboardId" name="selected_dashboard" class="form-control" (change)="loadDashboardReports()" >
                        <option *ngFor="let container of dashboardContainers" [value]="container.guid">{{ container.data['label'] ? container.data['label'] : container.data['name'] }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
