import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class LastUpdateService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/lastupdate`;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    get(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/`, { headers: headers }
            );
    }

    force(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/force/`, { headers: headers }
            );
    }
}
