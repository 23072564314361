<p-autoComplete [(ngModel)]="selectedValues"
                (ngModelChange) ="onChange()"
                [group] = "true"
                [suggestions]="suggestedValues"
                (completeMethod)="getValueSuggestions($event)"
                (onBlur)="onDeselect($event)"
                [minLength]="1"
                placeholder="Search"
                field="label"
                name="keywords"
                [multiple]="multiSelectEnabled"
                appendTo="body"
                forceSelection="true"
                [autoHighlight]="true"
                delay="0"
                emptyMessage="No match found."
                unique="true"
                [dropdown]="true"
                [disabled]="!enabled">

        <ng-template let-group pTemplate="group">
                <span style="font-size:18px">{{group.label}}</span>
        </ng-template>
</p-autoComplete>