import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { Container } from '../../models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'document-form',
    templateUrl: 'document-form.component.html',
    styleUrls: ['document-form.component.css']
})

export class DocumentFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityType;
    public isLoading = false;
    public content: string;
    private savedContent: string;

    private subtypes = ['anchor', 'survey', 'faq', 'article', 'toast', 'post'].sort();

    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }
        
        this.content = this.container.data['content'] ?  this.container.data['content'] : '';
        this.savedContent = this.content;

        this.isLoading = true;
        this.entityTypeService
            .getByName('document')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );

            //this.selectedStatus = this.container.status;
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the document title.' });
            valid = false;
        }

        if (!('subtype' in this.container.data) || (!this.container.data['subtype'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please select document type.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            if (this.changesInEditor()) {
                //changes in p-editor - should be passsed in container
                this.container.data['content'] = this.content;
            } else {
                // may have changes in advanced editor - 
                // should be passed to p-editor
                this.content = this.container.data['content'];
            }
            this.savedContent = this.content;
            this.ngForm.form.markAsPristine();
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine && !this.changesInEditor();
    }

    changesInEditor() {
        return this.content != this.savedContent;
    }   

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
