import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'profile-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'profile-form.component.html'
})
export class ProfileFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    @ViewChild('payload') editor: JsonEditorComponent;
    private entityType;
    private profileTypes = [
        'container',
        'encoding',
        'export',
        'policy',
        'resource',
        'segment',
        'settings',
        'template',
        'upload',
        'qrcode',
        'other',
    ];
    private payloadTypes = [
        'application/json',
        'text/plain',
        'text/html',
        'text/xml',
    ];
    private payloadObj: Object;
    private editorOptionsJson: JsonEditorOptions;

    public isLoading = false;
    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.editorOptionsJson = new JsonEditorOptions();
        this.editorOptionsJson.mode = 'tree';
        this.editorOptionsJson.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsJson.statusBar = true;
        this.editorOptionsJson.expandAll = true;
        this.editorOptionsJson.onChange = () => this.changePayload();

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }

        // Default to JSON
        if (!this.container.data['payload_type']) {
            this.container.data['payload_type'] = 'application/json';
        }
        if (this.container.data['payload_type'] == 'application/json') {
            // Convert legacy stringified JSON to object
            if (typeof this.container.data['payload'] == 'string') {
                this.container.data['payload'] = JSON.parse(this.container.data['payload']);
            } else if (!this.container.data['payload']) {
                this.container.data['payload'] = {};
            }
            this.payloadObj = this.container.data['payload'];
        } else {
            this.payloadObj = null;
        }

        this.isLoading = true;
        this.entityTypeService
            .getByName('profile')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the profile name.' });
            valid = false;
        }
        if (!('bundle_id' in this.container.data) || (!this.container.data['bundle_id']) || !/\S/.test(this.container.data['bundle_id'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the bundle id.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.ngForm.form.markAsPristine();
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    changePayload() {
        try {
            var newPayload = this.editor.get();
        } catch (e) {
            // Keep current
            return;
        }
        this.container.data['payload'] = newPayload;
    }
}
