<div class="card card-w-title top-level-card">
    <h1>Edit Advertisement{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>

    <page-header title="Edit Advertisement{{!isLoading && container ? ': ' + container.data['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <div *ngIf="container && !isLoading && !isProcessing">
        <p-toast key="cpMsg"></p-toast>
        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <advertisement-form class="ui-g-12 ui-g-nopad" #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)" (pipelineTriggered)="onPipelineTriggered($event)">
        </advertisement-form>

        <hr>

        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload" *ngxPermissionsOnly="['view.show.upload']"></button>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource', 'upload']" *ngxPermissionsOnly="['object.write.all', 'object.write.advertisement']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
            </resource-datarow>
        </div>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
