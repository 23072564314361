import { Component, OnInit, Input, Output, EventEmitter,ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { CommerceService } from '../../services/commerce.service';
import { Table } from 'primeng/table';
import { Subscription, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { ContentGroupService } from '../../services/content-group.service';

@Component({
    selector: 'segment-media-table',
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'segment-media-table.component.html',
    styleUrls: ['segment-media-table.component.css']
})

export class CASegmentMediaTableComponent implements OnInit {
    @Input() segmentKeywords: Array<string>;
    @Input() segmentOrigins: Array<string>;
    @Input() rows: number;
    @Input() first: number;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @ViewChild(Table) itemTable;
    public error: any;
    public container: Container;
    public isLoading:boolean = false;
    public totalRecords: number;
    public episodeContainers = [];
    private limit: number;
    private offset: number;
    public rangeDates: Date[];
    public mediaGuids = [];
    public mediaContainers = [];
    public getAllParentGuidsSubscr: Subscription;
    public getFilteredParentGuidsSubscr: Subscription;
    public isScheduleSearch: boolean = false;
    public saveValidatedMediaSubscr: Subscription;
    public validationStatus = {};
    public cgState: any;
    private destroy$ = new Subject();
    public isChecking:boolean=false;

    constructor(
        public containerService: ContainerService,
        private commerceService: CommerceService,
        public changeDetectorRef: ChangeDetectorRef,
        public msgSrv: MessageService,
        private cgService: ContentGroupService
    ) {}

    ngOnInit() {
        this.getAllParentGuids();
    }

    ngAfterViewInit(): void {
        this.destroy$.next(false);
        this.createCgSub();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
            });
    }

    onRowSelect() {
        if (!this.isChecking) {
            this.rowSelect.emit({
                container: this.container
            });
        }
        this.isChecking = false;
    }

    unselectTableItems() {
        this.itemTable.selection = null;
        this.isChecking = false;
    }

    getAllParentGuids() {
        this.isLoading = true;
        this.getAllParentGuidsSubscr = this.commerceService
        .getUniqueByParentFromKeywordTypeSegment(this.segmentKeywords, this.segmentOrigins, 9999, 0, this.rangeDates)
        .subscribe(
            res => {
                let segmentsPayload = res.results;
                this.mediaGuids = segmentsPayload.map(segment => segment.parent);
                this.totalRecords = this.mediaGuids.length;
            },
            err => {
                this.error = err;
                console.log('error', this.error);
            },
            () => {
                if (this.mediaGuids && this.mediaGuids.length > 0) {
                    this.getMediaContainers();
                } else {
                    this.mediaContainers = [];
                    this.msgSrv.add({ key: 'ceMsg', severity: 'error', summary: 'No Media to load'});
                }
                this.getAllParentGuidsSubscr.unsubscribe();
                this.isLoading = false;
            }
        )
    }

    getScheduleParentGuids() {
        this.isLoading = true;
        this.getFilteredParentGuidsSubscr = combineLatest([
            this.commerceService.getUniqueByParentFromKeywordTypeSegment(this.segmentKeywords, this.segmentOrigins, 9999, 0),
            this.commerceService.getUniqueByParentFromScheduleTypeSegment('segment.schedule.linear.s4m', this.segmentOrigins, 9999, 0, this.rangeDates, ['-updated_date'])]
        ).subscribe(
            res => {
                let episodeSegmentPayload = res[0]['results'];
                let scheduleSegmentPayload = res[1]['results'];
                let episodeParentGuids = episodeSegmentPayload.map(segment => segment.parent);
                let scheduleParentGuids = scheduleSegmentPayload.map(segment => segment.parent);
                this.mediaGuids = episodeParentGuids.filter(parent => scheduleParentGuids.includes(parent));
                this.totalRecords = this.mediaGuids.length;
            },
            err => {
                this.error = err;
                console.log('error', this.error);
            },
            () => {
                if (this.mediaGuids && this.mediaGuids.length > 0) {
                    this.getMediaContainers();
                } else {
                    this.mediaContainers = [];
                    this.msgSrv.add({ key: 'ceMsg', severity: 'error', summary: 'No Media to load'});
                }
                this.getFilteredParentGuidsSubscr.unsubscribe();
                this.isLoading = false;
            }
        )
    }

    getMediaContainers() {
        let guidsToFetch = this.mediaGuids.slice(this.offset, (this.offset + this.limit));
        this.commerceService
        .getContainersByGuid(guidsToFetch)
        .subscribe(
            res => {
                this.mediaContainers = res;
                // First, check if the `validated_media` property exists in the content group data field
                if (this.cgState && (!('validated_media' in this.cgState['contentGroup']['data']))) {
                    this.cgState['contentGroup']['data']['validated_media'] = [];
                }
                // Assign `null` if the media has yet been valiated. Otherwise, assign values stored in the `validated_media` field
                let validatedMedia = this.cgState['contentGroup']['data']['validated_media'];
                for (let i=0 ; i < this.mediaContainers.length; i++) {
                    this.validationStatus[this.mediaContainers[i].guid] = null;
                    for (let j=0; j < validatedMedia.length; j++) {
                        if (validatedMedia[j]['media_guid'] == this.mediaContainers[i]['guid']) {
                            this.validationStatus[this.mediaContainers[i].guid] = validatedMedia[j]['validation_status'];
                        }
                    }
                }
                if (!this.container) {
                    this.container = this.mediaContainers[0];
                    this.onRowSelect();
                }
            },
            err => {
                this.error = err;
                console.log('error', this.error);
            },
            () => {}
        )
    }

    onLazyload(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.getMediaContainers();
    }

    handleDateSelect() {
        if (this.rangeDates.length === 2 && this.rangeDates[1] && this.rangeDates[0] !== this.rangeDates[1]) {
            this.rangeDates[0].setHours(0, 0, 0, 0);
            this.rangeDates[1].setHours(24, 0, -1, 0);
            this.mediaGuids = [];
            this.itemTable.clear();

            if (this.isScheduleSearch) {
                this.getScheduleParentGuids();
            } else {
                this.getAllParentGuids();
            }
        }
    }

    handleFilterSelect() {
        this.mediaGuids = [];
        this.itemTable.clear();

        if (this.isScheduleSearch) {
            this.getScheduleParentGuids();
        } else {
            this.getAllParentGuids();
        }
    }

    onClearClick() {
        this.mediaGuids = [];
        this.itemTable.clear();

        if (this.isScheduleSearch) {
            this.getScheduleParentGuids();
        } else {
            this.getAllParentGuids();
        }
    }

    // When tristatecheckbox is selected,
    // A change in selected media and validation_status ('true' or `false` or `null') will be detected
    // Save as new entry only if the selected media guid does not exist in the `validated_media`
    // Otherwise, just override the validation_status of existing entry with the new change
    onTristateCheckBoxChecked(media, validationStatus) {
        this.isChecking = true;
        this.validationStatus[media.guid] = validationStatus;
        let validatedMediaItemIndex = this.cgState['contentGroup']['data']['validated_media'].findIndex(item => item['media_guid'] == media.guid);
        if (validatedMediaItemIndex < 0) {
            this.cgState['contentGroup']['data']['validated_media'].push({'media_guid': media.guid, 'validation_status': validationStatus});
        } else {
            this.cgState['contentGroup']['data']['validated_media'][validatedMediaItemIndex]['validation_status'] = validationStatus;
        }
        // Save the change
        this.saveValidatedMediaSubscr = this.containerService
        .save(this.cgState['contentGroup'])
        .subscribe(
            res => {},
            err => {
                console.error(err);
            },
            () => {
                this.saveValidatedMediaSubscr.unsubscribe();
            }
        )
    }

}
