<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-commerce-forms-content-group-form-button-save"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;" data-element-id="app-commerce-forms-content-group-form-schange-status"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="label">Label</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['label']" name="label" placeholder="Required" data-element-id="app-commerce-forms-content-group-form-label" >
                </div>
                <div class="ui-g-4 p-fluid">
                    <label for="keywords" style="display:block;">Keywords</label>
                    <segment-keyword-autocomplete [(container)]=container (onKeywordChange)="keywordsOriginsWarning($event)"></segment-keyword-autocomplete>
                </div>
                <div class="ui-g-4">
                    <label for="Origins">Origins</label>
                    <origin-select [(container)]=container (onOriginChange)="keywordsOriginsWarning($event)" data-element-id="app-commerce-forms-content-group-form-select-origin"></origin-select>
                </div>
            </div>
        </p-panel>

        <ng-content></ng-content>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-commerce-forms-content-group-form-description">
                </div>

                <div *ngIf="container.id > 0" class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
         </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="container.guid" name="guid" data-element-id="app-commerce-forms-content-group-form-guid">
                </div>
                <div class="ui-g-6">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type">
                </div>
            </div>
        </p-panel>

        <container-tags-panel [container]=container viewName="contentgroup" class="ui-g-12"></container-tags-panel>
    </div>

</form>
