import { Component, OnInit, ViewChild } from '@angular/core';
import { MetricsAnalyticsService } from '../../services/mas.service';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { PageHeaderComponent } from '../../../ui/page-header.component';

@Component({
    selector: 'mas-status',
    providers: [MetricsAnalyticsService],
    templateUrl: 'mas-status.component.html',
})

export class MetricsAnalyticsServiceStatusComponent implements OnInit {
    @ViewChild('masstatus') statusDisplay: JsonEditorComponent;
    @ViewChild('masstreamstatus') streamStatusDisplay: JsonEditorComponent;

    public isLoading = false;
    public error: string;
    public status: Object;
    public statusCode: number;
    public streamStatus: Object;
    public streamStatusCode: number;
    public displayOptions = new JsonEditorOptions();

    constructor(
        public metricsAnalyticsService: MetricsAnalyticsService
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.displayOptions.mode = 'view';
        this.displayOptions.modes = ['view'];
        this.displayOptions.statusBar = true;
        this.displayOptions.expandAll = true;
        this.metricsAnalyticsService
            .fetchStatus()
            .subscribe(
                (res) => {
                    this.status = res;
                    this.statusCode = 200;
                },
                (err) => {
                    console.log(err);
                    this.error = err.statusText;
                    // Pass the error body anyway as it should contain the details
                    this.status = err.error;
                    this.statusCode = err.status;
                },
                () => this.isLoading = false
            );
        this.metricsAnalyticsService
            .fetchStreamStatus()
            .subscribe(
                (res) => {
                    this.streamStatus = res;
                    this.streamStatusCode = 200;
                },
                (err) => {
                    console.log(err);
                    this.error = err.statusText;
                    // Pass the error body anyway as it should contain the details
                    this.streamStatus = err.error;
                    this.streamStatusCode = err.status;
                },
                () => this.isLoading = false
            );
    }
}