import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

// import { bootstrap } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
// import { AppComponent, environment } from './app';
// import { HTTP_PROVIDERS } from '@angular/http';
// import { APP_ROUTER_PROVIDERS } from './app/app.routes';
// import { disableDeprecatedForms, provideForms } from '@angular/forms';
// import { OAuthService } from 'angular2-oauth2/oauth-service';
// import { AuthService } from './app/auth/auth.service';
// import { AuthGuard } from './app/auth/auth-guard.service';
// import { enableProdMode } from '@angular/core';
// import './favicon.ico';
// import './logo.png';
// import './app/assets/layout/css/layout-green.css';
// import './app/assets/theme/theme-green.css';
// import './app/assets/layout/css/style-overrides.css';
if (environment.production === true) {
    enableProdMode();
}

// bootstrap(AppComponent, [
//     OAuthService,
//     HTTP_PROVIDERS,
//     APP_ROUTER_PROVIDERS,
//     disableDeprecatedForms(),
//     provideForms(),
//     AuthService,
//     AuthGuard
// ])
//     .catch(err => console.error(err))
// ;

platformBrowserDynamic()
    .bootstrapModule(AppModule);
