import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MetricsService } from '../../services';
import { Container } from '../../../content/models';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { AppContext } from '../../../app.context';

@Component({
    selector: 'pipeline-action',
    templateUrl: 'pipeline-action.component.html',
    styleUrls: ['pipeline-action.component.css']
})
export class PipelineActionComponent implements OnInit {
    @Input() pipeline: any;
    @Output() onExecuteAction: EventEmitter < any > = new EventEmitter();

    private error: any;
    filteredAction = '';
    filteredLabel = '';
    displayDialog: boolean = false;
    private actions: MenuItem[];
    defaultLabel = 'No action to perform';

    startOption = {
        label: 'Start', icon: 'fa fa-check', command: () => {
            this.setDisplayDialog('Start');
            }
        };

    restartOption = {
        label: 'Restart', icon: 'fa fa-check', command: () => {
            this.setDisplayDialog('Restart');
            }

        };

    waitOption = {
        label: 'Wait', icon: 'fa fa-spinner', command: () => {
            this.setDisplayDialog('Wait');
            }
        };

    cancelOption = {
        label: 'Cancel', icon: 'fa fa-close', command: () => {
            this.setDisplayDialog('Cancel');
            }
        };

    constructor(
        private metricsService: MetricsService,
        private appContext: AppContext,
        private pipelineService: PipelineService,
    ) {}

    ngOnInit() {
        this.defaultAction();
    }

    triggerAction() {
        this.pipelineService
            .pipelineAction(
                this.pipeline['id'],
                this.filteredAction,
            )
            .subscribe(
                res => {
                    console.log('Pipeline action complete:', res);
                    this.onExecuteAction.emit(res['data']);
                },
                err => {
                    console.error(err);
                    this.displayDialog = false;
                },
                () => {
                    this.displayDialog = false;
                }
            );
    }

    listActions() {
        if (this.pipeline['status'] == 'waiting') {
            this.actions = [this.cancelOption, ];
        }
        if (this.pipeline['status'] == 'running') {
            this.actions = [this.cancelOption, ];
        }
        if (this.pipeline['status'] == 'pending') {
            this.actions = [this.cancelOption, ];
        }
        return this.actions;
    }

    setDisplayDialog(filteredLabel) {
        if (filteredLabel == 'Restart') {
            this.filteredLabel = 'Restart';
            this.filteredAction = 'start';
        } else if (filteredLabel == 'Wait') {
            this.filteredLabel = 'Wait';
            this.filteredAction = 'wait';
        } else if (filteredLabel == 'Cancel') {
            this.filteredLabel = 'Cancel';
            this.filteredAction = 'cancel';
        } else if (filteredLabel == 'Start') {
            this.filteredLabel = 'Start';
            this.filteredAction = 'start';
        }
        this.displayDialog = true;
    }


    defaultAction() {
        if (this.pipeline['status'] == 'failure') {
            this.defaultLabel = 'Restart';
        } else if (this.pipeline['status'] == 'waiting') {
            this.defaultLabel = 'Restart';
        } else if (this.pipeline['status'] == 'running') {
            this.defaultLabel = 'Wait';
        } else if (this.pipeline['status'] == 'pending') {
            this.defaultLabel = 'Start';
        } else {
            this.defaultLabel = 'No action to perform';
        }

    }

    triggerDefaultAction() {
        this.setDisplayDialog(this.defaultLabel);
    }

}
