import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Container, Schema } from '../../models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { defaultPaginationLimit } from '../../../shared/enum';

@Injectable()
export class SchemaService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;
    private defaultLimit = defaultPaginationLimit['schema'];

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private messageService: MessageService,
        private appContext: AppContext,
        private containerService: ContainerService
    ) {}

    get(id, showContainers=false): Observable <any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let query = `show_containers=` + showContainers;

        return this.http
            .get(
                `${this.baseUrl}/schemas/${id}/?${query}` + this.containerService.appendOriginQueryParamFromContext(),
                { headers: headers }
            )
    }

    getByGuid(guid): Observable <any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        return this.http
            .get(
                `${this.baseUrl}/schemas/?guid=${guid}` + this.containerService.appendOriginQueryParamFromContext(),
                { headers: headers }
            )
    }

    list(status: string | Array<string> = [], ordering: string | Array<string> = [], schemaRelationship:string = '',
        limit:Number = 0, offset:Number = 0, type: string | Array<string> = [], namespace:string = '', ignoreOrigin = false): Observable <any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let query = `offset=`+ offset;

        if (limit > 0) {
            query += `&limit=` + limit;
        } else {
            query += `&limit=${this.defaultLimit}`
        }

        if (namespace) {
            query += `&namespace=` + namespace
        }

        if (type || type.length > 0) {
            type = Array.isArray(type) ? type : [type];
            for (let ty of type) {
                query += `&type=${ty}`;
            }
        }

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];
            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        if (ordering && ordering.length > 0) {
            ordering = Array.isArray(ordering) ? ordering : [ordering];
            for (let ord of ordering) {
                query += `&ordering=${ord}`;
            }
        }
        if(schemaRelationship)
        {
            // schemaRelationship is 'true' returns unassigned schemas, 
            // 'false' returns 'type' assigned schemas
            // returns all schemas if schemaRelationship is not specified 
            schemaRelationship = schemaRelationship.toLowerCase().trim();
            if(schemaRelationship=='true')
                query += `&type__isnull=true`;
            if(schemaRelationship=='false')
                query += `&type__isnull=false`;
        }

        let originStr = ignoreOrigin
            ? this.containerService.constructOriginParameter(true, '*')
            : this.containerService.appendOriginQueryParamFromContext();
            
        return this.http
            .get(
                `${this.baseUrl}/schemas/?${query}` + originStr,
                { headers: headers }
            )
    }

    save(schema: Schema): Observable <any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let origin;
        if (schema.id === -1) {
            origin = this.appContext.activeOrigin;
        } else {
            origin = schema.origin
        }

        let resp;
        let data = JSON.stringify({
            type: schema.type,
            guid: schema.guid,
            namespace: schema.namespace,
            label: schema.label,
            status: schema.status,
            origin: origin,
            schema: schema.schema,
            data: schema.data
        })
        if (schema.id === -1) {
            resp = this.http
                .post(
                    `${this.baseUrl}/schemas/`,
                    data, { headers: headers }
                ).pipe(
                tap(
                    res => {
                        schema.id = res['id'];
                    }
                ));
        } else {
            resp = this.http
                .put(
                    `${this.baseUrl}/schemas/${schema.id}/`,
                    data, { headers: headers }
                )
        }

        return resp.pipe(tap(
            null,
            err => this.showError(err),
        ));
    }

    private showError(err, summary='Error') {
        const detail = (err.error && err.error.detail) || err.statusText;
        this.messageService.add({ key: 'api', severity: 'error', summary, detail });
    }

    relate(fromSchema: Schema, toContainer: Container): Observable <any> {
        return this.relateById(fromSchema.id, toContainer.id)
    }

    relateById(fromSchemaId: Number, toContainerId: Number): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .post(
                `${this.baseUrl}/schemas/${fromSchemaId}/relate/${toContainerId}/`,
                JSON.stringify({}),
                { headers: headers }
            ).pipe(tap({next:null,
                    error: (err) => this.showError(err),
                    complete: null,
            }));
    }

    unrelate(fromSchema: Schema, toContainer: Container): Observable <any> {
        return this.unrelateById(fromSchema.id, toContainer.id)
    }

    unrelateById(fromSchemaId: Number, toContainerId: Number): Observable <any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .delete(
                `${this.baseUrl}/schemas/${fromSchemaId}/relate/${toContainerId}/`,
                { headers: headers }
            ).pipe(tap({next:null,
                    error: (err) => this.showError(err),
                    complete: null,
            }));
    }


}
