<div class="ui-g-12"  style="padding:0;">
<ngx-charts-bar-horizontal  class="ui-g-12"  style="padding:0;"
  [scheme]="colorScheme"
  [results]="formattedDataNGX"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  [view]="view">
</ngx-charts-bar-horizontal>
</div>