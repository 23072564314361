<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-4 ui-lg-4 view-title" style="padding:0;">{{title}}</span>
            <div class="ui-g-12 ui-md-4 ui-lg-4" style="padding:0;">
                <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="onFilter($event.target.value)" class="filter-bar"
                />
            </div>
            <div class="ui-g-12 ui-md-4 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>
    <div class="items" [dragula]="name" [(dragulaModel)]="allContainers">
        <div *ngFor="let container of shownContainers">
            <container *ngIf="container.show" class="item" [container]="container" [parentContainer]="parentContainer" (onUpdate)="update($event)"
                (onRemove)="containerRemoved($event)"></container>
        </div>
    </div>
</p-panel>