import { Component, OnInit, Input } from '@angular/core';
import { Container } from '../../models';

@Component({
    selector: 'collection-container',
    templateUrl: 'collection-container.component.html',
    styleUrls: ['collection-container.component.css']
})

export class CollectionContainerComponent implements OnInit {
    @Input() container: Container;

    constructor() {}

    ngOnInit() {}

    // TODO: default thumbnail
    thumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        }
        url = url ? url : '';
        return url;
    }
}
