import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Container } from '../../models';
import { Message } from 'primeng/api';

@Component({
  selector: 'cue-point-edit',
  templateUrl: './cue-point-edit.component.html',
  styleUrls: ['./cue-point-edit.component.css']
})
export class CuePointEditComponent implements OnInit {
    @Input() cuePoints: string;
    @Output() onCuepointEdit: EventEmitter<any> = new EventEmitter();
    public isLoading = false;
    public columns = [];
    public editedCuepoints = [];
    public modifiedCuepoint = '';
    public cuepointEditDisplayDialog: boolean = false;
    public confirmationDisplayDialog: boolean = false;
    public cuepointToDelete = '';
    private editedCuepointString;
    public requiredMsgs: Message[] = [];
    private valid = true;
    private newRowAdded = false;

    constructor() { }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['cuePoints'].previousValue != changes['cuePoints'].currentValue) {
            this.initializeCuepoints();
        }
    }

    initializeCuepoints(){
        if(this.cuePoints === undefined || this.cuePoints == ""){
            this.editedCuepoints = [];
        }
        else {
            this.editedCuepoints = this.cuePoints.split(",");
            this.editedCuepoints = this.editedCuepoints.map(s => s.trim());
            this.editedCuepoints.sort((a,b) => {return this.compareTimes(a,b)});
        }
    }

    onChange(e){
        this.modifiedCuepoint = e;
    }

    addNew(){
        if (!this.validateExistance('__:__:__', this.editedCuepoints))
        {
            this.editedCuepoints.splice(0, 0, '__:__:__');
            this.newRowAdded = true;
        } 
    }

    onEditInit(event){
        this.modifiedCuepoint = '';
        this.valid = true;
    }

    onEditComplete(event){
        if(this.modifiedCuepoint != ''){
            this.requiredMsgs = [];
            if (this.validateFormat(this.modifiedCuepoint) && !this.validateExistance(this.modifiedCuepoint, this.editedCuepoints))
            {
                this.editedCuepoints[event['index']] = this.modifiedCuepoint;
                this.editedCuepoints = this.editedCuepoints.sort((a,b) => {return this.compareTimes(a,b)});
                this.formatAndEmitOutput(this.editedCuepoints);
            }
        }
    }

    validateFormat(timeCode)
    {
        let cuepoint_regx = /^(?:[0-9][0-9]):[0-5][0-9]:[0-5][0-9]$/;
        let matchFound = timeCode.match(cuepoint_regx);
        if(matchFound === null){
            this.requiredMsgs.push({ severity: 'error', detail: 'Cue points need to be in time code format.' });
            return false;
        }
        return true;
    }
    

    removeCuepoint(){
        this.editedCuepoints.forEach((element, index)=>{
            if(element == this.cuepointToDelete) this.editedCuepoints.splice(index, 1)
        });
        this.confirmationDisplayDialog = false;
        this.formatAndEmitOutput(this.editedCuepoints);
    }

    confirmDelete(event) {
        this.cuepointToDelete = event;
        this.confirmationDisplayDialog = true;
    }

    compareTimes(a,b){
        var actualTimeA = a.split(":");
        var actualTimeB = b.split(":");
        var ASeconds = (+actualTimeA[0]) * 60 * 60 + (+actualTimeA[1]) * 60 + (+actualTimeA[2]);
        var BSeconds = (+actualTimeB[0]) * 60 * 60 + (+actualTimeB[1]) * 60 + (+actualTimeB[2]);

        if(ASeconds < BSeconds){
            return -1;
        }
        return 1;
    }

    showDialog(){
        this.cuepointEditDisplayDialog = true;
    }

    formatAndEmitOutput(arr){
        if(arr.includes('')){
            arr = arr.filter(item => item !== '');
        }
        let formattedStr = arr.join(",");
        this.onCuepointEdit.emit({
            edited: formattedStr
        });
        this.newRowAdded = false;
    }

    validateExistance(cuePoint,cuePointSet){
        if(cuePointSet.includes(cuePoint)){
            this.requiredMsgs.push({ severity: 'error', detail: 'Cue point already exists!!!' });
            return true;
        }
        return false;
    }

    onClear(){
        this.requiredMsgs = [];
        this.modifiedCuepoint = '';
        this.valid = true;
        this.newRowAdded = false;
        this.formatAndEmitOutput(this.editedCuepoints);
    }

}
