import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container'
import { AppContext } from '../../../app.context';

@Component({
  selector: 'testing-results-modal',
  providers: [
    ContainerService
  ],
  templateUrl: 'testing-results-modal.component.html',
  styleUrls: ['testing-results-modal.component.css']
})

export class TestingResultsModalComponent implements OnInit {
  @Input() container: Container;
  @Input() disabled: Boolean;

  @Output() onStateChange: EventEmitter<any> = new EventEmitter();

  public displayDialog: Boolean = false;
  public isDisabled: Boolean = true;
  public isLoading = false;

  private error: any;

  public resultPassDetails: any;
  public resultFailDetails: any;

  public resultStats: any;
  public testResult: Container;
  public duration: any;
  public createdBy: any;

  public resultsLabel: any

  public view = [700, 200];

  formattedDataNGX: any;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor(
    private containerService: ContainerService,
    private appContext: AppContext
  ) {
    this.initializeGraphData();
  }

  ngOnInit(): void {
    this.getResultsData();
    this.isDisabled = this.disabled
  }

  resetResultsData() {
    this.isLoading = true;
    this.containerService
      .getRelatedByTypeAndGuidPaginated('result', this.container.guid, 1, 1, '-created_date', '', 'published')
      .subscribe(
        res => {
          this.isLoading = false;
          let results = res;
          this.testResult = results[0];
          this.resultStats = this.testResult['data']['results']['stats']
          this.createdBy = this.container.data['initiator']
          this.duration = this.resultStats['duration']
          this.resultsLabel = this.testResult['data']['label']
          this.formattedDataNGX = [];

          this.resultPassDetails = this.testResult['data']['details']['pass']
          this.resultFailDetails = this.testResult['data']['details']['fail']

          this.createGraphSeriesFromResultsNGX(this.resultStats)
          console.log("Result", this.testResult, results)
        },
        err => this.error = err,
        () => this.isLoading = false
      );

  }

  showResultsModal() {
    this.onStateChange.emit({
      modal_show_view: true
    });

    this.resetResultsData();
    this.displayDialog = true;
  }

  initializeGraphData() {
    this.formattedDataNGX = [];
  }

  createGraphSeriesFromResultsNGX(results) {
    console.log("RESUTS::", results)
    let passed = new Number(results['passes']);
    let failures = new Number(results['failures']);
    console.log("RESUTS::", failures, passed, results['passes'], results['failures'])
    this.formattedDataNGX.push({ name: 'Passed', value: passed });
    this.formattedDataNGX.push({ name: 'Failed', value: failures });

  }

  closeDialog() {
    this.onStateChange.emit({
      modal_show_view: false
    });
    this.displayDialog = false;
  }

  getResultsData() {
    this.isLoading = true;

  }
}
