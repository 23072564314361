<!-- <p-panel>
    <p-header>
        <span class="p-panel-title" style="font-size:16px;width:100%;margin-left:5px;margin-right:5px;">{{container.data ? container.data['title'] : container.title}}</span>
    </p-header>
    <div *ngIf="container.data && container.data.source_status" class="ui-g-4">
        <div class="label label-info">Pinned</div>
    </div>
    <div class="ui-g-12" style="padding-bottom:0;">
        <div class="ui-g-12 label label-default">{{containerName}}</div>
    </div>
    <img class="ui-g-12" [src]="thumbnailUrl()">
</p-panel> -->


<p-card styleClass="p-card-shadow">
    <p-header>
        <div style="width:100%;font-size:16px;text-align:center;padding-bottom:0.5em"><b>{{container.data['title']}}</b></div>
        <div class="ui-g-12" style="padding-bottom:0;">
            <div class="ui-g-12 label label-default">{{containerTypeName}}</div>
        </div>
        <img [src]="thumbnailUrl()" style="padding:0.5em;padding-bottom:0;">
    </p-header>
    <div *ngIf="containerTypeName == 'series'">
        <button label="Edit" class="p-button-success" pButton type="button" [routerLink]="['/series', container.id]" icon="pi pi-pencil" style="width:100%"></button>
    </div>
</p-card>