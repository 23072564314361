import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container'
import { NgForm } from '@angular/forms';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'contextual-ads-catalog-modal-form',
  providers: [
    ContainerService,
    DatePipe
  ],
  templateUrl: 'contextual-ads-catalog-modal-form.component.html',
  styleUrls: ['contextual-ads-catalog-modal-form.component.css']
})

export class CACatalogModalFormComponent implements OnInit {
  public static readonly catalogOrigins = [
    "system",
  ];

  @Input() container: Container;
  @Input() catalogTypes: Array<string>;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() disabled: Boolean;
  @ViewChild('ngForm') ngForm: NgForm;

  public catalogs: any[] = [];
  public displayDialog: boolean = false;
  public isDisabled = true;
  public isLoading = false;
  public rangeDates: Date[];
  public selectedCatalogId;
  public url;
  private catalogContainer;
  private start_datetime;
  private end_datetime;

  constructor(
    private containerService: ContainerService,
    private appContext: AppContext,
    private datepipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.getCatalogData(this.catalogTypes);
    this.setDates();
  }

  setDates() {
    if(this.startDate && this.endDate) {
      let startDateDefault = new Date(this.startDate);
      let endDateDefault = new Date(this.endDate);
      this.rangeDates = [startDateDefault, endDateDefault];
    }
  }

  resetFormData() {
    this.selectedCatalogId = '';
  }

  checkExport() {
    if (this.catalogs.length && this.container && this.container.id > 0 && !this.disabled) {
        this.isDisabled = false;
    }
  }

  showDialog() {
    this.resetFormData();
    this.displayDialog = true;
  }

  closeDialog() {
    this.displayDialog = false;
  }

  getCatalogData(catalogTypes) {
    this.isLoading = true;
    this.catalogSearch(catalogTypes)
       .subscribe(
           res => {
               this.catalogs = res;
               this.checkExport();
           },
           err => {
               console.log('error', err);
           },
           () => this.isLoading = false
       );
  }

  catalogSearch(catalogTypes: Array<string> = []) {
    function isCatalogValid(c) {
        return c.data && c.data.template;
    }

    function compareCatalogTypes(c, catalogTypes) {
        if (catalogTypes && catalogTypes.length) {
            return catalogTypes.includes(c.data.catalog_type);
        } else {
            return true;
        }
    }

    let origins = CACatalogModalFormComponent.catalogOrigins.slice();
    if (!origins.includes(this.appContext.activeOrigin)) {
        origins.push(this.appContext.activeOrigin);
    }

    return this.containerService
        .clientSearch('catalog', 'published', ['label'], origins.join(',')).pipe(
        map(
            res => {
                return res.results.filter(
                    c => (
                        isCatalogValid(c)
                        && compareCatalogTypes(c, catalogTypes)
                    )
                );
            }
        ));
  }

  onCatalogChange() {
    this.catalogContainer = this.catalogs.find(t => t.id === parseInt(this.selectedCatalogId, 10));
    this.constructCatalogUrl();
  }

  constructCatalogUrl() {
    let baseUrl = environment.PROXY_API_BASE_URL;
    let query = 'guid=' + this.container.guid

    if (this.start_datetime && this.end_datetime) {
        query += '&start_datetime=' + this.start_datetime + '&end_datetime=' + this.end_datetime;
    }

    if (this.catalogContainer.data['catalog_type'] == 'export_campaign') {
        let date = this.datepipe.transform(new Date(), 'MM_dd_yyyy');
        let filename = this.container.data['label'] + '_' + date + '.csv';
        query += '&filename=' + encodeURIComponent(filename)
    }

    this.url = `${baseUrl}/templates/catalog/${this.catalogContainer.guid}/?${query}`
  }

  handleDateSelect() {
    if (this.rangeDates.length === 2 && this.rangeDates[1] && this.rangeDates[0] !== this.rangeDates[1]) {
        this.start_datetime = this.rangeDates[0].setHours(0, 0, 0, 0);
        this.end_datetime = this.rangeDates[1].setHours(24, 0, -1, 0);
        if (this.catalogContainer) {
            this.constructCatalogUrl();
        }
    }
  }

  exportCatalog() {
    this.closeDialog();
  }

}
