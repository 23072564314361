<div *ngIf="resource && !isLoading" class="card card-w-title top-level-card">
    <h1>New Resource</h1>
    <page-header title="New Resource" [showSpinner]="isLoading" [error]="error"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="resource && !isLoading">
        <resource-form #form [resource]="resource" [containerId]="containerId" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)" style="width: 100%;"></resource-form>
    </div>
</div>
