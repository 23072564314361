import { Component, OnInit, Input } from '@angular/core';
import { Container } from '../../models';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'container-default-ordering',
  templateUrl: 'container-default-ordering.component.html',
  styleUrls: ['container-default-ordering.component.css']
})
export class ContainerDefaultOrderingComponent implements OnInit {
    @Input() container: Container;
    
    public orderingOptions = [
        { label: 'Curated Order', value: "order" },
        { label: 'Curated Order Desc', value: "-order" },
        { label: 'Reference Date', value: "reference_date" },
        { label: 'Reference Date Desc', value: "-reference_date" },
        { label: 'Published Date', value: "published_date" },
        { label: 'Published Date Desc', value: "-published_date" },
        { label: 'Created Date', value: "created_date" },
        { label: 'Created Date Desc', value: "-created_date" },
        { label: 'Score', value: "_score" },
        { label: 'ID', value: "id" },
        { label: 'ID Desc', value: "-id" },
        { label: 'Label', value: "label" },
        { label: 'Label Desc', value: "-label" },
        { label: 'Priority', value: "priority" },
        { label: 'Priority Desc', value: "-priority" },
        { label: 'User Rating', value: "user_rating" },
        { label: 'User Rating Desc', value: "-user_rating" }
    ]
    public selectedOrderingOption: SelectItem = null;

    constructor() { }

    ngOnInit() {
        if ('default_ordering' in this.container.data && this.container.data['default_ordering']) {
            this.selectedOrderingOption = this.container.data['default_ordering'];
        }
    }

    onValueChange() {
        if (this.selectedOrderingOption) {
            this.container.data['default_ordering'] = this.selectedOrderingOption;
        } else {
            delete this.container.data['default_ordering'];
        }
    }

}
