<div *ngIf="inputObject">
	<div class="ui-g-12" style="padding-left: 0">
    	<label for="last_modified_by">Last Modified By</label>
        <div class="p-inputgroup">
            <input type="text" class="form-control" [(ngModel)]="lastModifiedByInfo" [readonly]="true" name="last_modified_by">   
            <div *ngxPermissionsOnly="['view.show.history']">
            	<button pButton type="button" label="Details" icon="fa fa-binoculars" [routerLink]="['/history', inputType, inputObject.id]" class="p-button-primary" data-element-id="app-content-components-history-details"></button>
            </div>     
        </div>
	</div>
</div>