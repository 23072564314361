import { Component, OnInit, ViewChild } from '@angular/core';
import { SchemaFormComponent } from '../../forms/schema-form/schema-form.component';
import { Schema } from '../../../content/models/schema';
import { Location } from '@angular/common';
import { Message } from 'primeng/api';
import { SchemaService } from '../../../content/services/schema/schema.service';
import { Router } from '@angular/router';
import { handleError } from '../../../shared/utilities';

@Component({
  selector: 'schema-create',
  providers: [
    SchemaService
  ],
  templateUrl: './schema-create.component.html',
  styleUrls: ['./schema-create.component.css']
})
export class SchemaCreateComponent implements OnInit {
  @ViewChild('form') form: SchemaFormComponent;
  public schema: Schema;
  public isLoading: boolean = false;
  public isDisabled: boolean = true;
  private requiredMsgs: Message[] = [];

  constructor(
    private location: Location,
    private router: Router,
    private schemaService: SchemaService,
  ) {}

  ngOnInit() {
    this.schema = new Schema();
    this.schema.status = 'new';
  }

  onFail(e) {
    this.requiredMsgs = [];
    this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
  }

  onCancel(e) {
    if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
      this.location.back();
    }
  }

  onSubmit(schema: Schema) {
    console.log(schema);
    this.schemaService
      .save(schema)
      .subscribe(
        res => {
          console.log(res);
          this.router.navigate(['/settings/schema', res.id], { replaceUrl: true })
        },
        err => {
          this.requiredMsgs = [];
          let errorMessages = handleError(err);
          errorMessages.forEach(errorMessage => {
            this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: errorMessage });
          })
        },
        () => this.isLoading = false
      );
  }

}
