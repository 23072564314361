import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'series-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'series-form.component.html'
})
export class SeriesFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @Output() statusError: EventEmitter<any> = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();

    @ViewChild('ngForm') ngForm: NgForm;
    private entityType;
    public isLoading = false;

    private publishDate :Date;

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];
    private seriesType = ['series', 'miniseries', 'seasonless'];
    private genre = genreList;


    constructor(
        private entityTypeService: EntityTypeService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.setup();
    }

    resetToDefaultState() {
        this.ngForm.reset();
        this.setup();
    }

    setup() {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);

        if (!('locked' in this.container.data) || !this.container.data['locked']) {
            this.container.data['locked'] = true;
        }

        // if (!this.container.is_enabled) {
        //     this.container.is_enabled = true;
        // }

        if (!('series_type' in this.container.data) || (!this.container.data['series_type']) ) {
            this.container.data['series_type'] = 'series';
        }

        this.isLoading = true;
        this.entityTypeService
            .getByName('series')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date = new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSubmit(e) {

        console.log('container submitted', this.container);

        // Backward compatibility with older clients. display_pretitle and display_title are deprecated.
        if ('super_label' in this.container.data) {
            this.container.data['display_pretitle'] = this.container.data['super_label'];
        }
        if ('display_label' in this.container.data) {
            this.container.data['display_title'] = this.container.data['display_label'];
        }
        if ('sub_label' in this.container.data) {
            this.container.data['display_subtitle'] = this.container.data['sub_label'];
        }

        this.ngForm.form.markAsPristine()
        this.formSubmit.emit({
            container: this.container
        });
    }

    onSimpleStatusChange(event) {
        console.log('simple status transition', event);
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onComplexStatusChange(event) {
        console.log('pipeline triggered successfully');
        this.pipelineTriggered.emit(event);
    }

    onStatusTransitionFailure(e) {
        this.messageService.add(e);
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onError(error) {
        this.statusError.emit(error);
    }

    toUpper(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
