<div *ngxPermissionsOnly="['view.show.resourcetag']" class="card card-w-title top-level-card">
  <h1>Resource Tags</h1>
  <div class="ui-g">
    <p-messages styleClass="w-100"></p-messages>
    <p-confirmDialog  key="deleteTag"
                      header='Delete
                      Confirmation' icon="pi pi-info-circle"
                      [style]="{minWidth: '10vw'}"
                      [baseZIndex]="10000"
    ></p-confirmDialog>
  </div>
    <p-table #dt [value]="tags"
            [lazy]="true"
            (onLazyLoad)="loadTags($event)"
            [rows]="limit"
            [totalRecords]="totalRecords"
            [paginator]="true"
            [loading]="isLoading"
            paginatorPosition="bottom"
            selectionMode="single"
            styleClass="p-datatable-gridlines"
            [responsive]="true"
            [globalFilterFields]="['tag.type']">
        <ng-template pTemplate="caption">
          <div class="ui-g">
            <div class="ui-g-4">
              <input type="text" id="type" name="type" pInputText placeholder="Filter by resource tag type"
                    (keyup)="dt.filterGlobal($event.target.value)"
                    style="width:100%"
              />
            </div>
            <div class="ui-g-8">
              <button pButton type="button" label="Create" [routerLink]="['/settings/resourcetag', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;" data-element-id="app-settings-components-resource-tags-create"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
              <th>Type</th>
              <th>Label</th>
              <th>Value</th>
              <th style="width:12rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tag>
            <tr>
              <td>{{tag.type}}</td>
              <td>{{tag.label}}</td>
              <td>{{tag.value}}</td>
              <td>
                <span>
                  <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/settings/resourcetag', tag.id]"></button>
                  <button pButton type="button" style="margin-left: 5px;" class="p-button-danger" label="Delete" (click)="onRemove(tag)"></button>
                </span>
              </td>
            </tr>
        </ng-template>
    </p-table>
