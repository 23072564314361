export function makeTime(sec: number) {
    let hh: string;
    let mm: string;
    let ss: string;
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - (hours * 3600)) / 60);
    const seconds = sec - (hours * 3600) - (minutes * 60);

    hh = (hours < 10) ? ('0' + hours.toString()) : hours.toString();
    mm = (minutes < 10) ? ('0' + minutes.toString()) : minutes.toString();
    ss = (seconds < 10) ? ('0' + seconds.toString()) : seconds.toString();

    const ts = hh + ':' + mm + ':' + ss;

    return ts;
}

// Convert mouse position to raw seconds
export function toRawSeconds(seconds, factor): number {
    return Math.round(seconds / factor);
}

// Scaling seconds to be placed on timeline
export function toScaledSeconds(seconds, factor): number {
    return Math.round(seconds * factor);
}

export function generateListOfYears(desc?:boolean, startYear?:number, endYear?:number) : number[] {
    let years: number[] = [];

    if(!startYear && startYear != 0) {
        startYear = 1950;
    }

    if(!endYear) {
        endYear = new Date().getFullYear();
    }

    for(let i = startYear; i <= endYear; i++) {
        if(desc) {
            years.unshift(i);
        } else {
            years.push(i);
        }
    }

    return years;

}

export function handleError(err) {
    let error = err.error;
    let message: string;
    let errorMessage: string;
    let errorMessages: Array<string> = [];

    // Handle error array
    if (Array.isArray(error) && error.length > 0) {
        error.forEach(err => {
            if (err instanceof Object) {
                Object.keys(err).map(key => {
                    message = key + ': ' + err[key];
                    errorMessage = sanitizeErrorMessage(message)
                    errorMessages.push(errorMessage)
                })
            } else {
                errorMessage = sanitizeErrorMessage(err);
                errorMessages.push(errorMessage)
            }
        })
    // Handle error object
    } else if (error instanceof Object) {
        Object.keys(error).map(key => {
            message = key + ': ' + error[key];
            errorMessage = sanitizeErrorMessage(message);
            errorMessages.push(errorMessage);
        })
    // Handle error string (and the rest)
    } else {
        // Concatenate error code and text to produce a error string
        // since error string can be ambiguous and long
        errorMessage = err.status + ' ' +  err.statusText;
        errorMessages.push(errorMessage);
    }

    return errorMessages
}

export function formatError(error, msgSrv, key) {
    let requiredMsgs = [];
    let errorMessages = handleError(error);
    errorMessages.forEach(errorMessage => {
        if (error.status && error.status == 403) {
            msgSrv.add({ key: key, severity: 'error', summary: 'Error', detail: errorMessage });
        } else {
            requiredMsgs.push({ key: key, severity: 'error', summary: 'Error', detail: errorMessage });
        }
    })

    return [msgSrv, requiredMsgs]
}

export function formatMessageServicesError(error, msgSrv) {
        let errors = handleError(error);
        errors.forEach(errorMessage => {
            msgSrv.add({ severity: 'error', summary: 'Error', detail: errorMessage });
        })
        return msgSrv
}

export function sanitizeErrorMessage(message) {
    let errorField = message.split(/\s(.+)/)[0];
    let errorValue = message.split(/\s(.+)/)[1];

    // If error value contains special characters (excluding few ones), return hardcoded value
    const regexPattern = /[!@#$%^&*()_+\=\[\]{};:\\|<>\/?]/
    if (regexPattern.test(errorValue)) {
        errorValue = 'is invalid';
    }

    return toTitleCase(errorField) + ' ' + errorValue;
}

function toTitleCase(str) {
    let strArray = str.replaceAll(/'|"/g, '').split('_')

    for (let i=0; i < strArray.length; i++) {
        strArray[i] = strArray[i].charAt(0).toUpperCase() + strArray[i].slice(1)
    }
    return strArray.join(' ')
}