<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="csMsg"></p-toast>
        <p-toast key="meMsg"></p-toast>
        <p-toast key="wfMsg"></p-toast>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button" (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" icon="fa fa-thumbs-up" style="float:right;"></button>
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-media-form-save"></button>
                <container-workflow-status [container]="container" (onSimpleStatusChange)="onSimpleStatusChange($event)" (onComplexStatusChange)="onComplexStatusChange($event)"(onStatusTransitionFailure)="onStatusTransitionFailure($event)" style="float:right;margin-right:4px;"></container-workflow-status>

            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['title']" name="title" data-element-id="app-content-forms-media-form-title">
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-content-forms-media-form-guid">
                </div>
                <div class="ui-g-12">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-content-forms-media-form-description">
                </div>
            </div>
        </p-panel>

        <!--
        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
                <p-header>
                    <span class="p-panel-title" style="font-size:16px;">Preview</span>
                </p-header>
                <div *ngFor="let resource of container.resources; let i = index">
                            <preview-player *ngIf="resource.type.name == 'video' && resource.data.delivery_protocol == 'mpeg-dash'" [resource]="resource" [playerStyle]="{'height': '130'}"></preview-player>
                </div>
        </p-panel>
         -->

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability</span>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['admin', 'export']" *ngxPermissionsOnly="['view.show.admin']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <span *ngxPermissionsExcept="['view.show.admin']">
                    <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['export']"  *ngxPermissionsOnly="['object.write.all', 'object.write.media']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                </span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="publishDate" style="display:block;">Publish Date</label>
                    <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="availableDate" style="display:block;">Available Date</label>
                    <p-calendar [(ngModel)]="container.availableDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="publicWindowEndDate" style="display:block;">Public Window End Date</label>
                    <p-calendar [(ngModel)]="container.publicWindowEndDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="expirationDate" style="display:block;">Expiration Date</label>
                    <p-calendar [(ngModel)]="container.expirationDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
            </div>
        </p-panel>
        <container-tags-panel [container]=container viewName="media" class="ui-g-12 ui-md-12 ui-lg-12"></container-tags-panel>
        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6 ui-lg-3">
                    <label for="referenceDate">Air Date/Reference Date</label><br>
                    <p-calendar [(ngModel)]="container.referenceDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}" data-element-id="app-content-forms-media-form-air-date-reference-date"></p-calendar>
                    <!-- <input type="text" class="form-control" [(ngModel)]="container.data.pub_date" name="pubDate"> -->
                </div>
                <div class="ui-g-6 ui-lg-3">
                    <label for="localAirDate">Local Air Date</label><br>
                    <p-calendar [(ngModel)]="container.localAirDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}" data-element-id="app-content-forms-media-form-local-air-date"></p-calendar>
                    <!-- <input type="text" class="form-control" [(ngModel)]="container.data.local_air_date" name="localAirDate"> -->
                </div>
                <div class="ui-g-6 ui-lg-3">
                    <label for="originalAirDate">Original Air Date</label><br>
                    <p-calendar [(ngModel)]="container.originalAirDate" [showTime]="true" [inline]="false" [showIcon]="true" appendTo="body" [ngModelOptions]="{standalone: true}" data-element-id="app-content-forms-media-form-original-air-date"></p-calendar>
                    <!-- <input type="text" class="form-control" [(ngModel)]="container.data.original_air_date" name="originalAirDate"> -->
                </div>
                <div class="ui-g-6 ui-lg-3">
                    <label for="rating">Country of Origin</label>
                    <select [(ngModel)]="container.data.country_of_origin" name="country_of_origin" class="form-control" data-element-id="app-content-forms-media-form-country-origin">
                        <option *ngFor="let ccountry of country_of_origin" [value]="ccountry">{{ccountry}}</option>
                    </select>
                </div>
                <!--
                <div class="ui-g-4">
                    <label for="releaseDate">Default Release Date</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.release_date" name="releaseDate">
                </div>
                 -->
                <div class="ui-g-12">
                    <label for="keywords">Keywords</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.keywords" name="keywords" data-element-id="app-content-forms-media-form-keywords">
                </div>
                <div *ngIf="container.id > 0" class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Details</span>
                <cue-point-edit [cuePoints]="this.container.data['chapter_start_times']" (onCuepointEdit)="onCuepointEdit($event)" style="float:right"></cue-point-edit>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="genre">Genre</label>
                    <select [(ngModel)]="container.data.genre" name="genre" class="form-control" data-element-id="app-content-forms-media-form-genre">
                        <option *ngFor="let ctype of genre" [value]="ctype.value">{{ctype.label}}</option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <container-subgenre [container]="container"></container-subgenre>
                </div>
                <div class="ui-g-4">
                    <label for="rating">Rating</label>
                    <container-ratings-pulldown [(model)]="container.data.tv_rating"  system ="ca-tv" ></container-ratings-pulldown>
                </div>
                <div class="ui-g-4">
                    <label for="episodeNumber">Episode &#35;</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.episode_number" name="episodeNumber" data-element-id="app-content-forms-media-form-episode-number">
                </div>
                <div class="ui-g-4">
                    <label for="season">Season &#35;</label>
                    <input [disabled]="true" type="text" class="form-control" [(ngModel)]="container.data.season_number" name="season" data-element-id="app-content-forms-media-form-season">
                </div>
            </div>
        </p-panel>


        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
                <reparent-modal-form [container]=container *ngxPermissionsOnly="['object.write.all', 'object.write.media']" (reparenting)="onReparenting($event)"></reparent-modal-form>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-content-forms-media-form-type">
                </div>
                <div class="ui-g-4">
                    <label for="houseId">House Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.houseId" name="houseId" data-element-id="app-content-forms-media-form-house-id">
                </div>
                <div class="ui-g-4">
                    <label for="externalVendorId">External Vendor Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.externalVendorId" name="externalVendorId">
                </div>
                <!--
                <div class="ui-g-4">
                    <label for="status">Status</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="container.status" name="status">
                </div>
                 -->
                <div class="ui-g-4">
                    <label for="showTitle">Show Title</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="container.data.show_title" name="ShowTitle" data-element-id="app-content-forms-media-form-show-title">
                </div>
                <div class="ui-g-4">
                    <label for="showId">Show Id</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.data.primary_parent_container_id" name="ShowId" data-element-id="app-content-forms-media-form-show-id">
                </div>
                <div class="ui-g-4">
                    <label for="seasonId">Season Id</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.data.secondary_parent_container_id" name="seasonId" data-element-id="app-content-forms-media-form-season-id">
                </div>
            </div>
        </p-panel>
    </div>
</form>
