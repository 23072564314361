<p-dialog header="Confirm Status Change"
        [(visible)]="displayDialog"
        [modal]="true"
        [minY]="70"
        [baseZIndex]="10000"
        styleClass="dialog-max-40em"
        [style]="{'min-width': '200px'}"
        [appendTo]="'body'">
    <span *ngIf="processingRequest == false" style="white-space: normal;">Are you sure you want to put this object into <b>{{capitalize(this.newContainerStatus)}}</b> status ?</span>

    <div *ngIf="this.isSegmentScheduled && processingRequest != true" >
            <h5>Select schedule date and time:</h5>
            <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="true" [showIcon]="true" dateFormat="@"></p-calendar>
    </div>
    <p-progressSpinner *ngIf="processingRequest == true" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        <p-footer *ngIf="processingRequest == false">
            <button type="button"  pButton
                    autofocus
                    *ngIf="publishDate || this.newContainerStatus != this.isSegmentScheduled"
                    icon="pi pi-check"
                    (click)="saveContainerAndPostStatusChangeToService()"
                    label="Yes"
                    data-element-id="app-content-components-container-workflow-status-button-yes">
            </button>
            <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false" label="No" class="p-button-secondary" data-element-id="app-content-components-container-workflow-status-button-no"></button>
        </p-footer>
</p-dialog>

<p-splitButton label="{{capitalize(this.container.status)}}" icon="pi pi-file"  [model]="workflowStatusMenuItems" appendTo="body" [disabled]="!this.container.guid || this.container.status === 'waiting' || this.container.status === 'processing' || isContainerLocked" data-element-id="app-content-components-container-workflow-status-edit"></p-splitButton>
