import { Container } from './container';

export class Lock {
  id: Number = null;
  type: string = '';
  guid: string = '';
  data: object = {};
  origin: string = '';
  created_date: number = null;
  updated_date: number = null;
  container_id : Number = new Container().id;
  user_id: number = null;
}
