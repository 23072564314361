<p-panel>
    <p-header>
            <span class="p-panel-title ui-g-12 ui-md-6 ui-lg-4 view-title" style="padding:0;">Media</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <div style="float:right;">
                <p-checkbox
                    labelStyleClass="p-schedule-checkbox"
                    binary="true"
                    label="S4M Schedule"
                    [(ngModel)]="isScheduleSearch"
                    inputId="scheduleSearch"
                    (onChange)="handleFilterSelect()">
                </p-checkbox>
                <p-calendar
                    [(ngModel)]="rangeDates"
                    [showTime]="false"
                    [inline]="false"
                    [showIcon]="true"
                    appendTo="body"
                    placeholder="Select Date Range"
                    selectionMode="range"
                    (onSelect)="handleDateSelect()"
                    showButtonBar="true"
                    (onClearClick)="onClearClick()">
                </p-calendar>
            </div>
            <spinner [hidden]= "!isLoading"></spinner>
    </p-header>
    <p-table
        [value]="mediaContainers"
        [rows]="rows"
        [first]="first"
        selectionMode="single"
        dataKey="guid"
        [lazy]="true"
        [paginator]="true"
        paginatorPosition="bottom"
        (onLazyLoad)="onLazyload($event)"
        (onRowSelect)="onRowSelect()"
        (onRowUnselect)="unselectTableItems()"
        [(selection)]="container"
        [totalRecords]="totalRecords"
        styleClass="p-datatable-gridlines p-datatable-nested">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:5em;text-align:center">Origin</th>
                <th style="width:7.8em;text-align:center">Title</th>
                <th style="width:18em;text-align:center">Relevance</th>
                <th style="width:5.4em;text-align:center">Reviewed</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-media>
            <tr [pSelectableRow]="media">
                <td style="word-break:break-all;overflow:hidden">{{media.origin}}</td>
                <td style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"><strong>{{media.data.show_title}}</strong><br>{{media.label}}</td>
                <td><contextual-ads-graph [inputContainer]="media" [segmentKeywords]="segmentKeywords"></contextual-ads-graph></td>
                <td style="text-align:center;overflow:hidden">
                    <p-triStateCheckbox
                        styleClass="p-tristate-checkbox"
                        [(ngModel)]="validationStatus[media.guid]"
                        (ngModelChange)="onTristateCheckBoxChecked(media, validationStatus[media.guid])">
                    </p-triStateCheckbox>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>