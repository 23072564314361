import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';
import { SelectItem } from 'primeng/api';
import { DataView } from 'primeng/dataview';

@Component({
    selector: 'container-dataview',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-dataview.component.html',
    styleUrls: ['container-dataview.component.css']
})
export class ContainerDataviewComponent implements OnInit {
    @Input() containers: Array<Container>;
    @Input() parentContainer: Container;
    @Input() rows: number;
    @Input() filterBy: string;
    @Input() sortOptions: Array<any>;
    @Input() isLoading: boolean;
    @Input() sortField: string;
    @Input() sortOrder: number;
    @Output() statusSelectionChange: EventEmitter<any> = new EventEmitter();
    @ViewChild('dv') dataView: DataView;

    public statusList: Array<SelectItem> = [];
    public selectedStatusOption = 'published';


    constructor() {}

    ngOnInit() {
        this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));
    }

    onStatusChange() {
        this.statusSelectionChange.emit({
            status: this.selectedStatusOption
        });
        this.dataView.first = 0;
    }

    // throttledPageReset() {
    //     if (this.throttledReset) {
    //         window.clearTimeout(this.throttledReset);
    //         this.throttledReset = null;
    //     }
    //     this.throttledReset = window.setTimeout(function() {
    //         let pages: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ui-paginator-page') as HTMLCollectionOf<HTMLElement>;
    //         pages[0].click();
    //     }, 500);
    // }
}
