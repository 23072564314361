<div class="login-body">
    <div class="login-panel p-fluid">
        <div class="ui-g login-container" style="padding-bottom: 20px;">
            <div class="ui-g-12 logo-container">
                <img src="../assets/layout/images/vms-logo-green.png" class="morpheus-logo" />
            </div>
            <login-form (formSubmit)="onSubmit($event)"></login-form>
        </div>
    </div>
</div>
