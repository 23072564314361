<page-header title="Edit Schema{{!isLoading && schema ? ': ' + schema.label : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Schema{{!isLoading && schema ? ': ' + schema.label : ''}}</h1>

    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="schema && !isLoading">
        <schema-form #form class="ui-g-12 ui-g-nopad" [schema]="schema" (formSubmit)="onSubmit($event)" (formCancelled)="onBack($event)" (formFailed)="onFailure($event)"></schema-form>
        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            <div *ngIf="schema.id > 0" class="ui-g-12">
              <history [inputObject]="schema" inputType='schema'></history>
            </div>
        </p-panel>
        <div class="ui-g-12" *ngIf="activeOrigin == 'system'">
            <schema-container-table [schema]="schema" [containers]="containers" (containerRemoved)="onContainerRemoved($event)" [title]="'Limit to Origins'" [rows]=10>
                <button pButton type="button" label="Add" [routerLink]="['/settings/schemas', schema?.id, 'relate', 'origin']" icon="pi pi-plus" style="float:right;margin-left:0px;height:100%;"></button>
            </schema-container-table>
        </div>

        <hr>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Schema</span>
            </p-header>
            <json-editor #schema_schema [options]="editorOptionsSchema" [data]="schemaObject"></json-editor>
        </p-panel>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Data</span>
            </p-header>
            <json-editor #schema_data [options]="editorOptionsData" [data]="dataObject"></json-editor>
        </p-panel>
    </div>
</div>