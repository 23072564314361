<p-panel>
  <p-header>
      <div class="ui-g">
          <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Origins</span>
          <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
              <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="dt.filterGlobal($event.target.value, 'contains')" class="filter-bar"/>
          </div>
          <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
              <ng-content></ng-content>
          </div>
          <spinner *ngIf="isLoading"></spinner>
      </div>
  </p-header>

  <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.label', 'data.origin_id', 'data.adobe_resource_id']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both"
            styleClass="p-datatable-gridlines  p-datatable-nested"
            [responsive]="true">
      <ng-template pTemplate="header">
          <tr>
              <th>Origin</th>
              <th>Origin Id</th>
              <th>Adobe Resource Id</th>
              <th style="width:42px;"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-origin>
          <tr>
              <td>{{origin.data.label}}</td>
              <td ><div class="label label-default">{{origin.data.origin_id}}</div></td>
              <td>{{origin.data.adobe_resource_id}}</td>
              <td>
                  <button pButton type="button" class="p-button-danger" (click)="onRemove($event, origin)" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;"></button>
              </td>
          </tr>
      </ng-template>
  </p-table>
</p-panel>
