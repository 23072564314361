<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Platforms</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar"/>
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.name', 'data.platform_type', 'data.bundle_id']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines p-datatable-nested"
            [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Platform Type</th>
                <th>Bundle Id</th>
                <th>GUID</th>
                <th style="width:42px;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-platform>
            <tr>
                <td>{{platform.data.name}}</td>
                <td><div class="label label-default">{{platform.data.platform_type}}</div></td>
                <td style="word-break: break-all;">{{platform.data.bundle_id}}</td>
                <td style="word-break: break-all;">{{platform.guid}}</td>
                <td>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, platform)" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>