import { Component, OnInit, Input, Output, EventEmitter,SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'container-single-select-pulldown',
  templateUrl: './container-single-select-pulldown.component.html',
  styleUrls: ['./container-single-select-pulldown.component.css']
})
export class ContainerSingleSelectPulldown implements OnInit, OnChanges {
    @Input() label: String;
    @Input() placeholder: String;
    @Input() ngModel: any;
    @Input() options: any[];
    @Input() allowedOptions: [];
    @Input() disabled: Boolean = false;
    @Input() optionsLabelKey: string;
    @Input() allowOptions: Array<string> = [];
    @Output() onChangeSelection: EventEmitter < any > = new EventEmitter();
    private selectedOption;

    constructor() { }

    ngOnInit() {
      if (this.ngModel) { this.selectedOption = this.ngModel }

        if (this.allowOptions.length > 0) {
            this.options = this.options.filter(option => this.allowOptions.includes(option[this.optionsLabelKey]))
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('options' in changes && changes['options'].previousValue != changes['options'].currentValue ||
            'allowOptions' in changes && changes.allowOptions.previousValue != changes.allowOptions.currentValue ) {
            if (this.allowOptions.length > 0) {
                this.options = this.options.filter(option => this.allowOptions.includes(option[this.optionsLabelKey]))
            }
        }
        if (!this.selectedOption) { this.selectedOption = this.ngModel }
    }


    onChangeValue(event) {
        this.ngModel = this.selectedOption;
        this.onChangeSelection.emit(event)
    }

    capitalize(word) {

        let new_word = word
            .split('_')
            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ');

        return new_word;

    }
}
