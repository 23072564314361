<page-header title="Create Document" 
             [showSpinner]="isLoading" 
             [error]="error"></page-header>
<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">

    <h1>New Document</h1>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <document-form [container]="container" 
                       (formSubmit)="onSubmit($event)" 
                       (formFailed)="onFailure($event)" 
                       (formCancelled)="onCancel($event)">
        </document-form>
    </div>

</div>
