
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { ResourceUploadService } from '../../../upload/resource-upload.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription ,  Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContainerTagsTabComponent } from '../../../content/components/container-tags-tab/container-tags-tab.component';
import { UploadService } from '../../../upload/upload.service';
import { CASegmentKeywordComponent } from '../../components/segment-keyword-autocomplete/segment-keyword-autocomplete.component'
import { OriginSelectComponent } from '../../components/origin-select/origin-select.component';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'contextual-ads-context-form',
    providers: [
        ContainerService,
        UploadService,
    ],
    templateUrl: 'contextual-ads-context-form.component.html',
    styleUrls: ['contextual-ads-context-form.component.css']
})
export class CAContextFormComponent implements OnInit {
    @Input() containerId: Number;
    @Input() seasons: Container[];
    @Output() onContainerUpdate: EventEmitter < any > = new EventEmitter();
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancel: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    public container: Container;
    private containers = [];
    private templateContainer: Container;

    public error: any;
    public isLoading = false;

    publishDate: Date;
    
    private tagTypeFilterList = [];
    private tagTypeHiddenList = [];

    private keywordChips: string[];


    private containerStatus: string;

    public msgs: Message[] = [];
    private msgDetail = [];

    private count: number;
    private page: number = 0;
    private lastSearch = null;
    private lastSearchTerms = [];
    private totalRecords;
    private result;

    @ViewChild(CASegmentKeywordComponent)segmentKeywordComponent: CASegmentKeywordComponent;
    @ViewChild(OriginSelectComponent)segmentOriginComponent: OriginSelectComponent

    constructor(
        private entityTypeService: EntityTypeService,
        private containerService: ContainerService,
        private authService : AuthService,
        
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['containerId'].previousValue != changes['containerId'].currentValue && this.containerId) {
            console.log('unsub');
            this.msgs = [];

            this.refetchContainer();
        }
    }

    refetchContainer() {
        this.isLoading =true
        this.containerService
        .get(this.containerId,'full',0,'none')
        .subscribe(
            res => {
                this.container = res instanceof Container
                    ? res
                    : Container.fromJson(res);
                //this.container = res;
                this.containerStatus = this.container.status;
                this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
            },
              err => {
                this.error = err;
                console.log('error', this.error);
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    refetchResources(): Observable<any> {
        return this.containerService
                .get(this.containerId).pipe(
                tap(
                    res => {
                        let container = res instanceof Container
                            ? res
                            : Container.fromJson(res);
                        if (this.container && container.id == this.container.id) {
                            // Replace resource list
                            this.container.resources = container.resources;
                        }
                    }
                ));
    }
    
    ngOnInit() {

        //this.isLoading = true;
    }

/*
    filterKeywords(event) {
        let query = event.query;
        
        this.filteredKeywords = [];
        for(let i = 0; i < this.matchingKeywords.length; i++) {
            let keyw = this.matchingKeywords[i];
            if(keyw.label.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                this.filteredKeywords.push(keyw);
            }
            console.log("KEYWORD Filtered: filter",this.filteredKeywords)
        }

 
    }

    */

    ngOnDestroy() {
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        }
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    getContainerTagTypes() {
        return this.container.tags ? this.container.tags.map(tag => tag['type']) : [];
    }

    onSubmit(e) {
        console.log('container submitted', this.container);


        if (this.publishDate) {
            this.container.published_date = Math.trunc(this.publishDate.getTime() / 1000);
        }
  

        //if (this.keywordChips.length > 0) {
        //    this.container['data']['keywords'] = this.keywordChips.join(', ');
        //}

        this.msgs = [];
        this.msgDetail = [];


        let valid = null;
        //let segmentKeywords = this.segmentKeywordComponent.selectedKeywords;
        let segmentKeywords = this.container['data']['segment_keywords'];
        let segmentOrigins =  this.segmentOriginComponent.selectedOrigins;

        let keywordsMissing = (segmentKeywords.length < 1);
        let originsMissing =  (segmentOrigins.length < 1);


        if (!keywordsMissing && !originsMissing) {
            valid = this.ngForm.form.status === 'VALID';
        }
        if (keywordsMissing) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please choose at least one keyword.' });
            valid = false;
        }
        if (originsMissing) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please choose at least one origin.' });
            valid = false;
        }
        if (!('label' in this.container.data) || !(this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the title.' });
            valid = false;
        }

        if (valid) {
            //this.container['data']['segment_keywords'] = this.segmentKeywordComponent.selectedKeywords;
            this.container['data']['origins'] = this.segmentOriginComponent.selectedOrigins;
            this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                    this.onContainerUpdate.emit({
                        container: this.container
                    });
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );

            this.formSubmit.emit({
                container: this.container
            });   
        }
    }

    onStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date); 
        this.onContainerUpdate.emit({
            container: this.container
        }); 
    }

    updateContainerStatus(status: string) {
        this.container.status = status;
        this.containerService
        .save(this.container)
        .subscribe(
            res => {
                console.log(res);
                this.onContainerUpdate.emit({
                    container: this.container
                }); 
                this.isLoading = false 
            },
            err => {
                console.error(err);
                this.msgs = [];
                this.msgs.push({ severity: 'error', summary: 'Error saving container status', detail: '' });
                this.isLoading = false  
            },
            () => this.isLoading = false
        );
    }

    getThumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }
  
    updateMainTags(newTags) {
        console.log(newTags);

    }

    onKeywordChange(event) {
        if (!this.msgDetail.includes(event.requiredmsgs.detail)) {
            this.msgs = [];
            this.msgs.push(event.requiredmsgs);
            this.msgDetail.push(event.requiredmsgs.detail);
        }
    }

    onOriginChange(event) {
        if (!this.msgDetail.includes(event.requiredmsgs.detail)) {
            this.msgs = [];
            this.msgs.push(event.requiredmsgs);
            this.msgDetail.push(event.requiredmsgs.detail);
        }
    }

}
