<div class="card card-w-title top-level-card">
    <h1>Edit Resource</h1>

    <page-header title="Edit Resource" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="resource && !isLoading">
       <resource-form #form [resource]="resource" [containerId]="containerId" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)" style="width: 100%;"></resource-form>

        <div *ngIf="resource.data != null" class="ui-g-12">
            <object-advancedview [object]="resource" title="Advanced Editing"></object-advancedview>
        </div>

    </div>
</div>
