<div *ngxPermissionsOnly="['view.show.bulktag']" class="card card-w-title top-level-card">
	<h1>Bulk Assign</h1>
	<div class="ui-g card-content">
    <p-messages styleClass="w-100"></p-messages>
		<!-- Info Text -->
		<div class="ui-g-12">
			<p-panel>
				<p-header>
					<div class="p-text-center p-text-normal">Select all of the items and tags you would like to add/remove to, and then click 'Confirm' below.</div>
				</p-header>
			</p-panel>
		</div>

		<!-- Tags Panel -->
		<div class="ui-g-12">
      <container-tags-panel [container]="container" (onBulkTagSelected)="onTagSelected($event)"></container-tags-panel>
		</div>

    <!-- List of Containers -->
    <div class="ui-g-12">
        <container-relate-form [reload]='reload' (cntSelected)="onCntSelected($event)" (formSubmit)="onSubmit($event)" (formCancelled)="onBack($event)"></container-relate-form>
    </div>

	</div>
</div>
