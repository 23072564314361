import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { History } from '../../models';
import { HistoryService } from '../../services/history/history.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { AppContext } from '../../../app.context';

@Component({
    selector: 'history-list',
    providers: [
        HistoryService
    ],
    templateUrl: 'history-list.component.html',
    styleUrls: ['history-list.component.css']
})

export class HistoryListComponent implements OnInit {

    public isLoading = false;
    public histories = [];
    public historyRecordsLength: number;
    public history : History;
    private sub: any;
    private objectId: number;
    private objectType = ''
    public columns = [];
    private limit: number;
    public offset: number = 0;
    private error: string;
    private currentHistoryId: number;

    constructor(
        public historyService: HistoryService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private appContext: AppContext,
    ) {}

    ngOnInit() {

        this.isLoading = true;

        this.sub = this.route.params.subscribe(params => {
            this.objectId = +params['id'];
            this.objectType = params['type'];
            if (this.appContext.componentState['history_view'] == null) {
                this.setListParams();
            }
            this.fetchRecentHistoryEntry();
            this.getListParams();
        });

    }

    setListParams(resetOffset: boolean = false) {
        if (resetOffset) {
            this.offset = 0;
        }
        this.appContext.componentState['history_view'] = {
            'offset': this.offset || null,
        };

    }

    getListParams() {
        this.offset = this.appContext.componentState['history_view']['offset'] || 0;
    }

    fetchRecentHistoryEntry() {

        this.historyService
            .getHistoryFromId(this.objectId, this.objectType, 1, 0)
            .subscribe(
                res => {
                    this.currentHistoryId = (res['results'])[0].history_id;
                },
                err => {
                    console.log(err);
                }
            ); 
    }

    fetchObjectHistory() {

        this.isLoading = true;

        this.historyService
            .getHistoryFromId(this.objectId, this.objectType, this.limit, this.offset)
            .subscribe(
                res => {
                    this.histories = res['results'];
                    this.historyRecordsLength = res.count;
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            ); 
    }

    loadResultsLazy(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.fetchObjectHistory();
        this.setListParams();
    }

    onRowSelect(event) {}

}
