import { Component, OnInit, Input } from '@angular/core';
import { Container } from '../../models';

@Component({
    selector: 'container-card',
    templateUrl: 'container-card.component.html'
})

export class ContainerCardComponent implements OnInit {
    @Input() container: Container;
    @Input() parentContainer: Container;
    public containerTypeName: string;
    constructor() {}

    ngOnInit() {
        if (typeof this.container.type === 'string') {
            this.containerTypeName = this.container.type;
        } else if (typeof this.container.type.name === 'string') {
            this.containerTypeName = this.container.type.name;
        } else {
            this.containerTypeName = '';
        }
    }

    // TODO: default thumbnail
    thumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }
}
