<p-panel [style]="{'textAlign':'center'}">
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title">{{segment.label}}</span>
        </div>
    </p-header>
    <div class="ui-g">
        <div *ngIf="appContext.activeOrigin !== segment.origin" class="ui-g-4 ">
            <div class="label label-warning">{{segment.origin}}</div>
        </div>
        <div class="ui-g-12" style="padding-bottom:0;">
            <div class="ui-g-12 label label-default">{{typeString}}</div>
        </div>
       <div class="ui-g-12">
            <div class="ui-g-12 label" [ngClass]="statusLabelClass()">{{ segment.status | titlecase }}</div>
       </div>
    </div>
</p-panel>
<p-toolbar>
    <div class="ui-g">
        <div class="ui-g-6"  style="margin:0 auto;">
            <button *ngIf="segment.origin === appContext.activeOrigin" label="Edit" class="p-button-primary panel-button" pButton
                type="button" [routerLink]="editRoute" icon="pi pi-pencil"></button>
        </div>
        <div class="ui-g-6" *ngIf="parentContainer && parentContainer.origin === appContext.activeOrigin" style="padding-left:0">
            <button label="Remove" class="p-button-danger panel-button" pButton type="button" (click)="remove($event, segment)" icon="pi pi-times"></button>
        </div>
    </div>
</p-toolbar>