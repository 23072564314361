import { Component, OnInit, Input, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'pipeline-log-modal',
  templateUrl: './pipeline-log-modal.component.html',
  styleUrls: ['./pipeline-log-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PipelineLogModalComponent implements OnInit {

  @ViewChild('pipelinelog') pipelineLogDisplay: JsonEditorComponent;
  @Input() selectedPipeline;

  public displayDialog = false;

  public pipelineLogDisplayOptions = new JsonEditorOptions();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.displayDialog = changes.selectedPipeline && this.selectedPipeline ? true : false
  }

  ngOnInit(): void {
    this.displayDialog = false;
    
    // json init values
    this.pipelineLogDisplayOptions = new JsonEditorOptions();
    this.pipelineLogDisplayOptions.mode = 'view';
    this.pipelineLogDisplayOptions.modes = ['view'];
    this.pipelineLogDisplayOptions.statusBar = true;
    this.pipelineLogDisplayOptions.expandAll = true;
  }

  showDialog() {
    this.displayDialog = true;
  }

  closeDialog() {
    this.displayDialog = false;
  }
}