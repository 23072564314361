import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'catalog-form',
    providers: [
        EntityTypeService,
    ],
    templateUrl: 'catalog-form.component.html'
})
export class CatalogFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formCancel: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityType;
    public isLoading = false;
    private msgs: Message[];

    private catalogTypes = [
        'apple_universal_services',
        'apple_itunes',
        'amazon_cdf',
        'google_dfp',
        'msn_syndication',
        'yahoo_syndication',
        'export_campaign',
        'other',
    ];

    private queryTypes = [
        'django_query',
        'vms_api_query',
    ];

    private contentTypes = [
        'application/json',
        'text/plain',
        'text/html',
        'text/xml',
        'text/csv'
    ];

    constructor(
        private entityTypeService: EntityTypeService,
    ) { }

    ngOnInit() {
      console.log("ngOnInit called")
        this.isLoading = true;
        this.entityTypeService
            .getByName('catalog')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    if (!('requires_basic_authentication' in this.container.data)) {
                      this.container.data['requires_basic_authentication'] = false;
                    }


                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
      console.log('container submitted', this.container);
      this.msgs = []
      if (!('label' in this.container.data) || (!this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
          this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the catalog label.' });
          return
      }

      if (!('bundle_id' in this.container.data) || (!this.container.data['bundle_id']) || !/\S/.test(this.container.data['bundle_id'])) {
        this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the catalog bundle id.' });
        return
    }
      this.formSubmit.emit({
            container: this.container
        });
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }
}
