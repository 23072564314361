import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'collection-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'collection-form.component.html'
})
export class CollectionFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @Output() statusError: EventEmitter<any> = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityType;
    public isLoading = false;
    private publishDate: Date;


    constructor(
        private entityTypeService: EntityTypeService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.setup();
    }

    resetToDefaultState() {
        this.ngForm.reset();
        this.setup();
    }

    setup() {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);

        if (!('locked' in this.container.data) || !this.container.data['locked']) {
            this.container.data['locked'] = true;
        }

        // if (!this.container.is_enabled) {
        //     this.container.is_enabled = true;
        // }

        this.isLoading = true;
        this.entityTypeService
            .getByName('collection')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date = new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.ngForm.form.markAsPristine()
        this.formSubmit.emit({
            container: this.container
        });

    }

    onSimpleStatusChange(event) {
        console.log('simple status transition', event);
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onComplexStatusChange(event) {
        console.log('pipeline triggered successfully');
        this.pipelineTriggered.emit(event);
    }

    onStatusTransitionFailure(e) {
        this.messageService.add(e);
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onError(error) {
        this.statusError.emit(error);
    }
}
