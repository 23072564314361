import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ContainerService } from '../../../../content/services/container/container.service';
import { Container } from '../../../../content/models';
import { Message } from 'primeng/api';


@Component({
    selector: 'advertisement-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'advertisement-create.component.html',
})
export class AdvertisementCreateComponent implements OnInit {
    public container: Container = new Container();
    public error: string;
    public isLoading: Boolean = false;
    public msgs: Message[] = [];

    constructor(
        private location: Location,
        public containerService: ContainerService,
        private router: Router
    ) {}

    ngOnInit() {
        console.log('advertisement create init');
    }

    onSubmit(e) {
        this.isLoading = true;
        console.log('advertisement submit', e);

        this.containerService
            .save(e.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/commerce/advertisement', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.isLoading = false;
                    this.error = err;
                    let error_message = (
                        err.error || err.statusText ||
                        'There are errors in the form, please review and fix errors before saving.'
                    );

                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error',
                        detail: error_message,
                    });
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
