import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';



@Injectable()
export class MetricsAnalyticsService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/templates/mas`

    constructor(
        private authService: AuthService,
        private http: HttpClient
    ) {}

    fetchStatus(): Observable<Object> {
        const headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        
        return this.http.get(
            `${this.baseUrl}/status/`, { headers }
        );
    }
    
    fetchStreamStatus(): Observable<Object> {
        const headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        
        return this.http.get(
            `${this.baseUrl}/stream-status/`, { headers }
        );
    }
}