import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ContentGroupState } from './content-group.interface';
import { CommerceService } from './commerce.service';

@Injectable()
export class ContentGroupService {

    private initCgState: ContentGroupState = {
        segments: {},
        contentGroup: null,
        activeChapter: [],
        activeKeywords: [],
        activeMoment: null,
        activeTimecode: 0,
        userTimecode: 0,
        playerTimecode: 0,
        activeChapterNumber: 1,
        activeMediaContainer: null,
        activeMediaChapters: [],
        activeMediaDuration: 0
      };

    /** Get state through observable **/
    private cgState: ContentGroupState;
    private currentCgState: BehaviorSubject<ContentGroupState> = new BehaviorSubject(this.initCgState);
    public playerTimecode: number;
    
    constructor(
        private commerceService: CommerceService,
    ) {}
    
    /** Allows subscription to the behavior subject as an observable **/
    public getCgState(): Observable<ContentGroupState> {
        return this.currentCgState.asObservable().pipe(distinctUntilChanged());
    }

    /** get cgState without subscription, e.g. on component init **/
    public getCurrentCgState(): ContentGroupState{
        return this.cgState; 
    }

    /**
     * Allows updating the current value of the behavior subject
     * @param val an AdState object containing changed property or properties
     */
    public updateCgState(val: any): void {
        // console.log('cg state ------------ begin')
        // console.log('cg state 1', this.cgState)
        // console.log('cg state 2', val)
        this.cgState = { ...this.cgState, ...val};
        //if (this.cgState['userTimecode'] === -1 && this.cgState['playerTimecode'] > 0) {
        if (this.cgState['userTimecode'] === -1) {
            this.cgState['activeTimecode'] = this.cgState['playerTimecode'];
        } else {
            this.cgState['activeTimecode'] = this.cgState['userTimecode'];
        }
        if ('activeMediaContainer' in val && val['activeMediaContainer']) {
            this.cgState['duration'] = Math.floor(val['activeMediaContainer']['data']['duration'])
            this.cgState['activeMediaChapters'] = this.getChaptersSeconds(val['activeMediaContainer']['data']['chapter_start_times'], this.cgState['duration'])
            this.cgState['activeChapter'] = this.getActiveChapter(this.cgState['activeTimecode'], this.cgState['activeMediaChapters'], this.cgState['duration'])
            this.cgState['activeChapterNumber'] = this.getActiveChapterNumber(this.cgState['activeTimecode'], this.cgState['activeMediaChapters'])
        }
        this.currentCgState.next(this.cgState);
        // console.log('cg state 3', this.cgState);
        // console.log('cg state ------------ end')
    }

    /**
     * Detect active chapter
     * @param time a number marking current moment in seconds
     * ex: 223
     * @param chapters array of numbers representing chapters start in seconds
     * ex:[ 0, 554, 969, 1245 ]
     * @param duration resource duration in seconds
     * ex: 1300
     */
    public getActiveChapter(time: number, chapters: any, duration: number): Array<number> {
        let startRange, endRange;
        // Sort array if needed
        // chapters.sort((a, b) => a - b);
        const index = chapters.findIndex(el => el > time);
        if (index > 0) {
            startRange = chapters[index - 1];
            endRange = chapters[index];
        } else {
            startRange = chapters[chapters.length - 1];
            endRange = Math.floor(duration);
        }
        return [startRange, endRange];
    }

    public getActiveChapterNumber(timecode, chapters) {
        for (let i=1; i < chapters.length; i++) {
            //console.log('chapter', chapters[i]);
            if (chapters[i] > timecode) {
                //console.log('chapter curr', i);
                return i;
            }
        }
    }

    public getSegmentsWithTimestamps(): Observable<any> {
        let keywordArr = this.cgState['contentGroup']['data']['segment_keywords'];
        let obsArray = [];
        if ( keywordArr &&  keywordArr.length > 0) {
            keywordArr.forEach(term => {
                obsArray.push(this.commerceService
                    .getSegmentForTermByParent(this.cgState['activeMediaContainer']['guid'], term['value']).pipe(
                    map(
                        res => {   
                            let segementResults = res['results'];
                            let timeArray = [];
                            if (segementResults.length > 0) {
                                let keyword =  segementResults[0]['label'];
                                for(let i = 0; i < segementResults.length; i++) { 
                                    let segment_object = {
                                        'start_time_code':segementResults[i]['start_time_code'],
                                        'end_time_code':segementResults[i]['end_time_code']
                                    }
                                    timeArray.push(segment_object);
                                }
                            }
                            return {"key": term['value'], "timestamps": timeArray}
                        },
                        err => { 
                            console.log(err);
                        }
                    ))
                );
            });
        }
        return forkJoin(...obsArray);
    }

    public getChaptersSeconds(chapters, duration=null)
    {   
        let chaptersSecondsArr = [0]
        if (chapters) {
            let chaptersSplit =  chapters.split(',')

            chaptersSplit.forEach((chapter, i) => { 
                let cArr = chapter.split(':');
                let seconds = (+cArr[0]) * 60 * 60 + (+cArr[1]) * 60 + (+cArr[2]);
                chaptersSecondsArr.push(seconds)
            });
        }
        //console.log("Chapter Seconds duration:::", duration)
        if (duration && typeof(duration)==='number') {
            chaptersSecondsArr.push(duration)
        }
        //console.log("Chapter Seconds:::", chaptersSecondsArr)
        return chaptersSecondsArr;
    }

}
