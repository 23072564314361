import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
@Component({
    selector: 'test-suite-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'test-suite-form.component.html'
})
export class TestSuiteFormComponent implements OnInit {
        @Input() container: Container;
        @Output() formSubmit: EventEmitter < any > = new EventEmitter();
        @Output() formFailed: EventEmitter < any > = new EventEmitter();
        @Output() formCancelled: EventEmitter < any > = new EventEmitter();
        @ViewChild('ngForm') ngForm: NgForm;
    
        private entityType;
        private entityTypeId;
        public isLoading = false;
    
        private publishDate :Date;

        private msgs: Message[] = [];
    
        constructor(
            private entityTypeService: EntityTypeService
        ) {}
    
        ngOnInit() {
            this.entityTypeId = this.container.type.id;
            this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
            this.isLoading = true;
            this.entityTypeService
            .getByName('test_suite')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
        }
    
        getDateFromTimeNumber(time) {
            if (!time) return null;
            var date =  new Date(0);
            date.setUTCSeconds(time);
            return date;
        }
    
        onSubmit(e) {
            console.log('container submitted', this.container);
    

            this.msgs = [];
    
            var valid = this.ngForm.form.status === 'VALID';
            if (valid) {
                this.ngForm.form.markAsPristine();
                console.log('container submitted', this.container);
                this.formSubmit.emit({
                    container: this.container
                });
            } else {
                this.formFailed.emit();
            }
        }
    
        onStatusComplete() {
            this.container.data['metadata_ingest_workflow_status'] = 'Complete';
            this.formSubmit.emit({
                container: this.container
            });
        }
    
        onStatusChange(event)
        {   
            this.publishDate = this.getDateFromTimeNumber(this.container.published_date); 
        }

        isPristine() {
            return this.ngForm.form.pristine;
        }
    
        onCancel(e) {
            this.formCancelled.emit();
        }
    }
    