<page-header title="Create Segment"
             [showSpinner]="isLoading"
             [error]="error"></page-header>
<div *ngIf="!segment && !isLoading && requiredMsgs">
    <p-messages [(value)]="requiredMsgs"></p-messages>
</div>
<div *ngIf="segment && !isLoading" class="card card-w-title top-level-card">

        <h1>New Segment</h1>

        <div *ngIf="segment && !isLoading">
            <p-messages [(value)]="requiredMsgs"></p-messages>
            <p-messages [(value)]="msgs"></p-messages>
        </div>

        <div class="ui-g card-content" *ngIf="segment && !isLoading">
            <segment-form *ngIf="!isLoading"
                          [segment]="segment"
                          (formSubmit)="onSubmit($event)"
                          (formFailed)="onFailure($event)"
                          (formCancelled)="onCancel($event)">
            </segment-form>
        </div>
</div>