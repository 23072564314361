import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { Container } from '../../models/container';
import { MenuItem } from 'primeng/api';

import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'object-advancedview',
    templateUrl: 'object-advancedview.component.html',
    styleUrls: ['object-advancedview.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ObjectAdvancedViewComponent implements OnInit {
    @Input() object: Container;
    public data:any;
    @Input() title: string;
    @Input() toggleable = true;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;


    editorOptionsEdit = new JsonEditorOptions();

    editorOptionsRead = new JsonEditorOptions();

    constructor() { }

    ngOnInit() {
        this.editorOptionsEdit.mode = 'tree';
        this.editorOptionsEdit.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsEdit.statusBar = true;

        this.editorOptionsRead.mode = 'view';
        this.editorOptionsRead.statusBar = true;
        console.log("container received-->", this.object)
        this.data = this.object['data']

    }

    ngOnChanges(changes: SimpleChanges) {
      if (this.editor) {
        this.data = this.object['data']
        let currentEditor = this.editor
        currentEditor.update(this.data)

      }
  }

    changeData(event)
    {
        let currentEditorDict = this.editor.get();

        for (let key of Object.keys(this.object['data'])) {
            // if (!Object.prototype.hasOwnProperty.call(currentEditorDict, key)) {
            if (!currentEditorDict.hasOwnProperty(key)) {
                delete this.object['data'][key];
            }
        }

        for (let key of Object.keys(currentEditorDict)) {
            this.object['data'][key] = currentEditorDict[key];
        }

        this.onChange.emit({
            object: this.object
        });
    }
}
