<page-header title="Create Cast Memeber" [showSpinner]="isLoading"></page-header>
<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">
  <h1>New Cast Member</h1>
    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages key="chMsg" [(value)]="msgs"></p-messages>

  <div class="card-content" *ngIf="container && !isLoading">
    <cast-form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onCancel($event)"></cast-form>
  </div>

</div>
