<p-panel class="ui-g-12 ui-md-12 ui-lg-12">
    <p-header>
        <span class="p-panel-title" style="font-size:16px;" data-element-id="app-landing-quick-links-label">Quick Links</span>
    </p-header>
    <div *ngFor="let quickLink of quickLinks">
        <a [routerLink]="quickLink.path" *ngxPermissionsOnly="['view.show.content']" data-element-id="app-landing-quick-links-target">
            <i class="fa fa-fw fa-caret-right"></i>
                {{ quickLink.title }}
        </a>
    </div>
</p-panel>
