import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import {genderList, roleList} from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'cast-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'cast-form.component.html'
})
export class CastFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityType;
    public isLoading = false;
    private status: any;
    private isProcessing = false;
    private disable_tabular_view = false;

    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    public readonly genderList = genderList;
    public readonly roleList = roleList;

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
      this.isLoading = true;
      this.entityTypeService.getByName('cast').subscribe((res: any) => {
          this.entityType = res;
          this.container.type = this.entityType;
          console.log(res);
      },
        err => console.log(err),
        () => this.isLoading = false
      );
    }

    onSubmit(e) {
        this.msgs = [];
        let valid = this.ngForm.form.status === 'VALID';

        if (!('first_name' in this.container.data) || (!this.container.data['first_name']) || !/\S/.test(this.container.data['first_name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the first name.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);

            let label = [];

            if ('last_name' in this.container.data && /\S/.test(this.container.data['last_name'])) {
                label.push(this.capitalize((this.container.data['last_name'] as string).trim()));
            }

            if ('first_name' in this.container.data && /\S/.test(this.container.data['first_name'])) {
                label.push(this.capitalize((this.container.data['first_name'] as string).trim()));
            }

            if ('middle_name' in this.container.data && /\S/.test(this.container.data['middle_name'])) {
                label.push(this.capitalize((this.container.data['middle_name'] as string).trim()));
            }

            if ('type' in this.container.data && /\S/.test(this.container.data['type'])) {
                label.push(this.capitalize((this.container.data['type']as string).trim()));
            }

            this.container.data['label'] = label.join(" ");

            this.ngForm.form.markAsPristine();
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    capitalize(str) {
        if (!str)
            return str;
        return  str[0].toUpperCase() + str.slice(1).toLowerCase();
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }
    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

}
