import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';

@Component({
    selector: 'profile-table',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'profile-table.component.html',
    styleUrls: ['profile-table.component.css']
})
export class ProfileTableComponent implements OnInit {
    @Input() containers: Array<Container>;
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() rows: number;
    @Input() isLoading = false;
    private displayDialog = false;
    public container: Container;
    private entityTypes = [];
    private entityTypeId;

    constructor(
        public containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {}

    onRowSelect(event) {
        this.displayDialog = true;
    }

    save() {
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                },
                err => {
                    console.log(err);
                    this.displayDialog = false;
                },
                () => this.isLoading = false
            );
    }

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this profile?')) {
            console.log('remove', e, container);

            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                        let i = this.containers.indexOf(container);
                        this.containers.splice(i, 1);
                    },
                    err => console.log(err)
                );
        }
    }
}
