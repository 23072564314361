import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'landing-quick-links',
    templateUrl: 'landing-quick-links.component.html',
    styleUrls: ['landing-quick-links.component.css']
})
export class LandingQuickLinksComponent implements OnInit {
    public readonly quickLinks = [
        { title: 'Search', path: '/containers' },
        { title: 'Series', path: '/series' },
        { title: 'Channels', path: '/channels' }
      ];

    constructor() { }

    ngOnInit(): void {
    }

}
