<div *ngxPermissionsOnly="['view.show.locks']" class="card card-w-title top-level-card" data-element-id="app-settings-components-locks-header">
  <h1>Content Locks</h1>
  <div class="ui-g">
    <p-messages styleClass="w-100" data-element-id="app-settings-components-locks-message"></p-messages>
    <p-confirmDialog
        key="deleteLock"
        header='Release
        Confirmation' icon="pi pi-info-circle"
        [style]="{minWidth: '10vw'}"
        [baseZIndex]="10000"
        data-element-id="app-settings-components-locks-release-confirm">
    </p-confirmDialog>
  </div>
  <p-table
    #dt
    [value]="filteredLocks"
    dataKey="guid"
    [rows]="rowsPerPage"
    [paginator]="true"
    [loading]="isLoading"
    paginatorPosition="bottom"
    selectionMode="single"
    styleClass="p-datatable-gridlines"
    [globalFilterFields]="['guid', 'origin', 'container.type']"
    data-element-id="app-settings-components-locks-table">
    <ng-template pTemplate="caption">
      <div class="ui-g">
        <div class="ui-g-4">
          <input pInputText
            type="text"
            id="type"
            name="type"
            pInputText
            placeholder="Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            [style]="{'text-align':'center', 'height':'100%', 'width':'100%'}"
          />
        </div>
        <div class="ui-g-3">
          <p-dropdown
            [options]="lockTypes"
            (onChange)="onChangeLockType($event)"
            placeholder="No Type Filter"
            optionLabel="label"
            display="menu"
            [ngModel]="selectedType"
            [style]="{'height':'100%','width':'100%'}"
            data-element-id="app-settings-components-locks-lock-types">
          </p-dropdown>
        </div>
        <div class="ui-g-5">
          <button pButton
            type="button"
            (click)="fetchLocks()"
            label="Refresh"
            class="p-button-success"
            icon="fa fa-refresh"
            style="float:right;"
            data-element-id="app-settings-components-locks-refresh"
          >
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
          <th>Guid</th>
          <th>Title</th>
          <th>Origin</th>
          <th>Container Type</th>
          <th>Lock Type</th>
          <th>Created</th>
          <th style="width:7rem"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-lock>
        <tr>
          <td style="text-align:left">{{lock.guid}}</td>
          <td style="text-align:left">{{lock.container.data.title || lock.container.data.name || lock.container.data.label}}</td>
          <td>{{lock.origin}}</td>
          <td><div class="label label-default">{{lock.container.type}}</div></td>
          <td>
            <div [ngClass]="lock.type==='hard'?'label label-warning':'label label-success'">
              {{lock.type.charAt(0).toUpperCase() + lock.type.slice(1)}}
            </div>
          </td>
          <td>{{datePipe.transform(lock.created_date, 'yyyy-MMM-dd HH:mm:ss')}}</td>
          <td>
            <span>
              <button pButton
                type="button"
                class="p-button-danger"
                label="Release"
                (click)="onRelease(lock)"
                data-element-id="app-settings-components-locks-release">
              </button>
            </span>
          </td>
        </tr>
    </ng-template>
  </p-table>
</div>
