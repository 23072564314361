<p-panel>
    <p-header>
        <span class="p-panel-title" style="font-size:16px;width:100%;margin-left:5px;margin-right:5px;">{{container.data['title']}}</span>
    </p-header>

    <div class="ui-g-12" style="padding-bottom:0;">
        <div class="ui-g-12 label label-default">{{container.data['content_listing']}}</div>
    </div>
    <img class="ui-g-12" [src]="thumbnailUrl()">
</p-panel>
