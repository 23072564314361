import { Component, Input, OnInit,Output, EventEmitter } from '@angular/core';
import { Container } from '../../models';
import { ContainerService } from '../../services/container/container.service';

@Component({
    selector: 'container-notification-edit',
    templateUrl: 'container-notification-edit.component.html',
    styleUrls: ['container-notification-edit.component.css']
})

export class ContainerNotificationEditComponent implements OnInit {
    @Input() container: Container;
    @Output() addNotification: EventEmitter < any > = new EventEmitter();
    public displayDialog: boolean = false;
    public isLoading = false;
    public isProcessing = false;
    public progress = 0;
    public error: string;
    public notificationContainers = [];
    public selectedNotificationContainers = [];

    constructor(
        private containerService: ContainerService,
    ) {}

    ngOnInit() {
        this.getNotificationContainers();
    }

    showDialog() {
        this.displayDialog = true;
    }

    getNotificationContainers() {
        this.isLoading = true;
        this.containerService
            .clientSearch('notification', ['published'])
            .subscribe(
                res => {
                    // Filter notifications based on subtype (subtype must match container type) and mode (mode must be set to `send-manual`)
                    res.results.forEach(notificationContainer => {
                        let data = notificationContainer.data;
                        if ((data.hasOwnProperty('subtype') && data['subtype'] === this.container.type.name) && (data.hasOwnProperty('mode') && data['mode'] === 'send-manual')) {
                            this.notificationContainers.push({ 'label': data['name'], 'value': notificationContainer});
                        }
                    })
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    onSubmit() {
        this.submitAllContainersRecursively(0)
    }

    submitAllContainersRecursively(currentIndex) {
        this.isProcessing = true;
        this.progress = 0;
        this.containerService
            .relate(this.container, this.selectedNotificationContainers[currentIndex]['value'])
            .subscribe(
                res => {
                    this.progress = ((currentIndex + 1) / this.selectedNotificationContainers.length) * 100;
                    if (currentIndex < this.selectedNotificationContainers.length - 1) {
                        this.submitAllContainersRecursively(currentIndex + 1);
                    } else {
                        this.displayDialog = false;
                        this.isProcessing = false;
                        this.selectedNotificationContainers = [];
                    }
                },
                err => {
                    this.error = 'Relationship failed or already exists';
                    this.progress = ((currentIndex + 1) / this.selectedNotificationContainers.length) * 100;
                    if (currentIndex < this.selectedNotificationContainers.length -1) {
                        this.submitAllContainersRecursively(currentIndex + 1);
                    } else {
                        this.displayDialog = false;
                        this.isProcessing = false;
                        this.selectedNotificationContainers= [];
                    }
                },
                () => this.addNotification.emit()
            )
    }

    onClose() {
      this.displayDialog = false
      this.selectedNotificationContainers = []
    }

}
