import { UXAnalyticsProvider, UXAnalyticsUserAttributes } from './ux-analytics.types';

interface WindowHJ extends Window {
    hj?: any,
    _hjSettings?: any
}

// Slightly simplified default Hotjar init code
function initHotjar(window: WindowHJ, document: Document, hjId: number) {
    window.hj =
        window.hj ||
        function () {
            (window.hj.q = window.hj.q || []).push(arguments);
        };
    window._hjSettings = { hjid: hjId, hjsv: 6 };
    const headTag = document.getElementsByTagName('head')[0];
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
    headTag.appendChild(scriptTag);
    return window.hj;
}

export class UXAnalyticsHotJarProvider implements UXAnalyticsProvider {
    private _hj: CallableFunction = null;
    private hjId: number = null;

    constructor(hjId: number) {
        this.hjId = hjId;
    }

    init() {
        if (this.hjId) {
            this._hj = initHotjar(window, document, this.hjId);
        }
        return this
    }

    private hj(...args: Array<any>) {
        // Do nothing if Hotjar is not initialized
        if (!this._hj) return

        try {
            this._hj(...args);
        } catch (err) {
            console.error(err.message);
        }
    }

    recordPathChange(path: string) {
        this.hj('stateChange', path);
        return this;
    }

    recordEvent(name: string) {
        this.hj('event', name);
        return this;
    }

    identifyUser(userId: string | number, attributes: UXAnalyticsUserAttributes=null) {
        if (userId) {
            if (attributes) {
                this.hj("identify", userId, attributes);
            } else {
                this.hj("identify", userId);
            }
        }
        return this;
    }
}
