<div *ngIf="!isPinned" class="items" [dragula]="name" [(dragulaModel)]="containers">
    <div class="item" *ngFor="let container of containers; let i = index">
        <div *ngIf="container && container.id">
            <div class="content">
                <container [container]="container" [parentContainer]="parentContainer" (onRemove)="onRemove(container)"></container>
            </div>

            <!-- <p-toolbar>
                <div *ngIf="parentContainer.data.source_container_id" class="p-toolbar-group-left">
                    <button *ngIf="container.data.source_status" pButton type="button" class="p-button-OK" (click)="onUnPin($event, container)" icon="fa fa-circle"></button>
                    <button *ngIf="!container.data.source_status" pButton type="button" class="p-button-danger" (click)="onPin($event, container)" icon="fa fa-circle-o"></button>
                </div>
                <div class="p-toolbar-group-right">
                    <button pButton type="button" label="Remove" class="p-button-danger" (click)="onRemove($event, container)" icon="pi pi-times"></button>
                </div>
            </p-toolbar> -->

        </div>
    </div>
</div>
