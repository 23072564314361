<div class="items">
    <div *ngFor="let resource of resources; let i = index" class="item">

        <div class="content">
            <resource [resource]="resource"></resource>
        </div>

        <p-toolbar>
            <div class="p-toolbar-group-left">
                <button pButton type="button" [routerLink]="['/containers', parentContainer.id, 'resources', resource.id]" label="Edit" icon="pi pi-pencil"></button>
            </div>
            <div class="p-toolbar-group-right">
                <button pButton type="button" class="p-button-danger" (click)="onDelete(resource)" icon="pi pi-trash"></button>
            </div>
        </p-toolbar>

    </div>
</div>
