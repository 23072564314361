import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { JobsService } from '../../../content/services/jobs/jobs.service';
import { Container } from '../../../content/models/container';
import { Tag } from '../../../content/models/tag';
import { Status } from '../../../content/models/status';
import { ResourceListComponent } from '../../../content/components/resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../../../content/components/container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { ConfigurationFormComponent } from '../../forms/configuration-form/configuration-form.component';
import { BreadcrumbService } from '../../../app.breadcrumb.service';

@Component({
    selector: 'configuration-edit',
    providers: [
        ContainerService,
        JobsService
    ],
    templateUrl: 'configuration-edit.component.html',
    styleUrls: ['configuration-edit.component.css']
})
export class ConfigurationEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: ConfigurationFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private dataSet: Array<any>;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private breadcrumbService: BreadcrumbService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                        if (!this.container.data['entries']) {
                            this.container.data['entries'] = {};
                        }
                        this.dataSet = this.toIterable(this.container.data['entries']);

                        let updateBreadcrubMap = {config_name: this.container['data']['name']}
                        this.breadcrumbService.updateTemplatedLabels(updateBreadcrubMap)
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                    this.validateFormData();

                    let updateBreadcrubMap = {config_name: this.container['data']['name']}
                    this.breadcrumbService.updateTemplatedLabels(updateBreadcrubMap)
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    ngOnDestroy() {
        this.sub.unsubscribe();

    }

    // from MapToIterablePipe
    toIterable(dict: Object): Array<any> {
        var a = [];
        for (var key in dict) {
            if (dict.hasOwnProperty(key)) {
                a.push({ key: key, val: dict[key] });
            }
        }
        return a;
    }

    addProperty(newProperty : string) {
        if (newProperty && !this.container.data.hasOwnProperty(newProperty)) {
            console.log('Adding new property to policy:', newProperty);
            this.container.data['entries'][newProperty] = '';
            this.dataSet = this.toIterable(this.container.data['entries']);
        }
    }

    onReorder(e) {
        console.log('reorder', e);

        this.isLoading = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => console.log(res),
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.refetchContainer() // this.isLoading = false
            );

    }

    onEditComplete(e) {
        this.container.data['entries'][e.data.key] = e.data.val;
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    validateFormData() {
        this.msgs = [];
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        this.validateFormData();
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
