<div *ngIf="showFilters">
    <div *ngFor="let reportFilter of reportFilters">
        <div *ngIf="reportFilter.render_type === 'calendar_with_time'" class="ui-g-12 ui-md-12 ui-lg-12">
            <label for="filterType">{{reportFilter.label | labelToDisplay}}</label>
            <div class="ui-g-12" style="display:flex; flex-direction:row; padding-left:0">
                <div [innerHTML]="dateString" style="padding-right:30px;"></div>
                <div *ngIf="dateString !== ''" style="margin-left:-20px; display:flex; flex-direction:column; justify-content:center;">
                    <a (click)="clearDate(reportFilter.filter)">
                        <strong class="pi pi-times" style="color: rgb(119, 119, 119); cursor: pointer;"></strong>
                    </a>
                </div>
            </div>
            <p-calendar 
                [(ngModel)]="selectedFiltersTypeMap[reportFilter.filter]" 
                name="filterType" 
                [showTime]="true" 
                [inline]="true" 
                [showIcon]="true" 
                [disabled]="false" 
                appendTo="body" 
                [ngModelOptions]="{standalone: true}" 
                (onSelect)="handleDateSelect(reportFilter.filter)">
            </p-calendar>
        </div>
        <div *ngIf="reportFilter.render_type === 'calendar_with_range'" class="ui-g-12 ui-md-12 ui-lg-12">
            <label for="filterType">{{reportFilter.label | labelToDisplay}}</label>
            <span class="p-float-label">
                <metrics-reports-date-range
                    [filterOptions]="reportFilter['options']"
                    [filter]="reportFilter['filter']"
                    (onValueChange)="setFilterValues($event)">
                </metrics-reports-date-range>
            </span>    
        </div>

        <div *ngIf="reportFilter.render_type === 'container_search'" class="ui-g-12 ui-md-12 ui-lg-12">
            <label for="filterType">{{reportFilter.label | labelToDisplay}}</label>
            <span class="p-float-label">
                <metrics-reports-filter-containers-autocomplete (onValueChange)="setFilterValues($event)" [filterDefinition]="reportFilter"></metrics-reports-filter-containers-autocomplete>
            </span>
        </div>

        <div *ngIf="reportFilter.render_type === 'enum_drop_down'" class="ui-g-12 ui-md-12 ui-lg-12">
            <label for="filterType">{{reportFilter.label | labelToDisplay}}</label>
            <span class="p-float-label">
                <metrics-reports-filter-enum-pulldown (onValueChange)="setFilterValues($event)" [filterDefinition]="reportFilter"></metrics-reports-filter-enum-pulldown>
            </span>
        </div>

        

        <div *ngIf="reportFilter.render_type === 'container_pulldown'" class="ui-g-12 ui-md-12 ui-lg-12">
            <label for="filterType">{{reportFilter.label | labelToDisplay}}</label>
            <span class="p-float-label">
                <metrics-reports-filter-containers-pulldown (onValueChange)="setFilterValues($event)" [filterDefinition]="reportFilter"></metrics-reports-filter-containers-pulldown>
            </span>
        </div>
        

        <div *ngIf="reportFilter.render_type === 'drop_down'" class="ui-g-6 ui-md-6 ui-lg-6">
            <label for="reportFilter">{{reportFilter.label | labelToDisplay}}</label>
            <span class="p-float-label">
                <p-multiSelect [options]="reportFilter.options" 
                                [(ngModel)]="selectedFiltersTypeMap[reportFilter.filter]"
                                name="filterType" 
                                optionLabel="label" 
                                maxSelectedLabels="0" 
                                appendTo="body" 
                                [selectedItemsLabel]="'{0} selected'"
                                [panelStyle]="{minWidth:'20em'}" 
                                [defaultLabel]="'Filter(s)'"
                                (onChange)="handleSelectFilterItem()">
                    <ng-template let-filter pTemplate="item">
                        <div style="font-size:14px;float:right;margin-top:4px">{{ filter.label }}</div>
                    </ng-template>
                </p-multiSelect>
            </span>
        </div>
    </div>
</div>