import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Container } from '../../models';
import { MenuItem } from 'primeng/api';
import { ContainerService } from '../../services/container/container.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'container-operations',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-operations.component.html',
    styleUrls: ['container-operations.component.css']
})

export class ContainerOperationsComponent implements OnInit {
    @Input() containerId: Number;
    @Input() parentContainerId: Number;
    @Output() onOperationComplete: EventEmitter<any> = new EventEmitter();

    public processingRequest = false;
    private error: HttpResponse<any>;

    displayDialog: boolean = false;

    //for future split button
    operationsItems: MenuItem[];

    cloneOperationItem = {
        label: 'Clone', icon: 'fa fa-thumbs-up', command: () => {
            if (confirm('Are you sure you want to clone this item?')) {
                this.cloneContainer();
            }
        }
    }
    removeOperationItem = {
        label: 'Remove', icon: 'fa fa-times', command: () => {
            if (confirm('Are you sure you want to remove this item?')) {
                this.unrelateContainer();
            }
        }
    }

    constructor
        (
        private containerService: ContainerService,
    ) { }



    cloneContainerAction() {
        if (confirm('Are you sure you want to clone this item?')) {
            this.cloneContainer();
        }
    }

    cloneContainer() {
        console.log('container loaded');

        this.processingRequest = true;
        this.containerService
            .get(this.containerId, 'full', 0)
            .subscribe(
                res => {
                    let container = res;
                    container.id = -1;
                    delete container["guid"];
                    if ('name' in container.data) {
                        container.data.name = container.data.name + " (Clone)"
                    }
                    else if ('title' in container.data) {
                        container.data.title = container.data.title + " (Clone)"
                    }
                    console.log(container)
                    this.postCloneOperationToService(container)
                },
                err => this.error = err,
                () => this.processingRequest = false
            );
    };



    ngOnInit() {

        this.operationsItems = [this.cloneOperationItem, this.removeOperationItem]
    }

    relateContainer(newContainerId) {
        this.containerService
            .relateById(this.parentContainerId, newContainerId)
            .subscribe(
                res => {
                    this.processingRequest = false;
                    this.onOperationComplete.emit({
                        operation: 'clone',
                        cloned_container_id: newContainerId,
                    })
                },
                err => console.log(err)
            );
    }

    unrelateContainer() {
        this.containerService
            .unrelateById(this.parentContainerId, this.containerId)
            .subscribe(
                res => {
                    this.processingRequest = false;
                    this.onOperationComplete.emit({
                        operation: 'remove',
                    })
                },
                err => console.log(err)
            );
    }

    postCloneOperationToService(container) {

        this.containerService
            .save(container)
            .subscribe(
                res => {
                    let container = res;
                    console.log(res);
                    this.relateContainer(container.id)
                },
                err => {
                    console.log(err);
                },
                () => {
                    this.processingRequest = false;
                    this.displayDialog = false;
                }
            );
    }
}

