import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Lock } from '../../../content/models';
import { ContainerService } from '../../../content/services/container/container.service';
import { MessageService } from 'primeng/api';
import { AppContext } from '../../../app.context';
import { ContentLockTypes } from '../../../shared/enum';

@Component({
  selector: 'locks',
  providers: [
    ContainerService
],
  templateUrl: './locks.component.html',
  styleUrls: ['./locks.component.css']
})
export class LocksComponent implements OnInit {
  private allLocks:Lock[];
  public filteredLocks: Lock[];
  public isLoading:boolean
  public rowsPerPage = 25; // Number of records to show per page:number = 0

  public lockTypes = ContentLockTypes;
  public selectedType: object;


  constructor(
    private containerService: ContainerService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private msgService: MessageService,
    private appContext: AppContext,
  ) {}

  ngOnInit() {
    this.isLoading = true
    this.selectedType = this.lockTypes.find(obj => obj.value === 'soft')

    this.fetchLocks()
  }

  fetchLocks() {
    this.containerService.getAllLocks().subscribe(
      res => {
        this.allLocks = res,
        this.filteredLocks = this.allLocks.filter(obj => obj.type === this.selectedType['value'])
      },
      err => console.log(err),
      () => this.isLoading= false
    )
  }

  onRelease(lock){
    this.msgService.clear()
    this.confirmationService.confirm({
      message: 'Are you sure you want to release this lock?',
      key: 'deleteLock',
      accept: () => {
        this.isLoading = true;
        this.containerService.deleteLockByContainerId(lock.container.id).subscribe(
          res => {
            this.allLocks = this.allLocks.filter(obj => obj.id !== lock.id)
            this.filteredLocks = this.allLocks.filter(obj => obj.type === this.selectedType['value'])
          },
          err => this._showMsg(err.error.detail ?? err.error.msg, 'error'),
          () =>  this._showMsg('Lock released successfully')
        );
      },
      reject: null,
    });
  }

  onChangeLockType(event) {
    this.selectedType  = event.value
    this.filteredLocks = this.allLocks.filter(obj => obj.type === this.selectedType['value'])
  }

  _showMsg(msg, severity='success') {
    this.isLoading = false
    this.msgService.add({severity: severity, detail:msg});
  }

}
