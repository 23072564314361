import { Component, OnInit,Input, OnDestroy, ViewChild, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { MetricsService } from '../../services/metrics.service';

// import {
//   ChartComponent,
//   ApexAxisChartSeries,
//   ApexChart,
//   ApexXAxis,
//   ApexTitleSubtitle
// } from "ng-apexcharts";

// export type ChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   xaxis: ApexXAxis;
//   title: ApexTitleSubtitle;
// };


@Component({
    selector: 'metrics-report-line-graph',
    templateUrl: './metrics-report-line-graph.component.html',
    styleUrls: ['metrics-report-line-graph.component.css'],
    providers: [
        MetricsService
    ]
})
export class MetricsReportLineGraphComponent implements OnInit {

    @Input() reportData: any;
    @Input() renderOptions: object;
    @Input() view: any;

    private sub: any;
    private requiredMsgs = [];
    private error: any;
    public isLoading = false;

    private reportResults: any;

    // options
    legend: boolean = false;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = '';
    yAxisLabel: string = '';
    timeline: boolean = true;

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };

    //Input for transfor type / maybe use a pipe
    //Should use ngx-charts
    //Add ability to compare different filtered sets

    data: any;
    rawData: any;
    formattedDataNGX: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private metricsService: MetricsService
    ) {
        this.initializeGraphData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('reportData' in changes && changes['reportData'].previousValue != changes['reportData'].currentValue) {
            this.initializeGraphData();
            if (this.reportData) {
                this.createGraphSeriesFromResultsNGX(this.reportData['results']);
            }
        }
        if ('renderOptions' in changes && changes['renderOptions'].previousValue != changes['renderOptions'].currentValue ){
            this.xAxisLabel = this.renderOptions['x_axis_label'] || '';
            this.yAxisLabel = this.renderOptions['y_axis_label'] || '';
        }
    }
    
    ngOnInit() {
    }

    initializeGraphData() {
        this.formattedDataNGX = [];
    }

    createGraphSeriesFromResultsNGX(results) {
        // At some point we may need a multi-series mode
        const seriesValues = results.map(res => ({ name: res['label'], value: res['value'] }));
        this.formattedDataNGX.push({ name: '', series: seriesValues });
    }

    capitalize(word) {
        if (!word) return '<undefined>';
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    onSelect(event) {
    }

    onActivate(event) {
    }

    onDeactivate(event) {
    }

}
