import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services/container/container.service'
import { Container } from '../../../content/models/container';

@Component({
    selector: 'testing-reports-data-table',
    templateUrl: './testing-reports-data-table.component.html',
    styleUrls: ['testing-reports-data-table.component.css']
})
export class TestingReportsDataTableComponent implements OnInit {
    @Input() parentContainer: Container;
    @Input() isLoading = false;
    @Input() modifyPermissions: Array<any> = [];

    @Output() onStateChange: EventEmitter<any> = new EventEmitter();


    public container: Container;
    @Input() containers: Array<Container>;

    constructor(
        public containerService: ContainerService
    ) { }

    ngOnInit() { }

    handleModalViewStateChange(event) {
        let modalViewShowState = event
        this.onStateChange.emit(modalViewShowState);

        console.log("handleModalViewStateChange Table", event)
    }

    onOpenTestResults(event, testResults) {

    }
    onRowSelect(event) { }

}


