import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';

@Component({
    selector: 'applications',
    providers: [
        ContainerService
    ],
    templateUrl: 'applications.component.html',
    styleUrls: ['applications.component.css']
})
export class ApplicationsComponent implements OnInit {

    public containers = [];
    public isLoading = false;
    private error: string;
    public sortField: string;
    public sortOrder: number;
    private status: string = 'published';

    constructor(
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        this.sortField = 'data.name';
        this.sortOrder = 1;
        this.loadPage();  
    }

    loadPage() {
        this.isLoading = true;
        this.containerService
            .filterByTypeName('application', 'full', 1, true, this.status)
            .subscribe(
                res => this.containers = res,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => {
                    this.isLoading = false;
                    console.log('applications:', this.containers); // TODO: remove this eventually
                }
            );
    }

    getDistributions(app: Container) {
        return app.containers;
    }

    onStatusSelectionChange($event) {
        this.status = $event.status;
        this.loadPage();
    }
}
