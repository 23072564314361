import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Injectable, Inject } from '@angular/core';
import { UploadService } from '../upload/upload.service';
import { ResourceService } from '../content/services/resource/resource.service';
import { ContainerService } from '../content/services/container/container.service';
import { Resource, Container } from '../content/models';
import { ResourceUploadContext } from '../upload/resource-upload-context';
import { Observable } from 'rxjs';
import { EntityTypeService } from '../content/services/entitytype/entitytype.service';

@Injectable()
export class ResourceUploadService {

    private resourceUploadContexts = <any>{}
    private fileEntityType;
    private entityTypes;
    private uploadErrors = false;

    constructor(        
        public uploadService: UploadService,
        private authService: AuthService,
        private containerService: ContainerService,
        public resourceService: ResourceService,
        private entityTypeService: EntityTypeService) 
    {
        this.authService.userState.subscribe(
            res => {
                if (res.id) {
                    this.getEntityTypeData();
                }
            },
            err => { console.log(err)},
            () => { }
        )
    }

    hasUploadError() {
        return this.uploadErrors;
    }

    getEntityTypeData() {
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.fileEntityType = (this.entityTypes.find(t => t.name === 'file'));
                },
                err => console.log(err),
            );
    }


    createResourceUploadContext(container: Container, filesToUpload: any[],) {
       let resourceUploadContext = new ResourceUploadContext(container, filesToUpload, this.uploadService, this.containerService, this.resourceService)
       this.resourceUploadContexts[container.id] = resourceUploadContext;
       resourceUploadContext.uploadFiles(filesToUpload, this.fileEntityType)
    }

    getResourceUploadContextIdList() {
        if (this.resourceUploadContexts) {
            let keys = Object.keys(this.resourceUploadContexts);
            return keys;
        }
        return [];
    }

    getUploadProgressForContainer(id) {
        return new Observable<number>(observer => {
            const interval = setInterval(() => {
                let uploadStatus = this.getUploadingFilesForContainerId(id)
                observer.next(uploadStatus);

                if (uploadStatus && uploadStatus['status'] == 'error') {
                    this.uploadErrors = true;
                    observer.complete();
                }

                if (!uploadStatus || uploadStatus['status'] == 'complete') {
                    observer.complete();
                }
            }, 1000);
            
            return () => {
              console.log('Teardown');
              clearInterval(interval);
            }
        });
    }

    getUploadingFilesForContainerId(id) {
        if (this.resourceUploadContexts[id]) {
            return this.resourceUploadContexts[id].getUploadStatus()
        }
    }

    getNumberOfUploadingContexts() {
        let numberOfContextsUloading = 0
        for(var key in this.resourceUploadContexts) {
            var context = this.resourceUploadContexts[key]
            if (context.getUploadStatus().status === 'uploading') {
                numberOfContextsUloading++;
            }
        }
        return numberOfContextsUloading;
    }

    checkRequiredFiles(fileNames: Array<string>, required: Array<Object> = [], optional: Array<Object> = []): Object {
        let result = {
            allowed: {
                required: [],
                optional: [],
            },
            missing: {
                required: [],
                optional: [],
            },
            extra: [],
        };

        let requiredMap = new Map(required.map<[number, Object]>((f, i) => [i, f]));
        let optionalMap = new Map(optional.map<[number, Object]>((f, i) => [i, f]));

        // Check provided files
        for (let f of fileNames) {
            let found = false;
            // First check if file matches any required slot
            for (let [i, spec] of Array.from(requiredMap)) {
                let ext = this.uploadService.getFileExtension(f).toLowerCase();
                let formats = spec['formats'].map(e => e.toLowerCase());
                if (formats.includes(ext)) {
                    result.allowed.required.push([f, spec]);
                    requiredMap.delete(i);
                    found = true;
                    break;
                }
            }
            // If not found in required, check optional
            if (!found) {
                for (let [i, spec] of Array.from(optionalMap)) {
                    let ext = this.uploadService.getFileExtension(f).toLowerCase();
                    let formats = spec['formats'].map(e => e.toLowerCase());
                    if (formats.includes(ext)) {
                        result.allowed.optional.push([f, spec]);
                        optionalMap.delete(i);
                        found = true;
                        break;
                    }
                }
            }
            // If still not found, add to extra
            if (!found) {
                result.extra.push(f);
            }
        }

        // Enumerate unfilled slots
        for (let spec of Array.from(requiredMap.values())) {
            result['missing']['required'].push(spec);
        }
        for (let spec of Array.from(optionalMap.values())) {
            result['missing']['optional'].push(spec);
        }

        return result;
    }
}
