<div class="p-inputgroup">
    <input type="text" pInputText 
            [placeholder]="placeholderLabel"
            [value]="containerLabel"
            disabled>   
    <button *ngIf="containerId === 0" type="button" pButton pRipple
            [routerLink]="['/containers', parentContainer.id, childContainer.id, 'relatethrough', containerTypeName]"
            icon="pi pi-plus" 
            class="p-button-warn">
    </button> 
    <button *ngIf="containerId > 0" type="button" pButton pRipple
            (click)="removeThrough($event)"
            icon="pi pi-times" 
            class="p-button-danger">
    </button>   
</div>
