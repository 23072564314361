<p-dialog header="Cue Points"
        [(visible)]="cuepointEditDisplayDialog"
        [modal]="true"
        [dismissableMask]="true"
        [blockScroll]="true"
        [responsive]="true"
        [minY]="70"
        [baseZIndex]="10000"
        styleClass="dialog-max-40em"
        [style]="{'min-width': '200px'}"
        [appendTo]="'body'"
        (onHide)="onClear()">
    <div class="ui-g-12">
        <button pButton type="button" label="New" (click)="addNew()" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
    </div>
    <div class="ui-g-12">
        <p-messages [(value)]="requiredMsgs"></p-messages>
    </div>
    <div class="ui-g-12">
        <p-table [columns]="columns" #dt
                [value]="editedCuepoints"
                [responsive]="true"
                [scrollable]="true"
                scrollHeight="100px"
                styleClass="p-datatable-gridlines"
                [resizableColumns]="true"
                (onEditInit)="onEditInit($event)"
                (onEditComplete)="onEditComplete($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th>Time Code</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cuepoint let-rowIndex="rowIndex">
                <tr>
                    <td [pEditableColumn]="cuepoint" [pEditableColumnField]="'timecode'" [pEditableColumnRowIndex]="rowIndex">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                               <p-inputMask mask="99:99:99" [ngModel]="cuepoint" (ngModelChange)="onChange($event)"></p-inputMask> 
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{editedCuepoints[rowIndex]}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <div class="text-center">
                            <button pButton type="button" (click)="confirmDelete(editedCuepoints[rowIndex])" class="p-button-danger" icon="pi pi-trash"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-dialog header="Cue Point Delete Confirm"
        [(visible)]="confirmationDisplayDialog"
        [modal]="true"
        [minY]="70"
        [baseZIndex]="10000"
        styleClass="dialog-max-40em"
        [style]="{'min-width': '200px'}"
        [appendTo]="'body'">
        <span style="white-space: normal;">Are you sure you want to delete cue point <b>{{cuepointToDelete}}</b>?</span>
        <p-footer>
            <button type="button" pButton
                    autofocus 
                    icon="pi pi-check" 
                    (click)="removeCuepoint()" 
                    label="Yes">
            </button>
            <button type="button" pButton icon="fa fa-close" (click)="confirmationDisplayDialog=false" label="No" class="p-button-secondary"></button>
        </p-footer>
    </p-dialog>
</p-dialog>

<div>
    <button pButton type="button" label="Cue Points" icon="pi pi-arrow-right" (click)="showDialog()"></button>
</div>
