import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SchemaService } from '../../../content/services/schema/schema.service';
import { Schema } from '../../../content/models';

@Component({
  selector: 'schema-relate',
  templateUrl: 'schema-relate.component.html',
  styleUrls: ['schema-relate.component.css']
})
export class SchemaRelateComponent implements OnInit {
  private sub: any;
  public containerFilterType: string;
  public containerDisplayType: string;
  public schema: Schema;
  public error: string;
  public processing = false;
  private isLoading = false;
  private progress = 0;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public schemaService: SchemaService,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      let id = +params['id'];
      this.containerFilterType = params['type'] || '';
      this.containerDisplayType = this.containerFilterType.replace('_', ' ');

      console.log('id', id);
      console.log('containerFilterType', this.containerFilterType);

      this.isLoading = true;
      this.schemaService
        .get(id, true)
        .subscribe(
          res => {
            this.schema = res;
            this.isLoading = false;
          },
          err => {
            console.log(err);
            this.error = err.statusText;
          }
        );
    });
  }

  onSubmit(e) {
    console.log('relate submit heard', e);
    this.processing = true;
    this.progress = 0;
    this.relateRecursively(e.containers, 0, () => {
      if (this.error) {
        alert("Completed, but an error was encountered when relating one or more items: " + this.error)
        this.location.back();
      } else {
        this.location.back();
      }
    });
  }

  relateRecursively(containers, currentIndex, onDone) {
    this.schemaService
        .relate(this.schema, containers[currentIndex])
        .subscribe(
          res => {
            console.log(res);
            this.progress = ((currentIndex + 1) / containers.length) * 100;
            if (currentIndex < containers.length - 1) {
              console.log('Next relation...');
              this.relateRecursively(containers, currentIndex + 1, onDone);
            } else {
              console.log('Finished');
              onDone();
            }
          },
          err => {
            console.log('FAILURE:', err);
            this.error = 'Relationship failed or already exists';

            this.progress = ((currentIndex + 1) / containers.length) * 100;
            if (currentIndex < containers.length - 1) {
              console.log('Next relation...');
              this.relateRecursively(containers, currentIndex + 1, onDone);
            } else {
              console.log('Finished');
              onDone();
            }
          }
      );
  }

  onBack(e) {
    this.location.back();
  }

}
