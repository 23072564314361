<page-header title="Edit Policy - {{container?.data.title}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Policy</h1>

    <div class="ui-g" *ngIf="container && !isLoading">
        <!--<p-growl class="ui-g-12" [(value)]="requiredMsgs"></p-growl> -->
        <p-messages class="ui-g-12" [(value)]="requiredMsgs"></p-messages>
        <p-messages class="ui-g-12" [(value)]="msgs"></p-messages>

        <policy-form #form [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)" style="width:100%;"></policy-form>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>

        <!--
        <div class="p-widget-header ui-g-12" style="padding:4px 10px;border-bottom: 0 none">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input #gb type="text" pInputText size="50" placeholder="Global Filter">

            <button pButton label="Add" icon="pi pi-plus" (click)="addProperty(newProperty.value)" style="float:right; margin-left: 4px;"></button>
            <input #newProperty newProperty.value="" type="text" pInputText size="50" placeholder="New Property" style="float:right;">
        </div>
         -->
    </div>
</div>
