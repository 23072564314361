import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { UploadComponent } from '../../../upload/upload.component';
import { ContainerService } from '../../services/container/container.service';
import { ResourceService } from '../../services/resource/resource.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { UploadService } from '../../../upload/upload.service';
import { AuthService } from '../../../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { Resource } from '../../models';
import { Container } from '../../models/container';
import { genre as genreList } from '../../../shared/enum';
import { ResourceUploadService } from '../../../upload/resource-upload.service';

import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'upload-media-form',
    providers: [
        // ContainerService,
        // ResourceService,
        // PipelineService,
        // UploadService,
        // EntityTypeService,
        // AuthService,
    ],
    templateUrl: 'upload-media-form.component.html',
    styles: [`
        .ui-steps .ui-steps-item {
            width: 100%;
            `]
})
export class UploadMediaFormComponent implements OnInit {
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];

    container: Container = new Container();

    private entityTypeId;
    public isLoading = false;
    private isUploading = false;
    private error: string;
    private selectedSeasonId;
    private selectedPipelineId;
    private selectedEntityType;
    public ratingSystem = "ca-tv";

    private containerTypeName = 'episode';

    private pipelineContainer;
    private seriesContainerId;
    private createdContainerId;
    private createdContainerGuid;
    private uploadId;

    items: MenuItem[];
    activeIndex: number = 0;

    seasons: any[] = [];
    series: any[] = [];
    pipelines: any[] = [];

    uploadingFiles: any[] = [];
    uploadedFiles: any[] = [];
    deferredFiles: any[] = [];
    resourceUrls: any[] = [];
    resources: any[] = [];

    private selectedSeries;
    selectedProfile: string;
    selectedTemplate: string;

    private templateProfiles = [];

    private contentTemplates = [];

    private genre = genreList;

    public msgs: Message[] = [];
    private currentUser: object = {};

    additionalParentsQuery: any;
    additionalParentsFilter: any;
    additionalParentsVisible: Boolean = false;
    additionalParentsEnabled: Boolean = false;
    additionalParentsTypes: [];
    additionalParentsMultiselect: Boolean = false;
    additionalParentsSeriesDependency: Boolean = false;
    additionalParentsObjects: [];
    additionalParentsRequired: Boolean = false;

    constructor(
        private router: Router,
        private containerService: ContainerService,
        private resourceService: ResourceService,
        private pipelineService: PipelineService,
        private uploadService: UploadService,
        private resourceUploadService: ResourceUploadService,
        private entityTypeService: EntityTypeService,
        private authService: AuthService,
    ) { }

    resetUploadData() {
        this.container = new Container();
        this.setDefaultContainerObjectData(this.container);

        this.isUploading = false;
        this.uploadId = this.containerService.getRandomId();
        this.createdContainerId = null;
        this.createdContainerGuid = null;

        this.selectedSeasonId = "";
        this.selectedPipelineId = "";
        this.selectedEntityType = "";

        this.uploadingFiles = [];
        this.uploadedFiles = [];
        this.deferredFiles = [];
        this.resourceUrls = [];
        this.resources = [];

        this.selectedSeries = "";
        this.selectedProfile = "";
        this.selectedTemplate = "";

        this.activeIndex = 0;
    }

    ngOnInit() {
        this.items = [{
            label: 'Basic Information',
            command: (event: any) => {
                this.activeIndex = 0;
            }
        },
        {
            label: 'Advanced Metadata',
            command: (event: any) => {
                this.activeIndex = 1;
            }
        },
        {
            label: 'Upload Assets',
            command: (event: any) => {
                this.activeIndex = 2;
            }
        },
        {
            label: 'Publish & Availability',
            command: (event: any) => {
                this.activeIndex = 3;
            }
        }
        ];

        this.uploadId = this.containerService.getRandomId();
        this.currentUser = this.authService.getAuthenticatedUser();
        this.getEntityData();
        this.getSeriesData();
        this.getTemplateProfiles();
        this.getPipelineData();
    }

    setDefaultContainerObjectData(container) {
        container.data =
            {
                title: '',
                houseId: '',
                description: '',
                genre: '',
                tv_rating: '',
                episode_number: '',
                available_date: null,
                expiration_date: null,
                public_window_end_date: null,
                original_air_date: null,
                local_air_date: null,
                release_date: null,
                keywords: '',
                locked: false,
                exclude_platforms: '',
                primary_parent_container_id: '',
                secondary_parent_container_id: '',
                externalVendorId: ''
            };
    }

    onUpload(event, deferFiles = true) {

        for (let file of event.files) {
            if (this.uploadService.getFileExtension(file.name) === 'mrss' && deferFiles && event.files.length > 1) {
                this.deferredFiles.push(file);
            }
            else {
                this.isUploading = true;

                // TODO: once container is created beforehand, use its
                // guid as file path prefix instead of this.uploadId

                this.uploadService
                    .upload(file, this.uploadId)
                    .subscribe(
                        res => {
                            file.progress = res.progress;
                            file.complete = res.complete;
                            let asset_url = res.url;
                            var fileFound = false;
                            for (let f of this.uploadingFiles) {
                                if (f.name === file.name) {
                                    f.progress = file.progress;
                                    fileFound = true;
                                    if (file.complete === true) {
                                        f.complete = file.complete;
                                        if (f.complete === true) {
                                            this.uploadedFiles.push(file);
                                            this.resourceUrls.push(asset_url);
                                        }

                                    }
                                }
                            }
                            if (fileFound === false) {
                                file.complete = false;
                                this.uploadingFiles.push(file);
                            }

                            if (this.uploadedFiles.length === this.uploadingFiles.length) {
                                var completeEvent = {};
                                completeEvent['files'] = this.deferredFiles;
                                this.isUploading = false;
                                this.onUpload(completeEvent, false);
                                if (this.deferredFiles.length === 0) {
                                    this.uploadingFiles = [];
                                }
                                this.deferredFiles = [];
                            }

                        },
                        err => console.log(err)
                    );
            }
        }
    }

    onBeforeUploadFile(event) {
        console.log('on before upload');
        console.log(event);
    }

    getEntityData() {
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.onTypeChange(this.containerTypeName);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getSeriesData() {
        this.isLoading = true;
        this.containerService
            .clientSearch('series', ['published', 'unpublished', 'scheduled'], ['label'])
            .subscribe(
                res => {
                    this.series = res.results;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    getSeasonData(guid) {
        this.isLoading = true;
        this.containerService
            .getRelatedByTypeAndGuidPaginated('season', guid, 1, 100)
            .subscribe(
                res => {
                    this.seasons = [];
                    res.forEach(element => {
                        this.seasons.push(element);
                        //element['show'] = true;
                    });

                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    getPipelineData() {
        this.isLoading = true;
        this.pipelineService
            .pipelineSearch(['upload'], ['container_id'], this.currentUser)
            .subscribe(
                res => {
                    this.pipelines = res;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    getTemplateProfiles() {
        this.isLoading = true;
        this.containerService
            .clientSearch('profile', 'published', ['label'])
            .subscribe(
                res => {
                    this.templateProfiles = (res.results.filter(t => t.data.profile_type === 'template'));
                    for (let template of this.templateProfiles) {
                        var templateValue = { label: template['data']['name'], value: template['data']['bundle_id'] };
                        this.contentTemplates.push(templateValue);
                    }
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onUploadComplete(e) {
        console.log('upload complete', e);
        // this.resource.uri = e.url;
    }

    onProgress(e) {
        console.log('upload event progress', e.progress);
    }

    onSeasonChange() {
        if (this.selectedSeasonId) {
            var seasonContainer = this.seasons.find(t => t.id === parseInt(this.selectedSeasonId, 10));
            this.container.data['secondary_parent_container_id'] = seasonContainer.guid;
        }
    }

    onPipelineChange() {
        this.pipelineContainer = this.pipelines.find(t => t.id === parseInt(this.selectedPipelineId, 10));
    }

    onTemplateChange() {
        this.msgs = [];
        this.container = new Container();
        this.setDefaultContainerObjectData(this.container);
        var selectedTemplateContainer = this.templateProfiles.find(t => t.id === parseInt(this.selectedTemplate, 10));
        // Handle legacy stringified JSON payloads
        var jsonPayload;
        if (typeof selectedTemplateContainer.data['payload'] == 'string') {
            jsonPayload = JSON.parse(selectedTemplateContainer.data['payload']);
        } else {
            jsonPayload = selectedTemplateContainer.data['payload']
        }
        this.container = Object.assign(this.container, jsonPayload.container);

        if (!('published_date' in jsonPayload.container)) {
            this.container.published_date = null;
        }
        if ('type' in jsonPayload.container) {
            this.onTypeChange(jsonPayload.container.type);
        }
        if ('status' in jsonPayload.container) {
            this.container.status = jsonPayload.container.status;
        }
        console.log("jsonPayload.container", jsonPayload)
        this.ratingSystem = "ca-tv";
        if(jsonPayload.container.template_options && jsonPayload.container.template_options.rating_system){
            this.ratingSystem = jsonPayload.container.template_options.rating_system;
        }
        if ('additional_parents_options' in jsonPayload) {
            this.additionalParentsVisible = true;
            this.setAdditionaParentOptions(jsonPayload['additional_parents_options']);
        }
        else {
            this.additionalParentsVisible = false;
            this.additionalParentsRequired = false;
        }

        if ('default_pipeline_bundle_id' in jsonPayload) {
            this.container.data['default_pipeline_bundle_id'] = jsonPayload['default_pipeline_bundle_id'];
        }

        this.selectedSeries = "";
        this.selectedSeasonId = "";

        // Handle placeholder available date
        if (this.container.availableDate === null) {
            this.container.availableDate = new Date();
        }
        // TODO: allow ISO intervval/duration format and calc expiration date
    }

    setAdditionalParents(e) {
        if ("selected" in e) {

            let selectedParents: any[] = e['selected'];
            if (selectedParents.length > 0) {
                this.container.data['additional_parents'] = e['selected'];
                this.additionalParentsObjects = e['selected'];
            }
            else if (selectedParents.length == 0) {
                delete this.container.data['additional_parents']
                this.additionalParentsObjects = [];
            }

        }
    }

    setAdditionaParentOptions(options) {

        if ('filter' in options) {
            this.additionalParentsQuery = { query_parameters: options['filter'] }
        }

        if ('dependency' in options) {
            this.additionalParentsSeriesDependency = options['dependency'];
        }

        if ((this.additionalParentsSeriesDependency == false)) {
            this.additionalParentsEnabled = true;
        }
        else {
            this.additionalParentsEnabled = false;
        }

        if ('multiselect' in options) {
            this.additionalParentsMultiselect = options['multiselect'];
        }
        else {
            this.additionalParentsMultiselect = false;
        }

        if ('required' in options) {
            this.additionalParentsRequired = options['required'];
        }
        else {
            this.additionalParentsRequired = false;
        }

        console.log("setAdditionaParentOptions", options, this.additionalParentsEnabled, this.additionalParentsFilter);
    }


    onSeriesChange() {
        var seriesContainer = this.series.find(t => t.id === parseInt(this.selectedSeries, 10));

        this.seriesContainerId = seriesContainer.id
        this.container.data['title'] = seriesContainer.data.title;
        this.container.data['show_title'] = seriesContainer.data.title;
        this.container.data['tv_rating'] = seriesContainer.data.tv_rating;
        this.container.data['genre'] = seriesContainer.data.genre;
        this.container.data['keywords'] = seriesContainer.data.keywords;
        this.container.data['primary_parent_container_id'] = seriesContainer.guid;

        this.getSeasonData(this.container.data['primary_parent_container_id']);
        this.onSeasonChange();

        if (this.additionalParentsVisible === true && this.seriesContainerId) {
            this.additionalParentsEnabled = true
            let queryString = this.additionalParentsQuery['query_parameters']
            if (this.additionalParentsSeriesDependency) {
                queryString = queryString + "&parent=" + seriesContainer.guid
            }

            this.additionalParentsFilter = {};
            this.additionalParentsFilter['query_parameters'] = queryString;
            console.log("Series Changed", this.additionalParentsFilter);
        }

    }

    onTypeChange(containerTypeName) {
        this.selectedEntityType = (this.entityTypes.find(t => t.name === containerTypeName));
        this.entityTypeId = this.selectedEntityType.id;
        this.container.type = this.selectedEntityType;
    }

    onSubmit(e) {

        this.msgs = [];
        var valid = this.ngForm.form.status === 'VALID';

        var season = this.seasons.find(t => t.guid === this.selectedSeasonId);
        if ((season) && ('season_number' in season.data)) {
            this.container.data['season_number'] = season.data['season_number'];
        }

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the title.' });
            valid = false;
        }
        const selectedTemplate_id = parseInt(this.selectedTemplate, 10);
        if (isNaN(selectedTemplate_id)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please select a template.' });
            valid = false;
        }

        var selectedTemplateContainer = this.templateProfiles.find(t => t.id === parseInt(this.selectedTemplate, 10));
        var jsonPayload;
        if(selectedTemplateContainer){
            if (typeof selectedTemplateContainer.data['payload'] == 'string') {
                jsonPayload = JSON.parse(selectedTemplateContainer.data['payload']);
            } else {
                jsonPayload = selectedTemplateContainer.data['payload']
            }

            let filesToUpload = this.uploadedFiles.map(f => f['name']);
            let requiredFiles = jsonPayload.required_files || [];
            let optionalFiles = jsonPayload.optional_files || [];
            let uploadCheck = this.resourceUploadService.checkRequiredFiles(filesToUpload, requiredFiles, optionalFiles);

            if (this.uploadedFiles.length == 0 || uploadCheck['missing']['required'].length > 0) {
                this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please attach media files.' });

                if (uploadCheck['missing']['required'].length) {
                    for (let spec of uploadCheck['missing']['required']) {
                        this.msgs.push({
                            severity: 'info',
                            summary: 'Required',
                            detail: `${spec['label']}: ${spec['formats'].join(', ')}`,
                        });
                    }
                    for (let spec of uploadCheck['missing']['optional']) {
                        this.msgs.push({
                            severity: 'info',
                            summary: 'Optional',
                            detail: `${spec['label']}: ${spec['formats'].join(', ')}`,
                        });
                    }
                }
                valid = false;
            }
        }

        if ((this.additionalParentsRequired === true) && !('additional_parents' in this.container.data)) {
            this.msgs.push({
                severity: 'error',
                summary: 'Error',
                detail: 'Please select an additional parent.',
            });

            valid = false;

        }

        if (valid === true) {
            console.log('container submitted', this.container);

            this.containerService
                .save(this.container, false)
                .subscribe(
                    res => {
                        console.log(res);
                        console.log('Container created:', this.container);
                        this.msgs = [];
                        this.msgs.push({ severity: 'success', summary: 'Media Object Created', detail: '' });
                        this.createdContainerId = res['id'];
                        this.createdContainerGuid = res['guid'];
                        this.attachNextResource();
                    },
                    err => {
                        console.error(err);
                    },
                    () => this.isLoading = false
                );
        }

    }

    attachNextResource() {
        if (!this.createdContainerId) {
            console.error('No container id available');
            return;
        }

        if (this.resourceUrls.length > 0 && this.resourceUrls.length > this.resources.length) {
            this.isLoading = true;

            // For now just grab next file uploaded
            const asset_url = this.resourceUrls[this.resources.length];

            const resource = new Resource();
            resource.uri = asset_url;
            resource.type = this.entityTypes.find(t => t.name === 'file');
            resource.data = {};
            // Set status so triggered pipeline can find uploaded resources
            resource.status = 'new';
            // Possibly add relevant things
            const s3_uri = this.uploadService.getS3UriFromUrl(asset_url);
            if (s3_uri) {
                resource.data['source_uri'] = s3_uri;
            }
            // TODO: allow overriding format somehow?
            const file_ext = this.uploadService.getFileExtension(asset_url);
            if (file_ext) {
                resource.data['format'] = file_ext;
            }
            // Add to list now, so we don't get accidental double-adds
            this.resources.push(resource);

            this.resourceService
                .save(resource, this.createdContainerId)
                .subscribe(
                    res => {
                        console.log(res);
                        console.log('Resource created:', resource);
                        this.attachNextResource();
                    },
                    err => {
                        console.error(err);
                    },
                    () => this.isLoading = false
                );
        } else {
            this.triggerPipeline();
        }
    }

    triggerPipeline() {
        if (this.pipelineContainer) {
            this.isLoading = true;

            let username = this.authService.getAuthenticatedUserName();
            let external_id = `vms:${username}:${this.createdContainerGuid}`;
            let bundle_id = this.pipelineContainer.data['bundle_id'];

            this.container.status = 'waiting';
            this.containerService
                .save(this.container)
                .subscribe(
                    res => {
                        console.log('Container updated:', res);
                        console.log('Triggering pipeline:', bundle_id);
                        this.pipelineService
                            .startPipeline(bundle_id, { container_id: this.createdContainerId }, external_id)
                            .subscribe(
                                res => {
                                    console.log('Pipeline started:', res);
                                    this.onStatusComplete();
                                },
                                err => {
                                    console.error(err);
                                },
                                () => this.isLoading = false
                            );
                    },
                    err => {
                        console.error(err);
                        this.isLoading = false;
                    }
                );
        } else {
            console.log('No pipeline selected');
            this.onStatusComplete();
        }
    }

    onStatusComplete() {
        const containerId = this.createdContainerId;
        const containerType = this.container.type.name;
        this.formSubmit.emit({
            container: this.container
        });
        this.resetUploadData();
        this.router.navigate(['/' + containerType, containerId, '']);
    }

    nextStep() {
        if (this.activeIndex < 4) {
            this.activeIndex = this.activeIndex + 1;
        }
    }

    prevStep() {
        if (this.activeIndex > 0) {
            this.activeIndex = this.activeIndex - 1;
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
        this.resetUploadData();
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }

    removeFile(file: File) {
        const index = this.uploadedFiles.indexOf(file);
        this.uploadedFiles.splice(index, 1);
    }

    getSizeInMegaBytes(file: File) {
        return file ? file.size / (1024 * 1024) : 0;
    }
}


