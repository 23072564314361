import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { SegmentService } from '../../../content/services/segment/segment.service';
import { Container } from '../../../content/models/container';
import { Segment } from '../../../content/models/segment';

import { HttpResponse } from '@angular/common/http';


@Component({
    selector: 'flight-segment-table',
    providers: [
        ContainerService,
        SegmentService
    ],
    templateUrl: 'flight-segment-table.component.html',
    styleUrls: ['flight-segment-table.component.css']
})
export class FlightSegmentTable implements OnInit {
    @Input() parentContainer: Container;
    @Input() isLoading = false;

    public collapsed = false;
    public segments: Array<Segment>;
    public mocksSegments: Array<any>;
    
    public error: HttpResponse<any>;

    public limit: number;
    public offset: number;

    public segmentCount:number;

    public pageSize:number = 10;

    constructor(
        public containerService: ContainerService,
        private segmentService: SegmentService
    ) {}

    ngOnInit() {

        this.loadSegments(this.parentContainer,this.pageSize,0)

    }

    loadSegments(parentContainer, limit, offset)
    {
        let segmentServiceParams = [
            {'parent':this.parentContainer.guid},
            {'type':'flight_segment'},
            {'limit':limit},
            {'offset':offset},
            {'ordering':'label'},
            {'ordering':'start_time_code'} 
        ]

        this.isLoading = true;
        this.segmentService
            .listByParameters(segmentServiceParams)
            .subscribe(
                res => {
                    this.segments = res.results;
                    this.segmentCount = res.count
                    console.log("Flight Segment Results",res)
  
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    saveSegment(segment)
    {
        this.segmentService
            .save(segment)
            .subscribe(
                res => {
                    console.log("Segment Saved.",res)
                },
                err => this.error = err,
                () =>{}
            );
    }

    onLazyload(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.loadSegments(this.parentContainer,this.limit, this.offset);
    }

    onModifyTableCheckbox(event)
    {
        console.log("Checkbox Event",event)
        //TODO: This is a work around as we need to get the id before calling save()
        //In the future this should be replaced with a .save() that support saving 
        //using a guid instead of an id. 
        this.segmentService
        .getByGuid(event.guid)
        .subscribe(
            res => {
                console.log("GetGUID Segment Results",res)
                event.id = res.id;
                this.saveSegment(event)
            },
            err => this.error = err,
            () => {}
        );
    }
}
