import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { Tag } from '../../models';
import { TagService } from '../../services/tag/tag.service';

@Component({
    selector: 'tag-select',
    providers: [
        TagService
    ],
    templateUrl: 'tag-select.component.html'
})

export class TagSelectComponent implements OnInit {
  @Output() onTagSelect: EventEmitter <Tag[]> = new EventEmitter();
  @Input()  defaultTags: Tag[];
  @Input() allowTagCategories: Array<string> = [];

  private selectedTags: Tag[];  
  public groupedTags: any[];

  constructor(private tagService: TagService) { }

  ngOnInit(){
    this.tagService.list().subscribe(
      res => {
        this.groupedTags = this.groupTagsByType(res)
        if (this.allowTagCategories.length > 0)
        {
          this.groupedTags = this.filterTagsByCategory(this.allowTagCategories)
        }
      },
      err => console.log(err),
    );
    console.log("Default Tags", this.defaultTags)
    this.selectedTags = this.defaultTags;
  }

  filterTagsByCategory(categories)
  {
    let result = this.groupedTags.filter(tagGroup => categories.includes(tagGroup.val));
    return result
  }

  groupTagsByType(tags) {
    let groupedTypes = []
    tags.forEach(tag => {
      if (!(groupedTypes.some(grp => grp.val === tag.type))) {
        groupedTypes.push({label: this._toUpper(tag.type), val:tag.type})
      }
      let label = groupedTypes.find(grp => grp.val === tag.type)
      label.items ? (label.items.push(tag)): (label.items=[], label.items.push(tag))
    });
    return groupedTypes
  }

  onChangeTags() {
    console.log("user selected tags-->", this.selectedTags)
    this.onTagSelect.emit(this.selectedTags)
  }

  _toUpper(str){
    return str.toLowerCase().replace(/_/g, ' ').replace(
      /(?: |\b)(\w)/g, (k => k.toUpperCase()))
  }
}
