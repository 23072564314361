<p-multiSelect *ngIf="statusList" 
               [options]="statusList" 
               [(ngModel)]="selectedStatusList"
               (onChange)="onSelectedStatusChange($event)" 
               [style]="{'height':'100%','width':'100%'}"
               [selectedItemsLabel]="'{0} selected'"
               [defaultLabel]="'Select Status'"
               appendTo="body">
    <ng-template let-item pTemplate="item">
        <div style="font-size:14px;float:right;margin-top:4px">{{item.label}}</div>
    </ng-template>
</p-multiSelect>