<spinner *ngIf="isLoading"></spinner>
<form *ngIf="!isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-toolbar class="ui-g-12">
          <div class="ui-g-6">
            <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-settings-forms-resource-tag-form-cancel"></button>
          </div>
          <div class="ui-g-6">
            <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-settings-forms-resource-tag-form-submit"></button>
          </div>
        </p-toolbar>

        <p-panel class="ui-g-12">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-sm-12 ui-g-6">
                  <label for="type">Type</label>
                  <input type="text" class="form-control" name="type" [(ngModel)]="tag.type" data-element-id="app-settings-forms-resource-tag-form-type">
              </div>
              <div class="ui-sm-12 ui-g-6">
                  <label for="label">Label</label>
                  <input type="text" class="form-control" name="label" [(ngModel)]="tag.label" data-element-id="app-settings-forms-resource-tag-form-label">
              </div>
              <div class="ui-sm-12 ui-g-6">
                <label for="value">Value</label>
                <input type="text" class="form-control" name="value" placeholder="Required" [(ngModel)]="tag.value" data-element-id="app-settings-forms-resource-tag-form-value">
              </div>
              <div class="ui-sm-12 ui-g-6">
                <label for="origin" aria-readonly="true">Origin</label>
                <input type="text" class="form-control" name="origin" readonly [(ngModel)]="tag.origin" data-element-id="app-settings-forms-resource-tag-form-origin">
              </div>
          </div>
      </p-panel>
  </div>
</form>
