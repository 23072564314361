import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { SeriesFormComponent } from '../../../content/forms/series-form/series-form.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../../../content/components/container-create/maptoiterable.pipe';

@Component({
    selector: 'configuration-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'configuration-create.component.html'
})
export class ConfigurationCreateComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container = new Container();
    private parentContainer: Container;
    private error: HttpResponse<any>;
    public isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('configuration create initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['distId']; // + converts string to number
            console.log('distId', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.parentContainer = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        console.log('submit', e.model);
        let newContId;
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                function (res) {
                    console.log("Created: ", res);
                    newContId = res.id;
                    this.containerService
                        .relate(this.parentContainer, res)
                        .subscribe(
                            res => {
                                console.log("Related: ", res);
                                this.router.navigate(['/configurations', newContId, this.parentContainer.id], { replaceUrl: true });
                            },
                            function (err) {
                                // TODO: Fix this fallback deletion, it doesn't delete the container
                                console.log("Failed to relate: ", err);
                                this.error = err.statusText;

                                this.containerService
                                    .delete(res);
                                this.location.back();
                            }.bind(this)
                        );
                }.bind(this),
                err => {
                    console.log("Failed to create: ", err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
