import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AppContext } from '../../app.context';

@Injectable()
export class ViewAPIService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/templates`;
    private baseAPIUrl = `${environment.PROXY_API_BASE_URL}/api/container/v1/`;

   // https://corus-vms-content-ray.herokuapp.com/api/container/v1/

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext:AppContext,
    ) {}


    private appendOriginQueryParamFromContext(ignore = false) {
        if (!ignore) {
            return '&origin=' + this.appContext.activeOrigin
        }

        return ''

    }

    getSeries(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json');
        // headers.append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/series/collections/guid/${guid}/`, { headers: headers }
            );
    }

    getContainerByGUID(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json');
        // headers.append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseAPIUrl}?guid=${guid}`, { headers: headers }
            );
    }

    getContainersByParentGUID(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json');
        // headers.append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseAPIUrl}?parent=${guid}`, { headers: headers }
            );
    }

    getContainersByTypeAndParentGUID(type, guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json');
        // headers.append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseAPIUrl}?parent=${guid}&type=${type}`, { headers: headers }
            );
    }

    getContainerByType(type): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json');
        // headers.append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseAPIUrl}?type=${type}` + this.appendOriginQueryParamFromContext(false), { headers: headers }
            );
    }

    

    list(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/resources`, { headers: headers }
            );
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }
}
