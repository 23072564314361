import { Component, OnInit, Input, Output } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { Container } from '../../models';

@Component({
    selector: 'container-list',
    templateUrl: 'container-list.component.html',
    styleUrls: ['container-list.component.css']
})
export class ContainerListComponent implements OnInit {
    @Input() containers: any;

    constructor() {}

    ngOnInit() {}
}
