import { Component, Input } from '@angular/core';
import { ResourceComponent } from '../resource/resource.component';
import { ResourceService } from '../../services/resource/resource.service';
import { Resource } from '../../models';

@Component({
    selector: 'resource-list',
    providers: [
        ResourceService
    ],
    templateUrl: 'resource-list.component.html',
    styleUrls: ['resource-list.component.css']
})
export class ResourceListComponent {
    @Input() parentContainer;
    @Input() resources: any;
    private isLoading = false;

    constructor(
        private resourceService: ResourceService
    ) {}

    onDelete(resource) {
        if (confirm('Are you sure you want to delete this resource?')) {
            this.resourceService
                .delete(resource)
                .subscribe(
                    res => {
                        console.log(res);
                        let i = this.resources.indexOf(resource);
                        this.resources.splice(i, 1);
                    },
                    err => console.log(err)
                );
        }
    }
}
