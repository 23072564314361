<search-table
  [defaultSearchTerm]="defaultSearchTerm"
  (onRowSelect)="handleRowSelect($event)"
  [config]="worklistConfig"
  [allowTypes]="this.viewRestrictionsAllowedType"
  [allowedTagCategories]="this.viewRestrictionsAllowedTagCategory"
  [allowedStatuses]="this.viewRestrictionsAllowedStatus"
  [searchFilter]="this.viewRestrictionsSearchFilter"
  >
</search-table>
