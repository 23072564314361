import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services';
import { Container } from '../../../content/models/container';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'workflow-form',
  providers: [
    EntityTypeService,
    ContainerService
],
  templateUrl: './workflow-form.component.html',
  styleUrls: []
})
export class WorkflowFormComponent implements OnInit {
  @Input() container: Container;
  @Output() formSubmit: EventEmitter < any > = new EventEmitter();
  @Output() formCancelled: EventEmitter < any > = new EventEmitter();
  @Output() formFailed: EventEmitter < any > = new EventEmitter();
  @ViewChild('ngForm') ngForm: NgForm;
  private entityType;
  public isLoading = false;
  public statusList = ContainerService.statusList

  constructor(
    private appContext: AppContext,
    private entityTypeService: EntityTypeService,
    private msgService: MessageService,
   ){ }

  ngOnInit(): void {
    this.isLoading = true;
    this.container.origin = this.container.origin ? this.container.origin : this.appContext.activeOrigin
    this.entityTypeService
            .getByName('workflow')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
  }

  onSubmit() {
    this.msgService.clear()
    let errmsg = [];
    let valid = this.ngForm.form.status === 'VALID';

    if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
      errmsg.push({ severity: 'error', summary: 'Error', detail: 'Name is required.' });
      valid = false;
    }

    if (!('description' in this.container.data) || (!this.container.data['description']) || !/\S/.test(this.container.data['description'])) {
      errmsg.push({ severity: 'error', summary: 'Error', detail: 'Description is required' });
      valid = false;
    }

    if (valid) {
      this.ngForm.form.markAsPristine();
      this.formSubmit.emit(this.container);
    } else {this.formFailed.emit(errmsg)}

}
  resetToDefaultState() {
    this.ngForm.reset();
  }

  isPristine() {
    return this.ngForm.form.pristine;
  }

  onCancel(){
    this.formCancelled.emit()
  }

}
