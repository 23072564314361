import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { FlightFormComponent } from '../../forms/flight-form/flight-form.component';

@Component({
    selector: 'flight-edit',
    templateUrl: 'flight-edit.component.html',
    styleUrls: ['flight-edit.component.css']
})
export class FlightEditComponent implements OnInit {
    @ViewChild('form') form: FlightFormComponent;
    private sub;
    public isLoading = false;
    public error: HttpResponse<any>;
    public container: Container;
    public containerId: number;
    public parentContainerId: number;
    public msgs: Message[] = [];
    public isProcessing = false;
    public pipelineId = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public containerService: ContainerService,
        private msgService: MessageService,
        private location: Location,
    ) { }

    ngOnInit(): void {
        console.log('flight edit initialized');
        this.sub = this.route.params.subscribe(params => {
            this.containerId = +params['id'];
            this.parentContainerId = +params['parentId'];
            this.loadContainerData(this.containerId);

        });

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    loadContainerData(id) {
        this.isLoading = true;
        this.containerService
            .get(id)
            .subscribe(
                res => {
                    this.container = res instanceof Container ? res : Container.fromJson(res);
                    this.checkProcessingStatus();
                },
                err => this.error = err,
                () => {
                    this.isLoading = false;
                }
            );
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    onPipelineTriggered(event) {
        console.log(event);
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            this.pipelineId = event['pipeline_id'];
            if (isNaN(this.parentContainerId)) {
                this.location.back();
            } else {
                this.router.navigate(['/commerce/campaign/', this.parentContainerId], { replaceUrl: true });
            }
        }
    }

    onSubmit(e) {
        this.sub = this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.container = Object.assign(new Container(), this.container);
                    this.msgService.add({ severity: 'success', summary: 'Changes Saved', detail: '' });
                },
                err => {
                    if (err.status && err.status == 403) {
                        this.msgService.add({ key: 'csMsg', severity: 'error', summary: 'Error', detail: 'You do not have sufficient permissions to perform this action.' })
                    }
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: err.statusText });
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.msgService.add(e);
    }

}
