import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'channel-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'channel-form.component.html'
})
export class ChannelFormComponent implements OnInit {
    @Input() container: Container;
    @Input() seasons: Container[];
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @Output() statusError: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityType;
    private selectedSeasonGuid;
    public isLoading = false;

    private msgs: Message[] = [];

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {

        this.isLoading = true;
        this.entityTypeService
            .getByName('channel')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.ngForm.form.markAsPristine()
        this.formSubmit.emit({
            container: this.container
        });
    }

    onStatusComplete() {
        this.container.data['metadata_ingest_workflow_status'] = 'Complete';
        this.formSubmit.emit({
            container: this.container
        });
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onError(error) {
        this.statusError.emit(error);
    }
}
