import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { EpisodeRowComponent } from '../episode-row/episode-row.component';
import { SeriesFormComponent } from '../../forms/series-form/series-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { handleError } from '../../../shared/utilities';

@Component({
    selector: 'series-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'series-create.component.html',
    styleUrls: ['series-create.component.css']
})
export class SeriesCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: SeriesFormComponent;
    private sub: any;
    public container: Container = new Container();
    private error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    public requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('series create initialized');
        this.container.is_enabled = false;
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        this.container.data['content_listing'] = 'Show';

        if (!('display_pretitle' in this.container.data) || (!this.container.data['display_pretitle']) ) {
            this.container.data['display_pretitle'] = '';
        }

        if (!('display_title' in this.container.data) || (!this.container.data['display_title']) ) {
            this.container.data['display_title'] = '';
        }

        if (!('display_subtitle' in this.container.data) || (!this.container.data['display_subtitle']) ) {
            this.container.data['display_subtitle'] = '';
        }

        console.log('submit', e.model);
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/series', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.requiredMsgs = [];
                    let errorMessages = handleError(err);
                    errorMessages.forEach(errorMessage => {
                        this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: errorMessage });
                    })
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
    
}
