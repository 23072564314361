import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import { Container, Tag } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { TagService } from '../../services/tag/tag.service';
// import { objectPermGroup } from 'src/app/auth/auth.permissions';
import { viewTags } from '../../../shared/enum';

@Component({
    selector: 'container-tags-panel',
    providers: [
        ContainerService,
        TagService
    ],
    templateUrl: 'container-tags-panel.component.html',
    styleUrls: ['container-tags-panel.component.css'],
})

export class ContainerTagsPanelComponent implements OnInit, OnChanges {
    @Input() container:Container|Array<Container>;
    @Input() viewName:string;
    @Output() onTagsChange: EventEmitter < any > = new EventEmitter();
    @Output() onBulkTagSelected: EventEmitter<{}>=new EventEmitter();

    displayDialog: boolean = false;
    showTags:boolean = false;
    public isLoadingTags = false;
    public selectedTags: Tag[]|Array<Tag>;
    private tags: Tag[];
    private tagsDict = {};
    public selectedTagsDict = {};
    private tagsTypes: any;
    public selectedViewTag:string[]
    private curCntTags:Array<Tag>;


    constructor
        (
        private containerService: ContainerService,
        private tagService: TagService,

        ) { }

    ngOnChanges(changes: SimpleChanges) {
      if(Array.isArray(this.container) && this.container.length) {
        this.curCntTags=[]
        this.container.map(cnt=>cnt.tags.map(
          tag=>this.curCntTags.some(obj=>obj.id===tag.id)?null:this.curCntTags.push(tag)))
        this.selectedTags= [...this.curCntTags]
        this.container.map(cnt=>cnt.tags.map(
          mtag=>this.tags.map(stag=>mtag.id===stag.id?(stag['all']=mtag.all,stag['e']=mtag.e):null)))
        this._loadTags()
      } else {changes.container.currentValue instanceof Array?this.selectedTags=null:null }
    }

    ngOnInit() {
      this.selectedTags = this.container instanceof Array? null: this.container.tags
      this.isLoadingTags = true;
      this.tagService.list().subscribe(
          res => {
            this.tags = res
            this.selectedViewTag = viewTags.find(obj=> obj.name===this?.viewName)?.tags
            this.container instanceof Array?this.tags.map(tag=>(tag['all']=false,tag['e']=false)):this._loadTags(this.selectedViewTag)
          },
          err => console.log(err),
          () => this.isLoadingTags = false
      );

    }

    capitalize(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    showDialog() {
        this.displayDialog = true;
    }

    onChangeTags(){
      if(Array.isArray(this.container)) {
        this.onBulkTagSelected.emit({nTags: this.selectedTags, oTags:this.curCntTags})
      } else {this.container.tags = this.selectedTags}
    }

    _loadTags(prfTags=[]) {
      let fTags = prfTags.length == 0 ? this.tags: this.tags.filter(
                                  tag=>prfTags.includes(tag.type))
      this.tagsTypes = this.getTagTypes(fTags);
      this.selectedTagsDict = this.splitTagsByType(this.selectedTags);
      this.tagsDict = this.splitTagsByType(fTags);
    }

    getTagTypes(tagArray) {
        let tagsTypes = [];
        tagArray.forEach(tag => {
            if (!(tag.type in tagsTypes)) {
                tagsTypes.push(tag.type);
            }
        });
        let tagSet = new Set(tagsTypes);
        return tagSet;
    }

    splitTagsByType(tagsArray) {
        let tagsDict = {};
        tagsArray.forEach(tag => {
            if (!(tag.type in tagsDict)) {
                tagsDict[tag.type] = [];
            }
            tagsDict[tag.type].push(tag);
        });
        return tagsDict;
    }

    showAll(){
        this.showTags ? this._loadTags(): this._loadTags(this.selectedViewTag)
    }

    onDialogHide() {
        this.isLoadingTags = true;
    }

    onDialogShow() {
        this.isLoadingTags = false;
    }

}
