import { Container } from './container';
import { EntityType } from './entity-type';

export class Schema {
  id = -1;
  type: EntityType = new EntityType();
  guid = '';
  namespace  = '';
  label  = '';
  status  = '';
  origin = '';
  schema = {};
  data = {};
  created_date = "";
  updated_date = "";
  containers: Array < Container > = new Array < Container > ();
}
