import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, Event, RoutesRecognized, ActivationStart, RouterStateSnapshot, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreadcrumbService } from './app.breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  styleUrls: ['./app.breadcrumb.component.css'],
})
export class AppBreadcrumbComponent {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = { icon: 'pi pi-hoqe', url: 'home' };
  menuItems: any = [];
  rawMenuItems: any = [];
  appendBreadcrumb: any = false
  private routeURL;
  private routeData;
  private refreshRoute = false;
  private rawURL;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private breadcrumbService: BreadcrumbService) { }

  ngOnChanges(changes: SimpleChanges) { }

  ngOnInit(): void {

    this.breadcrumbService.breadcrumbLabelsMap.subscribe((labelData) => {
      for (const label in labelData) {
        if (labelData.hasOwnProperty(label)) {
          this.rawMenuItems.map((crumb) => {
            const labelParams = crumb.label.match(/[^{{]+(?=\}})/g)
            if (labelParams) {
              for (const labelParam of labelParams) {
                const dynamicData = labelData[label]
                if (labelParam === label) {
                  crumb.label = crumb.label.replace('{{' + labelParam + '}}', dynamicData);
                }
              }
            }
          });
        }
      }
      this.menuItems = [...this.rawMenuItems];
      this.sanitizeBreadbrumb()
    });

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.routeData = data.state.root.firstChild.data;
      }
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((data) => {
        //TODO: Might want to refresh on emit of sub to page refresh/reload
        //Sub does not currently exist, would require a refactor of services
        if (this.routeURL && (this.routeURL === data['url'])) {
          this.routeURL = data['url']
          this.refreshRoute = false;

        }
        else {
          this.routeURL = data['url']
          this.refreshRoute = true;
        }
      })

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((data) => {

      if (this.menuItems.length == 0) {
        this.menuItems = this.createDefaultBreadcrumbs(data)
      };
    });

    this.router.events
      .pipe(filter(event => event instanceof ActivationStart))
      .subscribe((data) => {
        if (data instanceof ActivationStart && this.refreshRoute) {
          if ('appendBreadcrumb' in data.snapshot.data) {
            this.appendBreadcrumb = data.snapshot.data.appendBreadcrumb
          }
          else {
            this.appendBreadcrumb = false;
            this.menuItems = [];
            this.rawMenuItems = [];
            this.breadcrumbService.clearBreadcrumbs()
          }

          if ('breadcrumb' in data.snapshot.data) {
            let breadcrumbData = this.parseBreadcrumb(data.snapshot.data.breadcrumb, data.snapshot.params)
            if (this.appendBreadcrumb) {
              this.rawMenuItems.splice(this.rawMenuItems.findIndex(({ label }) => label == "Edit"), 1);
              this.rawMenuItems = [...this.rawMenuItems, ...breadcrumbData];
            }
            else {
              this.rawMenuItems = [...breadcrumbData];
            }
            this.breadcrumbService.checkTemplatedLabels();
          }
        }
      });

    this.breadcrumbService.breadcrumbIdMap.subscribe((idMap) => {
      if (idMap && this.rawURL) {
        this.menuItems = this.updateDefaultBreadcrumbs(this.rawURL, idMap)
      }
    })
  }

  private updateDefaultBreadcrumbs(rawURL, idMap) {

    let breadcrumbs = []
    let url = ""
    let pathComponents = rawURL.split('/').filter(item => item !== "");
    for (const component of pathComponents) {
      url = url + "/" + component

      let label = component
      if (component in idMap) {
        label = idMap[component]
      }

      breadcrumbs.push({ label: this.capitalize(label), url: url });
    }
    console.log("ID breadcrumbs",breadcrumbs)
    return breadcrumbs;
  }


  private createDefaultBreadcrumbs(event) {
    this.rawURL = event.url;
    this.breadcrumbService.resolvedDefaultURL(event.url)

    let breadcrumbs = []
    let url = ""
    let pathComponents = event.url.split('/').filter(item => item !== "");
    for (const component of pathComponents) {
      url = url + "/" + component
      breadcrumbs.push({ label: this.capitalize(component), url: url });
    }
    return breadcrumbs;
  }

  private sanitizeBreadbrumb() {
    let templatedItems = this.rawMenuItems.filter(item => item.label.match(/[^{{]+(?=\}})/g) === null)
    this.menuItems = [...templatedItems];
  }


  private parseBreadcrumb(data, params) {

    const breadcrumb = (JSON.parse(JSON.stringify(data)));
    breadcrumb.map((crumb) => {
      if (!('disabled' in crumb)) {
        crumb.disabled = false
      }

      const urlChunks = crumb.url.split('/');
      for (const chunk of urlChunks) {
        if (chunk.includes(':')) {
          const paramID = chunk.replace(':', '');
          const routerParamID = params[paramID];
          crumb.url = crumb.url.replace(`:${paramID}`, routerParamID);
        }
      }

      const labelParams = crumb.label.match(/[^{{]+(?=\}})/g);
      if (labelParams) {
        for (const labelParam of labelParams) {
          const routerParamID = params[labelParam.trim()];
          if (routerParamID) {
            crumb.label = crumb.label.replace('{{' + labelParam + '}}', routerParamID);
          } else {
            // crumb.label = crumb.label.replace('{{' + labelParam + '}}', '');
          }
        }
      }

    });
    return breadcrumb
  }

  capitalize(word) {
    if (!word) return '<undefined>';
    return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
  }
}