<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="csMsg"></p-toast>
        <p-toast key="clMsg"></p-toast>
        <p-toast key="wfMsg"></p-toast>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button" (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" icon="fa fa-thumbs-up" style="float:right;" data-element-id="app-content-forms-collection-form-container-change"></button>
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-collection-form-save"></button>
                <container-workflow-status [container]="container" (onSimpleStatusChange)="onSimpleStatusChange($event)" (onComplexStatusChange)="onComplexStatusChange($event)"(onStatusTransitionFailure)="onStatusTransitionFailure($event)" style="float:right;margin-right:4px;"></container-workflow-status>

            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-9">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12 ui-md-6">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['title']" name="title" data-element-id="app-content-forms-collection-form-title-text">
                </div>
                <div class="ui-g-12 ui-md-6">
                    <label for="source_container_id">Source Container Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.source_container_id" name="source_container_id" data-element-id="app-content-forms-collection-form-source-container-id-text">
                </div>
                <div class="ui-g-12">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" data-element-id="app-content-forms-collection-form-description-text">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-3">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="publishDate" style="display:block;">Publish Date</label>
                    <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}" data-element-id="app-content-forms-collection-form-publish-date"></p-calendar>
                </div>
                <div class="ui-g-12">
                    <label for="expirationDate">Expiration Date</label>
                    <input type="text" class="form-control p-widget" [(ngModel)]="container.data.expiration_date" name="expirationDate" disabled>
                </div>
            </div>
        </p-panel>
        <container-tags-panel [container]=container class="ui-g-12"></container-tags-panel>
        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="showTitle">Show Title</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="container.data.show_title" name="ShowTitle">
                </div>
                <div class="ui-g-4">
                    <label for="show_id">Show Id</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.data.primary_parent_container_id" name="show_id">
                </div>
                <div class="ui-g-4">
                    <label for="keywords">Keywords</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.keywords" name="keywords">
                </div>
                <div *ngIf="container.id > 0" class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>
        <view-metadata-form-panel [container]=container class="ui-g-12"></view-metadata-form-panel>
        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type">
                </div>
                <div class="ui-g-5">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-content-forms-collection-form-guid-text">
                </div>
                <div class="ui-g-5">
                    <label for="externalVendorId">External Vendor Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.externalVendorId" name="externalVendorId">
                </div>
            </div>
        </p-panel>
    </div>
</form>
