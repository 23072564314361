<spinner *ngIf="isLoading"></spinner>
<form  #ngForm="ngForm" *ngIf="container && !isLoading" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-settings-forms-cast-form-cancel"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"  data-element-id="app-settings-forms-cast-form-submit"></button>
                <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-3">
                    <label for="first_name">First Name</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['first_name']" name="first_name" placeholder="Required" required data-element-id="app-settings-forms-cast-form-first-name">
                </div>
                <div class="ui-g-3">
                  <label for="middle_name">Middle Name</label>
                  <input type="text" class="form-control" [(ngModel)]="container.data['middle_name']" name="middle_name" data-element-id="app-settings-forms-cast-form-middle-name">
                </div>
                <div class="ui-g-3">
                    <label for="last_name">Last Name</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['last_name']" name="last_name" data-element-id="app-settings-forms-cast-form-last-name">
                </div>
                <div class="ui-g-3">
                    <label for="gender">Gender</label>
                    <select [(ngModel)]="container.data.gender" name="gender" class="form-control" data-element-id="app-settings-forms-cast-form-gender">
                        <option *ngFor="let opt of genderList" [value]="opt.value">{{ opt.label }}</option>
                    </select>
                </div>
                <div class="ui-g-3">
                    <label for="age">Age</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['age']" name="age" data-element-id="app-settings-forms-cast-form-age">
                </div>
                <div class="ui-g-3">
                  <label for="order">Order</label>
                  <input type="text" class="form-control" [(ngModel)]="container.data['order']" name="order" data-element-id="app-settings-forms-cast-form-order">
              </div>
              <div class="ui-g-3">
                <label for="cast_type">Type</label>
                <select [(ngModel)]="container.data.type" name="cast_type" class="form-control" data-element-id="app-settings-forms-cast-form-cast-type">
                  <option *ngFor="let opt of roleList" [value]="opt.value">{{ opt.label }}</option>
                </select>
              </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-settings-forms-cast-form-type">
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-settings-forms-cast-form-guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

    </div>
</form>
