<spinner *ngIf="isLoading"></spinner>
<p-messages [(value)]="msgs"></p-messages>
<p-toast key="wlMsg"></p-toast>
<p-toast key="csMsg"></p-toast>
<p-toast key="wfMsg"></p-toast>
<pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>
<form *ngIf="container && !isLoading && !isProcessing" (submit)="onSubmit($event)">
    <div class="ui-g">
        <div class="ui-g-8">
            <textarea [rows]="2" class="formTitleInputText" [(ngModel)]="container.data['title']" name="title" pInputTextarea autoResize="autoResize"></textarea>
        </div>
        <div class="ui-g-4" style="text-align:center">
            <img *ngIf="getThumbnailUrl()" style="max-height:8em;width:auto;max-width:100%" src="{{getThumbnailUrl()}}">
            <img *ngIf="!getThumbnailUrl()"  style="max-height:8em;width:auto;max-width:100%" src="/assets/layout/images/player-poster.png"/>
        </div>

        <div class="ui-g-12" *ngIf="isUploading">
            <label for="uploading">Uploading</label>
            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
        </div>

        <div class="ui-g-12" *ngIf="container.status == 'waiting' || container.status == 'processing'">
            <label for="uploading">Publishing</label>
            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
        </div>

        <div class="ui-g-12">
            <label for="description">Description</label>
            <textarea [rows]="3" class="form-control"  pInputTextarea [(ngModel)]="container.data.description" name="description" autoResize="autoResize"></textarea>
        </div>

        <div class="ui-g-12">
            <label for="author">Author</label>
            <input type="text" class="form-control" [(ngModel)]="container.data.author" name="author">
        </div>

        <!--
        <div class="ui-g-3">
            <label for="typeName">Type</label>
            <input type="text" class="form-control" [(ngModel)]="container.type.name" name="typeName" disabled>
        </div>
         -->

        <div class="ui-g-12">
            <label for="guid">GUID</label>
            <input type="text" class="form-control" [(ngModel)]="container.guid" name="guid" placeholder="Required" readonly>
        </div>

        <div class="ui-g-12">
            <label for="show_title">Show Title</label>
            <input type="text" class="form-control" [(ngModel)]="container.data.show_title" name="show_title" placeholder="Required" disabled="disabled">
        </div>
        <div *ngIf="container.id > 0" class="ui-g-12">
            <history [inputObject]="container" inputType='container'>
            </history>
        </div>
    </div>

    <div class="ui-g">
        <p-accordion class="ui-g-12">
            <p-accordionTab header="Availability" [selected]="true">
                <div class="ui-g">
                    <div class="ui-g-6">
                        <label for="availableDate" style="display:block;">Available Date</label>
                        <p-calendar [(ngModel)]="container.availableDate" name="availableDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="expirationDate" style="display:block;">Expiration Date</label>
                        <p-calendar [(ngModel)]="container.expirationDate" name="expirationDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="airDate" style="display:block;">Air Date</label>
                        <p-calendar [(ngModel)]="container.referenceDate" name="airDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="false" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                    <div class="ui-g-6">
                        <label for="publishDate" style="display:block;">Publish Date</label>
                        <p-calendar [(ngModel)]="publishDate" name="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab header="Tags & Keywords">
                <div class="ui-g">
                    <div class="ui-g-12">
                        <label for="tags">Tags</label>
                        <container-tags-tab #maintags [container]=container [tagTypeFilterList]=tagTypeFilterList [tagTypeExcludeList]="['syndication']" (onTagsChange)="updateSyndicationTags($event)"></container-tags-tab>
                    </div>
                    <div class="ui-g-12">
                        <label for="keywords">Keywords</label>
                        <input type="text" class="form-control" [(ngModel)]="container.data.keywords" name="keywords">
                        <!--  <p-chips [(ngModel)]="keywordChips" name="keywordsChips"></p-chips> -->
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Cue Points">
                <div class="ui-g">
                    <div class="ui-g-12">
                        <cue-point-edit #cuepoints [cuePoints]="container.data['chapter_start_times']" (onCuepointEdit)="onCuepointEdit($event)"></cue-point-edit>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab header="Syndication">
                <div class="ui-g">
                    <container-tags-tab #syndicationtags [container]=container [tagTypeFilterList]="['syndication']" [hideTagChips]="true" (onTagsChange)="updateMainTags($event)"></container-tags-tab>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <p-toolbar class="ui-g-12">
        <div class="ui-g-12" style="z-index: 0">
            <button pButton type="submit" label="Save" [disabled]="container.status == 'waiting' || container.status == 'processing'" style="font-size:13px;float:left;margin-right:.5em"></button>

            <button *ngIf="container.type.name == 'media'" pButton type="button" label="Edit" [disabled]="container.status == 'waiting' || container.status == 'processing'" [routerLink]="['/media', container?.id,'']" style="font-size:13px;float:left;"></button>
            <button *ngIf="container.type.name == 'episode'" pButton type="button" label="Edit" [disabled]="container.status == 'waiting' || container.status == 'processing'" [routerLink]="['/episodes', container?.id,'']" style="font-size:13px;float:left;"></button>
            <button *ngIf="container.type.name == 'movie'" pButton type="button" label="Edit" [disabled]="container.status == 'waiting' || container.status == 'processing'" [routerLink]="['/movie', container?.id]" style="font-size:13px;float:left;"></button>
            <container-workflow-status [container]="container" (onSimpleStatusChange)="onSimpleStatusChange($event)" (onComplexStatusChange)="onComplexStatusChange($event)" (onStatusTransitionFailure)="onStatusTransitionFailure($event)" style="float:right;margin-right:4px;"></container-workflow-status>
        </div>
    </p-toolbar>

</form>
