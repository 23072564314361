
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tag } from '../../models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';
import { MessageService } from 'primeng/api';


@Injectable()
export class WorkflowService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext,
        private msgService: MessageService,
    ) {}

    _appendHeaders() {
      return new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json')
          .append('Authorization', this.authService.getAuthHeader())
    }

    execute(transaction_type: string, workflow_container_guid: string, target_container_guid: string, transition_state_segment_guid, published_date: Date): Observable < any > {
      let data = JSON.stringify({transaction_type, workflow_container_guid,
                                 target_container_guid, transition_state_segment_guid,
                                 published_date
                                })
      let res = this.http.post(`${this.baseUrl}/workflow/`, data,
                          { headers: this._appendHeaders()})
      return this._handleRes(res)
    }


    // ***** To handle errors received in API response *****

    _handleRes(res): Observable < any > {
      return res.pipe(tap({
        next: null,
        error: (err) => this._handleError(err),
        complete: () => this._showMsg('Workflow executed successfully', 'success')
      }))
    }

    _handleError(err) {
      let errMsg = ''
      if (err.error instanceof Object && err.status!== 0) {
        Object.keys(err.error).map(key => key === 'msg' ? errMsg = err.error[key] : null)
      } else{ errMsg = err.status !== 0 ? err.status +'  '+err.statusText: err.statusText }
      this._showMsg(errMsg, 'error')
    }

    _showMsg(msg, severity) {
      this.msgService.add({key: 'wfMsg', severity: severity, summary:severity.charAt(0).toUpperCase()+severity.slice(1), detail:msg});
    }

}
