import { Component, OnInit, ViewChild } from '@angular/core';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { SelectItem } from 'primeng/api';
import { AppContext } from '../../../app.context';
import { Table } from 'primeng/table';

@Component({
  selector: 'workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.css']
})
export class WorkflowsComponent implements OnInit {
  @ViewChild('dt') dataTable: Table;
  public containers = [];
  public isLoading = false;
  public statusList: Array<SelectItem> = [];
  public selectedStatus: SelectItem = null;
  public columns = [];
  public container: Container;
  private filteredStatus = '';
  public filterTerm = '';
  private error: string;
  private sortField: string;

  constructor(
    public containerService: ContainerService,
    private appContext: AppContext,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));
    this.sortField = 'label';

    if (!this.appContext.componentState['workflow_view']['status']) {
      this.setDefaultParams();
      this.setStatusParams();
    }

    this.getStatusParams();
    this.loadResults();

  }

  onChangedFilteredStatus(event) {
    this.filteredStatus = event.value ? event.value.value : '';
    this.dataTable.first = 0;
    this.loadResults();
    this.setStatusParams();
  }

  onRowSelect(event) { }

  setDefaultParams() {
    this.filteredStatus = 'published';
  }

  setStatusParams() {
    this.appContext.componentState['workflow_view']['status'] =
      this.filteredStatus || '';
  }

  getStatusParams() {
    let statusContextParameters = this.appContext.componentState['workflow_view'];
    this.filteredStatus = (statusContextParameters['status'] || '').toLowerCase();
    this.filterTerm = (statusContextParameters['filter'] || '').toLowerCase();

    if (this.filteredStatus) {
      if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
        this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
      }
    } else if (this.selectedStatus) {
      this.selectedStatus = null;
    }
  }

  loadResults() {
    this.isLoading = true;
    this.containerService
    .clientSearch('workflow', this.filteredStatus || '', [this.sortField])
    .subscribe(
      res => {
          this.containers = res.results;
          if (this.appContext.componentState['workflow_view']['filter']) {
            this.dataTable.filterGlobal(this.filterTerm.toLowerCase(), 'contains');
          }
        },
        err => {
          console.log(err);
          this.error = err.statusText;
        },
        () => this.isLoading = false
      );
  }

  statusLabelClass(status) {
    return this.containerService.getStatusLabelClass(status);
  }

  changedFilter(event){
    this.dataTable.filterGlobal(event.toLowerCase(), 'contains');
    this.appContext.componentState['workflow_view']['filter'] = event;
  }

}
