import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'spinner',
    templateUrl: 'spinner.component.html',
    styleUrls: ['spinner.component.css']
})
export class SpinnerComponent implements OnInit {
    private defaultMessage = 'Loading...';
    @Input() message: string;

    constructor() {}

    ngOnInit() {
        this.message = this.message ? this.message : this.defaultMessage;
    }
}
