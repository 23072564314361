<page-header title="Edit Flight Container" [showSpinner]="isLoading"></page-header>

<div *ngIf="container" class="card card-w-title top-level-card">

    <h1>Edit Flight Container{{!isLoading && container ? ' : ' + container.data.label : ''}}</h1>

    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <div *ngIf="container && !isLoading && !isProcessing">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    </div>

    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <flight-form #form [container]="container"
                           (formSubmit)="onSubmit(container)"
                           (formCancelled)="onCancel($event)"
                           (formFailed)="onFailure($event)"
                           (pipelineTriggered)="onPipelineTriggered($event)">

            <div class="ui-g-12">
                <flight-segment-table [parentContainer]="container"></flight-segment-table>
            </div>
        </flight-form>



        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>

    </div>

</div>