import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models/container';
import { TagFormComponent } from '../../forms/tag-form/tag-form.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Router } from '@angular/router';



@Component({
  selector: 'notification-create',
  providers: [
    ContainerService
],
  templateUrl: './notification-create.component.html',
  styleUrls: []
})

export class NotificationCreateComponent implements OnInit {
  @ViewChild('form') form: TagFormComponent;
  public container: Container;
  public isLoading:boolean = false

  constructor(
    private location: Location,
    private router: Router,
    private ContainerService: ContainerService,
    private msgService: MessageService,
   ) { }

  ngOnInit() {
    this.container = new Container();
  }

  onSubmit(e){
    this.isLoading = true
    this.container.status = 'published';
    this.container.published_date = Math.trunc(Date.now() / 1000);

    this.ContainerService.save(this.container).subscribe(
      res => this.router.navigate(['/notification', res.id], { replaceUrl: true }),
      err => this.isLoading = false,
      () => this.isLoading = false
    );
  }

  onCancel(e) {
   this.location.back()
  }

  onFail(e){
    this.msgService.add(e);
  }

}
