<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Tests</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="dt.filterGlobal($event.target.value, 'contains')" class="filter-bar"/>
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-4 button-wrapper" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.title', 'data.type', 'data.description']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines  p-datatable-nested"
            [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Label</th>
                <th>Type</th>
                <th>Script</th>
                <th *ngxPermissionsOnly=modifyPermissions style="width:42px;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-test>
            <tr>
                <td>{{test.data.label}}</td>
                <td><div class="label label-default">{{test.data.type}}</div></td>
                <td style="word-break: break-all;">{{test.data.script_ref_guid}}</td>
                <td *ngxPermissionsOnly=modifyPermissions>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, test)" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>