<page-header title="Edit catalog" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

<div class="card card-w-title">
  <h1>Edit Catalog{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>

  <div *ngIf="container && !isLoading">
      <p-messages [(value)]="msgs"></p-messages>
      <catalog-form [container]="container" (formSubmit)="onSubmit($event)" (formCancel)="onCancel($event)"></catalog-form>
      <!-- ************** Additional Resources ******************** -->
      <hr>
      <div class="ui-g-12">
        <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
          <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
        </resource-datarow>
      </div>

      <p-panel class="ui-g-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Base QuerySet</span>
        </p-header>
        <json-editor #queryset [options]="editorOptionsQueryset" [data]="container.data.queryset"></json-editor>
      </p-panel>

      <p-panel class="ui-g-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">QuerySet Filter List</span>
        </p-header>
        <json-editor #filters [options]="editorOptionsFilters" [data]="container.data.queryset_q_object_list"></json-editor>
      </p-panel>

      <p-panel class="ui-g-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">QuerySet Exclude List</span>
        </p-header>
        <json-editor #excludes [options]="editorOptionsExcludes" [data]="container.data.queryset_exclude_list"></json-editor>
      </p-panel>

      <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Template</span>
        </p-header>
        <div class="ui-g-12">
            <textarea [rows]="20" class="ui-g-12" pInputTextarea name="payload" [(ngModel)]="container.data.template" autoResize="autoResize"></textarea>
        </div>
      </p-panel>

      <div class="ui-g-12">
        <object-advancedview [object]="container" title="Advanced Editing" data-element-id="app-content-components-catalog-edit-advanced-edit"></object-advancedview>
      </div>
  </div>
</div>
