import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models';
import { SearchTableComponent } from './../../../content/components/search-table/search-table.component';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'metrics-reports-list',
    templateUrl: 'metrics-reports-list.component.html',
    styleUrls: ['metrics-reports-list.component.css']
})
export class MetricsReportsListComponent implements OnInit {
    @Output() onReportSelected: EventEmitter < any > = new EventEmitter();
    //@ViewChild(SearchTableComponent) searchTable:  SearchTableComponent;

    menuItems: MenuItem[];
    public menuLoading = false;
    private reportContainers = [];
    private error: string;
    private selectedContainer;
    private selectedItem: MenuItem;


    reportSelected = false;
    constructor(
        private router: Router,
        private containerService: ContainerService,

    ) {}

    ngOnInit() {
        this.menuLoading = true;

        this.menuItems = [];

        this.containerService
        .filterByTypeName('report', 'full', -1)
        .subscribe(
            res => this.reportContainers = res,
            err => {
                console.log(err);
                this.error = err.statusText;
            },
            () => {
                this.menuLoading = false;
                this.buildMenu(this.reportContainers);
                console.log('Reports:', this.reportContainers);
            }
        );
    }

    buildMenu(reportContainerResults) {
        let reportsDict = {};
        reportContainerResults.forEach((report, i) => {
            let category = report.data['category'] ? report.data['category'] : 'Other';
            if (category.toLowerCase() != 'system') {
                if (!(category in reportsDict)) {
                    reportsDict[category] = [];
                }
                reportsDict[category].push({
                    label: report.data['label'],
                    icon: 'fa fa-fw fa-square',
                    guid: report.guid,
                    command: () => {
                        this.handleSelectReportItem(report);
                    }
                });
            }
        });
        console.log('Formatted Reports:', reportsDict);
        for (const [key, value] of Object.entries(reportsDict)) {
            let sortedValue = reportsDict[key].sort((a, b) => (a.label > b.label) ? 1 : -1);
            console.log('Formatted items:', sortedValue);
            this.menuItems.push({label: key, items: sortedValue});

        }
        this.menuItems.sort((a, b) => (a.label > b.label) ? 1 : -1);

        if (this.menuItems[0]) {
            this.menuItems[0]['items'][0]['command']();
            this.highlightSelectedItem(null, 0, 0);
        }

    }

    highlightSelectedItem(container = null, menuOrdinal = null, itemOrdinal = null) {
        // PrimeNG doesn't appear to allow to access menu methods directly
        if (this.selectedItem) {
            delete this.selectedItem['styleClass'];
        }
        if (menuOrdinal !== null && itemOrdinal !== null) {
            this.selectedItem = this.menuItems[menuOrdinal]['items'][itemOrdinal];
            this.menuItems[menuOrdinal]['items'][itemOrdinal]['styleClass'] = 'active-menuitem';
        } else {
            this.menuItems.map(item => {
                console.log(item);
                item['items'].map(subItem => {
                    if (subItem['guid'] === container.guid) {
                        subItem['styleClass'] = 'active-menuitem';
                        this.selectedItem = subItem;
                    }
                });
            });
        }
    }

    handleSelectReportItem(container) {
        this.selectedContainer = container;
        this.reportSelected = true;
        this.highlightSelectedItem(container);
        console.log('Report Selected Handling Event', container);
        this.onReportSelected.emit({
            container: container
        });
    }

    rowUpdate(guid) {
      //update menu
    }
}
