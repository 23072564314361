<ng-containter *ngIf="parentContainer || parent">
  <div class="ui-g ui-g-nopad" >
      <spinner *ngIf="isLoading" class="ui-g-12 ui-g-nopad"></spinner>
      <div class="p-widget-header table-header ui-g-12 ui-g-nopad" style="padding:6px;border-bottom: 0 none;">
          <div class="ui-g-3 ui-g-nopad">
              <div class="p-inputgroup">
                  <input type="text" pInputText size="50" placeholder="Search" class="filter-bar" [(ngModel)]="searchText" (keydown)="onAttemptSearch($event)" style="text-align:center;width:100%;height:100%;">
                  <button pButton type="button" icon="fa fa-search" (click)="onSearch($event)"></button>
              </div>
          </div>
          <div class="ui-g-2 ui-g-nopad">
              <div *ngIf="!isLoading" style="text-align:center;margin-top:4px;">{{resultsCount}} search result{{results.length==1?'':'s'}}</div>
              <div *ngIf="isLoading" style="text-align:center;margin-top:4px;">Loading...</div>
          </div>
          <div class="ui-g-2  ui-g-nopad" #org>
                  <p-dropdown [options]="originList" [(ngModel)]="selectedOrigin" (onChange)="onChangedOrigin($event)" placeholder="Select an Origin" [group]="false" appendTo="body" panelStyleClass="app-content-forms-container-relate-form-originlist" data-element-id="app-content-forms-container-relate-form-originlist" >
                          <ng-template let-item pTemplate="selectedItem">
                                  <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                  <div class="clearfix" style="position: relative;height: 25px;">
                                      <div style="font-size:14px;float:right;margin-top:4px">{{item.label}}</div>
                                  </div>
                              </ng-template>
                  </p-dropdown>
          </div>
          <div class="ui-g-2 ui-g-nopad">
                  <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" [group]="false" [(ngModel)]="selectedStatus" appendTo="body" panelStyleClass="app-content-forms-container-relate-form-statuslist" data-element-id="app-content-forms-container-relate-form-statuslist"></p-dropdown>
          </div>
      </div>

        <p-table *ngIf="selectionMode == 'multiple'" #rt class="ui-g-12 ui-g-nopad"
            [value]="results"
            [globalFilterFields]="['label', 'guid', 'keywords']"
            [paginator]="true"
            paginatorPosition="both"
            [selectionMode]="multiple"
            [(selection)]="selected"
            dataKey="guid"
            [lazy]="true"
            [rows]="10"
            [first]="offset"
            (onLazyLoad)="onLazyLoad($event)"
            styleClass="p-datatable-gridlines"
            [totalRecords]="resultsCount">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 2.4em">
                    </th>
                    <th>Title/Name/Label</th>
                    <th>Show Title</th>
                    <th>GUID</th>
                    <th>Keywords</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-container >
                <tr [pSelectableRow]="container">
                    <td data-element-id="app-content-forms-container-relate-form-checkbox" >
                        <p-tableCheckbox [value]="container"></p-tableCheckbox>
                    </td>
                    <td data-element-id="app-content-forms-container-relate-form-title">{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                    <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                    <td style="word-break: break-all;">{{container.guid}}</td>
                    <td style="word-break: break-all;">{{container.data['keywords'] || ''}}</td>
                </tr>
            </ng-template>
        </p-table>

        <p-table *ngIf="selectionMode == 'single'" #rt class="ui-g-12 ui-g-nopad"
            [value]="results"
            [globalFilterFields]="['label', 'guid', 'keywords']"
            [paginator]="true"
            paginatorPosition="both"
            [selectionMode]="selectionMode"
            [(selection)]="selected"
            dataKey="guid"
            [lazy]="true"
            [rows]="10"
            [first]="offset"
            (onLazyLoad)="onLazyLoad($event)"
            styleClass="p-datatable-gridlines"
            [totalRecords]="resultsCount">
            <ng-template pTemplate="header">
                <tr>
                    <th>Title/Name/Label</th>
                    <th>Show Title</th>
                    <th>GUID</th>
                    <th>Keywords</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-container let-rowIndex="rowIndex">
                <tr [pSelectableRow]="container" [pSelectableRowIndex]="rowIndex">
                    <td>{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                    <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                    <td style="word-break: break-all;">{{container.guid}}</td>
                    <td style="word-break: break-all;">{{container.data['keywords'] || ''}}</td>
                </tr>
            </ng-template>
        </p-table>

      <div class="ui-g-12" style="height:20px;"></div>

      <p-toolbar class="ui-g-12 ui-g-nopad" [style]="{'padding-left':'0','padding-right':'0'}">
          <div class="ui-g-2">
              <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
          </div>
          <div *ngIf="selectionMode == 'multiple'" class="ui-g-8" style="text-align:center;margin-top:4px;">
              {{selected.length}} {{filterType.replace('_', ' ')}}{{selected.length==1?'':'(s)'}} will be added. They are listed below.
          </div>

          <div class="ui-g-2" [ngSwitch]="selectionMode">
              <button *ngSwitchCase="'multiple'"  pButton [disabled]="!selected.length" type="submit" label="Confirm" (click)="onSubmit($event)" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-container-relate-form-confirm"></button>
              <button *ngSwitchCase="'single'"  pButton [disabled]="!selected" type="submit" label="Confirm" (click)="onSubmit($event)" icon="pi pi-check" style="float:right;"></button>
          </div>
      </p-toolbar>

      <div class="ui-g-12" style="height:20px;"></div>
      <div *ngIf="selectionMode  == 'multiple'">
      <div class="p-widget-header table-header ui-g-12 ui-g-nopad" style="padding:6px;border-bottom: 0 none;">
          <div class="ui-g-4 ui-g-offset-4 ui-g-nopad">
              <input type="text" pInputText size="50" placeholder="Filter" (keyup)="st.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar" style="text-align:center;width:100%;height:100%;">
          </div>
      </div>
      <p-table #st [value]="selected"
              [globalFilterFields]="['label', 'guid', 'keywords']"
              [rows]="10"
              [paginator]="true"
              paginatorPosition="both"
              styleClass="p-datatable-gridlines"
              [(selection)]="selected">
          <ng-template pTemplate="header">
              <tr>
                  <th style="width: 2.25em">
                  </th>
                  <th>Title/Name/Label</th>
                  <th>Show Title</th>
                  <th>GUID</th>
                  <th>Keywords</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-container>
              <tr [pSelectableRow]="container">
                  <td>
                      <p-tableCheckbox [value]="container"></p-tableCheckbox>
                  </td>
                  <td>{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                  <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                  <td style="word-break: break-all;">{{container.guid}}</td>
                  <td style="word-break: break-all;">{{container.data['keywords'] || ''}}</td>
              </tr>
          </ng-template>
      </p-table>
    </div>
  </div>
</ng-containter>

<!-- ***************************** Template for Bulk Assign Tag ********************************* -->

<ng-containter *ngIf="!parentContainer && !parent">
    <div class="ui-sm-12 ui-g-12 ui-lg-6 ui-xl-6">
      <div class="ui-sm-12 ui-g-nopad">
        <spinner *ngIf="isLoading" class="ui-g-12 ui-g-nopad"></spinner>
        <div class="p-widget-header table-header ui-sm-12 ui-g-12 ui-g-nopad" style="padding:6px;border-bottom: 0 none; display: flex;flex-wrap: wrap;">
            <div class="ui-sm-6 ui-g-6 ui-lg-3 ui-xl-3 ui-g-nopad">
                <div class="p-inputgroup">
                    <input type="text" pInputText size="50" placeholder="Search" class="filter-bar" [(ngModel)]="searchText" (keydown)="onAttemptSearch($event)" style="text-align:center;width:100%;height:100%;">
                    <button pButton type="button" icon="fa fa-search" (click)="onSearch($event)"></button>
                </div>
            </div>
            <div class="ui-sm-6 ui-g-6 ui-lg-3 ui-xl-3 ui-g-nopad">
                <div *ngIf="!isLoading" style="text-align:center;margin-top:4px;">{{resultsCount}} search result{{results.length==1?'':'s'}}</div>
                <div *ngIf="isLoading" style="text-align:center;margin-top:4px;">Loading...</div>
            </div>
            <div class="ui-sm-4 ui-g-4 ui-lg-3 ui-xl-2 ui-g-nopad" #org>
              <p-dropdown
                [options]="originList"
                [(ngModel)]="selectedOrigin"
                (onChange)="onChangedOrigin($event)"
                placeholder="Select an Origin"
                [group]="false"
                appendTo="body"
                [style]="{'width':'100%'}"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="clearfix" style="position: relative;height: 25px;">
                    <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="ui-sm-4 ui-g-4 ui-lg-3 ui-xl-2 ui-g-nopad">
              <p-dropdown
                [options]="statusList"
                (onChange)="onChangedFilteredStatus($event)"
                placeholder="No Status Filter"
                [group]="false"
                [(ngModel)]="selectedStatus"
                appendTo="body"
                [style]="{'width':'100%'}"
                data-element-id="app-content-forms-container-relate-form-status-filter-dropdown"
                panelStyleClass="app-content-forms-container-relate-form-status-filter-dropdown">
              </p-dropdown>
            </div>
            <div class="ui-sm-4 ui-g-4 ui-lg-3 ui-xl-2 ui-g-nopad">
              <span class="p-float-label">
                  <p-multiSelect
                    [options]="entityTypes"
                    [(ngModel)]="selectedEntityTypes"
                    [autoWidth]="false"
                    name="entityType"
                    optionLabel="name"
                    [style]="{'min-width':'100%', 'max-width':'100%'}"
                    [defaultLabel]="'Select Type(s)'"
                    (onChange)="onSearch($event)"
                    appendTo="body">
                  </p-multiSelect>
              </span>
          </div>
        </div>

        <p-table #rt class="ui-g-12 ui-g-nopad"
                [value]="results"
                [globalFilterFields]="['label', 'guid', 'keywords']"
                [paginator]="true"
                paginatorPosition="both"
                [(selection)]="selected"
                dataKey="guid"
                [lazy]="true"
                [rows]="10"
                [first]="offset"
                (onLazyLoad)="onLazyLoad($event)"
                styleClass="p-datatable-gridlines"
                [totalRecords]="resultsCount"
                (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnSelect($event)"
                (onHeaderCheckboxToggle)="handleHeaderCheckboxToggle($event)"
                >
            <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem; padding: 0.3em 0.5em">
                    <p-tableHeaderCheckbox data-element-id="app-content-forms-container-relate-form-checkbox-search-results-all"></p-tableHeaderCheckbox>
                  </th>
                  <th>Title/&#x200b;Name/&#x200b;Label</th>
                  <th>Show Title</th>
                  <th>GUID</th>
                  <th style="word-break:break-all">Keywords</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-container>
                <tr [pSelectableRow]="container">
                    <td><p-tableCheckbox [value]="container" data-element-id="app-content-forms-container-relate-form-checkbox-search-results"></p-tableCheckbox></td>
                    <td>{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                    <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                    <td style="word-break: break-all;">{{container.guid}}</td>
                    <td style="word-break: break-all;">{{container.data['keywords'] || ''}}</td>
                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>

    <!-- Selected Containers -->
    <div class="ui-sm-12 ui-g-12 ui-lg-6 ui-xl-6">
      <div class="ui-g ui-g-nopad">
          <div class="p-widget-header table-header ui-g-12 ui-g-nopad" style="padding:6px;border-bottom: 0 none;">
              <div class="ui-g-4 ui-g-offset-4 ui-g-nopad">
                <div class="ui-g-4 ui-g-offset-4 ui-g-nopad">
                  <input type="text" pInputText size="50" placeholder="Filter" (keyup)="bt.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar">
                </div>
              </div>
          </div>
          <p-table #bt [value]="selectedContainers"
                  [globalFilterFields]="['label', 'guid', 'keywords']"
                  [rows]="10"
                  [paginator]="true"
                  paginatorPosition="both"
                  styleClass="p-datatable-gridlines"
                  [(selection)]="selectedContainers"
                  (onRowUnselect)="onUnSelectContainer($event)">
              <ng-template pTemplate="header">
                  <tr>
                      <th style="width: 2.25em">
                      </th>
                      <th>Title/&#x200b;Name/&#x200b;Label</th>
                      <th>Show Title</th>
                      <th>GUID</th>
                      <th style="word-break:break-all">Keywords</th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-container>
                  <tr [pSelectableRow]="container">
                    <td><p-tableCheckbox [value]="container"></p-tableCheckbox></td>
                    <td>{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                    <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                    <td style="word-break: break-all;">{{container.guid}}</td>
                    <td style="word-break: break-all;">{{container.data['keywords'] || ''}}</td>
                  </tr>
              </ng-template>
          </p-table>
        </div>
    </div>

    <div class="ui-g-12" style="height:20px;"></div>
      <p-toolbar class="ui-g-12 ui-g-nopad" [style]="{'padding-left':'0','padding-right':'0'}">
          <div class="ui-g-2">
            <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
          </div>
          <div class="ui-g-8" style="text-align:center;margin-top:4px;">
              Tags will be added/removed from {{selected.length}} containers listed above
          </div>
          <div class="ui-g-2">
            <button [disabled]="!(selected.length||selectedContainers.length)" pButton type="submit" label="Confirm" (pRefresh)="onRefresh()"(click)="onSubmit($event)" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-container-relate-form-button-confirm"></button>
          </div>
      </p-toolbar>

    <div class="ui-g-12" style="height:20px;"></div>
</ng-containter>
