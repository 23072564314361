<page-header title="Edit Test Suite - {{container?.data.label}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Test Suite</h1>

    <div class="ui-g" *ngIf="container && !isLoading">
        <!--<p-growl class="ui-g-12" [(value)]="requiredMsgs"></p-growl> -->
        <p-messages class="ui-g-12" [(value)]="requiredMsgs"></p-messages>
        <p-messages class="ui-g-12" [(value)]="msgs"></p-messages>

        <test-suite-form #form [container]="container" (formSubmit)="onSubmit($event)" (formCancelled)="onCancel($event)" style="width:100%;"></test-suite-form>

        <hr>


        <div class="ui-g-12">
            <container-paginated-datarow type="test" itemsPerPage = 20 [parentContainer]="container" title="Tests" filterBy="data.label" (reorder)="onReorder($event)">
                <button pButton type="button" label="Create" [routerLink]="['/test', 'create', container?.id]" icon="pi pi-plus" style="float:right;margin-left:0px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow type="server" itemsPerPage = 20 [parentContainer]="container" title="Servers" filterBy="data.label" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'server']" icon="pi pi-plus" style="float:right;margin-left:0px;height:100%;"></button>
            </container-paginated-datarow>
        </div>


        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>

        <!--
        <div class="p-widget-header ui-g-12" style="padding:4px 10px;border-bottom: 0 none">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input #gb type="text" pInputText size="50" placeholder="Global Filter">

            <button pButton label="Add" icon="pi pi-plus" (click)="addProperty(newProperty.value)" style="float:right; margin-left: 4px;"></button>
            <input #newProperty newProperty.value="" type="text" pInputText size="50" placeholder="New Property" style="float:right;">
        </div>
         -->
    </div>
</div>
