<div class="card card-w-title top-level-card">
    <h1>Edit Platform{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>
    <div *ngIf="!container && !isLoading && !isProcessing && requiredMsgs">
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <button pButton type="button" label="Back" (click)="onBack($event)" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
    </div>

    <page-header title="Edit Platform{{!isLoading && container ? ': ' + container.data['title'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <div *ngIf="container && !isLoading && !isProcessing">
        <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <platform-form #form [container]="container" (formSubmit)="onSubmit($event)" (formCancel)="onCancel($event)" (formFailed)="onFailure($event)" (pipelineTriggered)="onPipelineTriggered($event)"></platform-form>

        <hr>

        <div class="ui-g-12">
            <policy-table [parentContainer]="container">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'policy']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </policy-table>
        </div>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>

    </div>


</div>

