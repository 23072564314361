<div class="card card-w-title top-level-card">
    <page-header title="Edit {{container?.data.title}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <div *ngIf="container && !isLoading && !isProcessing">
    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    </div>

    <div *ngIf="container && !isLoading">
        <container-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onCancel($event)"></container-form>

        <hr>
        <div class="ui-g-12"></div>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>

        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name"
                [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload" *ngxPermissionsOnly="['view.show.upload']"></button>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource', 'upload']" *ngxPermissionsOnly="['object.write.all', 'object.write.episode']"></trigger-pipeline-modal-form>
                <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
            </resource-datarow>
        </div>

        <p-panel class="ui-g-12">
            <p-header>
                <div class="ui-g">
                    <span class="p-panel-title ui-g-12 ui-md-4 ui-lg-4" style="padding:0;font-size:16px;">Child Items
                        <p-badge value='{{ containerCount }}' styleClass="p-mr-2" severity="info"></p-badge>
                    </span>
                    <div class="ui-g-12 ui-md-8 ui-lg-8" style="padding:0;">
                        <container-add [container]="container" [defaultAddAction]="defaultAddAction" [addActionsList]="addActionsList" style="float:right;"></container-add>
                        <!-- <button pButton type="button" label="Episode" [routerLink]="['/containers', container?.id, 'relate', 'episode']" icon="pi pi-plus"
                            style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Movie" [routerLink]="['/containers', container?.id, 'relate', 'movie']" icon="pi pi-plus"
                            style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Media" [routerLink]="['/containers', container?.id, 'relate', 'media']" icon="pi pi-plus"
                            style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Series" [routerLink]="['/containers', container?.id, 'relate', 'series']" icon="pi pi-plus"
                            style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Channel" [routerLink]="['/containers', container?.id, 'relate', 'channel']"
                            icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Collection" [routerLink]="['/containers', container?.id, 'relate', 'collection']"
                            icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Dynamic" [routerLink]="['/containers', container?.id, 'relate', 'dynamic']"
                            icon="pi pi-plus" style="float:right;margin-left:4px;"></button> -->
                    </div>
                    <spinner *ngIf="isReordering" class="ui-g-12"></spinner>
                </div>
            </p-header>

            <div class="collection__content">
                <!-- <collection-row [containers]="container?.containers" [parentContainer]="container" (reorder)="onReorder($event)" (pin)="onPinAction($event)"></collection-row> -->
                <container-paginated-datarow type="all"
                                itemsPerPage = 20
                                [parentContainer]="container"
                                filterBy="data.title, data.keywords"
                                [sortOptions]="collectionSortOptions"
                                class="nested-panel"
                                [badgeVisible]="False"
                                (countUpdatedEvent)="updateCount($event)"
                                (reorder)="onReorder($event)">
                </container-paginated-datarow>
            </div>

        </p-panel>


        <div class="ui-g-12">
            <container-membership-row [container]='container' containerTypeName="collection"></container-membership-row>
        </div>

        <div class="ui-g-12">
            <div *ngIf="['movie', 'episode', 'media'].indexOf(container?.type.name) > -1">
                <policy-table [parentContainer]="container" [modifyPermissions]="['view.show.admin']">
                    <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['policy']" *ngxPermissionsOnly="['object.write.all']"></trigger-pipeline-modal-form>
                    <button  *ngxPermissionsOnly="['view.show.admin']" pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'policy']" icon="pi pi-plus"
                        style="float:right;height:100%;"></button>
                </policy-table>
            </div>
            <div *ngIf="['movie', 'episode', 'media'].indexOf(container?.type.name) == -1">
                <policy-table [parentContainer]="container">
                    <button  pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'policy']" icon="pi pi-plus"
                    style="float:right;height:100%;"></button>
                </policy-table>
            </div>
        </div>

        <div class="ui-g-12">
            <platform-table [containers]="platforms" [parentContainer]="container">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'platform']" icon="pi pi-plus"
                    style="float:right;height:100%;"></button>
            </platform-table>
        </div>

        <hr>

    </div>
</div>
