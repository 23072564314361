<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">{{title}}</span>
            <div class="ui-g-12 ui-lg-8" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" [rows]="rows" [paginator]="true" paginatorPosition="bottom" selectionMode="single"
        (onRowSelect)="onRowSelect($event)" [(selection)]="container"
        styleClass="p-datatable-gridlines p-datatable-nested" [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Label</th>
                <th style="width:20%">Start Date</th>
                <th style="width:20%">End Date</th>
                <th style="width:20%">Status</th>
                <th style="width:6em">
                <th style="width:8em">
                <th style="width:3em">
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-flight>
            <tr [pSelectableRow]="flight">
                <td style="word-break: break-all;">{{flight.data?.label}}</td>
                <td style="word-break: break-all;">{{flight.data?.range_start_date}}</td>
                <td style="word-break: break-all;">{{flight.data?.range_end_date}}</td>
                <td>
                    <div *ngIf="flight.status == 'new'" class="label label-default">New</div>
                    <div *ngIf="flight.status == 'waiting'" class="label label-info">Waiting</div>
                    <div *ngIf="flight.status == 'processing'" class="label label-info">Processing</div>
                    <div *ngIf="flight.status == 'error'" class="label label-danger">Error</div>
                    <div *ngIf="flight.status == 'rejected'" class="label label-danger">Rejected</div>
                    <div *ngIf="flight.status == 'unpublished'" class="label label-danger">Unpublished</div>
                    <div *ngIf="flight.status == 'ready'" class="label label-primary">Ready</div>
                    <div *ngIf="flight.status == 'approved'" class="label label-primary">Approved</div>
                    <div *ngIf="flight.status == 'scheduled'" class="label label-warning">Scheduled</div>
                    <div *ngIf="flight.status == 'published'" class="label label-success">Published</div>
                    <div *ngIf="flight.status == 'hidden'" class="label label-default">Hidden</div>
                </td>

                <td>
                    <button pButton type="button" label="Edit"
                        [routerLink]="['/commerce/flight', flight?.id, this.parentContainer?.id]" icon="pi pi-pencil"
                        [disabled]="flight.status=='processing'"></button>
                </td>
                <td>
                    <contextual-ads-catalog-modal-form [container]=flight [catalogTypes]="['export_campaign']"
                        [startDate]=flight.data?.range_start_date [endDate]=flight.data?.range_end_date
                        [disabled]="flight.status=='processing'"></contextual-ads-catalog-modal-form>
                </td>
                <td *ngIf="flight.status !='processing' else loading">
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, flight)"
                        icon="pi pi-times" style="margin:auto;display:block;font-size:13px"></button>
                </td>
                <ng-template #loading>
                    <td>
                        <p-progressSpinner [style]="{width: '30px', height: '15px'}" strokeWidth="8" fill="#EEEEEE"
                            animationDuration="2s"></p-progressSpinner>
                    </td>
                </ng-template>
            </tr>
        </ng-template>
    </p-table>

</p-panel>