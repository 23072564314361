import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'seasons-row',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'seasons-row.component.html',
    styleUrls: ['seasons-row.component.css']
})
export class SeasonsRowComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() containers: Array < any > = [];
    @Input() parentContainer: Container;
    @Output() reorder: EventEmitter < any > = new EventEmitter();
    // TODO: implement controlsActive check

    private isLoading = true;
    private error: string;
    public name = '';
    private entityTypes = [];
    private entityTypeId;
    public seasonContainers = [];
    private dragulaSub: Subscription;

    constructor(
        private dragulaService: DragulaService,
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngAfterContentInit() {
        console.log('container row initialized', this.dragulaService);

        this.name = Math.random()
            .toString(36)
            .substring(7);

        this.dragulaService.createGroup(this.name, {});

        this.dragulaSub = this.dragulaService.dropModel(this.name)
            .subscribe((e) => {
                console.log('drop', e);
                this.onDrop(e);
        });

    }

    ngOnInit() {

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    // console.log(res);
                    this.entityTypeId = (this.entityTypes.find(t => t.name === 'season'))
                        .id;
                    this.seasonContainers = this.containers.filter(t => t.type.id === this.entityTypeId);
                    console.log('THIS IS THE ENT' + this.entityTypeId);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    private onDrop(e) {
        if (this.parentContainer) {
            let el = e['el'];
            let droppedItem = e['item'];
            let oldIndex = this.containers.indexOf(droppedItem);
            let newIndex = this.getElementIndex(el);
            // depending on the direction of dragging,
            // previous item can be retrieved by either getting using the new index
            // of dragged item of the old container array, or by using new index - 1
            let prevItemIndex = oldIndex > newIndex ? newIndex - 1 : newIndex;
            let droppedAfterItem = prevItemIndex >= 0 ? this.containers[prevItemIndex] : null;

            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: droppedItem,
                to: droppedAfterItem
            });
        }
    }

    ngOnDestroy() {
        this.dragulaSub.unsubscribe();
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++);
        return i;
    }

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this season?')) {
            console.log('remove', e, container);
            let i = this.seasonContainers.indexOf(container);
            this.seasonContainers.splice(i, 1);

            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => console.log(res),
                    err => console.log(err)
                );
        }
    }
}
