<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Profiles</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                  
            </div>
                 <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                    <!-- <button pButton type="button" label="Create" [routerLink]="['/policy', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button> -->
                    <button pButton type="button" label="Add" [routerLink]="['/containers', parentContainer?.id, 'relate', 'profile']" icon="pi pi-plus" style="float:right;height:100%;"></button>

                </div>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.name', 'data.bundle_id']" 
            [rows]="10" 
            [paginator]="false" 
            paginatorPosition="bottom" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines p-datatable-nested"
            [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Name</th>
                <th>Description</th>
                <th>Bundle Id</th>
                <th style="width:8%">Type</th>
                <th style="width:8%">Origin</th>
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-profile>
            <tr>
                <td>{{profile.data.name}}</td>
                <td>{{profile.data.description}}</td>
                <td>{{profile.data.bundle_id}}</td>
                <td>{{profile.data.profile_type}}</td>
                <td>{{profile.origin}}</td>
                <td>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, profile)" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>