<page-header [showSpinner]="isLoading" [error]="error?.statusText">
</page-header>
<div *ngIf="!isLoading" class="worklist-component">

    <div class="ui-g-7 worklist-table-view">
        <contextual-ads-context-search-table 
            [defaultSearchTerm]="defaultSearchTerm" 
            [containers]="this.containers"
            [selectedContainer]="selectedContainer"
            (rowSelect)="containerSelected($event)">
        </contextual-ads-context-search-table>
    </div>

    <div class="ui-g-5 worklist-form-view" *ngIf="selectedContainer">
        <contextual-ads-context-form  
            [containerId]="selectedContainer.id" 
            [seasons]="[]" 
            (formSubmit)="onSubmit($event)" 
            (onContainerUpdate)="onContainerUpdate($event)"></contextual-ads-context-form>
    </div>

</div>
