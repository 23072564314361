<page-header title="Create Flight" [showSpinner]="isLoading" [error]="error"></page-header>

<div *ngIf="container && !isLoading" class="card card-w-title top-level-card">

    <h1>New Flight</h1>

    <div *ngIf="container && !isLoading">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
    </div>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <flight-form [container]="container"
                     (formSubmit)="onSubmit($event)"
                     (formFailed)="onFailure($event)"
                     (formCancelled)="onCancel($event)">
        </flight-form>
    </div>

</div>
