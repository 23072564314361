import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';

@Component({
    selector: 'settings-origins',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'settings-origins.component.html',
    styleUrls: ['settings-origins.component.css']
})
export class SettingsOriginsComponent implements OnInit {
    public containers = [];
    private isLoading = false;
    private displayDialog = false;
    public container: Container;

    private entityTypes = [];
    private entityTypeId;

    constructor(
        public containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.isLoading = true;

        this.containerService
            .filterByTypeName('origin', 'full', -1)
            .subscribe(
                res => {
                    this.containers = res;
                    console.log(res);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
    }

    onRowSelect(event) {}
}
