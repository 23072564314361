import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'episode-form',
    providers: [
        // EntityTypeService
    ],
    templateUrl: 'episode-form.component.html'
})
export class EpisodeFormComponent implements OnInit {
    @Input() container: Container;
    @Input() seasons: Container[];
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formReparent: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();
    @Output() reparenting: EventEmitter<any> = new EventEmitter();
    @Output() statusError: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityType;
    private selectedSeasonGuid;
    public isLoading = false;

    private publishDate :Date;

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];
    private genre = genreList;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        if ('season_number' in this.container.data) {
            this.selectedSeasonGuid = this.container.data['secondary_parent_container_id'];
        }
        this.isLoading = true;
        this.entityTypeService
            .getByName('episode')
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSeasonChange() {
        console.log('selected season', this.selectedSeasonGuid);
        this.container.data['secondary_parent_container_id'] = this.selectedSeasonGuid;
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        let season = this.seasons.find(t => t.guid === this.selectedSeasonGuid);
        if ((season) && ('season_number' in season.data)) {
            this.container.data['season_number'] = season.data['season_number'];
        }

        this.ngForm.form.markAsPristine()
        this.formSubmit.emit({
            container: this.container
        });
    }

    onSimpleStatusChange(event) {
        console.log('simple status transition', event);
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onComplexStatusChange(event) {
        console.log('pipeline triggered successfully');
        this.pipelineTriggered.emit(event);
    }

    onStatusTransitionFailure(e) {
        this.messageService.add(e);
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onPipelineTriggered(e) {
        this.pipelineTriggered.emit(e);
    }

    onReparenting(event) {
        this.formReparent.emit({
            container: this.container
        });
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }

    onError(error) {
        this.statusError.emit(error);
    }
}
