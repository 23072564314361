import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../services/container/container.service';
import { AuthService } from '../../../auth/auth.service'
import { AppContext } from '../../../app.context';
import { Container } from '../../models';
import { containerTypeRoutes } from '../../../shared/enum';

@Component({
	selector: 'app-container-guid',
	templateUrl: './container-guid.component.html',
	styleUrls: ['./container-guid.component.css']
})
export class ContainerGuidComponent implements OnInit {

	private sub: any;
	private userPermSub: any;
	private isLoading: boolean = false;
	private isValid: boolean = false;
	private container: Container;
	private error: HttpResponse<any>;

	constructor(
	  private route: ActivatedRoute,
	  private router: Router,
	  private containerService: ContainerService,
	  private authService: AuthService,
	  private appContext: AppContext
  ) {}
		
	ngOnInit() {
		this.sub = this.route.params.subscribe(params => {
			let guid = params['guid'];

			this.loadContainerData(guid);

		});
	}

	loadContainerData(guid) {
		this.isLoading = true;
		this.containerService
			.getByGuid(guid, 'full', -1, false, true, true)
			.subscribe(
				res => {
					this.container = res;
					this.userPermSub = this.authService.userState.subscribe(
						res => {
							let userPermissions = res.permissions;
							this.checkUserPermissions(userPermissions);
						},
						err => this.error = err
					);
				},
			),
			err => this.error = err,
			() => {
				this.isLoading = false;
			}
	}

	checkUserPermissions(userPermissions) {
		/* Check if user has a proper set of permissions under the container origin */
		if (userPermissions.hasOwnProperty(this.container.origin)) {
			if (this.appContext['activeOrigin'] != this.container.origin) {
				this.appContext.setActiveOrigin(this.container.origin);
			}
			/* Check user view permission */
			if (userPermissions[this.appContext['activeOrigin']]?.view.show?.includes('content') || userPermissions[this.appContext['activeOrigin']].view.superuser) {
				this.isValid = true;
			}
		}

		if (this.isValid) {
			this.redirectUser();
		} else {
			console.error('You do not have sufficient permissions to perform this action');
		}
	}

	redirectUser() {
		/* Default to generic container edit page if specified container type does not have dedicated edit page */
		if (containerTypeRoutes.hasOwnProperty(this.container.type.name)) {
			this.router.navigate(['/' + containerTypeRoutes[this.container.type.name], this.container.id], { replaceUrl: true })
		}
		else {
			this.router.navigate(['/containers' , this.container.id], { replaceUrl: true })
		}
	}

	ngOnDestroy() {
        this.userPermSub.unsubscribe();
    }

}