import { Pipe, PipeTransform } from '@angular/core';
import * as utilities from './utilities';


@Pipe({ name: 'uriScheme' })
export class UriSchemePipe implements PipeTransform {
    transform (value: string) {
        let arr = value.split(':');
        return arr.length > 1 ? arr[0] : '';
    }
}

@Pipe({ name: 'fileExt' })
export class FileExtPipe implements PipeTransform {
    transform (value: string) {
        let arr = (value || '').split('?')[0].split('.');
        return arr.length > 1 ? arr[arr.length - 1] : '';
    }
}

@Pipe({ name: 'fileName' })
export class FileNamePipe implements PipeTransform {
    transform (value: string) {
        let arr = (value || '').split('/');
        return arr.length > 0 ? arr[arr.length - 1] : '';
    }
}

@Pipe({ name: 'timeframe' })
export class TimeframePipe implements PipeTransform {
    transform (value: number) {
        return utilities.makeTime(value);
    }
}

/*
 * Strip snake case
 * Usage:
 *   value | rawcase
 * Example:
 *   {{ snake_case | rawcase }}
 *   formats to: snake case
*/

@Pipe({
  name: 'rawcase'
})
export class RawcasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.split('_').join(' ');
  }

}


@Pipe({
    name: 'labelToDisplay'
})

export class LabelToDisplayPipe implements PipeTransform {
    transform (value: any) {
        if (!value) {
            return '<undefined>';
        } else if (typeof(value) !== 'string') {
            return '';
        }
        value = value.toLowerCase()
            .replace(/_/gi, ' ')
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        return value;
    }
}
