import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http'
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { SchemaService } from '../../../content/services/schema/schema.service'
import { Container, Schema } from '../../../content/models';
import { EntityType } from '../../../content/models';
import { SchemaFormComponent } from '../../forms/schema-form/schema-form.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message } from 'primeng/api';
import { AppContext } from '../../../app.context';
import { handleError } from '../../../shared/utilities';

@Component({
    selector: 'schema-edit',
    templateUrl: 'schema-edit.component.html',
    styleUrls: ['schema-edit.component.css']
})
export class SchemaEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: SchemaFormComponent;
    @ViewChild('schema_schema') schemaEditor: JsonEditorComponent;
    @ViewChild('schema_data') dataEditor: JsonEditorComponent;
    private sub: any;
    public schema: Schema;
    public containers: Array<Container>;
    public error: HttpResponse<any>;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private validSections: Object = {};
    public schemaObject: Object = {};
    public dataObject: Object = {};
    public editorOptionsSchema: JsonEditorOptions;
    public editorOptionsData: JsonEditorOptions;
    public activeOrigin: string = '';

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private schemaService: SchemaService,
        public containerService: ContainerService,
        private appContext: AppContext
    ) { }

    ngOnInit(): void {
        this.activeOrigin = this.appContext.activeOrigin;

        this.editorOptionsSchema = new JsonEditorOptions;
        this.editorOptionsSchema.mode = 'tree';
        this.editorOptionsSchema.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsSchema.statusBar = true;
        this.editorOptionsSchema.onChange = () => this.changeJson('schema', this.schemaEditor);

        this.editorOptionsData = new JsonEditorOptions;
        this.editorOptionsData.mode = 'tree';
        this.editorOptionsData.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsData.statusBar = true;
        this.editorOptionsData.onChange = () => this.changeJson('data', this.dataEditor);
        this.sub = this.route.params.subscribe(params => {
            let id = +params['id'];
            this.isLoading = true;
            this.schemaService
                .get(id, true)
                .subscribe(
                    res => {
                        this.schema = res;
                        this.containers = this.schema?.containers;
                        this.initValues();
                        this.validateFormData();
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    validateFormData() {
        this.msgs = [];
        let valid = true;
        for (let field of ['schema', 'data']) {
            if (this.validSections[field.toLowerCase()] === false) {
                valid = false;
                this.msgs.push({
                    severity: 'error',
                    summary: `${field}: Invalid JSON`,
                    detail: 'Cannot save invalid JSON.'
                });
            }
        }
        return valid;
    }

    onContainerRemoved(removedContainer: Container) {
        this.containers = this.containers.filter(container => container.id !== removedContainer.id);
    }

    onSubmit(e) {
        console.log('submit', e);
        let valid = this.validateFormData();
        if (!valid) {
            return;
        }
        this.requiredMsgs = [];
        this.schemaService
            .save(this.schema)
            .subscribe(
                res => {
                    console.log(res);
                    this.schema = res;
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.initValues();
                },
                err => {
                    this.requiredMsgs = [];
                    let errorMessages = handleError(err);
                    errorMessages.forEach(errorMessage => {
                      this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: errorMessage });
                    })
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    private initValues() {
        if (!this.schema['schema']) {
            this.schema['schema'] = {};
        }
        if (!this.schema['data']) {
            this.schema['data'] = {};
        }

        this.schemaObject =  this.schema['schema'];
        this.dataObject = this.schema['data'];
    }

    private changeJson(field: string, editor: JsonEditorComponent) {
        try {
            var editorData = editor.get();
        } catch (e) {
            this.validSections[field] = false;
            return;
        }
        this.validSections[field] = true;
        this.schema[field] = editorData;
    }

}
