<div *ngxPermissionsOnly="['view.show.persons']" class="card card-w-title top-level-card">
    <h1>Persons</h1>
        <p-toolbar>
            <div class="ui-g">
                <div class="ui-g-4">
                    <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (input)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" style="text-align:center;height:100%;width:100%;"/>
                </div>
                <div class="ui-g-4">
                    <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                </div>
                <div class="ui-g-4">
                  <button pButton type="button" label="Create" [routerLink]="['/settings/persons', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;" data-element-id="app-settings-components-persons-create"></button>
                </div>
                <spinner *ngIf="isLoading"></spinner>
            </div>
        </p-toolbar>
    <p-table [columns]="columns" #dt
            [value]="containers"
            [globalFilterFields]="['data.last_name','data.first_name', 'guid', 'status']"
            [rows]="10"
            [paginator]="true"
            paginatorPosition="both"
            dataKey="guid"
            selectionMode="single"
            (onRowSelect)="onRowSelect($event)"
            [(selection)]="container"
            [responsive]="true"
            styleClass="p-datatable-gridlines"
            expandableRows="true">
        <ng-template pTemplate="header" let-columns="columns">
            <tr>
                <th>Last Name</th>
                <th style="width: 9em">First Name</th>
                <th style="width: 9em">Age</th>
                <th style="width: 9em">Gender</th>
                <th>GUID</th>
                <th style="width: 6em">Status</th>
                <th style="width: 6em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-person let-expanded="expanded" let-columns="columns">
            <tr *ngIf="!isLoading">
                <td style="word-break: break-all;">{{person.data.last_name}}</td>
                <td>{{person.data.first_name}}</td>
                <td>{{person.data.age}}</td>
                <td>{{person.data.gender}}</td>
                <td style="word-break: break-all;">{{person.guid}}</td>
                <td><div class="label" [ngClass]="statusLabelClass(person.status)">{{person.status|titlecase}}</div></td>
                <td>
                    <div class="text-center">
                        <button pButton type="button" label="Edit" class="p-button-primary" [routerLink]="['/settings/persons', person.id]" icon="pi pi-pencil"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
