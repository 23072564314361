import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { CollectionFormComponent } from '../../forms/collection-form/collection-form.component';
import { Message } from 'primeng/api';
import { containerTypeRoutes } from '../../../shared/enum';

@Component({
    selector: 'media-object-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'media-object-create.component.html',
    styleUrls: ['media-object-create.component.css']
})
export class MediaObjectCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: CollectionFormComponent;
    private sub: any;
    public container: Container = new Container();
    private error: HttpResponse<any>;
    public isLoading: boolean = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('Media object create initialized');
    }

    ngOnDestroy() {

    }

    onSubmit(e) {

        if (containerTypeRoutes.hasOwnProperty(e.container.type.name)) {
			this.router.navigate(['/' + containerTypeRoutes[e.container.type.name], e.container.id], { replaceUrl: true })
		}
		else {
			this.router.navigate(['/containers' , e.container.id], { replaceUrl: true })
		}
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onCancel(e){
      this.location.back()
    }
}
