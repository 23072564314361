import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
//import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { DragulaService } from 'ng2-dragula';
//import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { SelectItem } from 'primeng/api';
import { AppContext } from '../../../app.context';

@Component({
    selector: 'settings-platforms',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'settings-platforms.component.html',
    styleUrls: ['settings-platforms.component.css']
})
export class SettingsPlatformsComponent implements OnInit {

    public containers = [];
    public isLoading = false;
    private displayDialog = false;
    public container: Container;

    private entityTypes = [];
    private entityTypeId;
    private statusList: Array<SelectItem> = [];
    private selectedStatus: SelectItem = null;
    private filteredStatus = 'published';

    constructor(
        public containerService: ContainerService,
        private entityTypeService: EntityTypeService,
        private appContext: AppContext
    ) {}

    ngOnInit() {
        this.isLoading = true;

        this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));

        if (this.appContext.componentState['platform_view']) {
            this.filteredStatus = this.appContext.componentState['platform_view']['status'];
        }
        
        if (this.filteredStatus) {
            this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
        } 

        // if (this.filteredStatus) {
        //     if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
        //         this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
        //     }
        // } else if (this.selectedStatus) {
        //     this.selectedStatus = null;
        // }

        this.loadResults();
    }

    loadResults() {
        this.containerService
            .filterByTypeNameAndStatus('platform', -1, true, this.filteredStatus)
            .subscribe(
                res => {
                    this.containers = res;
                    console.log(res);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
    }

    onChangedFilteredStatus(event) {
        this.filteredStatus = event.value ? event.value.value : '';
        this.setSearchParams();
        this.loadResults();
    }

    statusLabelClass(status) {
        return this.containerService.getStatusLabelClass(status);
    }

    setSearchParams() {
        if (!this.appContext.componentState['platform_view']) {
            this.appContext.componentState['platform_view'] = {};
        } 

        this.appContext.componentState['platform_view'] = {
            'status': this.filteredStatus || ''
        };

    }
}
