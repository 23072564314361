import { EntityType } from './entity-type';

export class Resource {
    id = -1;
    guid = '';
    namespace = '';
    subtype = '';
    uri = '';
    origin ='';
    status = 'new';
    data: Object = {};
    tags = []
    type: EntityType = new EntityType();
}
