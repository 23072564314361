<page-header title="Edit Profile{{!isLoading && container ? ': ' + container.data['name'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

<div class="card card-w-title">
    <h1>Edit Profile{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <profile-form #form class="ui-g-12" [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></profile-form>
        <hr>
        <div class="ui-g-12">
          <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name"
              [sortOptions]="resourceSortOptions">
              <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload" *ngxPermissionsOnly="['view.show.upload']"></button>
              <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource', 'upload']" *ngxPermissionsOnly="['object.write.all']"></trigger-pipeline-modal-form>
              <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
          </resource-datarow>
        </div>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing" data-element-id="app-settings-components-profile-edit-advanced-edit"></object-advancedview>
        </div>

    </div>
</div>
