<div class="card card-w-title top-level-card">
    <h1>Application Views</h1>
    <div class="ui-g card-content">
        <div class="ui-g-12">
            <p-tabView (onChange)="onTabChange($event)">
                <p-tabPanel *ngFor="let container of containers; let i = index" 
                            [header]="container.label"
                            leftIcon="pi pi-check" 
                            [selected]="i==0">
                    <container-dataview 
                            [containers]="views" 
                            rows="50"
                            filterBy="data.name"
                            [sortField]="sortField" 
                            [sortOrder]="sortOrder" 
                            [isLoading]="isLoading"
                            (statusSelectionChange)="onStatusSelectionChange($event)">
                    </container-dataview>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
