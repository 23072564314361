<spinner *ngIf="isLoading"></spinner>
<p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <p-panel class="p-padding-sm">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Profiles</span>
        </p-header>
        <div class="p-padding-md">
            <div class="p-grid">
                <div class="p-col">
                    <label for="title">Template</label>
                    <select [(ngModel)]="selectedTemplate" name="template" class="form-control" (ngModelChange)="onTemplateChange()">
                        <option *ngFor="let template of templateProfiles" [value]="template.id">{{template.data.name}}</option>
                    </select>
                </div>
                <div class="p-col">
                    <label for="title">Series</label>
                    <select [(ngModel)]="seriesContainerId" name="filteredSeriesList" class="form-control"
                        (ngModelChange)="onSeriesChange()">
                        <ng-container *ngFor="let item of filteredSeriesList">
                            <option *ngIf="item.status=='published'" [value]="item.id">{{item.label}}</option>
                            <option *ngIf="item.status!='published'" [value]="item.id">{{item.label}} ({{item.status}})</option>
                        </ng-container>
                    </select>
                </div>
                <div class="p-col" *ngIf="additionalParentsVisible">
                    <label for="title">Additional Parents</label>
                    <container-search-autocomplete (onValueChange)="setAdditionalParents($event)" [filterDefinition]="additionalParentsFilter"
                        [enabled]="additionalParentsEnabled" [multiSelectEnabled]="additionalParentsMultiselect"></container-search-autocomplete>
                </div>
            </div>
            <label for="title">Title</label>
            <input type="text" class="form-control" required [(ngModel)]="container.data['title']" name="title"
                placeholder="Required">
        </div>
    </p-panel>

    <p-panel class="p-padding-sm">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Assets</span>
        </p-header>
        <p-fileUpload #uploadComponent name="uploadAssets" class="upload-assets-simple-form nested-panel" customUpload="true"
            (uploadHandler)="onUpload($event)" multiple="multiple" [showUploadButton]="false" [showCancelButton]="false"
            *ngIf="!isUploading">
            <ng-template pTemplate="toolbar">
                <div class="ui-g">
                    <div class="ui-g-5">File Name</div>
                    <div class="ui-g-2">Size</div>
                    <div class="ui-g-5">Tags</div>
                </div>
            </ng-template>
            <ng-template let-file pTemplate="file">
                <div class="ui-g file-details">
                    <div class="ui-g-5 file-name">{{file.name}}</div>
                    <div class="ui-g-2 file-size">{{getSizeInMegaBytes(file) | number:'1.2-2'}} MB</div>
                    <div class="ui-g-4 file-tags">
                        <container-resource-tags [fileToUpload]='file' [tagTypeFilterList]="['all']" [hideTagChips]="true"
                            [hideTagLabel]="true"></container-resource-tags>
                    </div>
                    <div class="ui-g-1 file-remove">
                        <button icon="fa fa-close" class="p-button-danger" pButton type="button" (click)="removeFile(file)"></button>
                    </div>
                </div>
            </ng-template>
        </p-fileUpload>
    </p-panel>
    <p-toolbar class="p-padding-sm">
        <div class="p-padding-sm p-fullwidth">
            <button pButton type="submit" label="Upload" icon="pi pi-check" style="float:right;" [disabled]="!submitEnabled"></button>
        </div>
    </p-toolbar>
</form>