<div *ngIf="selectedContainer" class="reportWrapper ui-g-12" #reportWrapper>
    <div [ngClass]="autoRefreshSliderActive ? 'autorefresh': ''">
            <h1 class="ui-g-12" style="padding-top:0;">{{selectedContainer ? selectedContainer.data['category'] + ': ' + selectedContainer.data['label'] : ''}}</h1>
            <div class="switch" *ngIf="autoRefreshSliderActive">
                <label>Auto Update: </label>
                <p-inputSwitch [(ngModel)]="autoRefreshEnabled" ></p-inputSwitch>
            </div>
    </div>

    <div [ngClass]="showReportFilters ? 'report ui-g-12 ui-lg-8 ui-xl-9': 'report ui-g-12'" #chartContainer>
        <metrics-report-advanced-pie-graph *ngIf="selectedContainer.data['render_type'] == 'advanced_pie_graph'" 
            class="ui-g-12 ui-g-nopad" 
            [reportData]="reportData" 
            [renderOptions]="selectedContainer.data['render_options']" 
            [view]="viewSize">
        </metrics-report-advanced-pie-graph >

        <metrics-report-advanced-pie-grid *ngIf="selectedContainer.data['render_type'] == 'advanced_pie_grid'" 
            class="ui-g-12 ui-g-nopad" 
            [reportData]="reportData" 
            [renderOptions]="selectedContainer.data['render_options']" 
            [view]="viewSize">
        </metrics-report-advanced-pie-grid >

        <metrics-report-line-graph *ngIf="selectedContainer.data['render_type'] == 'line_graph'" 
            class="ui-g-12 ui-g-nopad" 
            [reportData]="reportData" 
            [renderOptions]="selectedContainer.data['render_options']" 
            [view]="viewSize">
        </metrics-report-line-graph>

        <metrics-report-horizontal-bar-graph *ngIf="selectedContainer.data['render_type'] == 'horizontal_bar_graph'" 
            class="ui-g-12 ui-g-nopad" 
            [showReportDetails]="showReportDetails" 
            [reportData]="reportData"  
            [renderOptions]="selectedContainer.data['render_options']" 
            [view]="viewSize">
        </metrics-report-horizontal-bar-graph>

        <metrics-report-number-cards *ngIf="selectedContainer.data['render_type'] == 'number_cards'" 
            class="ui-g-12 ui-g-nopad" 
            [reportData]="reportData" 
            [renderOptions]="selectedContainer.data['render_options']" 
            [view]="viewSize">
        </metrics-report-number-cards>

        <metrics-report-polar-graph *ngIf="selectedContainer.data['render_type'] == 'polar_graph'" 
            class="ui-g-12 ui-g-nopad" 
            [reportData]="reportData" 
            [renderOptions]="selectedContainer.data['render_options']" 
            [view]="viewSize">
        </metrics-report-polar-graph>
        <div class="ui-g-12"  style="padding:0;">
            <metrics-reports-data-table 
                class="ui-g-12 ui-g-nopad" 
                *ngIf="(selectedContainer.data['render_tabular_view'] || selectedContainer.data['render_type'] == 'horizontal_bar_graph') && showTable" 
                [reportData]="reportData"
                [renderOptions]="selectedContainer.data['render_options']" >
            </metrics-reports-data-table>
        </div>

        <div *ngIf="showReportDetails && selectedContainer.data['description']" class="ui-g-12 text-center">
            {{ selectedContainer.data['description'] }}
        </div>
    </div>

    <div [ngClass]="showReportFilters ? 'ui-g-6 ui-lg-4 ui-xl-3': 'p-disabled'">
        <metrics-reports-filter-bar 
            [showFilters]='showReportFilters' 
            [reportContainer]='selectedContainer' 
            (onFilterSelected)="onFilterParamChange($event)">
        </metrics-reports-filter-bar>
    </div>
</div>
