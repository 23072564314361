import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Container, EntityType, Tag } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { TagService } from '../../services/tag/tag.service';
import { AppContext } from '../../../app.context';

@Component({
    selector: 'relation-tag-form',
    providers: [
        ContainerService,
        TagService
    ],
    templateUrl: 'relation-tag-form.component.html',
})

export class RelationTagFormComponent implements OnInit {
    @Input() public showTagDialog: boolean;
    @Input() private parentId: number;
    @Input() private container: Container;
    @Output() private onCloseTagDialog: EventEmitter<any> = new EventEmitter();
    public isLoadingTags = false;
    private selectedTags: Tag[];
    private tags : Tag[];
    private tagsDict = {};
    private selectedTagsDict = {};
    private tagsTypes: any;

    constructor
        (
        private containerService: ContainerService,
        private tagService: TagService,
        private appContext: AppContext,
        ) { }

    ngOnInit() {
        if (!this.appContext.componentState['tags_dict'] || !this.appContext.componentState['tags_types']){
            this.initFetchTags();
        } else {
            this.tagsTypes = this.appContext.componentState['tags_types'];
            this.tagsDict = this.appContext.componentState['tags_dict'];
        }
        this.selectedTags = this.container['relation_tags'];
        this.selectedTagsDict = this.splitTagsByType(this.selectedTags);
    }

    initFetchTags() {
        this.isLoadingTags = true;
        this.tagService
        .list()
        .subscribe(
            res => {
                this.tags = res;
                this.tagsTypes = this.getTagTypes(this.tags);
                this.appContext.componentState['tags_types'] = this.tagsTypes;
                this.tagsDict = this.splitTagsByType(this.tags);
                this.appContext.componentState['tags_dict'] = this.tagsDict;
            },
            err => console.log(err),
            () => this.isLoadingTags = false
        )

    }

    capitalize(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    onSaveTags() {
        this.selectedTags = [];
        this.tagsTypes.forEach(tagType => {
            if (this.selectedTagsDict[tagType]) {
                this.selectedTagsDict[tagType].forEach(tag => {
                    this.selectedTags.push(tag);
                });
            }
        });
        let tagArray = this.selectedTags.map(r => r.value);
        this.containerService
            .setRelationTagsById(this.parentId, this.container.id, tagArray)
            .subscribe(
            res => {
                this.container['relation_tags'] = this.selectedTags;
                this.onCloseTagDialog.emit();
            },
            err => console.log(err)
        );
    }

    fetchTags() {
        this.isLoadingTags = true;
        this.tagService
        .list()
        .subscribe(
            res => {
                this.tags = res;
                this.tagsTypes = this.getTagTypes(this.tags);
                this.selectedTagsDict = this.splitTagsByType(this.selectedTags);
                this.tagsDict = this.splitTagsByType(this.tags);
            },
            err => console.log(err),
            () => this.isLoadingTags = false
        )

    }

    getTagTypes(tagArray) {
        var tagsTypes = [];
        tagArray.forEach(tag => {
            if (!(tag.type in tagsTypes)){
                tagsTypes.push(tag.type);
            }

        });
        var tagSet = new Set(tagsTypes);
        return tagSet;
    }

    splitTagsByType(tagsArray) {
        var tagsDict = {};
        tagsArray.forEach(tag => {
            if (!(tag.type in tagsDict)){
                tagsDict[tag.type] = [];
            }
            tagsDict[tag.type].push(tag);
        });
        return tagsDict;
    }

    hideForm(){
        this.onCloseTagDialog.emit();
    }

}
