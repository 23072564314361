import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Container } from '../../../content/models';
import { MetricsService } from '../../services/metrics.service';

import {genre} from '../../../shared/enum';

import { Message } from 'primeng/api';

@Component({
    selector: 'metrics-reports-filter-enum-pulldown',
    providers: [
        MetricsService
    ],
    templateUrl: 'metrics-reports-filter-enum-pulldown.component.html',
    styleUrls: ['metrics-reports-filter-enum-pulldown.component.css']
})
export class MetricsReportsFilterEnumPulldownComponent implements OnInit {

    @Input() filterDefinition: any;
    @Output() onValueChange: EventEmitter<any> = new EventEmitter();

    private query: any;
    public optionValues: any[] = [];
    private error: any;

    public selectedValues: any[] = [];

    private enumName = "";

    constructor(
        private metricsService: MetricsService,
    ) { }

    ngOnInit() {
        this.enumName = this.filterDefinition['options']['enum_name']
        
        if (this.enumName === 'genre'){
            this.optionValues = genre
        }
        else{
            console.error('Error: Specified filter list (enum_name) does not exist.');
        }
    }

    onChange() {
        this.onValueChange.emit({
            params: this.buildFilterTypeMap()
        });

    }
    buildFilterTypeMap() {
        let filter_key = this.filterDefinition['filter'];
        let selectedValuesMap = [];
        let value_array = []
        this.selectedValues.forEach(value => {
            value_array.push(value.value)
        })
        selectedValuesMap.push({ [filter_key]: value_array })
        return selectedValuesMap
    }

    onDeselect(event) { }

}
