import { Component, OnInit,EventEmitter,Input,Output,SimpleChanges} from '@angular/core';
import { ratings } from '../../../shared/enum';

@Component({
    selector: 'container-ratings-pulldown',
    templateUrl: 'container-ratings-pulldown.component.html',
    styleUrls: ['container-ratings-pulldown.component.css']
})
export class ContainerRatingsPulldownComponent implements OnInit {

    public ratingsList =[];
    public selectedRating;
    public enabled = true;

    @Input () model;
    @Output() modelChange = new EventEmitter<any>();
    @Input() system: any;

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.system && changes['system'] && changes['system'].previousValue != changes['system'].currentValue)
        {
            if (this.system in ratings)
            {
               this.selectedRating = this.model
               this.ratingsList = ratings[this.system]
            }
            else
            {
               this.enabled = false;
            }
        }
    }

    onChangeSelectedValue(event)
    {
        this.model = this.selectedRating
        this.modelChange.emit(this.model);
    }


}
