import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { Tag } from '../../../content/models';
import { TagService } from '../../../content/services/tag/tag.service';
import { MessageService } from 'primeng/api';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'tags',
  providers: [
    TagService
],
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  public tags:Tag[];
  public totalRecords:number
  public limit:number = 0
  private rowOffset:number = 0
  private filterText:string;
  private timer;
  public isLoading:boolean

  constructor(
    private tagService: TagService,
    private confirmationService: ConfirmationService,
    private msgService: MessageService,
    private appContext: AppContext,
  ) {}

  ngOnInit() {
    this.isLoading = true
  }

  loadTags(event: LazyLoadEvent) {
    this.rowOffset  = event ? event.first: this.rowOffset
    this.filterText = event?.globalFilter===null ? null : event.globalFilter

    if (event.globalFilter) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {this._fetchTags()}, 1000);
    } else {this.filterText=null;this._fetchTags()}
  }

  _fetchTags() {
    let args:Array<any> = [this.appContext.activeOrigin, this.limit, this.rowOffset,'type']
    args = this.filterText ? [...args, 'type_startwith', this.filterText]:args
    this.tagService.list(...args).subscribe(
      res => {
          this.tags = res.results;
          this.totalRecords = res['count']
          res?.next && this.limit === 0 ? this._setPageLimit(res.next): null
      },
      err => this.isLoading = false,
      () => this.isLoading= false
    );
  }

  _setPageLimit(next: string):void {
      this.limit = parseInt(/limit=([^&]+)/.exec(next)[1])
  }

  onRemove(tag){
    this.msgService.clear()
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      key: 'deleteTag',
      accept: () => {
        this.isLoading = true;
        this.tagService.delete(tag).subscribe(
          res => this._fetchTags(),
          err => this.isLoading = false,
          () =>  {
            this.isLoading = false
            this.msgService.add({severity: 'success', summary:'', detail:'Deleted successfully' })
          }
        );
      },
      reject: null,
  });

  }
}
