import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Container, EntityType } from '../../../content/models';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { AppContext } from '../../../app.context';

@Component({
  selector: 'notification-form',
  providers: [
    ContainerService,
    EntityTypeService
  ],
  templateUrl: './notification-form.component.html',
  styleUrls: []
})
export class NotificationFormComponent implements OnInit {
  @Input() container: Container;
  @Output() formSubmit: EventEmitter < Container > = new EventEmitter();
  @Output() formCancelled: EventEmitter < any > = new EventEmitter();
  @Output() formFailed: EventEmitter < any > = new EventEmitter();
  @ViewChild('ngForm') ngForm: NgForm;

  public isLoading = false;
  private entityTypes;
  private entityType;
  private entityTypeId;
  public filteredType;
  public selectedType: EntityType = null;
  public statusList = [];
  public modes = ["send-one", "send-multiple", "send-manual"]
  public notification_types = ["push", "email", "sms", "in-app"]

  constructor(
    private entityTypeService: EntityTypeService,
    private msgService: MessageService,
    private appContext: AppContext,
   ){ }

  ngOnInit(): void {
    this.isLoading=true;
    this.statusList = ContainerService.statusList.map(s => ({ label: s, value: s }));
    // *** Initialize form fields ***
    this.container.origin = this.container.origin ? this.container.origin : this.appContext.activeOrigin
    this.container.data['mode'] = this.container.data['mode']? this.container.data['mode'] : this.modes[2]
    this.container.data['notification_type'] = this.container.data['notification_type']? this.container.data['notification_type'] : this.notification_types[0]
    this.container.data['template'] = this.container.data['template']? this.container.data['template'] : ''
    this.container.data['query'] = this.container.data['query']? this.container.data['query'] : ''
    this.container.data['evaluate_frequency'] = this.container.data['evaluate_frequency']? this.container.data['evaluate_frequency'] : 120


    this.entityTypeService.list().subscribe(
      (res: any) => {
          this.entityTypes = res
          this.entityType = this.entityTypes.find(t => t.name === 'notification')
          this.filteredType = res.map(s => ({ label: s.name, value: s.name })).filter(t => t.label !== 'notification')
          this.container.type = this.entityType
          this.entityTypeId = this.entityType.id;

      },
      err => this.isLoading = false,
      () => this.isLoading = false
    );
  }

  onSubmit() {
    this.msgService.clear()
    let errmsg = [];
    let valid = this.ngForm.form.status === 'VALID';

    if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
      errmsg.push({ severity: 'error', summary: 'Error', detail: 'Please enter name for notification.' });
      valid = false;
    }

    if (!('namespace' in this.container.data) || (!this.container.data['namespace']) || !/\S/.test(this.container.data['namespace'])) {
      errmsg.push({ severity: 'error', summary: 'Error', detail: 'Please enter namespace for notification' });
      valid = false;
    }

    if (valid) {
      this.ngForm.form.markAsPristine();
      this.formSubmit.emit(this.container);
    } else {this.formFailed.emit(errmsg)}
  }


  resetToDefaultState() {
    this.ngForm.reset();
  }

  isPristine() {
    return this.ngForm.form.pristine;
  }

  onCancel(){
    this.formCancelled.emit()
  }

}
